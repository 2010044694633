import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {delay, filter, first, flatMap, map, retryWhen, take, takeUntil, tap} from 'rxjs/operators';
import {AssemblyListService} from './assembly-list.service';
import {PcbMetaService} from './assembly-view/pcb/pcb-meta.service';
import {NGXLogger} from 'ngx-logger';

import {
    Assembly,
    AssemblyFile,
    AssemblyPreviewMessage,
    AssemblyReference,
    AssemblyStageUpdateMessage,
    AssemblyUpdate,
    AssemblyWithReference,
    AssemblyWithShare,
    FileLifecycleMessage,
    FileLockMessage,
    FilePreviewMessage,
    FilesAddedMessage,
    FileType,
    LifeCycleStage,
    LifeCycleStageName,
    LifecycleStageStatus,
    SharedAssembly,
    SharedAssemblyReference,
    StageStatusName,
    StreamMessage
} from '../../../pcb-common/assembly/assembly';
import {LoginService} from '../../../common/auth/login.service';
import {EnvironmentService} from '../../../common/env/environment.service';
import {ProgressBarService} from '../../../common-ui/progressbar/progress-bar.service';
import {SegmentService} from '../../../services/segment.service';


@Injectable({
    providedIn: 'root'
})
export class AssemblyService implements OnDestroy {
    public assemblySubject: BehaviorSubject<AssemblyWithReference> = new BehaviorSubject<AssemblyWithReference>(null);
    public shareSubject: BehaviorSubject<SharedAssembly> = new BehaviorSubject<SharedAssembly>(null);
    public fileSubject: BehaviorSubject<AssemblyFile[]> = new BehaviorSubject([]);

    public assemblyChanged: Subject<string> = new Subject<string>();

    protected _unsubscribeAll: Subject<any> = new Subject<any>();

    private assemblyEndpoint = '/assembly/assemblies';
    private sharesEndpoint = '/assembly/shares';
    private ws: WebSocket;

    constructor(
        private _httpClient: HttpClient,
        private _loginService: LoginService,
        private _progress: ProgressBarService,
        private logger: NGXLogger,
        private _assList: AssemblyListService,
        private _meta: PcbMetaService,
        private env: EnvironmentService,
        private _segment: SegmentService
    ) {
    }

    public getFilteredFiles(fileFilter: (file: AssemblyFile) => boolean): Observable<AssemblyFile[]> {
        return this.fileSubject.pipe(map(a => a.filter(fileFilter)));
    }

    public getCurrentAssembly(): Observable<Assembly> {
        return this.assemblySubject.pipe(
            filter(a => a != null),
            first(),
            map(a => a.assembly)
        );
    }

    public getAssemblySubject(): Observable<Assembly> {
        return this.assemblySubject.pipe(
            filter(a => a != null),
            map(a => a.assembly)
        );
    }

    public getCurrentReferencedAssembly(): Observable<AssemblyWithReference> {
        return this.assemblySubject.pipe(
            filter(a => a != null),
            first()
        );
    }

    public getReferencedAssemblySubject(): Observable<AssemblyWithReference> {
        return this.assemblySubject.pipe(
            filter(a => a != null)
        );
    }

    ngOnDestroy(): void {
        this.assemblyChanged.next('');
        this.assemblyChanged.complete();

        this._unsubscribeAll.next('');
        this._unsubscribeAll.complete();
    }

    public getLifecycleUpdates(): Observable<[Assembly, Map<LifeCycleStageName, LifeCycleStage>]> {
        return this.assemblySubject.pipe(filter(x => !!x), flatMap(ass => {

            return this._assList.getLifecycleUpdatesForAssembly(ass.assembly.id).pipe(map(lc => {
                const r: [Assembly, Map<LifeCycleStageName, LifeCycleStage>] = [ass.assembly, lc];
                return r;
            }));
        }));
    }

    public getLifecycles(): Observable<Map<LifeCycleStageName, LifeCycleStage>> {
        return this.assemblySubject.pipe(map(ass => {
            const m = new Map<LifeCycleStageName, LifeCycleStage>();
            if (ass?.assembly.currentVersion?.lifecycles) {
                ass.assembly.currentVersion.lifecycles.forEach(lc => {
                    m.set(lc.name, lc);
                });
            }

            return m;
        }));
    }

    public setLocalLifecycle(name: LifeCycleStageName, status: StageStatusName, percent: number = null): void {
        const curAss = this.assemblySubject.getValue();
        curAss.assembly.currentVersion.lifecycles = this.replaceStage(curAss.assembly.currentVersion.lifecycles, name, status, percent);
        this.assemblySubject.next(curAss);

        const msg: AssemblyStageUpdateMessage = {
            assembly: curAss.assembly.id,
            lifecycle: curAss.assembly.currentVersion.lifecycles,
            _type: AssemblyStageUpdateMessage.jsonName
        }
        this._assList.makeLocalAssemblyStageUpdate(msg);
    }

    private replaceStage(stages: LifeCycleStage[], name: LifeCycleStageName, status: StageStatusName, percent: number = null): LifeCycleStage[] {
        return stages.map(stage => {
            if (stage.name === name) {
                const obj = new LifeCycleStage();
                const newStatus = new LifecycleStageStatus();
                newStatus.name = status;
                newStatus.percent = percent;

                obj.name = name;
                obj.status = newStatus;
                obj.history = stage.history;

                return obj;
            } else {
                return stage;
            }
        });
    }


    public setAssembly(a: AssemblyWithReference): void {

        this.closeCurrentWebSocket()

        this.assemblySubject.next(a);
        this.assemblyChanged.next('');

        if (a.assembly.currentVersion.files) {
            this.fileSubject.next(a.assembly.currentVersion.files);
        } else {
            this.getFiles(a.assembly.id, a.assembly.currentVersion.id).subscribe(fls => this.fileSubject.next(fls));
        }

        this.fetchNotifications(this._loginService.getCurrentAuthToken());
        this._assList.getLifecycleUpdatesForAssembly(a.assembly.id).pipe(
            takeUntil(this.assemblyChanged)
        ).subscribe(update => {
            const curAss = this.assemblySubject.getValue();

            if (curAss) {
                update.forEach((lc, k) => {
                    let updated = false;
                    curAss.assembly.currentVersion.lifecycles = curAss.assembly.currentVersion.lifecycles.map(x => {
                        if (x.name === lc.name) {
                            updated = true;
                            return lc;
                        } else {
                            return x;
                        }
                    });

                    if (!updated) {
                        curAss.assembly.currentVersion.lifecycles.push(lc);
                    }
                });
                this.assemblySubject.next(curAss);
            }
        });
    }

    getFiles(id: string, version: String): Observable<AssemblyFile[]> {
        return this._httpClient.get<AssemblyFile[]>(this.env.environment.api + this.assemblyEndpoint + '/' + id + '/versions/' + version + '/files');
    }

    unlockCurrentAssembly(): Observable<any> {
        const unlockState = {
            locked: false
        };
        return this.getCurrentAssembly().pipe(flatMap(ass => {
            // ass.currentVersion.filesLocked = false;
            // this.assemblySubject.next(ass);
            this._segment.logData('unlock_assembly', {'assembly_id': ass.currentVersion.id});
            return this._httpClient.put<any>(this.env.environment.api + this.assemblyEndpoint + '/' + ass.id + '/versions/' + ass.currentVersion.id + '/fileapproval/status', unlockState);
        }));

    }

    public fetchAssemblyByShare(share: string): Observable<AssemblyWithReference> {
        this.fileSubject.next([]);
        this._progress.show();
        return this._httpClient.get<AssemblyWithShare>(this.env.api(this.sharesEndpoint, [share])).pipe(
            map(a => {

                const share: SharedAssembly = a.share

                const ref = new SharedAssemblyReference(
                    share.team,
                    share.id,
                    new AssemblyReference(
                        a.assembly.team,
                        a.assembly.id,
                        share.ref.version
                    )
                )
                const ea = new AssemblyWithReference(
                    a.assembly,
                    true,
                    ref
                )

                this.shareSubject.next(share)
                this.setAssembly(ea);
                this._progress.hide();
                return ea
            }, e => {
                this.logger.debug(e);
                this._progress.hide();
            })
        )
    }

    public fetchAssemblyByGid(gid: string): Observable<AssemblyWithReference> {
        this.fileSubject.next([]);

        return this._assList
            .getAssemblies()
            .pipe(
                tap(a => this.logger.debug(a)),
                map(a => a.find(ass => ass.gid === gid)),
                first(),

                flatMap(la => {
                    this.logger.debug('fetch assembly');

                    let q = gid;
                    if (la) {
                        q = la.id;
                    }

                    this._progress.show();
                    return this._httpClient.get<Assembly>(this.env.api(this.assemblyEndpoint, [q])).pipe(
                        retryWhen(errors => errors.pipe(
                            delay(1000),
                            take(10)
                        )),
                        takeUntil(this._unsubscribeAll),
                        map(a => {
                            const ref = new AssemblyReference(
                                this._loginService.getTeam(),
                                a.id,
                                a.currentVersion.id
                            )

                            const ea = new AssemblyWithReference(
                                a,
                                false,
                                ref
                            )
                            return ea
                        }),
                        tap(ea => {
                            this._progress.hide();
                            this.setAssembly(ea);
                        }, err => {
                            this.logger.debug(err);
                            this._progress.hide();
                        })
                    );
                })
            );
        // .subscribe(la => {
        //
        //     // }
        // });
    }

    public setName(name: string): void {
        this._progress.show();
        const update = new AssemblyUpdate();
        update.name = name;

        this.assemblySubject.pipe(first()).subscribe(a => {
            if (a != null) {
                const oldName = a.assembly.name;
                this._httpClient.put<any>(this.env.environment.api + this.assemblyEndpoint + '/' + a.assembly.id, update).subscribe(
                    r => {
                        this._progress.hide();
                        a.assembly.name = name;
                        this.assemblySubject.next(a);
                    },
                    error => {
                        this._progress.hide();
                        this.assemblySubject.next(a);
                    }
                );
            } else {
                this._progress.hide();
            }
        });
    }

    public setDescription(description: string): void {
        const update = new AssemblyUpdate();
        update.description = description;

        this.assemblySubject.pipe(first()).subscribe(a => {
            if (a != null) {
                this._httpClient.put<any>(this.env.environment.api + this.assemblyEndpoint + '/' + a.assembly.id, update).subscribe(
                    r => {
                        a.assembly.information.description = description;
                        this.assemblySubject.next(a);
                    },
                    error => {
                        this.assemblySubject.next(a);
                    }
                );
            }
        });
    }


    public setAssignee(userid: string) {
        this._progress.show();
        const update = new AssemblyUpdate();
        update.assignee = userid;
        this.doUpdate(update);
    }

    public setCustomer(customerID: string): void {
        this._progress.show();
        const update = new AssemblyUpdate();
        update.customer = customerID;

        this.doUpdate(update);
    }

    private doUpdate(update: AssemblyUpdate) {
        this.assemblySubject.pipe(first()).subscribe(a => {
            this.doAssemblyUpdate(a.assembly, update).subscribe(
                r => {
                    this._progress.hide();

                    if (update.customer) {
                        a.assembly.information.customer = update.customer;
                    }
                    if (update.assignee) {
                        a.assembly.information.assignee = update.assignee;
                    }
                    if (update.name) {
                        a.assembly.information.assignee = update.name;
                    }

                    this.assemblySubject.next(a);
                },
                error => {
                    this._progress.hide();
                    this.assemblySubject.next(a);
                }
            );
        });
    }

    private doAssemblyUpdate<A>(a: Assembly, update: AssemblyUpdate): Observable<any> {
        console.log('update assembly ', a, 'to ', update);
        return this._httpClient.put<any>(this.env.environment.api + this.assemblyEndpoint + '/' + a.id, update);
    }

    public addUploadingFile(fileName: string): void {
        const currentList = this.fileSubject.getValue();
        const assemblyFile = new AssemblyFile();

        assemblyFile.name = fileName;
        const fType = new FileType();
        fType.service = 'local';
        assemblyFile.fType = fType;
        currentList.push(assemblyFile);
        // this.logger.debug(currentList);
        this.fileSubject.next(currentList);
    }

    approveFiles(): Observable<any> {
        const ass = this.assemblySubject.getValue().assembly;
        this._segment.logData('approve_assembly', {'assembly_id': ass.id});
        return this._httpClient.put(this.env.environment.api + this.assemblyEndpoint + '/' + ass.id + '/versions/' + ass.currentVersion.id + '/files', null);
    }

    releaseAssembly(): Observable<any> {
        const eass = this.assemblySubject.getValue();
        const ass = eass.assembly;
        return this._httpClient.put(this.env.environment.api + this.assemblyEndpoint + '/' + ass.id + '/versions/' + ass.currentVersion.id + '/status', {name: 'OnlineViewer'})
            .pipe(tap(version => {
                eass.assembly.currentVersion = version
                this.assemblySubject.next(eass);
            }));
    }

    cloneAssembly(): Observable<any> {
        const ass = this.assemblySubject.getValue();
        return this._httpClient.post(this.env.environment.api + this.assemblyEndpoint + '/' + ass.assembly.id + '/clone', {})
            .pipe(tap(version => {
            }));
    }

    public downloadInternalFileTree(): Observable<HttpResponse<Blob>> {
        return this.getCurrentReferencedAssembly().pipe(flatMap(ass => {
            const assembly = ass.assembly
            let url = [assembly.id, 'versions', assembly.currentVersion.id, 'internalzip']
            return this._httpClient.get(
                this.env.files('assembly', url, {k: this._loginService.getCurrentAuthToken()}),
                {
                    observe: 'response',
                    responseType: 'blob',
                });
        }))
    }

    public downloadFilesAsZip(): Observable<HttpResponse<Blob>> {
        return this.getCurrentReferencedAssembly().pipe(flatMap(ass => {
            const assembly = ass.assembly
            if (ass.isShared) {
                return this._httpClient.get(
                    this.env.api("pcb", [ass.reference.getReferenceIdentifier().toString(), "download-zip"], {includePanel: true, projectName: assembly.name, isShare: true}),
                    {
                        observe: 'response',
                        responseType: 'blob',
                    }
                );
            } else {
                let url = [assembly.id, 'versions', assembly.currentVersion.id, 'zip']
                return this._httpClient.get(
                    this.env.files('assembly', url, {k: this._loginService.getCurrentAuthToken()}),
                    {
                        observe: 'response',
                        responseType: 'blob',
                    });
            }
        }));
    }

    public downloadFilesAsZipForAssembly(ass: Assembly): Observable<HttpResponse<Blob>> {
        return this._httpClient.get(
            this.env.files('assembly', [ass.id, 'versions', ass.currentVersion.id, 'zip'], {k: this._loginService.getCurrentAuthToken()}),
            {
                observe: 'response',
                responseType: 'blob',
            });

    }

    public refreshAssemblyPreview(ass: Assembly): Observable<any> {
        return this._httpClient.post(this.env.environment.api + this.assemblyEndpoint + '/' + ass.id + '/preview', {});
    }

    private fetchNotifications(token: String): void {

        this.closeCurrentWebSocket();

        this.ws = new WebSocket(
            this.env.environment.ws +
            this.assemblyEndpoint +
            '/' +
            this.assemblySubject.getValue().assembly.id +
            '/versions/' +
            this.assemblySubject.getValue().assembly.currentVersion.id +
            '/fileStream?k=' +
            token
        );

        const me = this;

        this.ws.onclose = evt => {
            if (!evt.wasClean) {
                this.fetchNotifications(token)
            }
        }

        this.ws.onmessage = function (event: MessageEvent): void {
            const data: StreamMessage = JSON.parse(event.data);

            if (data.m._type.endsWith('FilesChangedMessage') || data.m._type.endsWith('FilesAddedMessage')) {
                const f = data.m as FilesAddedMessage;
                let currentList = me.fileSubject.getValue();

                f.file.forEach(update => {

                    if (currentList.find(o => o.name === update.name)) {
                        currentList = currentList.map(cur => {
                            if (cur.name === update.name) {
                                return update;
                            } else {
                                return cur;
                            }
                        });
                    } else {
                        currentList.push(update);
                    }
                });

                me.fileSubject.next(currentList);

            } else if (data.m._type.endsWith('FileLifecycleMessage')) {
                const f = data.m as FileLifecycleMessage;
                if (f.assembly === me.assemblySubject.value.assembly.id) {
                    const currentList = me.fileSubject.getValue();
                    me.fileSubject.next(
                        currentList.map(f2 => {
                            if (f2.name === f.file) {
                                f2.lifecycles = f2.lifecycles.filter(x => x.name !== f.lifecycle.name);
                                f2.lifecycles.push(f.lifecycle);
                            }
                            return f2;
                        })
                    );
                }
            } else if (data.m._type.endsWith('FilePreviewMessage')) {
                const f = data.m as FilePreviewMessage;
                const currentList = me.fileSubject.getValue();

                me.fileSubject.next(
                    currentList.map(f2 => {
                        if (f2.name === f.file.name) {
                            f2.preview = f.file.preview + '&ts=' + Date.now();
                        }
                        return f2;
                    })
                );
            }
            if (data.t === 'file') {
            } else if (data.t === 'preview') {
                const f = data.m as AssemblyPreviewMessage;
                const a = me.assemblySubject.getValue();

                a.assembly.preview = f.preview + '&ts=' + Date.now();

                me.assemblySubject.next(a);
            }

            if (data.t === 'fileLock') {
                if (data.m._type.endsWith('FileLockMessage')) {
                    const f = data.m as FileLockMessage;
                    const a = me.assemblySubject.getValue();
                    a.assembly.currentVersion.filesLocked = f.locked;
                    me.assemblySubject.next(a);
                }
            }
        };
    }

    private closeCurrentWebSocket() {
        if (this.ws) {
            this.ws.onclose = function () {

            }
            this.ws.close();
        }
    }


}
