import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {
    private config: MatSnackBarConfig<any>;
    constructor(public snackBar: MatSnackBar) {
        this.config = new MatSnackBarConfig();
        this.config.duration = 2500;
        this.config.politeness = 'polite';
    }

    openSnackBar(message: string, closeText?: string): void {
        this.snackBar.open(message, closeText ? closeText : '', this.config);
    }

    openBlockingSnackBar(message: string, closeText: string): void {
        const config = new MatSnackBarConfig();
        config.politeness = 'off';
        this.snackBar.open(message, closeText?.toUpperCase(), config);
    }

    hideSnackBar(): void {
        this.snackBar.dismiss();
    }
}
