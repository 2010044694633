export class TimelineRequest {
    public since?: Date;
    public max?: number;
    public offset?: number;
    public filteredChangeCategories?: string[];
    public filteredChangeTypes?: string[];
    public filteredTypes: string[];
    public filteredReferences: string[];

    public excludeSystem: boolean;


    constructor(filteredTypes: string[], filteredReferences: string[]) {
        this.filteredTypes = filteredTypes;
        this.filteredReferences = filteredReferences;
    }

//     since: Option[Instant],
//     max: Option[Int],
//     offset: Option[Int],
//     filteredChangeCategories: Option[Seq[String]],
//     filteredChangeTypes: Option[Seq[String]],
//
//     filteredTypes: Seq[String],
//     filteredReferences: Seq[String]
}

export class Timeline {
    content: TimelineEvent[];
    users: { [key: string]: TimelineUserInfo; };
}

export class TimelineEvent {
    team: string;
    creator: TimelineUser;
    reference: TimelineReference;
    change: TimelineChange;
    time: Date;
//                          team: String,
//                           creator: TimelineUser,
//                           reference: TimelineReference,
//                           change: TimelineChange,
//                           time: Instant

}

// tslint:disable-next-line
class TimelineUser {
    _type: string;
}

class TimelineChange {
    changeCategory: string;
    changeType: string;
    _type: string;
}

export class SpecificTimelineChange extends TimelineChange {
    summary: string;
    description: string; // markdown
}

export class PredefinedTimelineChange extends TimelineChange {
    summaryIdentifier: string;
    descriptionIdentifier: string;
    params: Map<string, string>;
}


export class TimelineGuest extends TimelineUser {
    share: string;
    grantingUser: string;

}

export class TimelineSystemUser extends TimelineUser {
    id: string;
}


export class TimelineReference {
    entity: string;
    entityType: string;
}

export class TimelineUserInfo {
    id: string;
    name: string;
    avatar?: string;
}

export function buildAssemblyDashboardTimelineRequest(filterTypes: string [], assId: string, assVersion: string): TimelineRequest {
    const timelineRequest = new TimelineRequest(DefaultTimeLineEntities, [assId, assVersion]);
    timelineRequest.filteredChangeCategories = filterTypes;
    timelineRequest.excludeSystem = !filterTypes.includes(CollaborationEntities.SYSTEM);

    return timelineRequest;
}

export function buildTimelineRequestForID(filterTypes: string [], specId: string): TimelineRequest {
    const timelineRequest = new TimelineRequest(DefaultTimeLineEntities, [specId]);
    timelineRequest.filteredChangeCategories = filterTypes;
    timelineRequest.excludeSystem = !filterTypes.includes(CollaborationEntities.SYSTEM);

    return timelineRequest;
}


export enum CollaborationTimeLineView {
    ASSEMBLY_DASHBOARD,
    GERBER_VIEWER,
    SPECIFICATION_DASHBOARD,
    SPECIFICATION_VIEW,
    PANEL_DASHBOARD,
    PANEL_CREATOR_VIEW,
    WEBDFM_DASHBOARD,
    WEBDFM_VIEW,
    PRICING_CENTER_DASHBOARD,
    QUOTATION_DASHBOARD,


}

export enum CollaborationEntities {
    SYSTEM = 'system',
    ASSEMBLY = 'assembly',
    PCB = 'pcb',
    PCB_SPECIFICATION = 'pcbspecification',
    LAYERSTACK_USAGE = 'layerstackusage',
    CUSTOMER_PANEL = 'customerpanel',
    QUOTATION = 'quotation',
    QUOTATION_ITEM = 'quotationitem',
    DFM_VIOLATIONS = 'dfmviolations',
}

export const DefaultTimeLineEntities: string [] = [
    CollaborationEntities.ASSEMBLY,
    CollaborationEntities.PCB,
    CollaborationEntities.PCB_SPECIFICATION,
    CollaborationEntities.LAYERSTACK_USAGE,
    CollaborationEntities.CUSTOMER_PANEL,
    CollaborationEntities.QUOTATION,
    CollaborationEntities.DFM_VIOLATIONS,
    // CollaborationEntities.QUOTATION_ITEM,
    CollaborationEntities.SYSTEM,

];

export const DefaultTimeLineEntityNames: Map<string, string> = new Map<string, string>([
    [CollaborationEntities.SYSTEM, 'System'],
    [CollaborationEntities.ASSEMBLY, 'Assembly'],
    [CollaborationEntities.PCB, 'PCB'],
    [CollaborationEntities.PCB_SPECIFICATION, 'Spec.'],
    [CollaborationEntities.LAYERSTACK_USAGE, 'StackUp'],
    [CollaborationEntities.CUSTOMER_PANEL, 'Panel'],
    [CollaborationEntities.QUOTATION, 'Quotation'],
    [CollaborationEntities.QUOTATION_ITEM, 'Quotation item'],
    [CollaborationEntities.DFM_VIOLATIONS, 'WebDFM'],
]);


