import {PermissionActions, PermissionAllSymbol} from './permission-constants';

export class Permission {
    resourceClass: string[] = [];
    team: string[] = [];
    owner: string[] = [];
    resource: string[] = [];
    attribute: string[] = [];
    action: string[] = [];

    constructor(resourceClass?: string[], team?: string[], owner?: string[], resource?: string[], attribute?: string[], action?: string[]) {
        this.resourceClass = resourceClass ? resourceClass : [];
        this.owner = owner ? owner : [];
        this.team = team ? team : [];
        this.resource = resource ? resource : [];
        this.attribute = attribute ? attribute : [];
        this.action = action ? action : [];
    }

    public static fromString(s: String, user?: string, team?: string): Permission {
        const parts = s.split(':');

        if (!user) {
            user = '*';
        }
        if (!team) {
            team = '*';
        }

        while (parts.length < 6) {
            parts.push('*');
        }

        function wildcard(r: string[]): string[] {
            if (r.length === 0) {
                return ['*'];
            }
            if (r.length === 1 && r[0].trim() === '') {
                return ['*'];
            }

            return r;
        }

        return new Permission(
            wildcard(parts[0].split(',')),
            wildcard(parts[1].split(',').map(x => {
                if (x === '_') {
                    return team;
                } else {
                    return x;
                }
            })),
            wildcard(parts[2].split(',').map(x => {
                if (x === '_') {
                    return user;
                } else {
                    return x;
                }
            })),
            wildcard(parts[3].split(',')),
            wildcard(parts[4].split(',')),
            wildcard(parts[5].split(','))
        );
    }
}

export function permissionToString(permission: Permission): string {
    const str = [];
    str.push(permission.resourceClass.join());
    str.push(getPermissionTeams(permission));
    str.push(getPermissionOwners(permission));
    str.push(getPermissionResources(permission));
    str.push(getPermissionAttributes(permission));
    str.push(getPermissionActions(permission));
    return str.join(':');
}


export function getPermissionTeams(permission: Permission): string[] {
    if (!permission.team) {
        return [PermissionAllSymbol];
    }
    return permission.team.length > 0 ? permission.team : [PermissionAllSymbol];
}

export function getPermissionOwners(permission: Permission): string[] {
    if (!permission.owner) {
        return [PermissionAllSymbol];
    }
    return permission.owner.length > 0 ? permission.owner : [PermissionAllSymbol];
}

export function getPermissionResources(permission: Permission): string[] {
    if (!permission.resource) {
        return [PermissionAllSymbol];
    }
    return permission.resource.length > 0 ? permission.resource : [PermissionAllSymbol];
}

export function getPermissionAttributes(permission: Permission): string[] {
    if (!permission.attribute) {
        return [PermissionAllSymbol];
    }
    return permission.attribute.length > 0 ? permission.attribute : [PermissionAllSymbol];
}


export function getPermissionActions(permission: Permission): string[] {
    if (!permission.action) {
        return [PermissionAllSymbol];
    }
    return permission.action.length > 0 ? permission.action : PermissionActions;
}

export class Claim {
    permission: String;
}

