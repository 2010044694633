<div class="m-2 flex flex-row flex-wrap">

    <tag-button (tagClick)="collabUI.toggleFilterElement(but)" *ngFor="let but of collabUI.getDefaultTimeLineFilters()"
                [color]="getTagElement(but)?.color"
                [isActive]="(collabUI.subscribeTimeLineFilters() | async)?.includes(but)"
                [isButton]="true"
                [text]="getTagElement(but)?.text"
                class="px-1 pb-1"
    ></tag-button>
</div>

<div>
    <ng-container *ngIf="(colla.subscribeTimeline() | async) as tl">
        <div class="center m-4" fxLayout="column">
            <div class="content">
                <ng-container *ngFor="let event of tl.content">
                    <ng-container *ngIf="event.change._type.endsWith('PredefinedTimelineChange')">
                        <fuse-card [@animate]="{ value: '*', params: {  y: '-25px' }}"
                                   class="timeline-card flex flex-col mb-2 p-2">

                            <ng-container *ngIf="event.creator._type.endsWith('TimelineSystemUser')">

                                <div class="mb-2 flex flex-row center-aligned">
                                    <img
                                        *ngIf="tl.users[(event.creator | SystemUser).id]?.avatar as avatar else defAv"
                                        [src]="avatar" class="mat-icon rounded-full w-30 h-30"
                                        mat-card-avatar/>

                                    <ng-template #defAv>
                                        <img [src]="defaultAvatar" class="mat-icon rounded-full"
                                             mat-card-avatar/>
                                    </ng-template>

                                    <div class="mb-0 flex flex-col ml-2">
                                        <div
                                            class="text-base">{{ tl.users[(event.creator | SystemUser).id]?.name  }}</div>
                                        <div matTooltip="{{ event.time |  timeLineDate}}">
                                            <b>{{ event.time | timeAgo}}</b></div>
                                    </div>

                                </div>
                            </ng-container>

                            <ng-container *ngIf="event.creator._type.endsWith('TimelineGuest')">
                                <div class="mb-8 flex flex-row center-aligned">
                                    <img [src]="guestAvatar" class="mat-icon rounded-full"
                                         mat-card-avatar/>
                                    <div class="text-base">Guest</div>
                                </div>

                                <div class="mb-0 flex flex-col ml-2">
                                    <a href="{{getSharingURL((event.creator | GuestUser)?.share)}} ">
                                        Share
                                    </a>
                                    <div class="text-secondary pl-4">
                                        created by {{tl.users[(event.creator | GuestUser)?.grantingUser]?.name  }}
                                    </div>
                                    <div matTooltip="{{ event.time |  timeLineDate}}">
                                        <b>{{ event.time | timeAgo}}</b></div>

                                </div>
                            </ng-container>


                            <div>
                                <div
                                    [innerHTML]="((event.change | PredefinedChange).descriptionIdentifier | transloco:(event.change | PredefinedChange).params) ">

                                </div>
                                <div class="flex flex-row justify-end">
                                    <tag-button [color]="getTagElement(event.change.changeCategory)?.color"
                                                [text]="getTagElement(event.change.changeCategory)?.text"></tag-button>
                                </div>
                            </div>
                        </fuse-card>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>