import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'highlight'
})
export class HighlightPipe implements PipeTransform {


    constructor(private sanitizer: DomSanitizer) {

    }

    transform(value: string): any {
        this.sanitizer.sanitize(SecurityContext.HTML, value);

        // const dmnSignsList = DMNSpecialSigns;
        // // create list like group.variable (such as pcb.area)
        // const dmnVariables = [].concat(...this.dmnVariableList.map(l => l.variables.map(v => (l.name + VARIABLE_SEPARATOR + v.name))));

        // console.log('value ', value);

        value = this.replaceHighlightCssList(value, '#106CC8', 'Spec.', ['Specification', 'specification']);
        value = this.replaceHighlightCssList(value, '#004200', 'Panel', ['Panel', 'panel']);
        value = this.replaceHighlightCssList(value, '#283747', 'PCB', ['PCB', 'pcb']);
        value = this.replaceHighlightCssList(value, '#a0522d', 'Stack-Up', ['Layerstack', 'layerstack']);
        value = this.replaceHighlightCssList(value, '#CD5C5C', 'Assembly', ['Assembly', 'assembly']);
        value = this.replaceHighlightCssList(value, '#581845', 'Quotation', ['Quotation', 'quotation', 'Quotationitem', 'quotationitem']);


        return this.sanitizer.bypassSecurityTrustHtml(value);
    }

    replaceHighlightCssList(value: string, color: string, text: string, list: string[]): string {

        list.forEach(sign => {
            value = value.split(sign)
                .join('<span style="color:' + color + '; font-weight: bolder; background-color: #d1d1d1; padding: 2px 3px;border-radius: 2px;">' + text + '</span>');
        });
        return value;
    }


}
