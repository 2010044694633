import {EnvironmentService} from '../../common/env/environment.service';

export class TeamUtils {
    // TODO: use EnvironmentService
    public static getTeamFromSubDomain(env: EnvironmentService): string {
        let fixedTeam = env.environment.fixed_team;
        if (fixedTeam && typeof fixedTeam === 'string' && fixedTeam.toLowerCase() != 'false' && fixedTeam.toLowerCase() != 'true') {
            return fixedTeam;
        } else {
            const domain = window.location.hostname;
            let teamName: string;
            if (domain.indexOf('.') < 0 ||
                domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www') {
                teamName = '';
            } else {
                teamName = domain.split('.')[0];
            }
            return teamName;
        }
    }
}
