import {UUID} from 'angular2-uuid';
import {Project} from './project';

export interface AbstractAssemblyReference {
    getReferenceIdentifier(): UUID;

    isShare(): Boolean

    _type: string;
}

export class SharedAssemblyReference implements AbstractAssemblyReference {
    static TYPE = "SharedAssemblyReference"
    _type: string = SharedAssemblyReference.TYPE
    id: UUID;
    team: string;
    sharedAssembly: AssemblyReference;

    public getReferenceIdentifier(): UUID {
        return this.id;
    }

    public isShare(): Boolean {
        return true;
    }

    constructor(team: string, id: UUID, sharedAssembly: AssemblyReference) {
        this.team = team
        this.id = id;
        this.sharedAssembly = sharedAssembly;
    }
}

export class AssemblyReference implements AbstractAssemblyReference {
    static TYPE = "AssemblyReference"
    _type: string = AssemblyReference.TYPE
    team = '';
    id: UUID;
    version: UUID;

    public getReferenceIdentifier(): UUID {
        return this.version;
    }

    public isShare(): Boolean {
        return false;
    }

    constructor(team, id, version) {
        this.team = team;
        this.id = id;
        this.version = version;
    }
}

export class Tag {
    title: string;
    color: string;
    icon: string;
}

export class Version {
    id: string;
    name: string;
    created: Date;
    released: Date;
    files: AssemblyFile[];
    filesLocked: boolean;
    lifecycles: LifeCycleStage[] = [];
}

export class AssemblyFile {
    name: string;
    fType: FileType = new FileType();
    detectedTypes: FileType[];
    created: Date;
    preview: string;
    deleting = false;
    inverted = false; // only a local helper
    lifecycles: LifeCycleStage[] = [];
}

export enum LifeCycleStageName {
    DFM = 'dfm',
    DFM_ANALYSIS = 'dfmanalysis',
    ANALYSIS = 'analysis',
    LAYERSTACK = 'layerstack',
    SPECIFICATION = 'specification-render',
    PANEL = 'customer-panel',
    RENDERER = 'render',
    PREVIEW = 'preview',
    FILES = 'files',
    FILE_ANALYSIS = 'fileanalysis',
    INITIALIZATION = 'initialization',
    COMPOSITION = 'composition',
    PRODUCTION_ANALYSIS = 'production-analysis',

    MAIN = 'main',
    DMFANALYSIS = 'dfmanalysis',
    COMPOSIION = 'COMPOSITION'
}

export enum StageStatusName {
    UNKNOWN = 'unknown',
    PROGRESS = 'progress',
    SUCCESS = 'success',
    WAITING = 'waiting',
    ERROR = 'error',
    TIMEOUT = 'timeout'
}

export class LifeCycleStage {
    name: LifeCycleStageName;
    status: LifecycleStageStatus;
    history: LifeCycleHistory[] = [];

    static find(lc: LifeCycleStage[], name: LifeCycleStageName): LifeCycleStage | undefined {
        return lc.find(c => c.name === name);
    }
}

export class LifecycleStageStatus {
    name: StageStatusName;
    messages: string[] = [];
    percent?: number;

    static stopped(status: LifecycleStageStatus): boolean {
        return status.name === StageStatusName.SUCCESS || status.name === StageStatusName.ERROR || status.name === StageStatusName.TIMEOUT;
    }

}

export class LifeCycleHistory {
    name: string;
    desc: string;
    start: number;
    end: number;
}

export class FileType {
    service = 'local';
    fileType = 'unknown';
    productionFile = false;
    mimeType: string;
    category: string;
    index: number;  // if drillset is used
    to: number;
    from: number;

    constructor(service?: string, category?: string, fileType?: string, mimeType?: string) {
        this.service = service || 'general';
        this.category = category || 'unknown';
        this.fileType = fileType || 'unknown';
        this.productionFile = true;
        this.mimeType = mimeType;
    }
}

export enum MimeTypes {
    GERBER = 'text/gerber',
    LEGACY_GERBER = 'text/gerber-d',
    DRILL = 'text/drill',
    EAGLE = 'application/eagle',
    ALTIUM = 'application/altium',
    KICAD = 'application/kicad',
    PDF = 'application/pdf',
    CAD = 'application/*',
}


export class FileTypes {

    static COPPER_MID = new FileType('pcb', 'gerber', 'CopperMid', MimeTypes.GERBER);
    static COPPER_PLANE = new FileType('pcb', 'gerber', 'PlaneMid', MimeTypes.GERBER);
    static PEELABLE = new FileType('pcb', 'gerber', 'Peelable', MimeTypes.GERBER);
    static CARBON = new FileType('pcb', 'gerber', 'Carbon', MimeTypes.GERBER);

    static COPPER_TOP = new FileType('pcb', 'gerber', 'CopperTop', MimeTypes.GERBER);
    static COPPER_BOT = new FileType('pcb', 'gerber', 'CopperBottom', MimeTypes.GERBER);
    static PASTE_TOP = new FileType('pcb', 'gerber', 'PasteTop', MimeTypes.GERBER);
    static PASTE_BOT = new FileType('pcb', 'gerber', 'PasteBottom', MimeTypes.GERBER);
    static SILKSCREEN_TOP = new FileType('pcb', 'gerber', 'SilkscreenTop', MimeTypes.GERBER);
    static SILKSCREEN_BOT = new FileType('pcb', 'gerber', 'SilkscreenBottom', MimeTypes.GERBER);

    static SOLDERMASK_TOP = new FileType('pcb', 'gerber', 'SoldermaskTop', MimeTypes.GERBER);
    static SOLDERMASK_BOT = new FileType('pcb', 'gerber', 'SoldermaskBottom', MimeTypes.GERBER);
    static ADHESIVE_TOP = new FileType('pcb', 'gerber', 'AdhesiveTop', MimeTypes.GERBER);
    static ADHESIVE_BOT = new FileType('pcb', 'gerber', 'AdhesiveBottom', MimeTypes.GERBER);

    static MECHANICAL = new FileType('pcb', 'mechanical', 'Mechanical', MimeTypes.GERBER);
    static OUTLINE = new FileType('pcb', 'mechanical', 'Outline', MimeTypes.GERBER);
    static KEEP_OUT = new FileType('pcb', 'mechanical', 'KeepOut', MimeTypes.GERBER);
    static DRILL = new FileType('pcb', 'mechanical', 'Drill', MimeTypes.DRILL);
    static DRILLSET = new FileType('pcb', 'mechanical', 'DrillSets', MimeTypes.DRILL);
    static NPHDRILL = new FileType('pcb', 'mechanical', 'NPHDrill', MimeTypes.DRILL);
    static PHDRILL = new FileType('pcb', 'mechanical', 'PHDrill', MimeTypes.DRILL);

    static EAGLE_BOARD = new FileType('pcb', 'unknown', 'native-eagle', MimeTypes.EAGLE);
    static KICAD_BOARD = new FileType('pcb', 'unknown', 'native-kicad', MimeTypes.KICAD);
    static ALTIUM_BOARD = new FileType('pcb', 'unknown', 'native-altium', MimeTypes.ALTIUM);
    static CAD_NATIVE = new FileType('pcb', 'unknown', 'native', MimeTypes.CAD);
    static PDF = new FileType('pcb', 'unknown', 'PDF', MimeTypes.PDF);

    static LEGACY_GERBER = new FileType('pcb', 'unknown', 'LegacyGerber', MimeTypes.LEGACY_GERBER);
    static UNKNOWN = new FileType();


    static GERBER_TYPES = [
        FileTypes.PASTE_TOP,
        FileTypes.ADHESIVE_TOP,
        FileTypes.SILKSCREEN_TOP,
        FileTypes.SOLDERMASK_TOP,
        FileTypes.COPPER_TOP,
        FileTypes.COPPER_MID,
        FileTypes.COPPER_PLANE,
        FileTypes.COPPER_BOT,
        FileTypes.SOLDERMASK_BOT,
        FileTypes.SILKSCREEN_BOT,
        FileTypes.ADHESIVE_BOT,
        FileTypes.PASTE_BOT,
    ];
    static DRILL_TYPES = [
        FileTypes.DRILL,
        FileTypes.DRILLSET,
        FileTypes.NPHDRILL,
        FileTypes.PHDRILL
    ];

    static MECHANICAL_TYPES = [
        FileTypes.OUTLINE,
        FileTypes.KEEP_OUT,
        FileTypes.MECHANICAL
    ];

    static NATIVE_TYPES = [
        FileTypes.EAGLE_BOARD,
        FileTypes.KICAD_BOARD,
        FileTypes.ALTIUM_BOARD,
        FileTypes.CAD_NATIVE
    ];
    static MISC_TYPES = [
        FileTypes.PDF,
        FileTypes.LEGACY_GERBER,
        FileTypes.UNKNOWN,
    ];

    static layers(count: number, offset: number, name: string): FileType[] {
        let i = offset;
        return Array.from(Array(count).keys()).map(_ => {
            const f = new FileType('pcb', 'gerber', name);
            f.index = i;
            i++;
            return f;
        });
    }

    static planes(count: number, offset: number = 0): FileType[] {
        return FileTypes.layers(count, offset, 'PlaneMid');
    }

    static mid(count: number, offset: number = 0): FileType[] {
        return FileTypes.layers(count, offset, 'CopperMid');
    }
}

export class UserRole {
    user: string;
    roles: Projectrole[];
}

export class Projectrole {
    role: ['developer', 'validator'];
}

export type EncodedMailMessageEntityId = string;

export class LqReference {
    rfqId: UUID;
    assemblyId: UUID;
}

export class AssemblyInformation {
    customer: string;
    uiStatus: UIStatus;
    assignee: string;
    description: string;
    project: Project;
}

export class AssemblyWithShare {
    assembly: Assembly;
    share: SharedAssembly;
}

export class SharedAssemblyInformation {
    uiStatus: UIStatus;
    customer: UUID;
    assignee: UUID;
}

export class SharedAssembly {
    team: string;
    id: UUID;
    ref: AssemblyReference;
    created: Date;
    information: SharedAssemblyInformation;
}

export class AssemblyWithReference {
    assembly: Assembly;
    isShared: boolean;
    reference: AbstractAssemblyReference;


    constructor(assembly: Assembly, isShared: boolean, reference: AbstractAssemblyReference) {
        this.assembly = assembly;
        this.isShared = isShared;
        this.reference = reference;
    }
}

export class Assembly {
    team: string;
    id: string;
    gid: string;
    name: string;
    creator: string;
    lastUpdate: Date;
    information: AssemblyInformation;
    created: Date;
    preview: string;
    activities: AssemblyActivity[];
    warnings: AssemblyWarning[];
    currentVersion: Version;
    features: Feature[];
    status: String;
    mail?: EncodedMailMessageEntityId;
    externalReference?: LqReference;

}

export class AssemblyUIStatusStyle {

    color?: string;
    text: string;
    icon?: string;
    value?: string;

    constructor(value: string, color?: string, text?: string, icon?: string) {
        this.color = color;
        this.text = text;
        this.icon = icon;
        this.value = value;
    }
}

export const defaultState = new AssemblyUIStatusStyle('#a3a2a3', 'Unknown', 'help');


export class AssemblyUIStatusNames {
    static unknown = 'unknown';
    static new = 'new';
    static progress = 'progress';
    static finished = 'finished';
    static all = [
        AssemblyUIStatusNames.unknown,
        AssemblyUIStatusNames.new,
        AssemblyUIStatusNames.finished,
        AssemblyUIStatusNames.progress
    ];

    static labels = new Map([
        [AssemblyUIStatusNames.unknown, 'Unknown'],
        [AssemblyUIStatusNames.new, 'New'],
        [AssemblyUIStatusNames.finished, 'Finished'],
        [AssemblyUIStatusNames.progress, 'In Progress'],
    ]);
}


const clr_error = '#c43138';
const clr_unknown = '#858ca0'
const clr_running = '#4d58b2';
const clr_open = '#e99f0c';
const clr_done = '#195742';

export const AssemblyUIStates = new Map<string, AssemblyUIStatusStyle>([
    [AssemblyUIStatusNames.unknown, new AssemblyUIStatusStyle(AssemblyUIStatusNames.unknown, clr_unknown, AssemblyUIStatusNames.labels.get(AssemblyUIStatusNames.unknown), 'help')],
    [AssemblyUIStatusNames.new, new AssemblyUIStatusStyle(AssemblyUIStatusNames.new, clr_open, AssemblyUIStatusNames.labels.get(AssemblyUIStatusNames.new), 'pending')],
    [AssemblyUIStatusNames.progress, new AssemblyUIStatusStyle(AssemblyUIStatusNames.progress, clr_running, AssemblyUIStatusNames.labels.get(AssemblyUIStatusNames.progress), 'play_circle')],
    [AssemblyUIStatusNames.finished, new AssemblyUIStatusStyle(AssemblyUIStatusNames.finished, clr_done, AssemblyUIStatusNames.labels.get(AssemblyUIStatusNames.finished), 'check_circle')],
]);

export function hasUIState(st: UIStatus): Boolean {
    return (AssemblyUIStates.has(st.status.toLowerCase()));
}

export function getUIState(st: UIStatus): AssemblyUIStatusStyle {
    const s = st.status;
    if (AssemblyUIStates.has(s)) {
        return AssemblyUIStates.get(s);
    } else {
        return defaultState;
    }
}

export class UIStatus {
    status: string;
    progress?: number;

    constructor(status: string) {
        this.status = status;
    }
}

export class AssemblyUpdate {
    name: string;
    customer: string;
    assignee: string;
    description: string;
}


export class Feature {
    service: String;
    feature: String;
}

export class StreamMessage {
    t: string;
    m: Message;
    ref?: AssemblyReference;
}

export interface Message {
    _type: string;
}

export class AssemblyMessage implements Message {
    assembly: Assembly;
    _type: string;
}

export class AssemblyVersionMessage implements Message {
    assembly: Assembly;
    version: Version;
    _type: string;
}

export class FilesAddedMessage implements Message {
    assembly: string;
    version: string;
    file: AssemblyFile[];
    _type: string;
}

export class FilePreviewMessage implements Message {
    assembly: string;
    version: string;
    file: AssemblyFile;
    _type: string;
}

export class FileLifecycleMessage implements Message {
    assembly: string;
    file: string;
    lifecycle: LifeCycleStage;
    _type: string;
}

export class FileLockMessage implements Message {
    assembly: Assembly;
    locked: boolean;
    _type: string;
}

export class AssemblyPreviewMessage implements Message {
    assembly: UUID;
    preview: string;
    _type: string;
}

/**
 * This message is fired by backend when one of stages of the assembly's lifecycle is changed.
 */
export class AssemblyStageUpdateMessage implements Message {
    assembly: UUID;
    lifecycle: LifeCycleStage[];
    _type: string;

    static jsonName = 'VersionLifecycleMessage';
}

export class FileUpdate {
    function: FileType;
}

export class AssemblyActivity {

}

export class AssemblyWarning {
}
