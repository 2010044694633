import { Injectable } from '@angular/core';
import Analytics, { AnalyticsInstance } from 'analytics';
import segmentPlugin from '@analytics/segment';
import { TeamUtils } from '../main/authentication/team-utils';
import { EnvironmentService } from '../common/env/environment.service';
import { JWTPayload } from "../common/auth/JWT";


@Injectable({
    providedIn: 'root'
})
export class SegmentService {
    private analytics: AnalyticsInstance;
    private team: any;

    constructor(private env: EnvironmentService) {
    }

    private getAnalytics() {
        if (this.analytics) {
            return this.analytics;
        }
        if (this.env.environment.segmentWriteKey) {
            let writeKey = this.env.environment.segmentWriteKey;
            this.analytics = Analytics({
                app: 'stackrate',
                plugins: [
                    segmentPlugin({ writeKey: writeKey })
                ]
            });
            return this.analytics;
        }
    }

    public setUserByToken(jwt: JWTPayload) {
        if (this.getAnalytics()) {
            this.analytics.identify(jwt.userId, {
                stackrate_team: jwt.team,
                stackrate_username: jwt.username,
                email: jwt.email
            });
        }
        this.team = jwt.team;
    }

    public log(event: string) {
        this.logData(event, {});
    }

    public logData(event: string, eventProperties: any) {
        if (this.getAnalytics()) {
            eventProperties['stackrate_team'] = this.getTeam();
            eventProperties['app'] = "stackrate"
            this.analytics.track(event, eventProperties);
        }
    }


    private getTeam(): string {
        if (!this.team) {
            this.team = TeamUtils.getTeamFromSubDomain(this.env)
        }
        return this.team;
    }
}
