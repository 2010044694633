import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {PrintTemplate} from "../printTemplate.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-select-template-dialog',
    templateUrl: './select-template-dialog.component.html',
    styleUrls: ['./select-template-dialog.component.scss']
})
export class SelectTemplateDialogComponent implements OnInit {
    public templateForm: FormGroup;

    public availableTemplates: PrintTemplate[];

    constructor(public dialogRef: MatDialogRef<SelectTemplateDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
        this.availableTemplates = data.templates
        this.templateForm = new FormGroup({
            template: new FormControl('', {validators: Validators.required})
        });
    }

    ngOnInit(): void {
    }

    onClose() {
        this.dialogRef.close("canceled");
    }

    onSelect() {
        const result = this.templateForm.get('template').value;
        this.dialogRef.close(result);
    }
}
