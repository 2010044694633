import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {distinctUntilChanged, filter, flatMap, tap} from 'rxjs/operators';
import {AssemblyService} from '../../assembly.service';
import {MessageChangedMessage, RenderResult, RenderStatus, RenderStreamMessage, StatusChangedMessage} from './render';
import {Dimension} from '../../../../../pcb-common/pcb/pcb';
import {LoginService} from '../../../../../common/auth/login.service';
import {EnvironmentService} from '../../../../../common/env/environment.service';

@Injectable({
    providedIn: 'root'
})
export class RendererService implements OnDestroy {
    private ws: WebSocket;
    private endpoint = '/ems/renderer';
    private fileStatusMap: BehaviorSubject<Map<string, RenderResult>> = new BehaviorSubject<Map<string, RenderResult>>(new Map());

    constructor(private _httpClient: HttpClient,
                private _loginService: LoginService,
                private _assService: AssemblyService,
                private env: EnvironmentService
    ) {

        this._assService.getAssemblySubject().pipe(
            filter(a => !!a),
            distinctUntilChanged((a1, a2) => a1.id === a2.id && a1.currentVersion.id === a2.currentVersion.id)
        ).subscribe(ass => {
            const token = this._loginService.getCurrentAuthToken();
            this.subscribeRendererSocket(token, ass.id, ass.currentVersion.id);
        });
    }

    ngOnDestroy(): void {
        this.closeCurrentWebSocket()
    }


    public convertSvg(blob: Blob, w ?: number, h?: number): Observable<HttpResponse<Blob>> {
        const formData: FormData = new FormData();
        formData.append('fileKey', blob, 'image');

        let sizeObject = null;
        if (w && h) {
            sizeObject = {width: w, height: h};
        }

        return this._httpClient.post(this.env.api(this.endpoint, ['convert'], sizeObject), formData, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    public createOutlineFromBox(dim: Dimension): Observable<any> {
        return this._assService.getCurrentAssembly()
            .pipe(
                flatMap(ass => {
                    return this._httpClient.post<any>(this.env.api(this.endpoint, ['assemblies', ass.id, 'versions',
                        ass.currentVersion.id, 'outline', 'create', 'box']), {rectangle: dim}).pipe(tap(c => {

                    }));
                }));
    }

    public createOutlineFromElement(id: string): Observable<any> {
        return this._assService.getCurrentAssembly()
            .pipe(
                flatMap(ass => {
                    return this._httpClient.post<any>(this.env.api(this.endpoint, ['assemblies', ass.id, 'versions',
                        ass.currentVersion.id, 'outline', 'create', 'trace']), {traces: [id]}).pipe(tap(c => {
                    }));
                }));
    }

    public getRenderingFileStatus(filename: string): Observable<RenderResult> {
        return this._assService.getCurrentAssembly()
            .pipe(
                flatMap(ass => {
                    return this._httpClient.get<RenderResult>(this.env.api(this.endpoint, ['assemblies', ass.id, 'versions',
                        ass.currentVersion.id, 'files', filename, 'status'])).pipe(tap(c => {

                    }));
                }));
    }

    public subscribeRendererSocket(token: String, assID: string, assVersion): void {

        this.closeCurrentWebSocket();

        this.ws = new WebSocket(
            this.env.environment.ws +
            this.endpoint +
            '/assemblies/' +
            assID +
            '/versions/' +
            assVersion +
            '/updates?k=' +
            token
        );

        const that = this;
        this.ws.onclose = evt => {
            if (!evt.wasClean) {
                this.subscribeRendererSocket(token, assID, assVersion)
            }
        }
        this.ws.onmessage = event => {
            const data: RenderStreamMessage = JSON.parse(event.data);

            const type = data.m._type;
            if (type.endsWith('Ping')) {

            } else if (type.endsWith('StatusChangedMessage')) {
                const statusChangedMsg = data.m as StatusChangedMessage;
                const fileMap = this.fileStatusMap.getValue();

                let r = fileMap.get(statusChangedMsg.file);
                if (!r) {
                    r = new RenderResult();
                }

                r.status = new RenderStatus();
                r.status.name = statusChangedMsg.status;
                fileMap.set(statusChangedMsg.file, r);
                that.fileStatusMap.next(fileMap);
            } else if (type.endsWith('MessageChangedMessage')) {
                const statusMsg = data.m as MessageChangedMessage;
                const fileMap = this.fileStatusMap.getValue();
                let r = fileMap.get(statusMsg.file);
                if (!r) {
                    r = new RenderResult();
                }

                r.messages = [...statusMsg.messages];
                fileMap.set(statusMsg.file, r);
                that.fileStatusMap.next(fileMap);
            }

        };
    }

    private closeCurrentWebSocket() {
        if (this.ws) {
            this.ws.onclose = evt => {
            }
            this.ws.close();
        }
    }

    subscribeFileStatusUpdates(): Observable<Map<string, RenderResult>> {
        return this.fileStatusMap.asObservable();
    }
}
