import {Injectable} from '@angular/core';
import {StackrateEnvironment, StackratePlatform} from './environment-api';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, first, map} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {

    constructor(private logger: NGXLogger) {
    }

    private _environment: BehaviorSubject<StackrateEnvironment> = new BehaviorSubject<StackrateEnvironment>(null);

    get environment(): StackrateEnvironment {
        if (!this._environment.value) {
            this.logger.debug('Environment is empty');
        }
        return this._environment.value;
    }

    set environment(env: StackrateEnvironment) {
        this.logger.debug('Environment is now', env);
        this._environment.next(env);
    }

    private _platform: BehaviorSubject<StackratePlatform> = new BehaviorSubject<StackratePlatform>(null);

    get platform(): StackratePlatform {
        if (!this._platform.value) {
            this.logger.debug('Environment is empty');
        }
        return this._platform.value;
    }

    set platform(env: StackratePlatform) {
        this._platform.next(env);
    }

    public getCurrentEnvironment(): Observable<StackrateEnvironment> {
        return this._environment.pipe(filter(x => !!x), first());
    }

    public getPlatform(): Observable<StackratePlatform> {
        return this._platform.pipe(filter(x => !!x));
    }

    public ws(endpoint: string, parts: string[], params?: { [key: string]: any; }): string {
        return this._url(this.environment.ws, endpoint, parts, params);

    }

    public api(endpoint: string, parts: string[], params?: { [key: string]: any; }): string {
        return this._url(this.environment.api, endpoint, parts, params);
    }

    public _api(endpoint: string, parts: string[], params?: { [key: string]: any; }): Observable<string> {
        return this.getCurrentEnvironment().pipe(map(env => this._url(env.api, endpoint, parts, params)));
    }

    public _url(base: string, ep: string, parts: string[], params?: { [key: string]: any; }): string {
        let endpoint = ep;
        if (!endpoint.startsWith('/')) {
            endpoint = '/' + endpoint;
        }

        let url = base + endpoint;
        parts.forEach(p => url = url + '/' + encodeURIComponent(p));
        if (params) {
            let firstPart = true;

            Object.entries(params).forEach((value) => {
                if (Array.isArray(value[1])) {
                    value[1].forEach(s => {
                        const sep = firstPart ? '?' : '&';
                        url = url + sep + value[0] + '=' + encodeURIComponent(s);
                        firstPart = false;
                    });
                } else {
                    const sep = firstPart ? '?' : '&';
                    url = url + sep + value[0] + '=' + encodeURIComponent(value[1]);
                    firstPart = false;
                }

            });
        }

        return url;
    }

    public files(ep: string, parts: string[], params?: { [key: string]: any; }): string {
        return this._url(this.environment.files, ep, parts, params);
    }

}
