import { Injectable, OnDestroy } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { HttpClient } from '@angular/common/http';
import { Pcb2Service } from './pcb2.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first, map, mergeMap, startWith, takeUntil } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { Graphic, LayerFile, Outline, PCB } from '../../../../../pcb-common/pcb/pcb';
import { LoginService } from '../../../../../common/auth/login.service';
import { EnvironmentService } from '../../../../../common/env/environment.service';
import { assignMap } from '../../../../../common/object-utils';
import { AssemblyService } from "../../assembly.service";
import { SpecificationService } from '../specification/specification.service';
import { S } from '@angular/cdk/keycodes';
import { PCBSpecification } from '../specification/PCBSpecification';
import { version } from 'process';

@Injectable({
    providedIn: 'root'
})
export class PcbGraphicsService implements OnDestroy {
    private graphics: BehaviorSubject<Map<UUID, Map<String, Graphic>>> = new BehaviorSubject(new Map());
    private candidates: BehaviorSubject<Map<UUID, Graphic>> = new BehaviorSubject(new Map());
    private specificationCandidate: BehaviorSubject<Graphic> = new BehaviorSubject(null);


    private candidateUpdates: BehaviorSubject<Map<UUID, Graphic>> = new BehaviorSubject(new Map());

    private graphicUpdates: BehaviorSubject<Map<UUID, Map<String, Graphic>>> = new BehaviorSubject(new Map());

    private destroyed = new Subject();

    constructor(
        private _httpClient: HttpClient,
        private _loginService: LoginService,
        private _pcb: Pcb2Service,
        private _specService: SpecificationService,
        private _assembly: AssemblyService,
        private logger: NGXLogger,
        private env: EnvironmentService
    ) {
        this.logger.debug('construct PCBGraphicsService');

        _pcb.getGraphicUpdates()
            .pipe(takeUntil(this.destroyed))
            .subscribe(l => {
                this.addGraphic(l.id, l.data);
            });
    }

    public reset(): void {
        this.graphics.next(new Map());
        this.graphicUpdates.next(new Map());
    }


    ngOnDestroy(): void {
        this.destroyed.next('');
        this.destroyed.complete();
    }

    public listGraphic(): Observable<Map<UUID, Map<String, Graphic>>> {
        return this.graphicUpdates.pipe(startWith(this.graphics.getValue()));
    }

    public observeGraphics(): Observable<Map<UUID, Map<String, Graphic>>> {
        return this.graphics.asObservable();
    }

    public observeGraphicsForFile(file: UUID): Observable<Map<String, Graphic>> {
        return this.graphicUpdates.pipe(
            filter(u => {
                return u.has(file);
            }),
            map(u => {
                return u.get(file);
            })
        );
    }

    public getGraphics(file: UUID): Map<String, Graphic> {
        return this.graphics.getValue().get(file);
    }

    public getSpecificationOutline(assembly: UUID, version: UUID): Observable<Graphic> {
        return this.specificationCandidate.pipe(filter(g => !!g));

    }

    public outline(candidate: UUID): Observable<Graphic> {
        return this.candidates.pipe(
            map(c => {
                return c.get(candidate)
            }
            ),
            filter(c => !!c)
        )
    }

    private getSpecificationOutlineGraphic(assembly: UUID, version: UUID, spec: string): void {
        let subPath = "outlines/specification/" + spec


        this._assembly.getCurrentReferencedAssembly().subscribe(ass => {
            let url = '';
            if (ass.isShared) {
                url =
                    this.env.environment.api +
                    Pcb2Service.PCB_ENDPOINT +
                    '/v2/shares/' +
                    ass.reference.getReferenceIdentifier() +
                    "/" +
                    subPath
            } else {
                url =
                    this.env.environment.api +
                    Pcb2Service.PCB_ENDPOINT +
                    '/' +
                    assembly +
                    '/versions/' +
                    version +
                    "/" +
                    subPath
            }

            this._httpClient
                .get<Graphic>(
                    url
                )
                .subscribe(
                    g => {
                        this.specificationCandidate.next(g);
                    },
                    e => {
                        this.logger.debug('failed to receive specification outline graphics for pcb ' + version);
                    }
                );
        })

    }



    private getOutlineGraphic(assembly: UUID, version: UUID, candidate?: UUID): void {
        let subPath = "outline"
        if (candidate) {
            subPath = "outlines/" + candidate
        }

        this._assembly.getCurrentReferencedAssembly().subscribe(ass => {
            let url = '';
            if (ass.isShared) {
                url =
                    this.env.environment.api +
                    Pcb2Service.PCB_ENDPOINT +
                    '/v2/shares/' +
                    ass.reference.getReferenceIdentifier() +
                    "/" +
                    subPath +
                    '?withGraphic=true'
            } else {
                url =
                    this.env.environment.api +
                    Pcb2Service.PCB_ENDPOINT +
                    '/' +
                    assembly +
                    '/versions/' +
                    version +
                    "/" +
                    subPath +
                    '?withGraphic=true'
            }

            this._httpClient
                .get<Outline>(
                    url
                )
                .subscribe(
                    g => {

                        if (g.graphic) {

                            const map = this.candidates.getValue();
                            map.set(g.id, g.graphic);
                            this.candidates.next(map);
                            const updates = new Map();
                            updates.set(g.id, g.graphic);
                            this.candidateUpdates.next(updates);


                        }

                    },
                    e => {
                        this.logger.debug('failed to receive outline graphics for pcb ' + version + ' and outline', candidate);
                    }
                );
        })


    }

    public loadGraphics(pcb: PCB, files: LayerFile[], outline?: Outline): void {
        files.filter(x => !!x).forEach(file => {
            this.loadGraphic(pcb.assembly, pcb.version, file.name);
        });
        if (outline?.file) {
            this.loadGraphic(pcb.assembly, pcb.version, outline.file);
        }
        if (outline?.id) {
            this.getOutlineGraphic(pcb.assembly, pcb.version, outline?.id)
        }

        if (!outline) {
            this.getSpecificationOutlineGraphic(
                pcb.assembly,
                pcb.version,
                pcb.defaultSpecification
            )

        }
    }

    private addGraphic(id: UUID, newRenders: Map<String, Graphic>): void {
        const nr = assignMap(newRenders);

        nr.forEach((k, v) => {
            const map = this.graphics.getValue();
            if (!map.has(id)) {
                map.set(id, new Map());
            }

            map.get(id).set(v, k);

            this.graphics.next(map);

            const updates = new Map();
            updates.set(id, map.get(id));
            this.graphicUpdates.next(updates);
        });
    }

    private loadGraphic(assembly: UUID, version: UUID, layer: any): void {

        this._assembly.getCurrentReferencedAssembly().subscribe(ass => {

            let url = ''
            if (ass.isShared) {
                // TODO
            } else {
                url =
                    this.env.environment.api +
                    Pcb2Service.RENDER_ENDPOINT +
                    '/assemblies/' +
                    assembly +
                    '/versions/' +
                    version +
                    '/files/graphics?file=' +
                    encodeURIComponent(layer)
            }


            this._httpClient
                .get<Map<String, Graphic>>(
                    url
                )
                .subscribe(
                    g => {
                        const gm = assignMap(g);
                        const map = this.graphics.getValue();
                        map.set(layer, gm);

                        this.graphics.next(map);

                        const updates = new Map();
                        updates.set(layer, gm);
                        this.graphicUpdates.next(updates);
                    },
                    e => {
                        this.logger.debug('failed to receive graphics  for layer ' + layer);
                    }
                );
        })
    }

    mask(assembly: string, version: string, name: string) {

        // TODO shared
        return this.env.environment.api +
            Pcb2Service.RENDER_ENDPOINT +
            '/assemblies/' +
            assembly +
            '/versions/' +
            version +
            '/files/mask/' +
            name + "?k=" + this._loginService.getCurrentAuthToken();
    }
}