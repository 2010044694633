import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {LoginService} from '../common/auth/login.service';

@Injectable()
export class AuthGuard implements CanActivateChild, CanActivate {
    constructor(private router: Router,
                public _lgn: LoginService) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this._lgn.isLoggedIn()) {
            // logged in so return true
            return true;
        }

        // Fix to clean return URL which is just / (slash)
        const targetUrl = state.url;
        if (targetUrl === '/') {
            this.router.navigate(['/auth/login']);
            return false;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/auth/login'], {
            queryParams: {returnUrl: targetUrl}
        });
        return false;
    }
}
