import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomerService} from '../../../main/customer/customer.service';
import {AvatarItem} from '../avatar-list';
import {Customer} from '../../../common/customer';

@Component({
    selector: 'customer-list',
    templateUrl: './customer-list.component.html',
    styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {

    @Input() appearance = 'outline';
    @Output()
    selectionChanged: EventEmitter<Customer> = new EventEmitter();
    @Output()
    listChanged: EventEmitter<Customer[]> = new EventEmitter(); // not implemented
    _customers: AvatarItem [] = [];
    _customerList: Customer [] = [];
    _staticAvatar: AvatarItem;

    constructor(private _customerService: CustomerService) {

    }

    @Input() set staticCustomer(staticCustomer: Customer) {
        if (staticCustomer) {
            this._staticAvatar = new AvatarItem(staticCustomer.name, staticCustomer.id, staticCustomer.image);
        }
    }

    @Input() set setType(list: string) {
        if (list === 'list') {
            this._customerService.getCustomers(false).subscribe(cl => {
                    this._customerList = cl.results;
                    this._customerList.forEach(c => {
                        this._customers.push(new AvatarItem(c.name + (c.externalID ? ' (' + c.externalID + ')' : ''), c.id, c.image));
                    });
                }
            );
        }
    }

    onSelectCustomer(avatar: AvatarItem): void {
        if (avatar) {
            const c = this._customerList.find(a => a.id === avatar.id);
            if (c) {
                this.selectionChanged.emit(c);
            }
        } else {
            this.selectionChanged.emit(null);
        }
    }

    ngOnInit() {
    }
}
