import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FabNavigatorComponent} from './fab-navigator.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [FabNavigatorComponent],
    exports: [
        FabNavigatorComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        RouterModule

    ]
})
export class FabNavigatorModule {
}
