import {Injectable, OnDestroy} from '@angular/core';
import {Pcb2Service} from './pcb2.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {StreamMessage} from '../../../../../pcb-common/assembly/assembly';
import {AnalysisMsg} from '../../../../../pcb-common/pcb/pcb';
import {LoginService} from '../../../../../common/auth/login.service';
import {EnvironmentService} from '../../../../../common/env/environment.service';
import {UUID} from "angular2-uuid";

@Injectable({
    providedIn: 'root'
})
export class PCBListService implements OnDestroy {
    private pcbEndpoint = '/ems/pcb/';

    private ws: WebSocket;

    private pcbMeta: Map<String, BehaviorSubject<any>> = new Map();

    constructor(private _pcb: Pcb2Service, _login: LoginService, private env: EnvironmentService) {
        const token = _login.getCurrentAuthToken();

        this.connectSocket(token);
    }

    private connectSocket(token: string) {
        // TODO: subscribe to the environment
        this.ws = new WebSocket(
            this.env.environment.ws +
            this.pcbEndpoint +
            'updates?k=' +
            token
        );

        this.ws.onclose = evt => {
            if(!evt.wasClean){
                this.connectSocket(token)
            }
        }

        this.ws.onmessage = ev => {
            const data: StreamMessage = JSON.parse(ev.data);
            if (data.t === 'analysis') {
                const analysis = data.m as AnalysisMsg;
                const version = data.ref.version.toString();

                if (this.pcbMeta.has(version)) {
                    this.pcbMeta.get(version).next(analysis.meta);
                }
            }
        };
    }

    ngOnDestroy(): void {
        this.ws.onclose = evt => {}
        this.ws.close();
    }

    public getSharedPCB(shareID: UUID): Observable<any> {
        const idString = shareID.toString();
        if (this.pcbMeta.has(idString)) {
            return this.pcbMeta.get(idString);
        } else {
            const s = new BehaviorSubject<any>(null);
            this._pcb.getPCBMetaForShare(idString).subscribe(m => s.next(m), error => {
                // catch error when no meta is available
            });
            this.pcbMeta.set(idString, s);
            return s;
        }
    }
    public getPCBMetaData(id: string, version: string): Observable<any> {
        if (this.pcbMeta.has(version)) {
            return this.pcbMeta.get(version);
        } else {
            const s = new BehaviorSubject<any>(null);
            this._pcb.getPCBMetaForAssemblyID(id, version).subscribe(m => s.next(m), error => {
                // catch error when no meta is available
            });
            this.pcbMeta.set(version, s);
            return s;
        }
    }
}
