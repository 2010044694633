<ng-container *transloco="let t">
    <div mat-dialog-title>
        <div fxLayout="row">
            <div fxFlex="100" fxLayoutAlign="flex-start">{{t("General-OUTLINE-SELECTOR")}}</div>
        </div>
    </div>


    <mat-dialog-content class="outline-viewer-content  p-8">
        <div class="m-8" fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
            <div class="outline-viewer" fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
                <div class="outline-svg-element" fxFlex="100" fxFlexAlign="start start" fxLayout="row" id="canvas">
                </div>
            </div>
            <div class="pr-32 outline-button-wrapper" fxLayout="column" fxLayoutAlign="center end">

                <button (click)="onFitPanel( )" class="tool-button mr-8" color="primary" mat-raised-button
                    matTooltip="{{t('Tooltip-FIT-OUTLINE')}}" matTooltipPosition="left">
                    <mat-icon> aspect_ratio</mat-icon>
                </button>
            </div>

        </div>
    </mat-dialog-content>

    <mat-dialog-actions>

    </mat-dialog-actions>
</ng-container>