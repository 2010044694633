import {Component, Input, OnInit} from '@angular/core';
import {Quotation, QuotationStatus, QuotationStatusText} from '../../../main/quotation/quotation';
import {DateService} from '../../../services/date.service';
import {QuotationService} from '../../../main/quotation/quotation.service';
import {QuotationViewService} from '../../../main/quotation/quotation-view.service';

@Component({
    selector: 'quotation-status-badge',
    templateUrl: './quotation-status-badge.component.html',
    styleUrls: ['./quotation-status-badge.component.scss']
})
export class QuotationStatusBadgeComponent implements OnInit {

    @Input()
    status: QuotationStatus;

    @Input()
    quotation: Quotation;

    public draft = QuotationStatusText.DRAFT;
    public sent = QuotationStatusText.SENT;
    public won = QuotationStatusText.WON;
    public lost = QuotationStatusText.LOST;

    constructor(
        public dateService: DateService,
        public _quotationService: QuotationService,
        public _quotationViewService: QuotationViewService
    ) {

    }

    ngOnInit(): void {
    }


    public getStatusClass(): string {
        if (this.status) {
            return 'quotation-status-' + this.status.name.toString();
        }
        return '';
    }

    public getStatusString(): string {
        if (this.status) {
            switch (this.status.name) {
                case 'draft' :
                    return 'Draft';
                case 'archived' :
                    return 'Archived';
                case 'overdue' :
                    return 'Overdue';
                case 'deleted' :
                    return 'Deleted';
                case 'sent' :
                    return 'Sent';
                case 'won' :
                    return 'Won';
                case 'lost' :
                    return 'Lost';

                    return 'Undefined';
            }
        }
    }

    public setQuotationStatus(status: QuotationStatusText) {
        const quotationStatus = new QuotationStatus(status);
        if (this.quotation) {
            this._quotationService.updateQuotationStatus(this.quotation.name, quotationStatus).subscribe(quotation => {
                this._quotationViewService.setQuotation(quotation);
            });
        }
    }
}
