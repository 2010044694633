import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
    name: 'timeLineDate'
})
export class TimeLineDatePipe extends DatePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        return super.transform(value, 'd.MM.yy h:mm');
    }

}


