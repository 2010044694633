import {NgxLoggerLevel} from 'ngx-logger';

export const base = 'gateway.test.electronic-fellows.de';
export const api_token = '28dc8a80-18af-4fc6-93e0-01edf64ea39a';
// export const base = 'localhost:9000';
export const environment = {
    production: false,
    hmr: false,
    api: 'http://' + base + '/api',
    files: 'http://' + base + '/files',
    ws: 'ws://' + base + '/api',
    debugRouter: false,
    fixed_team: undefined,
    logrocketInitKey: '08fcl7/stackrate-dev',
};
export const logLevel = NgxLoggerLevel.DEBUG;
export const serverLogLevel = NgxLoggerLevel.ERROR;

