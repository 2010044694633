<div class="chip-filter" fxLayout="row" fxLayoutAlign="center center">

    <div fxLayout="row" fxLayoutAlign="center center">
        <button (menuOpened)="onMenuOpen()" [disabled]="disabled" [matMenuTriggerFor]="addMenu" class="add-button"
                mat-button>
            <mat-icon class="text-secondary">add</mat-icon>
            <span class="text-secondary">{{filterText}}</span>
        </button>
    </div>

    <mat-chip-list #chipList [disabled]="disabled" class="chip-list">
        <mat-chip (removed)="remove(element)" *ngFor="let element of selectedElements" [disabled]="disabled"
                  [ngStyle]="{'background-color':element.color}"
                  [removable]="true" [selectable]="true"
                  class="chip-style">{{element.data}}
            <mat-icon (click)="remove(element)" class="chip-icon-style s-20">cancel</mat-icon>
        </mat-chip>
    </mat-chip-list>


    <mat-menu #addMenu="matMenu" class="w-240">
        <mat-form-field (click)="$event.stopPropagation()" class="px-16 w-100-p" floatLabel="never">
            <input #filterInput (input)="search($event)" [disabled]="disabled" matInput placeholder="{{placeholder}}"/>
        </mat-form-field>

        <mat-divider></mat-divider>
        <mat-list [disableRipple]="disabled">
            <mat-list-item (click)="itemClicked(chipItem)" *ngFor="let chipItem of filteredMenuElements"
                           class="chip-list-item">{{chipItem.data}}</mat-list-item>
        </mat-list>
    </mat-menu>
</div>
