import {Address} from './customer';

export class Profile {
    user: string;
    data: ProfileInfo;
    avatar: string;
    addresses: Address[];
}

export class ProfileInfo {
    firstName: string;
    lastName: string;
    country: string;
    info: any;
    language: string;
}


export enum APIMode {
    PUBLIC = 'public',
    PRIVATE = 'private'
}

export class APISettings {
    apiEnabled: boolean;
    apiMode: APIMode;
    tenants: string[];
}


