import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Assembly} from '../pcb-common/assembly/assembly';
import {EnvironmentService} from '../common/env/environment.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationInfoService {

    private assemblyEndpoint = '/assembly/assemblies';


    constructor(
        private _httpClient: HttpClient,
        private env: EnvironmentService
    ) {
    }

    public getNumberOfOpenAssemblies(): Observable<Assembly[]> {
        // ?filter=uiStatusSeverity=finished,uiStatusMessage=quotation&flat=true
        // return this._httpClient.get<Assembly[]>(this.env.environment.api + this.assemblyEndpoint);
        const params = {
            filter: 'uiStatusMessage=quotation',
            flat: true
        };
        return this._httpClient.get<Assembly[]>(this.env.api(this.assemblyEndpoint, [], params));
    }
}
