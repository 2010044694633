<ng-container *transloco="let t">
    <div mat-dialog-title>
        <div fxLayout="row">
            <div fxFlex="100" fxLayoutAlign="flex-start">{{t("Settings-SELECT-PRINT-TEMPLATE")}}</div>

        </div>
    </div>
    <mat-dialog-content class="content p-16">
        <form [formGroup]="templateForm" class="" fxLayout="column wrap" fxLayoutAlign="start">
            <!-- Alias -->
            <div class="pl-16" fxFlex="100" fxLayout="column">
                <!-- Category -->
                <mat-form-field class="catSelector" appearance="outline" fxFlex="25">
                    <mat-label>{{t("Settings-TEMPLATE")}}</mat-label>
                    <mat-select formControlName="template">
                        <mat-option *ngFor="let template of availableTemplates" [value]="template">
                            {{template.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div class="button-row" fxFlex="100" fxLayout="row">
                <div fxFlex="100" fxLayoutAlign="flex-end">
                    <button (click)="onClose()" class="mt-8 mr-8" color="warn" mat-button>
                        {{t("General-CLOSE-CAPS")}}
                    </button>
                    <button (click)="onSelect()" class="mt-8 mr-8" color="primary" mat-button>
                        {{t("General-SELECT-CAPS")}}
                    </button>
                </div>
            </div>
        </form>
    </mat-dialog-content>
</ng-container>