import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AvatarItem} from '../../avatar-list/avatar-list';
import {FormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {map, startWith} from 'rxjs/operators';
import {isEmail} from '../email-utils';
import {Contact} from '../../../common/customer';

@Component({
    selector: 'email-chip-contact',
    templateUrl: './email-chip-contact.component.html',
    styleUrls: ['./email-chip-contact.component.scss']
})
export class EmailChipContactComponent implements OnInit {

    @Input() headerText: string;
    @Output()
    emailAddressAdded: EventEmitter<string []> = new EventEmitter();
    avatarList: AvatarItem[] = [];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    emailFormCtrl = new FormControl();
    filteredNameList: Observable<string[]>;
    selectedNames: string[] = [];
    selectedEmailAddresses: string [] = [];
    availableNameList: string[] = [];
    @ViewChild('nameInput') nameInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') auto: MatAutocomplete;

    constructor() {
        this.filteredNameList = this.emailFormCtrl.valueChanges.pipe(
            startWith(null),
            map((name: string | null) => name ? this._filter(name) : this.availableNameList.slice()));
    }

    @Input() set contacts(contacts: Contact[]) {
        if (contacts) {
            this.createAvatarList(contacts);
        }
    }

    @Input() set contactToSelect(contactToSelect: Contact) {
        if (contactToSelect) {

            if (isEmail(contactToSelect.email)) {

                const name = contactToSelect.firstName + ' ' + contactToSelect.lastName;

                this.selectedNames.push(name);
                this.selectedEmailAddresses.push(contactToSelect.email);
                this.emailAddressAdded.emit(this.selectedEmailAddresses);


            }
        }
    }

    ngOnInit(): void {
    }

    add(event: MatChipInputEvent): void {

        if (!this.auto.isOpen) {
            const input = event.input;
            const value = event.value;

            if ((value || '').trim()) {
                if (this.availableNameList.find(a => a === value)) {
                    this.addEmailAddress(value.trim());
                } else {
                    this.addEmailAddress(value.trim());
                }
            }

            // Reset the input value
            if (input) {
                input.value = '';
            }

            this.emailFormCtrl.setValue(null);
        }
    }

    remove(name: string): void {
        const index = this.selectedNames.indexOf(name);

        if (index >= 0) {
            this.selectedNames.splice(index, 1);
            this.selectedEmailAddresses.splice(index, 1);
            this.emailAddressAdded.emit(this.selectedEmailAddresses);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.addEmailAddress(event.option.value);
        this.nameInput.nativeElement.value = '';
        this.emailFormCtrl.setValue(null);
    }


    addEmailAddress(name: string): void {

        this.selectedNames.push(name);
        const email = this.getEmail(name);

        if (email) {
            this.selectedEmailAddresses.push(email);
            this.emailAddressAdded.emit(this.selectedEmailAddresses);
        }
    }

    getEmail(name: string): string {
        const ava = this.avatarList.find(a => a.name === name);

        if (ava) {
            return ava.email;
        }

        if (isEmail(name)) {
            return name;
        } else {
            return 'no email sorry';
        }
    }

    createAvatarList(contacts: Contact []): void {
        this.availableNameList = [];
        this.avatarList = [];
        this.selectedEmailAddresses = [];
        if (contacts) {
            // create avatar list
            contacts.forEach(c => {
                let name;
                if (c.lastName) {
                    name = c.firstName + ' ' + c.lastName;
                } else {
                    name = c.firstName;
                }

                const avatar = new AvatarItem(name, c.id, c.image, c.email);
                this.avatarList.push(avatar);
                this.availableNameList.push(name);
            });
        }
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.availableNameList.filter(name => name.toLowerCase().indexOf(filterValue) === 0);
    }
}
