// Finish color schemes
import {RenderedLayer} from './pcb-ui.service';
import {Tool} from './drill';
import {LayerStyle} from '../../../../../pcb-common/pcb/pcb';
import {SvgDrillClassNPTH, SvgDrillClassPTH} from '../../../../../common-ui/svg/svg-constants';

export const RigidNoneColor = new Map<String, LayerStyle>([
    ['CopperTop', new LayerStyle('#B77729', 1, 1)],
    ['CopperBottom', new LayerStyle('#B77729', 1, 1)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
export const RigidENIGColor = new Map<String, LayerStyle>([
    ['CopperTop', new LayerStyle('#CC9933', 1, 1)],
    ['CopperBottom', new LayerStyle('#CC9933', 1, 1)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
export const RigidHALColor = new Map<String, LayerStyle>([
    ['CopperTop', new LayerStyle('#d1c9c9', 1, 1)],
    ['CopperBottom', new LayerStyle('#d1c9c9', 1, 1)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
//
// SolderMask color schemes
//
export const RigidRedSolderMask = new Map<String, LayerStyle>([
    ['SoldermaskTop', new LayerStyle('#b82915', 0.75)],
    ['SoldermaskBottom', new LayerStyle('#b82915', 0.75)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
export const RigidBlueSolderMask = new Map<String, LayerStyle>([
    ['SoldermaskTop', new LayerStyle('#06115b', 0.75)],
    ['SoldermaskBottom', new LayerStyle('#06115b', 0.75)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
export const RigidGreenSolderMask = new Map<String, LayerStyle>([
    ['SoldermaskTop', new LayerStyle('#004200', 0.75)],
    ['SoldermaskBottom', new LayerStyle('#004200', 0.75)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
export const RigidWhiteSolderMask = new Map<String, LayerStyle>([
    ['SoldermaskTop', new LayerStyle('#ffffff', 0.75)],
    ['SoldermaskBottom', new LayerStyle('#ffffff', 0.75)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
export const RigidBlackSolderMask = new Map<String, LayerStyle>([
    ['SoldermaskTop', new LayerStyle('#020202', 0.75)],
    ['SoldermaskBottom', new LayerStyle('#020202', 0.75)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
export const RigidGreenMattSolderMask = new Map<String, LayerStyle>([
    ['SoldermaskTop', new LayerStyle('#003500', 0.75)],
    ['SoldermaskBottom', new LayerStyle('#003500', 0.75)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
export const RigidGreenGlossySolderMask = new Map<String, LayerStyle>([
    ['SoldermaskTop', new LayerStyle('#004200', 0.75)],
    ['SoldermaskBottom', new LayerStyle('#004200', 0.75)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
export const RigidPurpleSolderMask = new Map<String, LayerStyle>([
    ['SoldermaskTop', new LayerStyle('#8B47AA', 0.75)],
    ['SoldermaskBottom', new LayerStyle('#8B47AA', 0.75)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
export const DefaultColors = new Map<String, LayerStyle>([
    ['PasteTop', new LayerStyle('#888888', 1)],
    ['PasteBottom', new LayerStyle('#888888', 1)],
    ['SilkscreenTop', new LayerStyle('#ffffff', 1)],
    ['SilkscreenBottom', new LayerStyle('#ffffff', 1)],
    ['SoldermaskTop', new LayerStyle('#004200', 0.75, 0)],
    ['SoldermaskBottom', new LayerStyle('#004200', 0.75, 0)],
    ['CopperTop', new LayerStyle('#CC9933', 1, 1, 1)],
    ['CopperMid', new LayerStyle('#CC9933', 1, 1, 1)],
    ['PlaneMid', new LayerStyle('#CC9933', 1, 1, 1)],
    ['CopperBottom', new LayerStyle('#CC9933', 1, 1, 1)],
    ['core', new LayerStyle('#A0522D', 0.9)],
    ['flexcore', new LayerStyle('#A0522D', 0.9)],
    ['prepreg', new LayerStyle('#708090', 0.9)],
    ['dielectric', new LayerStyle('#708090', 0.9)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);


export const traceHighlightOpacity = 0.1;
export const TraceHighLightColors = new Map<String, LayerStyle>([
    ['PasteTop', new LayerStyle('#888888', 0)],
    ['PasteBottom', new LayerStyle('#888888', 0)],
    ['SilkscreenTop', new LayerStyle('#ffffff', traceHighlightOpacity)],
    ['SilkscreenBottom', new LayerStyle('#ffffff', traceHighlightOpacity)],
    ['SoldermaskTop', new LayerStyle('#004200', traceHighlightOpacity / 2)],
    ['SoldermaskBottom', new LayerStyle('#004200', traceHighlightOpacity / 2)],
    ['CopperTop', new LayerStyle('#CC9933', 1, 1)],
    ['CopperMid', new LayerStyle('#CC9933', 1, 1)],
    ['PlaneMid', new LayerStyle('#CC9933', 1, 1)],
    ['CopperBottom', new LayerStyle('#CC9933', 1, 1)],
    ['core', new LayerStyle('#A0522D', 0)],
    ['flexcore', new LayerStyle('#A0522D', 0)],
    ['prepreg', new LayerStyle('#708090', 0)],
    ['dielectric', new LayerStyle('#708090', 0)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);


export const RigidWhiteSilkScreen = new Map<String, LayerStyle>([
    ['SilkscreenTop', new LayerStyle('#ffffff', 1)],
    ['SilkscreenBottom', new LayerStyle('#ffffff', 1)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
export const RigidBlackSilkScreen = new Map<String, LayerStyle>([
    ['SilkscreenTop', new LayerStyle('#020202', 1)],
    ['SilkscreenBottom', new LayerStyle('#020202', 1)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);

export const RigidYellowSilkScreen = new Map<String, LayerStyle>([
    ['SilkscreenTop', new LayerStyle('#EAC100', 1)],
    ['SilkscreenBottom', new LayerStyle('#EAC100', 1)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
//
// Default color schemes
//
export const DefaultFlexColors = new Map<String, LayerStyle>([
    ['PasteTop', new LayerStyle('#888888', 1)],
    ['PasteBottom', new LayerStyle('#888888', 1)],
    ['SilkscreenTop', new LayerStyle('#ffffff', 0.9)],
    ['SilkscreenBottom', new LayerStyle('#ffffff', 0.9)],
    ['SoldermaskTop', new LayerStyle('#8d6541', 0.7)],
    ['SoldermaskBottom', new LayerStyle('#8d6541', 0.7)],
    ['CopperTop', new LayerStyle('#CC9933', 1, 1)],
    ['CopperMid', new LayerStyle('#CC9933', 1, 1)],
    ['PlaneMid', new LayerStyle('#CC9933', 1, 1)],
    ['CopperBottom', new LayerStyle('#CC9933', 1, 1)],
    ['core', new LayerStyle('#A0522D', 0.8)],
    ['flexcore', new LayerStyle('#A0522D', 0.8)],
    ['dielectric', new LayerStyle('#708090', 0.5)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
export const DefaultIMSColors = new Map<String, LayerStyle>([
    ['PasteTop', new LayerStyle('#888888', 1)],
    ['PasteBottom', new LayerStyle('#888888', 1)],
    ['SilkscreenTop', new LayerStyle('#040404', 1)],
    ['SilkscreenBottom', new LayerStyle('#040404', 1)],
    ['SoldermaskTop', new LayerStyle('#f9f9f9', 0.75)],
    ['SoldermaskBottom', new LayerStyle('#f9f9f9', 0.75)],
    ['CopperTop', new LayerStyle('#cecdcb', 1)],
    ['CopperMid', new LayerStyle('#cecdcb', 1)],
    ['PlaneMid', new LayerStyle('#cecdcb', 1)],
    ['CopperBottom', new LayerStyle('#cecdcb', 1)],
    ['core', new LayerStyle('#A0522D', 0.9)],
    ['dielectric', new LayerStyle('#708090', 0.9)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);

export const CADSchemeOpacity = 0.75;
export const CADSchemeOpacityInvisible = 0.1;
export const AltiumMidLayerColorScheme: LayerStyle[] = [
    new LayerStyle('#bc8e00', CADSchemeOpacity),
    new LayerStyle('#70dbfa', CADSchemeOpacity),
    new LayerStyle('#00cc66', CADSchemeOpacity),
    new LayerStyle('#bc8e00', CADSchemeOpacity),
    new LayerStyle('#9966ff', CADSchemeOpacity),
    new LayerStyle('#00ffff', CADSchemeOpacity),
    new LayerStyle('#800080', CADSchemeOpacity),
    new LayerStyle('#ff00ff', CADSchemeOpacity),
    new LayerStyle('#808000', CADSchemeOpacity),
    new LayerStyle('#ffff00', CADSchemeOpacity),
    new LayerStyle('#808080', CADSchemeOpacity),
    new LayerStyle('#808080', CADSchemeOpacity),
    new LayerStyle('#ffffff', CADSchemeOpacity),
    new LayerStyle('#800080', CADSchemeOpacity),
];

export const DefaultColorsAltium = new Map<String, LayerStyle>([
    ['PasteTop', new LayerStyle('#808080', CADSchemeOpacity)],
    ['PasteBottom', new LayerStyle('#800000', CADSchemeOpacity)],
    ['SilkscreenTop', new LayerStyle('#ffff00', CADSchemeOpacity)],
    ['SilkscreenBottom', new LayerStyle('#808000', CADSchemeOpacity)],
    ['SoldermaskTop', new LayerStyle('#800080', CADSchemeOpacity)],
    ['SoldermaskBottom', new LayerStyle('#ff00ff', CADSchemeOpacity)],
    ['CopperTop', new LayerStyle('#fe0000', CADSchemeOpacity, 1)],
    // ['CopperMid', AltiumMidLayerColorScheme],
    // ['PlaneMid', AltiumMidLayerColorScheme],
    ['CopperBottom', new LayerStyle('#0000ff', CADSchemeOpacity, 1)],
    ['core', new LayerStyle('#A0522D', CADSchemeOpacityInvisible)],
    ['flexcore', new LayerStyle('#A0522D', CADSchemeOpacityInvisible)],
    ['prepreg', new LayerStyle('#708090', CADSchemeOpacityInvisible)],
    ['dielectric', new LayerStyle('#708090', CADSchemeOpacityInvisible)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);


export const DefaultColorsKiCAD = new Map<String, LayerStyle>([
    ['PasteTop', new LayerStyle('#840000', 0.75)],
    ['PasteBottom', new LayerStyle('#00c2c2', 0.75)],
    ['SilkscreenTop', new LayerStyle('#008484', 0.75)],
    ['SilkscreenBottom', new LayerStyle('#840084', 0.75)],
    ['SoldermaskTop', new LayerStyle('#840084', 0.5)],
    ['SoldermaskBottom', new LayerStyle('#848400', 0.5)],
    ['CopperTop', new LayerStyle('#840000', 0.75)],
    ['CopperMid', new LayerStyle('#c2c200', 0.75)],
    ['PlaneMid', new LayerStyle('#c200c2', 0.75)],
    ['CopperBottom', new LayerStyle('#008400', 0.75)],
    ['core', new LayerStyle('#A0522D', 0.1)],
    ['flexcore', new LayerStyle('#A0522D', 0.1)],
    ['prepreg', new LayerStyle('#708090', 0.1)],
    ['dielectric', new LayerStyle('#708090', 0.1)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);
export const DefaultColorsEAGLE = new Map<String, LayerStyle>([
    ['PasteTop', new LayerStyle('#a0a0a0', 0.75)],
    ['PasteBottom', new LayerStyle('#a0a0a0', 0.75)],
    ['SilkscreenTop', new LayerStyle('#ffffff', 0.75)],
    ['SilkscreenBottom', new LayerStyle('#ffffff', 0.75)],
    ['SoldermaskTop', new LayerStyle('#d0d4af', 0.5)],
    ['SoldermaskBottom', new LayerStyle('#d0d4af', 0.5)],
    ['CopperTop', new LayerStyle('#86201b', 0.75)],
    ['CopperMid', new LayerStyle('#CC9933', 0.75)],
    ['PlaneMid', new LayerStyle('#73dff4', 0.75)],
    ['CopperBottom', new LayerStyle('#4b43aa', 0.75)],
    ['core', new LayerStyle('#A0522D', 0.1)],
    ['flexcore', new LayerStyle('#A0522D', 0.1)],
    ['prepreg', new LayerStyle('#708090', 0.1)],
    ['dielectric', new LayerStyle('#708090', 0.1)],
    ['Undefined', new LayerStyle('#711c91', 1)]
]);

export const ColorSchemes = new Map<string, Map<String, LayerStyle>>([
    ['Realistic', DefaultColors],
    ['Altium', DefaultColorsAltium],
    ['KiCAD', DefaultColorsKiCAD],
    ['EAGLE', DefaultColorsEAGLE],
]);

export function drillToolToClassName(t: Tool, index: number, scaling: number): string [] {
    const classNames = [];

    // Add classname drill type
    if (t.drillType === 'PLATED') {
        classNames.push(SvgDrillClassPTH);
    } else if (t.drillType === 'NON_PLATED') {
        classNames.push(SvgDrillClassNPTH);
    }
    // Add classname tool name
    classNames.push(t.name);// + '-' + index.toString());


    // Add classname diameter
    const dia = t.diameter / scaling;
    classNames.push('d' + dia.toFixed(3).toString());
    return classNames;
}


export const layerClassNames = new Map<string, string>([
    ['SoldermaskTop', 'soldermask_top'],
    ['SoldermaskBottom', 'soldermask_bot'],
    ['CopperTop', 'copper_top'],
    ['CopperMid', 'copper_mid'],
    ['PlaneMid', 'plane_mid'],
    ['CopperBottom', 'copper_bot'],
    ['SilkscreenTop', 'silkscreen_top'],
    ['SilkscreenBottom', 'silkscreen_bot'],
    ['PasteTop', 'paste_top'],
    ['PasteBottom', 'paste_bot'],
    // Material
    ['core', 'material_core'],
    ['dielectric', 'material_dielectric'],
    ['prepreg', 'material_prepreg'],
]);
export const layerClassesCopperNames: string [] = [
    'copper_top',
    'copper_mid',
    'plane_mid',
    'copper_bot',
];

export const blockingLayerClasses: string [] = [
    'copper_top',
    'copper_mid',
    'plane_mid',
    'copper_bot',
    'material_core',
    'material_dielectric',
    'material_prepreg',
];

export function classNameToLayerType(className: string): string {
    const v = [...layerClassNames.entries()];

    const type = v.filter(({1: l}) => l.includes(className)).map(([k]) => k);
    return type[0];
}

export function fileTypeToClassName(l: RenderedLayer): string {

    if (l && (l.file || l.layer.definition.layerType)) {
        let fileType = 'unknown';
        if (l.file) {
            fileType = l.file.fileType.fileType;
        } else if (l.layer.definition.layerType) {
            fileType = l.layer.definition.layerType;
        }
        const c = layerClassNames.has(fileType);

        if (c) {
            return layerClassNames.get(fileType);
        }
    }

}
