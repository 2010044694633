<ng-container *transloco="let t">
    <div class="bottom-sheet p-16" fxFlex="100" fxLayout="column" fxLayoutAlign="start center">

        <div class="pb-32" fxFlex="100" fxLayout="column" fxLayoutAlign="start start" style="width:100%">
            <div class="p-0" fxLayout="column" fxLayoutAlign="start start">
                <div class="h1 pb-8">{{t("Project_assemblies-SELECT-THE-OUTLINE")}} </div>
                <span class="text-secondary">{{t("Project_assemblies-SELECT-THE-OUTLINE-HINT")}}</span>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="flex-start">

            <div class="pb-24" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayoutGap="0px">
                <mechanical-card (click)="openMechanicalCard($event)"
                    *ngFor="let outline of (outlines | async)" [ass]="getAssembly() | async" [outline]="outline[0]" [file]="outline[1]"
                    [selected]="outline[0]?.id === (pcbService.getOutline() | async)?.id">

                </mechanical-card>
            </div>
        </div>
    </div>
</ng-container>