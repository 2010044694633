<div mat-dialog-title>
    <div class="pl-8" fxLayout="row" fxLayoutAlign="space-between start">
        <div fxFlex="100" fxLayoutAlign="flex-start"> Unlock</div>

        <button (click)="dialogRef.close(false)" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content class="content p-16">
    <div fxLayout="column" fxLayoutAlign="start stretch">

        <div class="text-xl p-16"> Please type <b>{{confirmText}}</b> to confirm.</div>

        <mat-form-field appearance="outline" class="pl-16" fxFlex="100">
            <!--            <mat-label>Name</mat-label>-->
            <input [(ngModel)]="userInput" matInput type="'text'"/>
        </mat-form-field>


        <div class="mt-8" fxFlex="100" fxLayout="row">
            <div class="button-row" fxFlex="100" fxLayoutAlign="flex-end">
                <button (click)="dialogRef.close(true)" [disabled]="testInput()" class="mt-8 mr-8" color="primary"
                        mat-raised-button>
                    <mat-icon class="pr-8">lock_open</mat-icon>
                    UNLOCK
                </button>
            </div>
        </div>

    </div>


</mat-dialog-content>
