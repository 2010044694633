<div fxLayout="column" fxLayoutAlign="space-around stretch">
    <div [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '-50px' }
                }" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center">
            <div *ngIf="titleText" class="h1 p-8">{{titleText}}
            </div>

            <button (click)="onDownloadFile()" mat-icon-button>
                <mat-icon>cloud_download</mat-icon>
            </button>
        </div>


        <div fxLayout="row" fxLayoutAlign="end center">
            <mat-form-field class="example-full-width">
                <input (input)="search($event.target.value)" matInput placeholder="Search">
            </mat-form-field>
            <mat-slide-toggle (toggleChange)="onToggleSize()" class="pl-8">fit</mat-slide-toggle>
        </div>
    </div>

    <!-- PDF VIEWER-->
    <mat-divider class="m-8"></mat-divider>

    <div class="pdf-viewer-wrapper ">
        <pdf-viewer (after-load-complete)="afterLoadCompleteCallback($event)" [(page)]="pageCounter" [fit-to-page]="false" [original-size]="fitSize"
                    [render-text]="true" [show-all]="true"
                    [show-borders]="false" [src]="pdfSource"
        ></pdf-viewer>
    </div>

    <!-- FILE LOADING-->

    <mat-divider class="m-8"></mat-divider>


    <div class="m-8" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
        <div *ngIf="pageCountMax!=0" [@animate]="{
                    value: '*',
                    params: { delay: '100ms', y: '-10px' }
                }" class="m-8" fxLayout="row" fxLayoutAlign="start center">
            <button (click)="onChangePageCounter(false)" mat-icon-button>
                <mat-icon>remove_circle_outline</mat-icon>
            </button>
            <div class="p-4 h3">
                {{pageCounter}} / {{pageCountMax}}
            </div>
            <button (click)="onChangePageCounter(true)" mat-icon-button>
                <mat-icon>add_circle_outline</mat-icon>
            </button>
        </div>

        <div class="m-8" fxLayout="row" fxLayoutAlign="end center">
            <button (click)="onClose()" color="warn" mat-button>
                CLOSE
            </button>
        </div>

    </div>

</div>
