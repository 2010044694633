<ng-container *transloco="let t">
    <form [formGroup]="comboForm" class="flex flex-col">
        <mat-form-field class="self-center w-full" appearance="legacy">
            <mat-label>{{t('General-CUSTOMER')}}</mat-label>

            <mat-select [formControl]="selectedCustomerCtrl" [multiple]="multiple">
                <mat-select-trigger>
                    {{selectedCustomer?.name}}
                </mat-select-trigger>
                <mat-option>
                    <ngx-mat-select-search [formControl]="entryFilterCtrl" placeholderLabel="{{t('General-SEARCH')}}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let cu of filteredEntries" [value]="cu">
                    {{cu.name}}
                </mat-option>
            </mat-select>
            <button mat-button *ngIf="selectedCustomer" (click)="resetFilter();$event.stopPropagation()" matSuffix
                mat-icon-button matTooltip="{{t('General-RESET-FILTER')}}" aria-label="Clear">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </form>
</ng-container>