import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'tag-button',
    templateUrl: './tag-button.component.html',
    styleUrls: ['./tag-button.component.scss']
})
export class TagButtonComponent {

    @Input() color: string;
    @Input() text: string;
    @Input() isButton = false;
    @Input() isActive = false;
    @Output() tagClick: EventEmitter<any> = new EventEmitter<any>();

    public inactiveColor = '#898789';

    constructor() {
    }

    onClick(): void {
        this.tagClick.emit();
    }


}
