import {EventEmitter, Injectable, Input, Output} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {StackrateSnackbarComponent} from './stackrate-snackbar.component';
import {Technology} from '../../main/supplier/capability';

@Injectable({
    providedIn: 'root'
})
export class StackrateSnackbarService {

    @Input()
    horizontalPosition: MatSnackBarHorizontalPosition = 'start';

    @Input()
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';

    /***
     Default duration in seconds
     */
    @Input()
    duration = 4;

    constructor(private _snackBar: MatSnackBar) {
    }

    open(message: string, buttonText?: string) {
        this._snackBar.openFromComponent(StackrateSnackbarComponent, {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['stackrate-snackbar'],
            duration: this.duration * 1000,
            data: {
                message: message,
                buttonText: buttonText?.toUpperCase()
            }
        });
    }

    close() {
        this._snackBar.dismiss();
    }

}
