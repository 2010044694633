<ng-container *transloco="let t">
    <fuse-card *ngIf="documentService.getViewerState() | async" [@anim]="documentService.getViewerState() | async"
               class="flex flex-col pl-4 p-2 mx-2 document-sidebar-content">

        <div class="pb-1 flex flex-col">
            <div class="flex flex-row font-medium flex-grow align-middle">
                <div class="text-base flex flex-grow pt-2">{{t("General-DOCUMENT-VIEWER")}}</div>
                <div>
                    <button (click)="onClose()" mat-icon-button matTooltip="{{t('Tooltip-CLOSE-DOCUMENT-VIEWER')}}"
                            matTooltipPosition="above">
                        <mat-icon>
                            close
                        </mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="pb-2 flex flex-row w-full px-2">
            <mat-form-field class="w-full" appearance="legacy">
                <mat-label class="flex flex-grow">{{t("General-SELECT-FILE")}}</mat-label>
                <mat-select (selectionChange)="fileSelected($event.value)" [value]="(selectedFile | async)">
                    <mat-option *ngFor="let file of (documentService.fileList | async)" [value]="file">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <p>
                                {{file.name}}
                            </p>
                            <mat-icon matPrefix>{{isImage(file) ? 'image' : 'description'}}</mat-icon>
                        </div>
                    </mat-option>

                </mat-select>
            </mat-form-field>

            <div *ngIf="!imagePath" class="flex flex-row w-1/3 ml-2">
                <button (click)="onZoom(true)" color="accent" mat-icon-button matTooltip="{{t('Tooltip-ZOOM-IN')}}"
                        matTooltipPosition="above">
                    <mat-icon>zoom_in</mat-icon>
                </button>

                <button (click)="onZoom(false)" color="accent" mat-icon-button matTooltip="{{t('Tooltip-ZOOM-OUT')}}"
                        matTooltipPosition="above">
                    <mat-icon>zoom_out</mat-icon>
                </button>
                <button (click)="onRotateLeft( )" color="accent" mat-icon-button
                        matTooltip="{{t('Tooltip-ROTATE-LEFT')}}" matTooltipPosition="above">
                    <mat-icon>rotate_left</mat-icon>
                </button>
            </div>
        </div>


        <div *ngIf="imagePath" class="pdf-viewer-wrapper">
            <pinch-zoom [backgroundColor]="'rgba(0,0,0,0.1)'" class="p-8">
                <img [src]="imagePath"/>
            </pinch-zoom>
        </div>

        <div *ngIf="!imagePath" class="pdf-viewer-wrapper">
            <pdf-viewer [autoresize]="false" [fit-to-page]="true" [rotation]="angle" [show-all]="true"
                        [src]="documentService.pdfSource | async" [zoom]="pdfZoom"></pdf-viewer>
        </div>


    </fuse-card>
</ng-container>