<!-- PROGRESS BAR -->
<!--<fuse-progress-bar></fuse-progress-bar>-->
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<!--<ng-container *ngIf="fuseConfig.layout === 'classy'">-->
<!--    <classy-layout></classy-layout>-->
<!--</ng-container>-->
<router-outlet></router-outlet>
<!-- / VERTICAL LAYOUT 1 -->


<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'timelineSidebar'"
    [position]="'right'"
    #timelineSidebar>

    <timeline-sidebar class="overflow-scroll"></timeline-sidebar>
</fuse-drawer>