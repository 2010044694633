export enum MaterialAttr {

    ArticleID = 'articleid',
    Width = 'width',
    Height = 'height',
    AreaPrice = 'areaprice',
    UnitPrice = 'unitprice',
    Density = 'density',

    BaseThickness = 'basethickness',
    CuThickness = 'cuthickness',
    MaskThickness = 'maskthickness',
    InkThickness = 'inkthickness',
    UpperCuThickness = 'uppercuthickness',
    LowerCuThickness = 'lowercuthickness',
    Supplier = 'supplier',
    SupplierDescription = 'supplierdescription',
    Description = 'description',
    PressedThickness = 'pressedthickness',
    Type = 'type',
    CuDensity = 'cudensity',
    UpperCuDensity = 'uppercudensity',
    LowerCuDensity = 'lowercudensity',
    TotalHeight = 'totalheight',
    TargetHeight = 'targetheight',

    DatasheetURL = 'datasheeturl',
    DelaminateTime_T260 = 'delaminatetime_t260',
    DelaminateTime_T288 = 'delaminatetime_t288',
    DielectricBreakdown = 'dielectricbreakdown',
    DielectricConstant = 'dielectricconstant',
    ExcessResin = 'excessresin',
    RequiredResin = 'requiredresin',
    FinishedThickness = 'finishedthickness',
    Flammability = 'flammability',
    HalogenFree = 'halogenfree',
    IPCSlashSheets = 'ipcslashsheets',
    LossTangent = 'losstangent',
    MoistureAbsorption = 'moistureabsorption',
    Ply = 'ply',
    ResinContent = 'resincontent',
    CopperRatio = 'copperratio',
    Td = 'td',
    Tg = 'tg',
    Tg_DMA = 'tg_dma',
    Tg_DSC = 'tg_dsc',
    Tg_TMA = 'tg_tma',
    ThermalConductivity = 'thermalconductivity',
    Tolerance = 'tolerance',
    TypeDescription = 'typedescription',
    Z_CTE_Post = 'z_cte_post',
    Z_CTE_Pre = 'z_cte_pre',
    ZAxisExpansion = 'zaxisexpansion',
}

export const MaterialAttrList = [
    MaterialAttr.ArticleID,
    MaterialAttr.Width,
    MaterialAttr.Height,
    MaterialAttr.AreaPrice,
    MaterialAttr.UnitPrice,
    MaterialAttr.Density,

    MaterialAttr.BaseThickness,
    MaterialAttr.CuThickness,
    MaterialAttr.MaskThickness,
    MaterialAttr.InkThickness,
    MaterialAttr.UpperCuThickness,
    MaterialAttr.LowerCuThickness,
    MaterialAttr.Supplier,
    MaterialAttr.SupplierDescription,
    MaterialAttr.Description,
    MaterialAttr.PressedThickness,
    MaterialAttr.Type,
    MaterialAttr.CuDensity,
    MaterialAttr.UpperCuDensity,
    MaterialAttr.LowerCuDensity,
    MaterialAttr.TotalHeight,
    MaterialAttr.TargetHeight,

    MaterialAttr.DatasheetURL,
    MaterialAttr.DelaminateTime_T260,
    MaterialAttr.DelaminateTime_T288,
    MaterialAttr.DielectricBreakdown,
    MaterialAttr.DielectricConstant,
    MaterialAttr.ExcessResin,
    MaterialAttr.RequiredResin,
    MaterialAttr.FinishedThickness,
    MaterialAttr.Flammability,
    MaterialAttr.HalogenFree,
    MaterialAttr.IPCSlashSheets,
    MaterialAttr.LossTangent,
    MaterialAttr.MoistureAbsorption,
    MaterialAttr.Ply,
    MaterialAttr.ResinContent,
    MaterialAttr.CopperRatio,
    MaterialAttr.Td,
    MaterialAttr.Tg,
    MaterialAttr.Tg_DMA,
    MaterialAttr.Tg_DSC,
    MaterialAttr.Tg_TMA,
    MaterialAttr.ThermalConductivity,
    MaterialAttr.Tolerance,
    MaterialAttr.TypeDescription,
    MaterialAttr.Z_CTE_Post,
    MaterialAttr.Z_CTE_Pre,
    MaterialAttr.ZAxisExpansion,
];

export enum MaterialLibraryTypes {
    FLEX_CORE = 'flexcore',
    PEELABLE = 'peelable',
    COVERLAY = 'coverlay',
    IDENT = 'ident',
    ADHESIVE = 'adhesive',
    SOLDERMASK = 'soldermask',
    BONDPLY = 'bondply',
    CORE = 'core',
    PREPREG = 'prepreg',
    FOIL = 'foil',
    MISC = 'misc',
}
