import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralComponent } from './general.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';
import {SharedModule} from '../../../../core/shared/shared.module';
import {HelperModule} from '../../../../helpers/helper.module';

@NgModule({
    declarations: [GeneralComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatIconModule,
        HelperModule,
        MatButtonModule,
        FlexLayoutModule,
        SharedModule
    ],
    exports: [GeneralComponent]
})
export class ProfileGeneralModule {}
