import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PcbFileUploadService} from '../pcb-file-manager/pcb-file-upload.service';
import {Subject} from 'rxjs';
import {G, Svg, SVG} from '@svgdotjs/svg.js';
import {PcbUiService} from '../../pcb/pcb-ui.service';
import {MaterialService} from '../../../../../../layerstack-common/material/material.service';
import {AutoUnsubscribable} from '../../../../../../helpers/autounsub';

@Component({
    selector: 'outline-selector-viewer',
    templateUrl: './outline-selector-viewer.component.html',
    styleUrls: ['./outline-selector-viewer.component.scss']
})
export class OutlineSelectorViewerComponent extends AutoUnsubscribable implements OnInit, AfterViewInit {

    private _draw: Svg;
    private _outlineGroup: G;


    constructor(public dialogRef: MatDialogRef<OutlineSelectorViewerComponent>,
                private _materialService: MaterialService,
                private _pcbFileService: PcbFileUploadService,
                private _uiService: PcbUiService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        super();
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        Promise.resolve().then(() => {
            setTimeout(() => {
                this.initialize();
            }, 200);
            // this.initialize()
        });
    }

    async initialize() {
        this.initSVG();
    }

    onFitPanel(): void {

    }

    private initSVG(): void {
        const f = this._uiService.getScaleFactor();
        this._draw = SVG().addTo('#canvas');

        this._draw.css('width', '100%');
        this._draw.css('height', '100%');

        this._draw.viewbox(-50, -50, 100, 100);

        this._outlineGroup = this._draw
            .group()
            .transform({scaleY: 1, scaleX: 1})
            .addClass('panel-group');
    }
}
