import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, first, map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ImageSizes} from '../settings/profile/avatar/avatar.service';
import {Address, Contact, ContactResult, Customer, CustomerResult} from '../../common/customer';
import {LoginService} from '../../common/auth/login.service';
import {EnvironmentService} from '../../common/env/environment.service';
import {ProgressBarService} from '../../common-ui/progressbar/progress-bar.service';
import {PaginationListResult} from '../../common/PaginationListResult';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    customersEndpoint = '/customer/customers';
    private customerListSubject: BehaviorSubject<Customer[]> = new BehaviorSubject([]);
    public currentCustomer: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);


    constructor(private _httpClient: HttpClient,
                private _progress: ProgressBarService,
                private _login: LoginService,
                private env: EnvironmentService
    ) {
        this.initCustomers();
    }

    //
    // CUSTOMER
    //
    public initCustomers(): void {
        this._progress.show();
        this.getCustomers(true)
            .subscribe(
                data => {
                    this.customerListSubject.next(data.results);
                    this._progress.hide();
                },
                error => {
                    this._progress.hide();
                }
            );
    }

    public getCustomersSubject(): Observable<Customer[]> {
        return this.customerListSubject.asObservable();
    }

    public findCustomers(customerName: string, page?: number, pageSize?: number): Observable<PaginationListResult<Customer>> {
        let htmlParam = '';
        if (page) {
            htmlParam = htmlParam + '&page=' + page;
        }

        if (pageSize) {
            htmlParam = htmlParam + '&pagesize=' + pageSize;
        }
        return this._httpClient.get<PaginationListResult<Customer>>(this.env.environment.api + this.customersEndpoint + '/find?q=' + customerName + htmlParam + '&contacts=true');
    }

    public getCustomer(id: string, update: boolean): Observable<Customer> {
        if (update) {
            return this._httpClient.get<Customer>(this.env.environment.api + this.customersEndpoint + '/' + id + '?contacts=true').pipe(
                tap(c => {
                    this.updateCustomerSubject(c); // in case contacts where not loaded update customer subject with get
                }, error => {
                }));
        } else {
            return this.customerListSubject.pipe(
                map(c => c.find(x => x.id === id)),
                filter(x => x != null),
                first()
            );
        }
    }

    public createCustomer(customer: Customer): Observable<Customer> {
        return this._httpClient.post<CustomerResult>(this.env.environment.api + this.customersEndpoint, customer).pipe(
            map(c => {
                const cl = this.customerListSubject.getValue();
                const nc = new Customer();
                Object.assign(nc, c.customer);
                cl.push(nc);
                this.customerListSubject.next(cl);
                return nc;
            })
        );
    }

    public updateCustomer(customer: Customer): Observable<Customer> {
        return this._httpClient.put<CustomerResult>(this.env.environment.api + this.customersEndpoint + '/' + customer.id, customer).pipe(
            tap(c => {
                const nc = new Customer();

                Object.assign(nc, c.customer);
                nc.contacts = customer.contacts;
                nc.addresses = customer.addresses;
                nc.tags = customer.tags;

                this.updateCustomerSubject(nc);
                return nc;
            }), map(c => c.customer)
        );
    }

    public deleteCustomerByID(customerID: string): Observable<any> {
        return this._httpClient.delete<any>(this.env.environment.api + this.customersEndpoint + '/' + customerID).pipe(
            tap(p => {
                const cl = this.customerListSubject.getValue();
                cl.splice(this.indexOfCustomer(cl, customerID), 1);
                this.customerListSubject.next(cl);
            })
        );
    }

    public deleteCustomer(customer: Customer): Observable<any> {
        return this.deleteCustomerByID(customer.id);
    }

    public getCustomers(withContacts: boolean, page?: number, pageSize?: number): Observable<PaginationListResult<Customer>> {
        this.env.getCurrentEnvironment().subscribe(e => {
            // console.log('got cur env: ', e);
        }, error => {
            console.log('getCustomers error : ', error);
        });
        let params = '?contacts=' + withContacts;
        if (page) {
            params = params + '&page=' + page;
        }
        if (pageSize) {
            params = params + '&pagesize=' + pageSize;
        }
        return this._httpClient.get<PaginationListResult<Customer>>(this.env.environment.api + this.customersEndpoint + params);
    }


    public getCustomerImagePath(id: string, image: string, size?: ImageSizes): string {
        if (image) {
            if (!size) {
                size = ImageSizes.TINY;
            }
            const s = this.env.environment.files + this.customersEndpoint + '/' + id + '/' + image;
            return s;
        }

        return '';
    }

    public uploadCustomerImage(id: string, image: File): Observable<any> {
        const endpoint = this.env.environment.files + this.customersEndpoint + '/' + id + '/avatar';
        const formData: FormData = new FormData();
        formData.append('fileKey', image, image.name);
        return this._httpClient.post<any>(endpoint, formData).pipe(
            tap(p => {
                this.setCustomerImage(id, p.file);
            })
        );
    }

    //
    // CONTACTS
    //
    public getContacts(id: string): Observable<Contact []> {
        return this._httpClient.get<Contact[]>(this.env.environment.api + this.customersEndpoint + '/' + id + '/contacts');
    }

    public createContact(id: string, contact: Contact): Observable<Contact> {
        return this._httpClient.post<ContactResult>(this.env.environment.api + this.customersEndpoint + '/' + id + '/contacts', contact).pipe(
            map(cr => {
                const con = new Contact();
                Object.assign(con, cr.contact);
                this.addContact(id, con);
                return con;
            })
        );
    }

    public deleteContact(id: string, contact: Contact): Observable<any> {
        return this._httpClient.delete<any>(this.env.environment.api + this.customersEndpoint + '/' + id + '/contacts/' + contact.id).pipe(
            tap(p => {
                const customer = this.findCustomer(id);
                const contacts = customer.contacts;
                if (contacts) {
                    customer.contacts.splice(this.indexOfContacts(contacts, contact), 1);
                    this.updateCustomerSubject(customer);
                }
            })
        );
    }

    public updateContact(id: string, contact: Contact): Observable<Contact> {
        console.log('update contact', contact);
        return this._httpClient.put<ContactResult>(this.env.environment.api + this.customersEndpoint + '/' + id + '/contacts/' + contact.id, contact).pipe(
            map(cr => {
                const con = new Contact();
                Object.assign(con, cr.contact);
                this.setContact(id, con);
                console.log('contact i constructed is ', cr);
                return con;
            })
        );
    }

    public uploadContactImage(id: string, contact: Contact, image: File): Observable<any> {
        const endpoint = this.env.environment.files + this.customersEndpoint + '/' + id + '/contacts/' + contact.id + '/avatar';
        const formData: FormData = new FormData();
        formData.append('fileKey', image, image.name);
        return this._httpClient.post<any>(endpoint, formData);
    }

    public getContactImagePath(id: string, contactID: string, image: string, size?: ImageSizes): string {
        if (id && contactID && image) {
            if (!size) {
                size = ImageSizes.TINY;
            }
            return this.env.environment.files + this.customersEndpoint + '/' + id + '/contacts/' + contactID + '/' + image + size.toString();
        }
        return '';
    }


    //
    // PRIVATE HELPER
    //

    // find customer by id
    private findCustomer(id: string): Customer {
        const list = this.customerListSubject.getValue();
        if (list) {
            return list.find(c => c.id === id);
        }
        return null;
    }

    // updates customer in customer list subject
    private updateCustomerSubject(customer: Customer): void {
        if (customer) {
            const list = this.customerListSubject.getValue();
            const customerIndex = this.indexOfCustomer(list, customer.id);
            list[customerIndex] = customer;
            this.customerListSubject.next(list);
        }
    }

    // add contact to customer list subject
    private addContact(id: string, contact: Contact): void {

        const customer = this.findCustomer(id);
        if (customer) {
            customer.contacts.push(contact);
            this.updateCustomerSubject(customer);
        }
    }

    // update customer image
    private setCustomerImage(id: string, image: string): void {

        const customer = this.findCustomer(id);
        if (customer) {
            customer.image = image;
            this.updateCustomerSubject(customer);
        }
    }

    // update contact in customer list subject
    private setContact(id: string, contact: Contact): void {

        const customer = this.findCustomer(id);
        if (customer) {

            const contactIndex = this.indexOfContacts(customer.contacts, contact);
            if (contactIndex) {
                customer.contacts[contactIndex] = contact;
                this.updateCustomerSubject(customer);
            }
        }
    }

    public indexOfContacts(contacts: Contact[], contact: Contact): any {
        return contacts.findIndex(c => contact.id === c.id);
    }

    private indexOfAddresses(addresses: Address[], address: Address): any {
        return addresses.findIndex(a => address.id === a.id);
    }

    private indexOfCustomer(customers: Customer[], customerID: string): any {
        return customers.findIndex(c => customerID === c.id);
    }

    public isPrimaryContact(contact: Contact, customerId: string): Observable<boolean> {
        return this.getCustomer(customerId, true).pipe(map(customer => {
            if (this.indexOfContacts(customer.contacts, contact) === 0) {
                return true;
            } else {
                return false;
            }
        }));
    }

    public isPrimaryAddress(address: Address, customerId: string): Observable<boolean> {
        return this.getCustomer(customerId, true).pipe(map(customer => {
            if (this.indexOfAddresses(customer.addresses, address) === 0) {
                return true;
            } else {
                return false;
            }
        }));
    }
}
