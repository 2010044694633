import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimelineComponent} from './timeline/timeline.component';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import {ProfileGeneralModule} from '../settings/profile/general/profile-general.module';
import {AccountModule} from '../settings/profile/account/account.module';
import {GravatarModule} from 'ngx-gravatar';
import {AsGuestUserPipe, AsPredefinedChangePipe, AsSpecificChangePipe, AsSystemUserPipe} from './Pipes.pipe';
import {TimelineSidebarComponent} from './timeline-sidebar/timeline-sidebar.component';
import {TimeLineDatePipe} from './time-line-date.pipe';
import {TimeAgoPipe} from './time-ago.pipe';
import {HighlightPipe} from './highlight.pipe';
import {HelperModule} from '../../helpers/helper.module';
import {SharedModule} from '../../core/shared/shared.module';
import {FuseScrollbarModule} from '../../../@fuse/directives/scrollbar';
import {FuseCardModule} from '../../../@fuse/components/card';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [TimelineComponent, AsPredefinedChangePipe, AsSpecificChangePipe, AsSystemUserPipe, AsGuestUserPipe, TimelineSidebarComponent, TimeLineDatePipe, TimeAgoPipe, HighlightPipe],
    imports: [
        CommonModule,
        FlexModule,
        FlexLayoutModule,
        MatCardModule,
        MatTabsModule,
        ProfileGeneralModule,
        MatDialogModule,
        MatButtonModule,
        AccountModule,
        GravatarModule,
        CommonModule,
        HelperModule,
        SharedModule,
        FuseScrollbarModule,
        FuseCardModule,
        MatIconModule,
        MatTooltipModule
    ],
    exports: [TimelineSidebarComponent, TimeAgoPipe]
})
export class CollaborationModule {
}
