import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {JWTRefresh} from '../common/auth/JWT';

export abstract class AbstractBearerInterceptor implements HttpInterceptor {

    private refreshing = false;

    abstract getCurrentAuthToken(request: HttpRequest<any>): String;

    abstract getAuthExpirationTime(): Date;

    abstract refresh(): Observable<JWTRefresh> ;

    abstract clearToken(): void ;


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        if (request.method !== 'OPTIONS' && !request.headers.has('Authorization') && (request.url.indexOf('assets') < 0)) {
            const currentToken = this.getCurrentAuthToken(request); // this._lgn.getCurrentAuthToken();
            if (currentToken) {
                // start refresh if token expires in less than 2 minutes
                const now = new Date();
                // this._lgn.getAuthExpirationTime()
                if (this.getAuthExpirationTime().getTime() - now.getTime() <= 2 * 60 * 1000) {
                    if (!this.refreshing) {
                        this.refreshing = true;
                        // this._lgn.refreshAuthToken('false')
                        this.refresh().subscribe(
                            p => {
                                this.refreshing = false;
                            },
                            error => {
                                // this._lgn.logout();
                                this.clearToken();
                            }
                        );
                    }
                }

                request = request.clone({
                    headers: request.headers.set('Authorization', `Bearer ${currentToken}`)
                });
            } else {
                // XXX:TODO This leads to problems with the register page
                // this._lgn.logout();
            }
        }
        return next.handle(request);
    }
}
