import {Injectable} from '@angular/core';
import {Timeline, TimelineRequest, TimelineUserInfo} from './collaboration';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {AvatarService, ImageSizes} from '../settings/profile/avatar/avatar.service';
import {EnvironmentService} from '../../common/env/environment.service';

@Injectable({
    providedIn: 'root'
})
export class CollaborationService {

    public timeline: BehaviorSubject<Timeline> = new BehaviorSubject<Timeline>(null);
    public timeLineEventCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);


    constructor(private env: EnvironmentService, private httpClient: HttpClient, private avatarService: AvatarService) {
    }

    public subscribeTimeline(): Observable<Timeline> {
        return this.timeline.asObservable();
    }

    public subscribeTimeLineEventCount(): Observable<number> {
        return this.timeLineEventCount.asObservable();
    }

    public setTimeline(req: TimelineRequest): void {
        this.getTimeline(req).subscribe(timeline => {
            timeline.content.reverse();
            this.timeLineEventCount.next(timeline.content.length);
            this.timeline.next(timeline);
        });
    }

    public getTimeline(req: TimelineRequest): Observable<Timeline> {
        return this.httpClient.put<Timeline>(this.env.api('collaboration', ['timeline', 'filtered']), req).pipe(map(tl => {
            const sys = new TimelineUserInfo();
            sys.name = 'System';
            tl.users['00000000-0000-0000-0000-000000000000'] = sys;

            Object.keys(tl.users).forEach(u => {
                const inf = tl.users[u];
                if (inf.avatar) {
                    const url = this.avatarService.getUserImage(inf.id, inf.avatar, ImageSizes.TINY);
                    inf.avatar = url;
                }
            });

            return tl;
        }));
    }

    public clearTimeLineCount(): void {
        this.timeLineEventCount.next(0);
    }
}
