import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-info-text-dialog',
    templateUrl: './info-text-dialog.component.html',
    styleUrls: ['./info-text-dialog.component.scss']
})
export class InfoTextDialogComponent {
    text: string;
    description: string;
    textHeader: string;
    showSaveButton = true;

    constructor(public dialogRef: MatDialogRef<InfoTextDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        if (data.text) {
            this.text = data.text;
        }

        if (!data.showSaveButton) {
           this.showSaveButton = false;
        }

        if(data.description) {
            this.description = data.description;
        }

        if (data.textHeader) {
            this.textHeader = data.textHeader;
        }
    }


}
