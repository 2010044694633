import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PdfViewerComponent} from 'ng2-pdf-viewer';
import {fuseAnimations} from '../../fuse/animations';

@Component({
    selector: 'pdf-viewer-dialog',
    templateUrl: './pdf-viewer-dialog.component.html',
    styleUrls: ['./pdf-viewer-dialog.component.scss'],
    animations: fuseAnimations
})
export class PdfViewerDialogComponent implements OnInit {

    @ViewChild(PdfViewerComponent, {static: true}) private pdfComponent: PdfViewerComponent;

    constructor(public dialogRef: MatDialogRef<any>,
                public dialog: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        if (data.pdfSource) {
            this.pdfSource = data.pdfSource;
        }

        if (data.title) {
            this.titleText = data.title;
        }
    }

    pdfSource: any;
    titleText: string;
    pageCounter = 1;
    pageCountMax = 0;
    fitSize = false;

    ngOnInit(): void {
    }

    public onClose(): void {
        this.dialogRef.close();
    }

    public afterLoadCompleteCallback(pdf: any): void {
        if (pdf.numPages) {
            this.pageCountMax = pdf.numPages;
        }
    }

    public onDownloadFile(): void {
    }


    public onToggleSize(): void {
        this.fitSize = !this.fitSize;
    }

    public onChangePageCounter(inc: boolean): void {
        if (inc && this.pageCounter < this.pageCountMax) {
            this.pageCounter++;
        } else if (!inc && this.pageCounter > 0) {
            this.pageCounter--;
        }
    }

    public search(stringToSearch: string): void {
        this.pdfComponent.pdfFindController.executeCommand('find', {
            caseSensitive: false, findPrevious: undefined, highlightAll: true, phraseSearch: true, query: stringToSearch
        });
    }
}
