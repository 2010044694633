export class PermissionConstants {
    public static PERMISSION = 'Permission';
    public static PERMISSION_URL = 'permission';
    public static DASHBOARD_URL = 'dashboard';
    public static GROUPS = 'Groups';
}

export const PermissionAllSymbol = '*';

export const PermissionActions = ['read', 'write', 'create'];
export const PermissionResources = [
    'layerstackusage', 'layerstackdefinition', 'library', 'renderer', 'pcb',
    'assembly', 'profile', 'user', 'notification',
    'quotation', 'customer', 'pcbsupplier', 'pricelist', 'inbox', 'dfm', 'customerpanels', 'workingpanels', 'material', 'teamprofile'
];


export const CHIP_COLOR_GREY = '#3c4252';
export const CHIP_COLOR_BLUE = '#039be5';
export const CHIP_COLOR_LILA = '#a533ff';
