import {Injectable} from '@angular/core';
import {PrintTemplate} from './printTemplate.model';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {mergeMap, Observable, of} from 'rxjs';
import {EnvironmentService} from '../../../common/env/environment.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {SelectTemplateDialogComponent} from "./select-template-dialog/select-template-dialog.component";

@Injectable({
    providedIn: 'root'
})
export class PrintTemplateService {
    // private ps: PrintTemplate[];


    templateService = 'user';

    constructor(
        private _httpClient: HttpClient,
        private env: EnvironmentService,
        private _dialog: MatDialog
    ) {
    }

    createPrintTemplate(printTemplate: PrintTemplate): Observable<PrintTemplate> {
        const body = {
            category: printTemplate.category,
            name: printTemplate.name,
            content: printTemplate.content
        };
        return this._httpClient.post<PrintTemplate>(this.env.api(this.templateService, ['templates']), body);
    }

    getPrintTemplates(): Observable<PrintTemplate[]> {
        return this._httpClient.get<PrintTemplate[]>(this.env.api(this.templateService, ['templates']));
    }

    getPrintTemplatesByCategory(cat: string): Observable<PrintTemplate[]> {
        return this._httpClient.get<PrintTemplate[]>(this.env.api(this.templateService, ['templates'], {category: cat}));
    }

    getPrintTemplatesByCategoryAndName(cat: string, name: string): Observable<PrintTemplate[]> {
        return this._httpClient.get<PrintTemplate[]>(this.env.api(this.templateService, ['templates'], {
            category: cat,
            name: name
        }));
    }

    getTemplateByID(templateID: string): Observable<PrintTemplate> {
        return this._httpClient.get<PrintTemplate>(this.env.api(this.templateService, ['templates', templateID]));
    }

    getAvailableTemplateCategories(): string[] {
        return ['layerstackdefinition', 'quotation', 'global', 'specification'];
    }

    updateTemplate(template: PrintTemplate): Observable<PrintTemplate> {
        return this._httpClient.put<PrintTemplate>(this.env.api(this.templateService, ['templates', template.id]), template);
    }

    render(template: PrintTemplate, variables: any): Observable<HttpResponse<Blob>> {
        return this._httpClient.put(this.env.api(this.templateService, ['templates', template.id, 'render']), variables, {
            headers: {
                'Accept': 'application/pdf'
            },
            observe: 'response',
            responseType: 'blob'
        });
    }

    deleteTemplate(templateID: string) {
        return this._httpClient.delete(this.env.api(this.templateService, ['templates', templateID]));
    }

    selectPrintTemplateForCategory(cat: string): Observable<PrintTemplate> {
        return this.getPrintTemplatesByCategory(cat).pipe(
            mergeMap(templates => {
                if (templates.length == 0) {
                    return of(null)
                } else if (templates.length == 1) {
                    return of(templates[0])
                } else {

                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.disableClose = false;
                    dialogConfig.autoFocus = true;
                    dialogConfig.width = '40vw';
                    dialogConfig.data = {
                        templates: templates
                    };

                    const dialogRef: MatDialogRef<SelectTemplateDialogComponent, PrintTemplate> = this._dialog.open(
                        SelectTemplateDialogComponent,
                        dialogConfig
                    );

                    return dialogRef.afterClosed()
                }
            })
        )

    }
}
