import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AvatarItem} from '../avatar-list';
import {User} from '../../../main/authentication/user';
import {Customer} from '../../../common/customer';

@Component({
    selector: 'user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

    @Output()
    selectionChanged: EventEmitter<User> = new EventEmitter();

    _users: AvatarItem [] = [];
    _userList: Customer [] = [];

    constructor() {
    }

    ngOnInit() {
    }

    onSelectUser(avatar: AvatarItem): void {

    }

}
