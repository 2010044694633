<div class="flex flex-col justify-center">
    <div class="flex flex-row">
        <div class="flex flex-grow align-middle">
            {{message}}
        </div>
        <div *ngIf="buttonText" class="flex align-middle px-2">
            <a class="splash-button">
                {{buttonText}}
            </a>
        </div>
        <div class="flex justify-end">
            <a (click)="stackrateSnackbar.close()" class="flex align-middle cursor-pointer ">
                <mat-icon>close</mat-icon>
            </a>
        </div>
    </div>
</div>
