import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Profile } from '../../../../common/profile.model';
import { ProfileService } from '../../../../common/profile.service';
import { SnackbarService } from '../../../../common-ui/snackbar/snackbar.service';
import { ProgressBarService } from '../../../../common-ui/progressbar/progress-bar.service';
import { TranslocoService } from '@ngneat/transloco';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { StackrateSnackbarService } from '../../../../generic-components/snackbar/stackrate-snackbar.service';

@Component({
    selector: 'profile-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {
    generalForm: FormGroup;
    passwordValueChanged = false;
    profile: Profile = new Profile();
    horizontalPosition: MatSnackBarHorizontalPosition = 'start';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';


    constructor(
        private profService: ProfileService,
        private _snackBarService: SnackbarService,
        private _fuseProgressBarService: ProgressBarService,
        private _translocoService: TranslocoService,
        private _snackBar: StackrateSnackbarService
    ) {
        this.generalForm = new FormGroup({
            firstName: new FormControl('', { validators: Validators.required }),
            lastName: new FormControl('', { validators: Validators.required }),
            country: new FormControl('', { validators: Validators.required }),
            phone: new FormControl('', {})
        });
    }

    ngOnInit(): void {
        this.loadProfileDetails();
    }

    /**
     * Submits the general profile information to the backend. Rises a snackbar if any error occurs
     */
    onSubmit(): void {
        this._fuseProgressBarService.show();

        this.profile.data.firstName = this.generalForm.get('firstName').value;
        this.profile.data.lastName = this.generalForm.get('lastName').value;
        this.profile.data.country = this.generalForm.get('country').value;
        this.profile.data.info.phone = this.generalForm.get('phone').value;

        this.profService.update(this.profile).subscribe(
            data => {
                this._fuseProgressBarService.hide();
            },
            error => {
                this._fuseProgressBarService.hide();
                this._snackBarService.openSnackBar(
                    this._translocoService.translate('Settings-UPDATE-PROFILE-ERROR'),
                    this._translocoService.translate('General-CLOSE')
                );
            }
        );

        this._snackBar.open('Profile updated!');
    }

    private loadProfileDetails(): void {
        this.profService.loadMyProfile().subscribe(p => {
            this.profile = p;
            if (!this.profile.data.info) {
                this.profile.data.info = {};
            }
            this.generalForm.patchValue(this.profile.data);
            this.generalForm.patchValue(this.profile.data.info);
        });
    }
}
