<span *ngIf="!isButton" [style.color]="color" class="tag-element rounded-lg text-base">
    {{text}}
</span>


<span (click)="onClick()" *ngIf="isButton"
      class="tag-element button-tag rounded-lg text-base">
    <span [style.background-color]="isActive ? color : inactiveColor" class="dot"></span>
    <span [style.color]="isActive ? color : inactiveColor"> {{text}} </span>
</span>
