import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {FlexLayoutModule} from '@angular/flex-layout';
import {InlineEditComponent} from './inline-edit.component';
import {SharedModule} from '../../core/shared/shared.module';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [InlineEditComponent],
    imports: [
        SharedModule,
        CommonModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        FlexLayoutModule
    ],
    exports: [InlineEditComponent]
})
export class InlineEditModule {
}
