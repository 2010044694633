import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Team, User} from './user';
import {ApiToken} from './api-token';
import {EnvironmentService} from '../../common/env/environment.service';
import {LoginService} from '../../common/auth/login.service';
import {AutoUnsubscribable} from '../../helpers/autounsub';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserService extends AutoUnsubscribable {

    static user_endpoint = 'user';

    private currentUsers: BehaviorSubject<User[]> = new BehaviorSubject([]);

    constructor(private _http: HttpClient, private env: EnvironmentService, private _lgn: LoginService) {
        super();
        this._lgn.getJWTPayload().pipe(
            takeUntil(this._unsubscribeAll),
            map(p => p?.team),
            distinctUntilChanged()).subscribe(team => {
            if (team && team !== '') {
                this.getUsersByTeam(team).subscribe(users => this.currentUsers.next(users));
            } else {
                this.currentUsers.next([]);
            }
        });
    }

    // get all teams
    getTeams(domainName?: string): Observable<Team[]> {
        const query = domainName ? {domain: domainName} : {};
        return this._http.get<Team[]>(this.env.api(UserService.user_endpoint, ['teams'], query));
    }

    // create a team
    createTeam(team: Team): Observable<any> {
        return this._http.post<any>(this.env.api(UserService.user_endpoint, ['teams']), team);
    }

    // delete (disable) a team
    deleteTeam(team: Team): Observable<any> {
        return this._http.delete<any>(this.env.api(UserService.user_endpoint, ['teams', team.domain]));
    }


    // update team
    updateTeam(teamID: string, team: Team): Observable<any> {
        return this._http.put<any>(this.env.api(UserService.user_endpoint, ['teams', teamID]), team);
    }

    // add user to teams
    addUserToTeams(userID: string, teams: Team[]): Observable<any> {
        return this._http.put<any>(this.env.api(UserService.user_endpoint, [userID, 'teams']), teams.map(t => t.domain));
    }

    // add user to teams
    addUserToTeamsByEmailOrName(emailOrName: string, teams: Team[]): Observable<any> {
        const query = { emailOrName };
        const body =  teams.map(t => t.domain);
        return this._http.put<any>(this.env.api(UserService.user_endpoint, ['teams'], query), body);
    }

    // delete user from team
    removeUserFromTeam(userID: string, domain: string): Observable<any> {
        return this._http.delete<any>(this.env.api(UserService.user_endpoint, [userID, 'teams', domain]));
    }


    // get users for team
    getUsersByTeam(teamDomain: string): Observable<User[]> {
        return this._http.get<User[]>(this.env.api(UserService.user_endpoint, ['teams', teamDomain, 'users']));
    }

    getUserInfo(id: string): Observable<User> {
        return this.currentUsers.pipe(map(users => users.find(u => u.uid === id)));
    }

    // invite a new user with an invitation email, return invite code
    inviteUserByMail(mailAddress: string, team: string, language: string, greeting?: string): Observable<string> {
        let param = {};
        if (greeting.length > 0) {
            param = {mail: mailAddress, sendNotification: true, greeting: greeting};
        } else {
            param = {mail: mailAddress, sendNotification: false};
        }
        if (language) {
            param['language'] = language;
        }

        return this._http.post(this.env.api(UserService.user_endpoint, ['teams', team, 'invite']), param, {responseType: 'text'});
    }

    generateNewToken(userID: string, teamID: string): Observable<ApiToken> {
        return this._http.put<ApiToken>(this.env.api(UserService.user_endpoint, [userID, 'teams', teamID, 'token']), {});
    }
}
