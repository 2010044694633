<div class="FileDropAreaContainer flex flex-col flex-grow">
    <ngx-file-drop (onFileDrop)="dropped($event)" (onFileLeave)="fileLeave($event)" (onFileOver)="fileOver($event)"
        [disabled]="(_assService.getCurrentAssembly() | async)?.currentVersion.released || disabled"
        class="ngx-file-drop">

        <ng-template let-openFileSelector="openFileSelector" ngx-file-drop-content-tmp class="p-3">
            <div *ngIf="!showTiny" class="flex flex-row flex-grow justify-center">

                <div class="flex flex-row  w2/3  justify-evenly">
                    <img class="image-logo" matTooltip="Gerber X2 Format" src="/assets/icons/cadtools/gerber.png">
                    <img class="image-logo" matTooltip="{{'Tooltip-EAGLE-BRD-FILES' | transloco }}"
                        src="/assets/icons/cadtools/eagle.png">
                    <img class="image-logo"
                        matTooltip="{{'Tooltip-NATIVE-KICAD-PROJECTS' | transloco }}"
                        src="/assets/icons/cadtools/kicad.png">
                    <div class="px-8" fxLayoutAlign="center center">
                        <button (click)="dropZoneClicked($event)" color="accent"
                            mat-raised-button>{{"Project_assemblies-BROWSE-FILES" | transloco }}
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="showTiny" class="flex flex-col justify-center">
                <div [ngClass]="disabled ? 'disabled':'active'" class="h3 py-3">
                    {{"Project_assemblies-DROP-FILES-TO-UPLOAD" | transloco }}<span *ngIf="!showTiny">,
                        or</span>
                </div>
                <button *ngIf="!showTiny" (click)="dropZoneClicked($event)" [disabled]="disabled" color="accent"
                    mat-raised-button>{{"Project_assemblies-BROWSE-FILES" | transloco }}
                </button>
            </div>
        </ng-template>

    </ngx-file-drop>
    <div *ngIf="!showTiny" class="pt-3 pr-3 flex flex-row justify-end">
        <div (click)="showSupportedFilesDialog()"
            class="font-weight-900 text-sm pr-1 supported-formats flex flex-row justify-center">
            <div>
                <mat-icon class="text-base">info</mat-icon>
            </div>
            <div class="justify-center">{{"Project_assemblies-CHECK-SUPPORTED-FORMATS" | transloco }}
            </div>
        </div>
    </div>
</div>