import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FuseModule } from '@fuse/fuse.module';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/fuse/config/app.config';
import { LayoutModule } from 'app/layout/layout.module';
import { LoggerModule } from 'ngx-logger';
import { NgxPendoModule } from 'ngx-pendo';
import { FuseDrawerModule } from '../@fuse/components/drawer';
import { FuseConfigModule } from '../@fuse/services/config';
import { environment, logLevel, serverLogLevel } from '../environments/environment';
import { IconsModule } from './core/icons/icons.module';
import { SharedModule } from './core/shared/shared.module';
import { TranslocoCoreModule } from './core/transloco/transloco.module';
import { AuthGuard } from './helpers/AuthGuard';
import { BearerInterceptor } from './helpers/bearer.interceptor';
import { LayoutComponent } from './layout/layout.component';
import { CollaborationModule } from './main/collaboration/collaboration.module';
import { AssemblyListService } from './main/project/assembly/assembly-list.service';
import { PCBFilesModule } from './main/project/assembly/assembly-view/files/pcbfiles.module';
import { PCBListService } from './main/project/assembly/assembly-view/pcb/PCBListService';
import { ConfInitializerService } from './services/conf-initializer.service';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'project',
        pathMatch: 'full',
        // canActivate: [AuthGuard]
    },

    // Auth routes for unauthorized users
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'auth',
                loadChildren: () =>
                    import('./main/authentication/authentication.module').then((m) => m.AuthenticationModule),
            },
            {
                path: 'error',
                loadChildren: () => import('./main/error/error.module').then((m) => m.ErrorModule),
            },
            {
                path: 'share',
                loadChildren: () => import('./main/share/share.module').then((m) => m.ShareModule),
            },
        ],
    },

    // Routes with full layout
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'classy',
        },
        children: [
            {
                path: 'settings',
                loadChildren: () => import('./main/settings/settings.module').then((m) => m.SettingsModule),
                canActivateChild: [AuthGuard],
            },
            {
                path: 'project',
                loadChildren: () => import('./main/project/project.module').then((m) => m.ProjectModule),
                canActivateChild: [AuthGuard],
            },
            {
                path: 'shares',
                loadChildren: () => import('./main/shares/shares.module').then((m) => m.SharesModule),
                canActivateChild: [AuthGuard],
            },
            // {
            //     path: 'collaboration',
            //     loadChildren: () => import('./main/collaboration/collaboration.module').then(m => m.CollaborationModule),
            //     canActivateChild: [AuthGuard]
            // },
            {
                path: 'customer',
                loadChildren: () => import('./main/customer/customer.module').then((m) => m.CustomerModule),
                canActivateChild: [AuthGuard],
            },
            {
                path: 'quotations',
                loadChildren: () => import('./main/quotation/quotation.module').then((m) => m.QuotationModule),
                canActivateChild: [AuthGuard],
            },
            {
                path: 'stackup',
                loadChildren: () => import('./main/layer-stack/layer-stack.module').then((m) => m.LayerStackModule),
                canActivateChild: [AuthGuard],
            },
            {
                path: 'supplier',
                loadChildren: () => import('./main/supplier/supplier.module').then((m) => m.SupplierModule),
                canActivateChild: [AuthGuard],
            },
            {
                path: 'pricings',
                loadChildren: () => import('./main/pricing/pricing.module').then((m) => m.PricingModule),
                canActivateChild: [AuthGuard],
            },
            {
                path: 'capabilities',
                loadChildren: () => import('./main/capability/capability.module').then((m) => m.CapabilityModule),
                canActivateChild: [AuthGuard],
            },
            {
                path: 'team',
                loadChildren: () => import('./main/permission/team/team.module').then((m) => m.TeamModule),
                // canActivateChild: [AuthGuard]
            },
            {
                path: 'group',
                loadChildren: () => import('./main/permission/group/group.module').then((m) => m.GroupModule),
                canActivateChild: [AuthGuard],
            },
        ],
    },
];

export function appInit(appConfigService: ConfInitializerService) {
    return () => appConfigService.load();
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        SharedModule,
        TranslocoCoreModule,
        IconsModule,
        BrowserModule,
        NgxPendoModule.forRoot({
            pendoApiKey: 'b7af9119-c2d4-47e5-4648-1f380810ad81',
            pendoIdFormatter: (value: any) => value.toString().toLowerCase(),
        }),
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            enableTracing: environment.debugRouter,
            paramsInheritanceStrategy: 'always',
        }),

        LoggerModule.forRoot({ level: logLevel, serverLogLevel: serverLogLevel }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,

        // Fuse modules
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        // App modules
        LayoutModule,
        MatToolbarModule,
        MatMenuModule,
        // OnlineCalculatorModule,
        PCBFilesModule,
        MatDialogModule,
        MatFormFieldModule,
        CollaborationModule,
        FuseDrawerModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: BearerInterceptor, multi: true },
        { provide: APP_INITIALIZER, multi: true, useFactory: appInit, deps: [ConfInitializerService] },
        AssemblyListService,
        PCBListService,
    ],
})
export class AppModule {}
