<ng-container *transloco="let t">
    <mat-dialog-content class="content p-16">
        <form class="" fxLayout="column wrap" fxLayoutAlign="start" name="form" [formGroup]="passwordForm">
            <div class="text-xl mb-24">{{t("Settings-CHANGE-PASSWORD")}}</div>

            <!-- Old Password -->
            <div fxLayout="row wrap">
                <div fxLayout="row wrap" fxFlex="100">
                    <mat-form-field class="p-8" fxFlex="100">
                        <mat-label>{{t("Settings-OLD-PASSWORD")}}</mat-label>
                        <input matInput id="input-oldpw" formControlName="oldPw" type="password" />
                        <mat-icon matSuffix class="text-secondary">lock</mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <!-- New Password -->
            <div fxLayout="row wrap">
                <div fxLayout="row wrap" fxFlex="100">
                    <mat-form-field class="p-8" fxFlex="100">
                        <mat-label>{{t("Settings-NEW-PASSWORD")}}</mat-label>
                        <input matInput id="input-newpw" formControlName="newPw" type="password" />
                        <mat-icon matSuffix class="text-secondary">lock</mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <!-- New Password Repeat -->
            <div fxLayout="row wrap">
                <div fxLayout="row wrap" fxFlex="100">
                    <mat-form-field class="p-8" fxFlex="100">
                        <mat-label>{{t("Settings-REPEAT-PASSWORD")}}</mat-label>
                        <input matInput id="input-newpw2" formControlName="newPw2" type="password" />
                        <mat-icon matSuffix class="text-secondary">lock</mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row wrap">
                <span class="p-8">
                    <button mat-raised-button color="warn" (click)="onClose()">
                        {{t("General-CANCEL-CAPS")}}
                    </button>
                </span>
                <span class="p-8">
                    <button mat-raised-button color="primary" (click)="onSubmit()" id="btn-submit"
                        [disabled]="passwordForm.invalid">
                        {{ t("General-SUBMIT-CAPS") }}
                    </button>
                </span>
            </div>
        </form>
    </mat-dialog-content>
</ng-container>