import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, switchMap} from 'rxjs';
import {Quotation, QuotationItem, QuotationItemInfo, QuotationListItem, QuotationStatus} from './quotation';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {map, mergeMap, tap} from 'rxjs/operators';
import {CustomerService} from '../customer/customer.service';
import {AutoUnsubscribable} from '../../helpers/autounsub';
import {
    AbstractAssemblyReference,
    Assembly, AssemblyReference,
    AssemblyWithReference,
    SharedAssembly, SharedAssemblyReference
} from '../../pcb-common/assembly/assembly';
import {LoginService} from '../../common/auth/login.service';
import {EnvironmentService} from '../../common/env/environment.service';
import {ProgressBarService} from '../../common-ui/progressbar/progress-bar.service';
import {PaginationInfo} from './quotation-pagination.service';
import {PaginationListResult} from '../../common/PaginationListResult';
import {UUID} from "angular2-uuid";
import {PrintTemplateService} from "../settings/print-template/print-template.service";

@Injectable({
    providedIn: 'root'
})
export class QuotationService extends AutoUnsubscribable {

    quotationEndpoint = '/quotation';

    private quotationListSubject: BehaviorSubject<Quotation[]> = new BehaviorSubject([]);
    private createdQuotationMap: Map<string, string> = new Map<string, string>([]);

    constructor(private _httpClient: HttpClient,
                private _loginService: LoginService,
                private _customerService: CustomerService,
                private _progress: ProgressBarService,
                private env: EnvironmentService,
                private _templates: PrintTemplateService
    ) {
        super();
        // this.loadQuotations();

        // const fakeQuotation = new Quotation();
        // this.quotation.next(fakeQuotation);

        this.loadQuotations();
        // this.createFakeQuotationsForTest();
    }

    public subscribeQuotationFromList(name: string): Observable<Quotation> {
        return this.quotationListSubject.pipe(map(ql => ql.find(q => q.name === name)));
    }

    public subscribeQuotationFromListByStatus(status: string): Observable<Quotation[]> {
        return this.quotationListSubject.pipe(map(ql => ql.filter(q => q.status?.name === status)?.sort(
            (a, b) => new Date(b?.created)?.getTime() - new Date(a?.created)?.getTime()
        )));
    }

    public getCachedQuotationID(name: string): string | undefined {
        if (this.createdQuotationMap.has(name)) {
            return this.createdQuotationMap.get(name);
        } else {
            return undefined;
        }
    }


    public loadQuotations(): void {
        this._progress.show();
        this.getQuotationsForTeam()
            .subscribe(
                data => {
                    this.quotationListSubject.next(data.results);
                    this._progress.hide();
                },
                () => {
                    this._progress.hide();
                }
            );
    }

    public getQuotationsForTeam(): Observable<PaginationListResult<Quotation>> {
        return this._httpClient.get<PaginationListResult<Quotation>>(this.env.environment.api + this.quotationEndpoint + '/quotations');
    }


    public getQuotationForAssembly(assemblyVersion: string): Observable<PaginationListResult<Quotation>> {
        // http://gateway.test.electronic-fellows.de/_openapi/quotation/rapidoc#get-/api/quotation/search

        const queryParams = {
            'version': assemblyVersion,
        };

        return this._httpClient.get<PaginationListResult<Quotation>>(this.env.api(this.quotationEndpoint, ['search'], queryParams));
    }

    public getQuotationForCustomer(customerID: string): Observable<PaginationListResult<Quotation>> {
        // http://gateway.test.electronic-fellows.de/_openapi/quotation/rapidoc#get-/api/quotation/search

        const queryParams = {
            'customer': customerID,
        };
        return this._httpClient.get<PaginationListResult<Quotation>>(this.env.api(this.quotationEndpoint, ['search'], queryParams));
    }


    public updateQuotationStatus(quotationName: string, status: QuotationStatus): Observable<Quotation> {
        return this._httpClient.put<Quotation>(this.env.api(this.quotationEndpoint, ['quotations', quotationName, 'status'], {}), status).pipe(
            tap(q => {
                const list = this.quotationListSubject.getValue();
                const index = list?.findIndex(qu => qu.name === quotationName);
                if (index >= 0) {
                    list [index] = q;
                    this.quotationListSubject.next(list);
                }
            })
        );
    }


    public searchQuotations(page: number, pagesize: number, paginationInfo: PaginationInfo): Observable<PaginationListResult<QuotationListItem>> {

        const params = {
            'page': page.toString(),
            'pagesize': pagesize.toString(),
        };

        if (paginationInfo.filter.status) {
            params['status'] = paginationInfo.filter.status;
        }
        if (paginationInfo.filter.customer) {
            params['customer'] = paginationInfo.filter.customer;
        }
        if (paginationInfo.filter.quotation) {
            params['quotation'] = paginationInfo.filter.quotation;
        }
        if (paginationInfo.filter.creator) {
            params['creator'] = paginationInfo.filter.creator;
        }
        if (paginationInfo.filter.origin) {
            params['origin'] = paginationInfo.filter.origin;
        } else {
            params['origin'] = 'manual';
        }

        return this._httpClient.get<PaginationListResult<QuotationListItem>>(this.env.api(this.quotationEndpoint, ['search'], params));
    }

    // this._httpClient.put<Quotation>(api('/', ['', 'api', 'quotation', 'quotations', quotation], queryParams), body);

    public addQuotationItem(item: QuotationItem): Observable<any> {
        return this._httpClient.post<QuotationItem>(this.env.api(this.quotationEndpoint, ['quotationitems']), item);
    }

    public setQuotationItem(itemId: string, item: QuotationItemInfo): Observable<any> {
        return this._httpClient.put<QuotationItem>(this.env.api(this.quotationEndpoint, ['quotationitems', itemId]), item);
    }


    public deleteQuotationItem(itemID: string): Observable<any> {
        return this._httpClient.delete<QuotationItem[]>(this.env.api(this.quotationEndpoint, ['quotationitems', itemID]));
    }

    public getQuotationItemsForAssembly(assembly: AbstractAssemblyReference): Observable<QuotationItem[]> {
        const query = {version: assembly.getReferenceIdentifier()};
        return this._httpClient.get<QuotationItem[]>(this.env.api(this.quotationEndpoint, ['quotationitems'], query));
    }

    public createQuotationForShare(a: SharedAssembly, ref: AbstractAssemblyReference, items: QuotationItem[]): Observable<Quotation | string> {

        if (!a.information.customer) {
            return of('Please add a customer to this assembly before creating a quotation.')
        } else {
            return this.assembleQuotationParams(a.information.customer.toString()).pipe(
                switchMap(params => {
                    params['assembly'] = ref
                    params['items'] = items.map(i => i.id)
                    params['requestDate'] = a.created
                    return this._httpClient.post<Quotation>(this.env.api('quotation', ['quotations']), params).pipe(
                        tap(qc => this.createdQuotationMap.set(qc.name, qc.quotationId))
                    );
                })
            )
        }
    }

    public createQuotation(aref: AssemblyWithReference, items: QuotationItem[]): Observable<Quotation | string> {
        const assembly = aref.assembly
        if (!assembly.information.customer) {
            return of('Please add a customer to this assembly before creating a quotation.')
        } else {

            return this.assembleQuotationParams(assembly.information.customer).pipe(
                switchMap(params => {
                    params['assembly'] = aref.reference
                    params['items'] = items.map(i => i.id)
                    params['requestDate'] = assembly.created
                    return this._httpClient.post<Quotation>(this.env.api('quotation', ['quotations']), params).pipe(
                        tap(qc => this.createdQuotationMap.set(qc.name, qc.quotationId))
                    );
                })
            )
        }
    }

    private assembleQuotationParams = (customer: string) => this._customerService.getCustomer(customer, true)
        .pipe(
            map(
                customer => {
                    const params = {
                        customerId: customer.id,
                    };

                    if (customer.addresses && customer.addresses.length > 0) {
                        params['billing'] = customer.addresses[0]
                        params['shipping'] = customer.addresses[0]
                    }

                    return params
                }
            )
        );

}
