import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Constants, Library, Material} from '../layerstack/layer-stack-definition';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {MaterialColumnSettingsDefaultMaps} from './material-column-names';
import {LoginService} from '../../common/auth/login.service';
import {EnvironmentService} from '../../common/env/environment.service';


@Injectable({
    providedIn: 'root'
})
export class MaterialService {
    public allLibrariesLabel = 'All Libraries';

    constructor(private _http: HttpClient,
                private _loginService: LoginService,
                private e: EnvironmentService) {
    }

    public getFlatLibraries(page: number, pagesize: number): Observable<Library[]> {
        return this._http.get<Library[]>(this.e.api(
            Constants.layerstackEndpoint,
            ['libraries'],
            {
                'flat': 'true',
                'page': page.toString(),
                'pagesize': pagesize.toString(),
            }
        ));
    }


    /**
     * Create a new Library
     * @param library the library. if it has a UUID it *will* be ignored and given a new one
     */
    public createLibrary(library: Library): Observable<Library> {
        return this._http.post<Library>(this.e.api(Constants.layerstackEndpoint, ['libraries']), library);
    }

    public getLibrary(name: string): Observable<Library> {
        return this._http.get<Library>(this.e.api(Constants.layerstackEndpoint, ['libraries', name]));
    }

    public getLibraries(page: number, pagesize: number, matFilter: string): Observable<Library[]> {
        return this._http.get<Library[]>(this.e.api(Constants.layerstackEndpoint, ['libraries'], {
            'page': page.toString(),
            'pagesize': pagesize.toString(),
            'filter': matFilter
        }));
    }

    /**
     * Upload files that represents material libraries.
     * Accepts .csv and .mlbx
     *
     * @param file - uploaded file from user
     */
    public uploadLibraryFromFile(file: File): Observable<Library[]> {

        const isCSV = file.type === 'text/csv' || file.name.includes('.csv');
        const isMLBX = file.name.includes('.mlbx');
        const teamDomain = this._loginService.getTeam();

        if (isCSV || isMLBX) {
            const formData: FormData = new FormData();
            formData.append('fileKey', file, file.name);

            const path = isCSV ? 'csv' : 'mlbx';
            return this._http.post<Library[]>(this.e.files(Constants.layerstackEndpoint, ['teams', teamDomain, 'libraries', 'convert', path]), formData);
        } else {
            return null;

        }
    }

    public getLibraryTypes(): Observable<Map<string, number>> {
        return this._http.get<[string, number][]>(this.e.api(Constants.layerstackEndpoint, ['metrics', 'libraries', 'types'])).pipe(
            map(l => {
                const m = new Map<string, number>();
                l.forEach(e => m.set(e[0], e[1]));
                return m;
            })
        );
    }

    public getLibrarySuppliers(): Observable<Map<string, number>> {
        return this._http.get<[string, number][]>(this.e.api(Constants.layerstackEndpoint, ['metrics', 'libraries', 'supplier'])).pipe(
            map(l => {
                const m = new Map<string, number>();
                l.forEach(e => m.set(e[0], e[1]));
                return m;
            })
        );
    }

    public getLibraryCount(): Observable<number> {
        return this._http.get<number>(this.e.api(Constants.layerstackEndpoint, ['metrics', 'libraries', 'count']));
    }

    public getMaterialTypes(): Observable<Map<string, number>> {
        return this._http.get<[string, number][]>(this.e.api(Constants.layerstackEndpoint, ['metrics', 'materials', 'types'])).pipe(
            map(l => {
                const m = new Map<string, number>();
                l.forEach(e => m.set(e[0], e[1]));
                return m;
            })
        );
    }

    public getMaterialSuppliers(): Observable<Map<string, number>> {
        return this._http.get<[string, number][]>(this.e.api(Constants.layerstackEndpoint, ['metrics', 'materials', 'supplier'])).pipe(
            map(l => {
                const m = new Map<string, number>();
                l.forEach(e => m.set(e[0], e[1]));
                return m;
            })
        );
    }

    public getMaterialCount(): Observable<number> {
        return this._http.get<number>(this.e.api(Constants.layerstackEndpoint, ['metrics', 'materials', 'count']));
    }

    public updateLibrary(name: string, library: Library): Observable<Library> {
        return this._http.put<Library>(this.e.api(Constants.layerstackEndpoint, ['libraries', name]), library);
    }

    public deleteLibrary(name: string): Observable<any> {
        return this._http.delete<any>(this.e.api(Constants.layerstackEndpoint, ['libraries', name]));
    }

    /**
     * Get all materials by library
     * @param libName the library name
     * @param flat if true, the returned materials will have no meta info set (for traffic purposes), should usually be false
     */
    public getMaterialsByLibrary(libName: string, flat: boolean, matFilter?: string): Observable<Material[]> {
        const param = {
            'flat': String(flat)
        };
        if (matFilter) {
            param['filter'] = matFilter;
        }
        return this._http.get<Material[]>(this.e.api(Constants.layerstackEndpoint, ['libraries', libName, 'materials'], param));
    }

    public getMaterial(materialID: string): Observable<Material> {
        return this._http.get<Material>(this.e.api(Constants.layerstackEndpoint, ['materials', materialID]));
    }

    /**
     * Get all materials
     * @param page pagination
     * @param pagesize pagination
     * @param matFilter filter the materials:
     *          comma separated string of key,operator,value. For instance: foo=bar
     *          keys can be "name", "materialType" or any property of the metadata (case sensitive)
     *          operators are =, !=, <, >, <=, >=.
     *          when comparing strings, the operators <, >, <=, >= all mean "contains"
     *
     * @param flat if true, the materials have filled metainfo. has to be true, if the matFilter contains metaInfo
     */
    public getMaterials(page: number, pagesize: number, matFilter: string, flat: boolean): Observable<Material[]> {

        const param = {
            'page': page.toString(),
            'pagesize': pagesize.toString(),
            'flat': String(flat)
        };
        if (matFilter) {
            param['filter'] = matFilter;
        }

        return this._http.get<Material[]>(this.e.api(Constants.layerstackEndpoint, ['materials'],
            param
        ));
    }

    public updateMaterial(materialID: string, material: Material): Observable<Material> {
        return this._http.put<Material>(this.e.api(Constants.layerstackEndpoint, ['materials', materialID]), material);
    }

    public addMaterialToLib(library: string, material: string): Observable<Library> {
        return this._http.put<Library>(this.e.api(Constants.layerstackEndpoint, ['libraries', library, 'materials', material]), {});
    }

    public removeMaterialFromLib(library: string, material: string): Observable<Library> {
        return this._http.delete<Library>(this.e.api(Constants.layerstackEndpoint, ['libraries', library, 'materials', material]), {});
    }

    public deleteMaterial(material: string): Observable<Library> {
        return this._http.delete<Library>(this.e.api(Constants.layerstackEndpoint, ['materials', material]), {});
    }


    public createMaterials(materials: Material[], lib?: string): Observable<Material[]> {
        let params = {};
        if (lib) {
            params = {
                'library': lib
            };
        }
        return this._http.post<Material[]>(this.e.api(Constants.layerstackEndpoint, ['materials'], params), materials);
    }

    public createMaterial(material: Material, lib?: string): Observable<Material> {
        return this.createMaterials([material], lib).pipe(
            map(m => m[0])
        );
    }

    public getColumnNames(category: string): Observable<string []> {
        return this.getLibraries(1, 1, 'type=' + category).pipe(map(m => {
            let colName: string[] = [];
            if (m[0]) {
                this.getMaterials(1, 1, '', false).subscribe(materials => {
                    if (materials[0]) {
                        const obj = materials[0].meta;
                        colName = Object.keys(obj);
                        return colName;
                    }
                });
            }
            return colName;
        }));
    }

    // get Header
    public getColumnNameSetting(category: string): string [] {
        if (category) {
            const header = MaterialColumnSettingsDefaultMaps.get(category.toLowerCase());
            return header;
        }
        return [];
    }

}
