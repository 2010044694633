import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MechanicalCardComponent } from './pcb-file-manager/mechanical-card/mechanical-card.component';
import { PcbFileManagerComponent } from './pcb-file-manager/pcb-file-manager.component';
import { PcbFileItemListComponent } from './pcb-file-manager/pcb-file-item-list/pcb-file-item-list.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatButtonModule } from '@angular/material/button';
import { PCBFileDropComponent } from './pcb-file-manager/pcbfile-drop/pcbfile-drop.component';
import { MaterialPageHeaderModule } from '../../../../../helpers/material-page-header/material-page-header.module';
import { FabNavigatorModule } from '../dashboard/fab-navigator/fab-navigator.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HelperModule } from '../../../../../helpers/helper.module';
import { FileInspectionDialogComponent } from './pcb-file-manager/file-inspection-dialog/file-inspection-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { DocumentViewerModule } from '../document-viewer/document-viewer.module';
import { OutlineSelectorViewerModule } from './outline-selector-viewer/outline-selector-viewer.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BottomSheetSetOutlineComponent } from './pcb-file-manager/bottom-sheet-set-outline/bottom-sheet-set-outline.component';
import { MatDividerModule } from '@angular/material/divider';
import { PcbFileManagerSupportedFilesDialogComponent } from './pcb-file-manager/pcb-file-manager-supported-files-dialog/pcb-file-manager-supported-files-dialog.component';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { SharedModule } from 'app/core/shared/shared.module';

@NgModule({
    declarations: [
        MechanicalCardComponent,
        PcbFileManagerComponent,
        PcbFileItemListComponent,
        PCBFileDropComponent,
        FileInspectionDialogComponent,
        BottomSheetSetOutlineComponent,
        PcbFileManagerSupportedFilesDialogComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MaterialPageHeaderModule,
        NgxFileDropModule,
        MatButtonModule,
        FabNavigatorModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatBadgeModule,
        MatExpansionModule,
        HelperModule,
        FlexLayoutModule,
        MatDialogModule,
        MatMenuModule,
        DocumentViewerModule,
        OutlineSelectorViewerModule,
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,
        MatListModule,
        SharedModule
    ],
    entryComponents: [
        BottomSheetSetOutlineComponent, FileInspectionDialogComponent
    ],
    exports: [
        PcbFileManagerComponent, PCBFileDropComponent
    ],
})
export class PCBFilesModule {
}
