import {Claim} from './permission';

export class JWT {
    authToken: string;
    refreshToken: string;
}

export class JWTRefresh {
    authToken: string;
}

export class JWTPayload {
    username: string;
    userId: string;
    email: string;
    exp: number;
    iat: number;
    team: string;
    claims: Claim[];
}

export class InvitationToken {
    team: string;
    inviter: string;
    mail: string;
}

export function parseTokenPayload<T>(token: string): T {
    return JSON.parse(atob(token.split('.')[1]));
}