import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, first, map, tap} from 'rxjs/operators';


import {NGXLogger} from 'ngx-logger';
import {assignMap, unassignMap} from './object-utils';
import {LoginService} from './auth/login.service';
import {EnvironmentService} from './env/environment.service';
import {Profile, ProfileInfo} from './profile.model';
import {AvatarService, ImageSizes} from '../main/settings/profile/avatar/avatar.service';
import {TranslocoService} from "@ngneat/transloco";
import {MaterialColumnSettingsDefaultMaps} from "../layerstack-common/material/material-column-names";

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    public firstName: string;
    public lastName: string;
    public avatar: string;
    public MaterialSettingsKey = 'MATERIAL_SETTINGS';

    profileEndpoint = '/profile/users';
    private profile: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(null);

    private profiles: BehaviorSubject<Map<String, Profile | string>> = new BehaviorSubject<Map<String, Profile | string>>(new Map());

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _loginService: LoginService,
        private logger: NGXLogger,
        private env: EnvironmentService,
        private _avatar: AvatarService,
        private _translocoService: TranslocoService,
    ) {
        this._loginService.getLoginSubject().subscribe(token => {
            if (token != null) {
                this.onLogin();
            }
        });
    }

    public getFullNameObs(): Observable<string> {
        return this.profile.pipe(
            filter(x => x != null && x.data != null),
            map(p => {
                return this.toName(p.data.firstName, p.data.lastName);
            })
        );
    }

    public getFullName(): string {
        return this.toName(this.firstName, this.lastName);
    }

    toName(fname?: string, lname?: string): string {
        let s = '';
        if (fname) {
            s = fname;
        }

        if (lname) {
            s = s + ' ' + lname;
        }

        return s;
    }


    public loadProfile(user: string): Observable<Profile> {
        if (this.profile.value && this.profile.value.user === user) {
            return this.profile.pipe(first());
        } else {
            if (!this.profiles.value.has(user)) {
                const cur = this.profiles.value;
                cur.set(user, '...');
                this.profiles.next(cur);

                this._httpClient.get<Profile>(this.env.environment.api + this.profileEndpoint + '/' + user).subscribe(p => {
                    p.avatar = this._avatar.getUserImage(user, p.avatar, ImageSizes.MEDIUM);

                    const m = this.profiles.value;
                    m.set(user, p);
                    this.profiles.next(m);
                }, e => {
                    const m = this.profiles.value;
                    m.delete(e);
                    this.profiles.next(m);
                });
            }
            return this.profiles.pipe(map(m => m.get(user)), filter(x => typeof x !== 'string'), map(x => x as Profile), first());
        }

    }

    public getProfileWithCheck(includeNull: boolean): Observable<Profile> {
        if (!includeNull) {
            return this.profile.pipe(filter(x => !!x));
        } else {
            return this.profile.asObservable();
        }
    }

    public getProfile(): Observable<Profile> {
        return this.getProfileWithCheck(true);
    }

    updateInfo(user: string, p: ProfileInfo): Observable<any> {
        return this._httpClient.put<Profile>(this.env.environment.api + this.profileEndpoint + '/' + user, p).pipe(
            tap(z => {
                this.setProfileInfo(p);
            })
        );
    }

    update(p: Profile): Observable<any> {
        return this.updateInfo(p.user, p.data);
    }

    onLogin(): void {
        this.loadProfile(this._loginService.getCurrentUserId()).subscribe(p => {
            this.setProfile(p);
            this.avatar = p.avatar;
            if(p?.data?.language) {
                this._translocoService.setActiveLang(p.data.language)
            }
        });
    }

    getImagePath(): string {
        return this.avatar;
    }

    public loadMyProfile(): Observable<Profile> {
        return this.profile.pipe(filter(s => s !== null), first());
    }

    public hasMaterialSettings(info: any): boolean {
        if (info) {
            return info[this.MaterialSettingsKey] !== null;
        }
        return false;
    }

    public getMaterialSettings(info: ProfileInfo): Map<string, string[]> {
        if (info && info.info && this.hasMaterialSettings(info)) {
            return assignMap(info?.info[this.MaterialSettingsKey]);
        }

        return MaterialColumnSettingsDefaultMaps;
    }


    // private materialSettings: Map<string, string[]> = new Map<string, string[]>([]);

    public saveMaterialSettings(settings: Map<string, string[]>): Observable<any> {

        const p = this.profile.getValue();

        if (!p.data.info) {
            p.data.info = new Map([]);
        }

        p.data.info[this.MaterialSettingsKey] = unassignMap(settings);
        this.profile.next(p);
        return this.update(p);
    }


    private setProfileInfo(p: ProfileInfo): void {
        // this.logger.debug('set profile to ', p);
        const pr = this.profile.value;
        pr.data = p;
        this.profile.next(pr);
    }

    private setProfile(p: Profile): void {
        // this.logger.debug('set profile to ', p);
        this.profile.next(p);
    }
}



