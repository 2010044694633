import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutlineSelectorViewerComponent } from './outline-selector-viewer.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MaterialPageHeaderModule } from '../../../../../../helpers/material-page-header/material-page-header.module';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatButtonModule } from '@angular/material/button';
import { FabNavigatorModule } from '../../dashboard/fab-navigator/fab-navigator.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HelperModule } from '../../../../../../helpers/helper.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from 'app/core/shared/shared.module';

@NgModule({
    declarations: [OutlineSelectorViewerComponent],
    imports: [
        CommonModule,
        CommonModule,
        MatIconModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MaterialPageHeaderModule,
        NgxFileDropModule,
        MatButtonModule,
        FabNavigatorModule,
        MatCheckboxModule,
        MatTooltipModule,
        HelperModule,
        MatDialogModule,
        MatMenuModule,
        SharedModule
    ],
    entryComponents: [OutlineSelectorViewerComponent]
})
export class OutlineSelectorViewerModule {
}
