import {Pipe, PipeTransform} from '@angular/core';
import {PredefinedTimelineChange, SpecificTimelineChange, TimelineGuest, TimelineSystemUser} from './collaboration';

@Pipe({
    name: 'SpecificChange'
})
export class AsSpecificChangePipe implements PipeTransform {

    transform(value: any, ...args: unknown[]): SpecificTimelineChange {
        return value;
    }

}


@Pipe({
    name: 'PredefinedChange'
})
export class AsPredefinedChangePipe implements PipeTransform {

    transform(value: any, ...args: unknown[]): PredefinedTimelineChange {
        return value;
    }

}


@Pipe({
    name: 'SystemUser'
})
export class AsSystemUserPipe implements PipeTransform {

    transform(value: any, ...args: unknown[]): TimelineSystemUser {
        return value;
    }

}

@Pipe({
    name: 'GuestUser'
})
export class AsGuestUserPipe implements PipeTransform {

    transform(value: any, ...args: unknown[]): TimelineGuest {
        return value;
    }

}
