import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {LoginService} from '../../../../common/auth/login.service';
import {ProfileService} from '../../../../common/profile.service';
import {EnvironmentService} from '../../../../common/env/environment.service';
import {ProgressBarService} from '../../../../common-ui/progressbar/progress-bar.service';

export enum ImageSizes {
    TINY = '&quality=tiny',
    ORIGINAL = '&quality=original',
    SMALL = '&quality=small',
    MEDIUM = '&quality=medium',
    LARGE = '&quality=large',
}

@Injectable({
    providedIn: 'root'
})
export class AvatarService {
    gravatar: boolean;
    public defaultAvatar = 'assets/images/avatars/profile.jpg';

    constructor(
        private _httpClient: HttpClient,
        private domSanitizer: DomSanitizer,
        private _progressBarService: ProgressBarService,
        private _loginService: LoginService,
        private logger: NGXLogger,
        private env: EnvironmentService
    ) {

        // this._profileService.getProfile().subscribe(profile => {
        //     // console.log('update avaatar for logged in user', profile);
        //     // if (!size) {
        //     //     size = ImageSizes.SMALL;
        //     // }
        //     const userId = profile.user;
        //     const avatarPath = profile.avatar;
        //
        //     if (avatarPath) {
        //         this.img.next(this.env.environment.files + '/profile/users/' + userId + '/' + avatarPath);
        //     } else {
        //
        //         this.img.next(this.defaultAvatar);
        //     }
        // });
    }


    getDefaultAvatar(): string {
        return this.defaultAvatar;
    }

    uploadFile(image: File): Observable<any> {
        const userId = this._loginService.getCurrentUserId();
        const endpoint = this.env.environment.files + '/profile/users/' + userId + '/avatar';
        const formData: FormData = new FormData();
        formData.append('fileKey', image, image.name);
        return this._httpClient.post(endpoint, formData);
    }


    getUserImage(userId: string, avatarPath: string, size?: ImageSizes): string {
        if (avatarPath) {
            if (!size) {
                size = ImageSizes.SMALL;
            }

            let base = this.env.environment.files + '/profile/users/';
            if (avatarPath.startsWith(base)) {
                return avatarPath;
            } else {
                return base + userId + '/' + avatarPath + size.toString();
            }
        } else {
            return this.getDefaultAvatar();
        }
    }


}
