import {Injectable} from '@angular/core';
import {CollaborationService} from './collaboration.service';
import {
    buildAssemblyDashboardTimelineRequest,
    buildTimelineRequestForID,
    CollaborationEntities,
    CollaborationTimeLineView,
    DefaultTimeLineEntities,
    DefaultTimeLineEntityNames,
    TimelineRequest
} from './collaboration';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FuseDrawerService} from '../../../@fuse/components/drawer';

@Injectable({
    providedIn: 'root'
})
export class CollaborationUiService {

    private timeLineFilters: BehaviorSubject<string []> = new BehaviorSubject<string[]>([CollaborationEntities.ASSEMBLY, CollaborationEntities.PCB,]);
    private defaultTimeLineFilterList: string [] = DefaultTimeLineEntities;
    private _unsubscribeSubject: Subject<any> = new Subject<any>();

    constructor(private _collaborationService: CollaborationService,
                private _fuseDrawerService: FuseDrawerService) {

    }

    public openCollaborationSidebar(): void {
        this._fuseDrawerService.getComponent('timelineSidebar').toggle();
    }

    public closeCollaborationSidebar(): void {
        this._fuseDrawerService.getComponent('timelineSidebar').toggle();
    }

    public toggleCollaborationSidebar(): void {
        this._fuseDrawerService.getComponent('timelineSidebar').toggle();
    }

    public loadTimLine(viewType: CollaborationTimeLineView, id: string, version?: string): void {


        // set filters for depending on the view
        switch (viewType) {
            // ------------------------- ASSEMBLY -------------------------
            case CollaborationTimeLineView.ASSEMBLY_DASHBOARD:
                this.clearTimeLineFilters(
                    [CollaborationEntities.ASSEMBLY,
                        CollaborationEntities.PCB,
                        CollaborationEntities.CUSTOMER_PANEL,
                        CollaborationEntities.LAYERSTACK_USAGE,
                        CollaborationEntities.QUOTATION,
                        CollaborationEntities.PCB_SPECIFICATION]);
                break;
            // ------------------------- SPECIFICATION -------------------------
            case CollaborationTimeLineView.SPECIFICATION_DASHBOARD:
                this.clearTimeLineFilters([CollaborationEntities.ASSEMBLY, CollaborationEntities.PCB_SPECIFICATION]);
                break;
            case CollaborationTimeLineView.SPECIFICATION_VIEW:
                this.clearTimeLineFilters([CollaborationEntities.PCB_SPECIFICATION]);
                break;
            // ------------------------- PANEL -------------------------
            case CollaborationTimeLineView.PANEL_DASHBOARD:
                this.clearTimeLineFilters([CollaborationEntities.ASSEMBLY, CollaborationEntities.CUSTOMER_PANEL]);
                break;
            case CollaborationTimeLineView.PANEL_CREATOR_VIEW:
                this.clearTimeLineFilters([CollaborationEntities.CUSTOMER_PANEL]);
                break;
            // ------------------------- PCB -------------------------
            case CollaborationTimeLineView.GERBER_VIEWER:
                this.clearTimeLineFilters([CollaborationEntities.PCB]);
                break;
            // ------------------------- WEBDFM -------------------------
            case CollaborationTimeLineView.WEBDFM_DASHBOARD:
                this.clearTimeLineFilters([CollaborationEntities.ASSEMBLY, CollaborationEntities.PCB]);
                break;
            case CollaborationTimeLineView.WEBDFM_VIEW:
                this.clearTimeLineFilters([CollaborationEntities.PCB, CollaborationEntities.DFM_VIOLATIONS]);
                break;
            // ------------------------- QUOTATION -------------------------
            case CollaborationTimeLineView.QUOTATION_DASHBOARD:
                this.clearTimeLineFilters([CollaborationEntities.QUOTATION, CollaborationEntities.QUOTATION_ITEM]);
                break;
            case CollaborationTimeLineView.PRICING_CENTER_DASHBOARD:
                this.clearTimeLineFilters([CollaborationEntities.QUOTATION, CollaborationEntities.QUOTATION_ITEM]);
                break;
            // ------------------------- QUOTATION -------------------------
        }

        // subscribe and set timeline
        this.clearTimeLine();
        this.subscribeTimeLineFilters().pipe(takeUntil(this._unsubscribeSubject)).subscribe(filterList => {
            let req: TimelineRequest;
            switch (viewType) {
                case CollaborationTimeLineView.ASSEMBLY_DASHBOARD:
                case CollaborationTimeLineView.PRICING_CENTER_DASHBOARD:
                case CollaborationTimeLineView.WEBDFM_DASHBOARD:
                case CollaborationTimeLineView.SPECIFICATION_DASHBOARD:
                case CollaborationTimeLineView.PANEL_DASHBOARD:
                    req = buildAssemblyDashboardTimelineRequest(filterList, id, version);
                    break;
                case CollaborationTimeLineView.QUOTATION_DASHBOARD:
                case CollaborationTimeLineView.WEBDFM_VIEW:
                case CollaborationTimeLineView.GERBER_VIEWER:
                case CollaborationTimeLineView.SPECIFICATION_VIEW:
                case CollaborationTimeLineView.PANEL_CREATOR_VIEW:
                    req = buildTimelineRequestForID(filterList, id);
                    break;
            }
            this._collaborationService.setTimeline(req);
        });

    }

    public clearTimeLine(): void {
        this._collaborationService.clearTimeLineCount();
        this._unsubscribeSubject.next(true);
    }


    public clearTimeLineFilters(initWithFilters = []): void {
        this.timeLineFilters.next(initWithFilters);
    }

    public getDefaultTimeLineFilters(): string [] {
        return this.defaultTimeLineFilterList;
    }

    public subscribeTimeLineFilters(): Observable<string []> {
        return this.timeLineFilters.asObservable();
    }


    public translateFilterName(entity: string): string {
        if (DefaultTimeLineEntityNames.has(entity)) {
            return DefaultTimeLineEntityNames.get(entity);
        }
    }

    public toggleFilterElement(entity: string): void {
        const list = this.timeLineFilters.getValue();
        if (list.find(n => n === entity)) {
            this.addOrRemoveEntityFromFilter(entity, false);
        } else {
            this.addOrRemoveEntityFromFilter(entity, true);
        }
    }


    private addOrRemoveEntityFromFilter(entity: string, add = true): void {

        const list = this.timeLineFilters.getValue();
        if (add) {
            list.push(entity);
        } else {
            const index = list.findIndex(n => n === entity);
            if (index >= 0) {
                list.splice(index, 1);
            }
        }
        console.log('list is ', list);
        this.timeLineFilters.next(list);
    }

}
