import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  constructor() { }

    hide(): void {
    }

    show(): void {
    }

    setMode(s: string): void {
    }
}
