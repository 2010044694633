import {Injectable} from '@angular/core';
import {BehaviorSubject, mergeMap, Observable} from 'rxjs';
import {Pcb2Service} from './pcb2.service';
import {filter, first, map, startWith, takeUntil} from 'rxjs/operators';
import {G} from '@svgdotjs/svg.js';
import {DefaultDrillColors, DrillStyle} from './drill';
import {
    blockingLayerClasses,
    classNameToLayerType,
    DefaultColors,
    fileTypeToClassName,
    layerClassesCopperNames,
    RigidGreenSolderMask,
    TraceHighLightColors
} from './pcb2-color-scheme';
import {
    PropertyToFinishColors,
    PropertyToSilkscreenColors,
    PropertyToSolderMaskColors,
    SpecKey
} from '../specification/PCBSpecification';
import {LayerStackService} from './layer-stack.service';
import {NGXLogger} from 'ngx-logger';
import {partition} from 'lodash-es';
import {Layer} from '../../../../../pcb-common/pcb/layer-stack';
import {LayerFile, LayerStyle} from '../../../../../pcb-common/pcb/pcb';
import {PcbGraphicsService} from "./pcb-graphics.service";
import {MaterialLibraryTypes} from "../../../../../layerstack-common/material/material-constants";

export class RenderedLayer {
    public layer?: Layer;
    public file?: LayerFile;
    public idx?: number;

    constructor(layer?: Layer, file?: LayerFile, idx?: number) {
        this.layer = layer;
        this.file = file;
        this.idx = idx;
    }

    public hash(): string {
        let fileId = '-';
        let layerId = '-';
        if (this.file) {
            fileId = '' + this.file.id;
        }
        if (this.layer) {
            layerId = this.layer.definition.id;
        }
        return [layerId, fileId].join('-');
    }

    public thickness(): number {

        let uppercu: number
        let lowercu: number

        if (this.idx == 0) {
            uppercu = this.layer?.definition?.material?.meta['uppercuthickness']
        }

        if (this.idx == 1) {
            lowercu = this.layer?.definition?.material?.meta['lowercuthickness']
        }

        const thickness = this.layer?.definition?.meta['cuthickness'];
        const material = this.layer?.definition?.material?.meta['cuthickness']
        const maskthickness = this.layer?.definition?.material?.meta['maskthickness']
        const basethickness = this.layer?.definition?.material?.meta['basethickness']

        return (uppercu || lowercu || thickness || material || maskthickness || basethickness || 10) / 1000
    }

    isCopper() {
        const lt = this.layer?.definition?.layerType
        return lt && lt == MaterialLibraryTypes.FOIL || (lt == MaterialLibraryTypes.CORE && this.file) || (lt == MaterialLibraryTypes.FLEX_CORE && this.file);
    }
}

@Injectable({
    providedIn: 'root'
})
export class PcbUiService {
    topView = true;
    scaleFactor: number = 1 / 100;
    pcbUnit = '';

    prevLayerScheme: Map<String, LayerStyle> = null;
    layerGroupRelation: Map<string, G>;

    private layerSchemeObservable = new BehaviorSubject<Map<String, LayerStyle>>(DefaultColors)

    idToFile: Map<string, RenderedLayer> = new Map();
    drillCompensationMap: Map<number, number> = new Map();
    private visible: BehaviorSubject<Map<string, Boolean>> = new BehaviorSubject(new Map());
    private visibleUpdate: BehaviorSubject<[string, Boolean]> = new BehaviorSubject(null);
    private editLayerstack: BehaviorSubject<Boolean> = new BehaviorSubject(false);
    private isHighlightMode = false;

    constructor(private _pcbService: Pcb2Service, private _layerStackService: LayerStackService, public logger: NGXLogger, private _graphicsService: PcbGraphicsService) {
        this.layerGroupRelation = new Map();

        console.log('create UI Service');

        this._pcbService
            .getOutline()
            .pipe(
                filter(m => m != null),
                mergeMap(m => this._graphicsService.outline(m.id))
            )
            .subscribe(outline => {
                this.setTopView();
                if (outline && outline.format) {
                    const f = outline.format;
                    this.pcbUnit = f.unit;

                    if (f.unit === 'in') {
                        this.scaleFactor = 25.4 / f.scaling;
                    } else {
                        this.scaleFactor = 1 / f.scaling;
                    }
                    this.logger.debug('scaling : ' + this.scaleFactor);
                }
            })
    }

    public static getDrillStyle(type: string): DrillStyle {
        return DefaultDrillColors.get(type);
    }

    public static getLayerStyle(layer: RenderedLayer | string, layerScheme: Map<String, LayerStyle>): LayerStyle {
        let style = null;
        if (layer instanceof RenderedLayer) {
            if (layer.file) {
                style = layerScheme.get(layer.file.fileType.fileType);
            } else if (layer.layer && layer.layer.definition && layer.layer.definition.layerType) {
                style = layerScheme.get(layer.layer.definition.layerType);
            }
        } else {
            style = layerScheme.get(classNameToLayerType(layer));
        }

        if (style) {
            return style;
        } else {
            return layerScheme.get('Undefined');
        }
    }

    //
    // COMMON
    //
    public getCompensatedDrillIndex(cuIndex: number): number {
        if (this.drillCompensationMap.has(cuIndex)) {
            return this.drillCompensationMap.get(cuIndex);
        }
        return 0;
    }

    public setHighLightMode(active: boolean): void {
        this.isHighlightMode = active;
        if (active) {
            // remember layer scheme
            this.prevLayerScheme = this.layerSchemeObservable.getValue();

            const offLayerTypes: string[] = ['PasteTop', 'PasteBottom', 'SoldermaskTop', 'SoldermaskBottom', 'SilkscreenTop', 'SilkscreenBottom'];
            this._layerStackService
                .subscribeLayerStack()
                .pipe(
                    filter(m => m != null && m.stacks.length > 0),
                    first()
                )
                .subscribe(ls => {
                    const rlayers = LayerStackService.toRenderedStack(ls);

                    const onLayers = rlayers.filter(l => {
                        if (l.file && l.file.fileType.fileType) {
                            return !offLayerTypes.includes(l.file.fileType.fileType);
                        }
                        if (l.layer && l.layer.definition.material) {
                            return true;
                        }

                    });
                    this.setVisibility(false, ...rlayers);
                    this.setVisibility(true, ...onLayers);

                    this.setColorScheme(TraceHighLightColors);
                });
        } else {
            this.setColorScheme(DefaultColors);
            if (this.topView) {
                this.setTopView();
            } else {
                this.setBottomView();
            }
        }
    }

    public setRelation(l: RenderedLayer, layerIndex: number, g: G, copper?: number): void {
        if (l && g) {
            const className = fileTypeToClassName(l);
            g.addClass(className);

            // if (className === 'copper_mid'
            //     || className === 'plane_mid'
            //     || className === 'copper_top'
            //     || className === 'copper_bot') {
            if (copper) {
                console.log('set rel to ', copper, ' for file ', l.file);
                this.drillCompensationMap.set(copper, layerIndex);
                g.addClass('l' + copper.toFixed(0));
            }

            this.logger.warn('set rel', l.hash(), g);
            this.layerGroupRelation.set(l.hash(), g);
            this.idToFile.set(l.hash(), l);
        }
    }

    public getLayerGroupByHash(layerHash: string): G {
        return this.layerGroupRelation.get(layerHash);
    }

    public getHighestActiveCopperLayer(): G {
        const vis = this.visible.getValue();
        const groups = [...vis.keys()].map(layerHash => this.layerGroupRelation.get(layerHash));

        if (groups) {
            const visibleGroups = groups.filter(g => g.visible());
            if (this.isTopView()) {
                return visibleGroups.find(g => this.isCopperLayer(g.classes()));
            } else {
                return visibleGroups.slice().reverse().find(g => this.isCopperLayer(g.classes()));
            }
        }

        return null;
    }

    public getHighestViewBlockingLayerIndex(): number {
        let index = 0;
        const vis = this.visible.getValue();
        const groups = [...vis.keys()].map(layerHash => this.layerGroupRelation.get(layerHash));
        const stack = [...this.layerGroupRelation.values()];


        if (groups) {
            // get visible layers that are view blocking
            const visibleGroups = groups.filter(g => g && g.visible() && this.isViewBlockingLayer(g.classes()));

            const layerIndices: number[] = [];
            visibleGroups.forEach(g => {
                layerIndices.push(stack.indexOf(g));
            });

            if (layerIndices && layerIndices.length > 0) {
                index = layerIndices.reduce(function (a, b): any {
                    return Math.max(a, b);
                });
            }
        }

        return index;
    }

    public getLayerGroup(layer: RenderedLayer): G {
        // this.logger.debug('get group from ', this.layerGroupRelation, 'for layer', layer);
        return this.layerGroupRelation.get(layer.hash());
    }

    public resetRelations(): void {
        this.layerGroupRelation.clear();
        this.idToFile.clear();
    }

    public hideAllLayersByNameExcept(fileName: string): void {

        this._layerStackService
            .subscribeLayerStack()
            .pipe(
                filter(m => m != null && m.stacks.length > 0),
                first()
            )
            .subscribe(ls => {
                const rlayers = LayerStackService.toRenderedStack(ls);
                const rlayer = partition(rlayers, x => x.file && x.file.name === fileName);

                this.setVisibility(true, ...rlayer[0]);
                this.setVisibility(false, ...rlayer[1]);
            });
    }

    public showOnlyOneLayer(exLayer: RenderedLayer): void {
        const cur: Map<string, Boolean> = this.visible.getValue();
        const v = new Map<string, Boolean>();
        v.set(exLayer.hash(), true); // exLayer is visible
        this.visible.next(v); // set the visibility state

        // notify update stream
        cur.forEach((val: Boolean, lay: string) => {
            this.visibleUpdate.next([lay, lay === exLayer.hash()]); // only exLayer is true
        });
    }

    public setAll(layers: RenderedLayer[], visible: boolean) {

        const v = new Map<string, Boolean>();
        this.visible.next(v); // set the visibility state

        layers.forEach(l => v.set(l.hash(), visible))
        // notify update stream
        v.forEach((val: Boolean, lay: string) => {
            this.visibleUpdate.next([lay, val]); // only exLayer is true
        });
    }

    public isVisible(): Observable<[string, Boolean]> {
        return this.visibleUpdate.pipe(startWith(...Array.from(this.visible.getValue().entries())));
    }

    public toggleView(): void {
        if (this.topView) {
            this.setBottomView();
        } else {
            this.setTopView();
        }
    }

    public toggleViewState(): void {
        this.topView = !this.topView;
    }

    public isTopView(): boolean {
        return this.topView;
    }

    public getScaleFactor(): number {
        return this.scaleFactor;
    }


    public resetVisibility(): void {
        this.setTopView();
    }

    public toggleLayerVisible(layer: RenderedLayer): void {
        const newState = !this.visible.getValue().get(layer.hash());
        this.setVisibility(newState, layer);
    }

    public setLayerVisible(layer: RenderedLayer, state: boolean): void {
        this.setVisibility(state, layer);
    }

    public setSilkScreenVisibilityState(top: boolean, bottom: boolean): void {

        this.visible.getValue().forEach((val, l) => {
            const ren = this.idToFile.get(l);
            if (ren && ren.file && this.layerGroupRelation.get(l)) {
                if (ren.file.fileType.fileType === 'SilkscreenTop') {
                    this.layerGroupRelation
                        .get(l)
                        .animate()
                        .attr({
                            opacity: top ? 1 : 0
                        });
                }
                if (ren.file.fileType.fileType === 'SilkscreenBottom') {
                    this.layerGroupRelation
                        .get(l)
                        .animate()
                        .attr({
                            opacity: bottom ? 1 : 0
                        });
                }
            }
        });
    }

    public setSolderMaskVisibilityState(top: boolean, bottom: boolean): void {
        this.visible.getValue().forEach((val, l) => {
            const ren = this.idToFile.get(l);

            if (ren && ren.file && this.layerGroupRelation.get(l)) {
                const type = ren.file.fileType.fileType;
                if (type === 'SoldermaskTop') {

                    this.layerGroupRelation
                        .get(l)
                        .animate()
                        .attr({
                            opacity: top ? RigidGreenSolderMask.get(type).opacity : 0
                        });
                }
                if (type === 'SoldermaskBottom') {
                    this.layerGroupRelation
                        .get(l)
                        .animate()
                        .attr({
                            opacity: bottom ? RigidGreenSolderMask.get(type).opacity : 0
                        });
                }
            }
        });
    }

    public getLayerStackEditSubject(): Observable<Boolean> {
        return this.editLayerstack.asObservable();
    }

    public setLayerStackEdit(edit: Boolean): void {
        this.editLayerstack.next(edit);
    }

    public setColorScheme(newScheme: Map<String, LayerStyle>): void {
        let value = this.layerSchemeObservable.getValue();
        if (newScheme !== value) {
            if (this.isHighlightMode) {
                // when highlight mode is active, opacity's needed to be layer  different
                newScheme.forEach((style, layerName,) => {
                    style.opacity = TraceHighLightColors.get(layerName).opacity;
                });
                this.layerSchemeObservable.next(newScheme);
                this.updateColorScheme();
            } else {
                this.layerSchemeObservable.next(newScheme);
                this.updateColorScheme();
            }
        }
    }

    public updateColorScheme(): void {
        this.layerGroupRelation.forEach((g: G, l: string) => {
            const renderedLayer = this.idToFile.get(l);
            // const layername = g.node.className.baseVal;
            const that = this;
            let name = '';
            if (renderedLayer.file) {
                name = renderedLayer.file.fileType.fileType;
            } else {
                name = renderedLayer.layer.definition.layerType;
            }
            g.attr('fill', null);

            const scheme = this.layerSchemeObservable.getValue()
            g.animate().attr({
                fill: scheme.get(name) ? scheme.get(name).color : scheme.get('Undefined').color,
                opacity: scheme.get(name) ? scheme.get(name).opacity : scheme.get('Undefined').opacity,
            });
        });
    }

    public getLayerStyle(layer: RenderedLayer | string): LayerStyle {
        return PcbUiService.getLayerStyle(layer, this.layerSchemeObservable.getValue());
    }

    public changeColorSchemeElements(newFinish: Map<String, LayerStyle>): void {
        const s = this.layerSchemeObservable.getValue();
        this.logger.debug('change soldermask color');
        newFinish.forEach((style: LayerStyle, layerName: String) => {
            s.set(layerName, style);
        });

        this.layerSchemeObservable.next(s);
    }

    public setProperties(props: any): void {

        if (props) {

            const color = props[SpecKey.COLOR];
            if (color) {
                this.setColorProperty(color, PropertyToSolderMaskColors);
            }
            const finish = props[SpecKey.FINISH];
            if (finish) {
                this.setColorProperty(finish, PropertyToFinishColors);

            }
            const silkscreen = props[SpecKey.SILK_COLOR];
            if (silkscreen) {
                this.setColorProperty(silkscreen, PropertyToSilkscreenColors);
            }

            this.updateColorScheme(); // must be called before solderMask/silkscreen setting

            const solderMaskVisibility = props[SpecKey.SOLDERMASK_SIDES];
            this.setSolderMaskVisibility(solderMaskVisibility);

            const silkScreenVisibility = props[SpecKey.SILK_SIDES];
            this.setSilkScreenVisibility(silkScreenVisibility);

        }
    }

    public getLayerColor(layer: RenderedLayer | string): string {
        return this.getLayerStyle(layer).color;
    }

    public getLayerOpacity(layer: RenderedLayer | string): number {
        return this.getLayerStyle(layer).opacity;
    }

    public setBottomView(): void {
        this.topView = false;
        this._layerStackService
            .subscribeLayerStack()
            .pipe(
                filter(m => m != null && m.stacks.length > 0),
                first()
            )
            .subscribe(ls => {
                const rlayers = LayerStackService.toRenderedStack(ls);

                let index = rlayers.findIndex(l => !l.file &&
                    (l.layer.definition.layerType === 'core' ||
                        l.layer.definition.layerType === 'dielectric' ||
                        l.layer.definition.layerType === 'flexcore' ||
                        l.layer.definition.layerType === 'prepreg'
                    )
                ) + 1;

                index = rlayers.length - index;

                const offlayer = rlayers.slice(0, index);
                const onlayer = rlayers.slice(index).filter(f => {
                    if (f.file) {
                        return f.file.fileType.fileType !== 'PasteBottom';
                    } else {
                        return true;
                    }
                });

                this.setVisibility(true, ...onlayer);
                this.setVisibility(false, ...offlayer);
            });
    }

    public setTopView(): void {
        this.topView = true;
        this._layerStackService
            .subscribeLayerStack()
            .pipe(
                filter(m => m != null && m.stacks.length > 0),
                first()
            )
            .subscribe(ls => {
                const rlayers = LayerStackService.toRenderedStack(ls);

                const index = rlayers.findIndex(l => !l.file &&
                    (l.layer.definition.layerType === 'core' ||
                        l.layer.definition.layerType === 'dielectric' ||
                        l.layer.definition.layerType === 'flexcore' ||
                        l.layer.definition.layerType === 'prepreg'
                    )
                ) + 1;

                const onlayer = rlayers.slice(0, index).filter(f => {
                    if (f.file) {
                        return f.file.fileType.fileType !== 'PasteTop';
                    } else {
                        return true;
                    }
                });

                const offlayer = rlayers.slice(index);

                // const newonlayer = onlayer.filter(f => {
                //     if (f.material === 'core' || f.material === 'dielectric') {
                //         return true;
                //     } else {
                //         if (f.file) {
                //             return f.file.fileType.fileType !== 'PasteTop';
                //         } else {
                //             return false;
                //         }
                //     }
                // });

                this.setVisibility(true, ...onlayer);
                this.setVisibility(false, ...offlayer);
            });
    }

    private isCopperLayer(classes: string[]): boolean {
        let isCopper = false;

        classes.forEach(c => {
            if (layerClassesCopperNames.includes(c)) {
                isCopper = true;
                return;
            }
        });

        return isCopper;
    }

    private isViewBlockingLayer(classes: string[]): boolean {
        let isBlocking = false;

        classes.forEach(c => {
            if (blockingLayerClasses.includes(c)) {
                isBlocking = true;
                return;
            }
        });

        return isBlocking;
    }

    private setVisibility(state: boolean, ...layers: Array<RenderedLayer>): void {
        layers.forEach(layer => {
            const v = this.visible.getValue();
            v.set(layer.hash(), state);
            this.visible.next(v);
            this.visibleUpdate.next([layer.hash(), state]);
        });
    }

    private setColorProperty(prop: string, propertyColor: Map<string, Map<String, LayerStyle>>): void {

        if (prop) {
            const newScheme = propertyColor.get(prop);
            if (newScheme) {
                this.changeColorSchemeElements(newScheme);
            }
        }
    }

    private setSolderMaskVisibility(prop: string): void {
        console.log('soldermask side:', prop);
        if (prop) {
            switch (prop) {
                case 'top':
                case 't':
                    this.setSolderMaskVisibilityState(true, false);
                    break;
                case 'bottom':
                case 'b':
                    this.setSolderMaskVisibilityState(false, true);
                    break;
                case 'both':
                    this.setSolderMaskVisibilityState(true, true);
                    break;
                case 'none':
                    this.logger.debug('set to none');
                    this.setSolderMaskVisibilityState(false, false);
                    break;
            }
        }
    }

    private setSilkScreenVisibility(prop: string): void {
        if (prop) {
            switch (prop) {
                case 'top':
                case 't':
                    this.setSilkScreenVisibilityState(true, false);
                    break;
                case 'bottom':
                case 'b':
                    this.setSilkScreenVisibilityState(false, true);
                    break;
                case 'both':
                    this.setSilkScreenVisibilityState(true, true);
                    break;
                case 'none':
                    this.setSilkScreenVisibilityState(false, false);
                    break;
            }
        }
    }


    public observeLayerStyle(l: RenderedLayer | string): Observable<LayerStyle> {
        return this.layerSchemeObservable.pipe(
            map(x => PcbUiService.getLayerStyle(l, x)),
            filter(x => !!x)
        )
    }

    public observeLayerStyles(): Observable<Map<String, LayerStyle>> {
        return this.layerSchemeObservable
    }
}
