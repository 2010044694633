import {MaterialAttr} from '../material/material-constants';

export class Constants {
    public static layerstackEndpoint = '/ems/layerstack';
}

export enum FileTypes {
    OUTLINE = 'Outline',
    KEEP_OUT = 'KeepOut',
    PASTE_TOP = 'PasteTop',
    PASTE_BOTTOM = 'PasteBottom',
    SILKSCREEN_TOP = 'SilkscreenTop',
    SILKSCREEN_BOTTOM = 'SilkscreenBottom',
    SOLDERMASK_TOP = 'SoldermaskTop',
    SOLDERMASK_BOTTOM = 'SoldermaskBottom',
    COPPER_TOP = 'CopperTop',
    COPPER_MID = 'CopperMid',
    PLANE_MID = 'PlaneMid',
    COPPER_BOTTOM = 'CopperBottom',
    DRILL = 'Drill',
    PHDRILL = 'PHDrill',
    NPHDRILL = 'NPHDrill',
    DRILLSETS = 'DrillSets'
}

export enum MaterialType {
    foil = 'foil',
    prepreg = 'prepreg',
    core = 'core',
    soldermask = 'soldermask',
    coverlay = 'coverlay',
    flexcore = 'flexcore'
}

// adhesive layers are used to clue copper layers together
export const adhesiveLayers: string [] = [
    MaterialType.coverlay,
    MaterialType.prepreg,
    MaterialType.soldermask,
];
// non adhesive layers are layers with copper on outside or copper foils
export const nonAdhesiveLayers: string [] = [
    MaterialType.foil,
    MaterialType.core,
    MaterialType.flexcore,
];


export enum LayerStackType {
    Rigid = 'rigid',
    Flex = 'flex',
    RigidFlex = 'rigidflex',
    IMS = 'IMS'
}

export enum StackDrillType {
    PTH = 'PTH',
    NPTH = 'NPTH',
    Blind = 'Blind',
    Buried = 'Buried',
    Micro = 'Laser'
}

export const DrillTypeList: StackDrillType[] = [
    StackDrillType.PTH,
    StackDrillType.NPTH,
    StackDrillType.Blind,
    StackDrillType.Buried,
    StackDrillType.Micro,
];

export const DrillTypeNames: Map<string, string> = new Map<StackDrillType, string>([
    [StackDrillType.PTH, 'Plated via'],
    [StackDrillType.NPTH, 'Non-Plated Hole'],
    [StackDrillType.Blind, 'Blind via'],
    [StackDrillType.Buried, 'Buried via'],
    [StackDrillType.Micro, 'Micro via'],
]);

export const DrillColumnMaxCount = 6;

export class DrillSupport {
    col: number;
    from: number;
    to: number;
    drillType: StackDrillType;

    constructor(type?: StackDrillType, from ?: number, to?: number, col?: number) {
        this.drillType = type;
        this.from = from;
        this.to = to;
        this.col = col;
    }
}

export class LayerStackDescription {
    id: string;
    team: string;
    name: string; // Unique
    metaInfo: any;

    stackType: string
    copperCount: number

    image?: string

    price: LayerStackPrices
}

export class LayerStackDefinition {
    id: string;
    team: string;
    name: string; // Unique
    stacks: SubStackDefinition [] = [];
    metaInfo: any;
    price?: number; // provisional, will change
    image?: string; // currently unused
}

export class SubStackDefinition {
    name: string;
    offset: number;
    // layerMaterials: Material [] = [];
    layers: LayerDefinition [] = [];
    stackType: LayerStackType;
    height = 0;
    drills: DrillSupport [] = [];
}

export class LayerDefinition {
    id: string;
    layerType: string;
    meta: any;
    materialRef: string;
    material: Material;
}

export class Material {
    id: string;
    name: string;
    materialType: string;
    meta: any;
}

export class Library {
    name: string;
    id: string;
    supplier: string;
    libraryType?: string;
    materials?: string[] = [];
}

export class MaterialColumnSetting {
    name: string;
    active: boolean;

    constructor(name: string, active: boolean) {
        this.name = name;
        this.active = active;
    }
}

export function objectNameToColumnName(objName: string, currencyString: string): string {

    const replaceMap: Map<string, string> = new Map<string, string>(
        [
            [MaterialAttr.ArticleID, 'ID'],
            [MaterialAttr.AreaPrice, 'Area price [' + currencyString + '/m²]'],
            [MaterialAttr.UnitPrice, 'Unit price [' + currencyString + ']'],
            [MaterialAttr.Width, 'Width [mm]'],
            [MaterialAttr.Height, 'Height [mm]'],
            [MaterialAttr.SupplierDescription, 'Desc.'],
            [MaterialAttr.ExcessResin, 'Excess Resin'],
            [MaterialAttr.LossTangent, 'tan d'],
            [MaterialAttr.Description, 'Description'],
            [MaterialAttr.ResinContent, 'Resin content'],
            [MaterialAttr.BaseThickness, 'Base Thick.'],
            [MaterialAttr.Tg, 'TG'],
            [MaterialAttr.Td, 'TD'],
            [MaterialAttr.Supplier, 'Supplier'],
            [MaterialAttr.FinishedThickness, 'Fin. Thick.'],
            [MaterialAttr.Tolerance, 'Tol.'],
            [MaterialAttr.DielectricConstant, 'εr @ 1GHz'],
            [MaterialAttr.ZAxisExpansion, 'ZAxis'],
            [MaterialAttr.Type, 'Type'],
            [MaterialAttr.LowerCuThickness, 'Bot. Cu-Thick.'],
            [MaterialAttr.UpperCuThickness, 'Top. Cu-Thick.'],
            [MaterialAttr.InkThickness, 'Ink Thick.'],
            [MaterialAttr.CuThickness, 'Cu. Thick.'],
            [MaterialAttr.MaskThickness, ' Mask Thick.'],
            [MaterialAttr.Density, 'Density [kg/m³]']
        ]
    );

    const replaceName = replaceMap.get(objName);
    return replaceName ? replaceName : objName;
}

export enum SubStackUpdateMsgType {
    ADD_MATERIAL,
    ADD_DRILL,
    REMOVE_MATERIAL,
    REDRAW_DRILLS,
    REMOVE_DRILL,
    HOVER_MATERIAL,
    HOVER_DRILL,
    SELECT,
    SAVE,
    DOWNLOAD_SCREENSHOT,
    DRAW,
    REDRAW_ALL,
    REDRAW_SUBSTACK,
    ADD_SUB,
    REMOVE_SUB,
    ACTIVATE_SUB
}

export class SubStackUpdateMsg {
    type: SubStackUpdateMsgType;
    element: LayerDefinition | Material | DrillSupport | DrillSupport [] | Material[];
    subStack: SubStackDefinition;
    stack: LayerStackDefinition;
    pos0: number;
    pos1: number;
    subId: number;

    constructor(type: SubStackUpdateMsgType, subId?: number, element ?: LayerDefinition | Material | DrillSupport | DrillSupport [] | Material [], pos0?: number, pos1?: number) {
        this.element = element;
        this.subId = subId;
        this.type = type;
        this.pos0 = pos0;
        this.pos1 = pos1;
    }

    setSubStack(sub: SubStackDefinition): void {
        this.subStack = sub;
    }

    setLayerStack(stack: LayerStackDefinition): void {
        this.stack = stack;
    }
}

export function isLayerStackHeightAttribute(attr: string): boolean {
    const heightAttributes: string [] = [
        MaterialAttr.LowerCuThickness,
        MaterialAttr.CuThickness,
        MaterialAttr.UpperCuThickness,
        MaterialAttr.BaseThickness,
        MaterialAttr.MaskThickness,
        MaterialAttr.FinishedThickness
    ];
    return heightAttributes.includes(attr);
}

export function isMaterialNumberAttribute(attr: string): boolean {
    const numberAttributes: string [] = [
        MaterialAttr.AreaPrice,
        MaterialAttr.UnitPrice,
        MaterialAttr.ExcessResin,
        MaterialAttr.LossTangent,
        MaterialAttr.ResinContent,
        MaterialAttr.LowerCuThickness,
        MaterialAttr.CuThickness,
        MaterialAttr.UpperCuThickness,
        MaterialAttr.BaseThickness,
        MaterialAttr.MaskThickness,
        MaterialAttr.FinishedThickness,
        MaterialAttr.InkThickness,
        MaterialAttr.Tg,
        MaterialAttr.Td,
        MaterialAttr.Tolerance,
        MaterialAttr.DielectricConstant,
    ];
    return numberAttributes.includes(attr);
}

export const layerStackTargetHeights: number [] = [0.5, 1.0, 1.2, 1.4, 1.55, 1.6, 1.8, 2.0, 2.4, 3.0];
export const layerStackTolerances: number [] = [5, 10, 15, 20];
export const layerStackCopperHeights: number [] = [5, 9, 12, 18, 25, 35, 43, 70, 105, 140, 175, 210, 245, 280, 315, 350, 385, 400, 455, 490, 525, 560];
export const layerStackTgTypes: number [] = [130, 135, 150, 170, 180, 250];
export const layerStackMaxCuCount = 20;

export enum LayerStackMetaAttributes {
    CuCount = 'cucount',
    TotalHeight = 'totalheight',
    InnerCopperHeight = 'innercopperheight',
    OuterCopperHeight = 'outercopperheight',
    TargetHeight = 'targetheight',
    Tolerance = 'tolerance',
    ArticleId = 'articleid',
    AreaPrice = 'areaprice',
    UnitPrice = 'unitprice',
    MaterialTgClass = 'materialtg',

    drillSupportPTH = 'pth',
    drillSupportNPTH = 'npth',
    drillSupportBlind = 'blind',
    drillSupportBuried = 'buried',
}


export enum LayerStackExportFormat {
    IPC2581
}

export class LayerStackPrices {
    unitPrice: number;
    areaPrice: number;
}