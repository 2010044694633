import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AvatarItem} from '../avatar-list';
import {CustomerService} from '../../../main/customer/customer.service';
import {Contact} from '../../../common/customer';

@Component({
    selector: 'contact-list',
    templateUrl: './contact-list.component.html',
    styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {

    @Input() appearance = 'outline';
    @Output()
    selectionChanged: EventEmitter<Contact> = new EventEmitter();
    @Output()
    listChanged: EventEmitter<Contact[]> = new EventEmitter(); // not implemented
    _contacts: AvatarItem [] = [];
    _contactsList: Contact [] = [];
    _enabled = false;
    _staticAvatar: AvatarItem;

    constructor(private _customerService: CustomerService) {
    }

    @Input() set staticContact(staticContact: Contact) {
        if (staticContact) {
            this._enabled = false;
            const name = staticContact.firstName + ' ' + staticContact.lastName;
            this._staticAvatar = new AvatarItem(name, staticContact.id, staticContact.image);
        }
    }

    _customerID: string;

    @Input() set customerID(customerID: string) {
        this.updateContactList(customerID);
    }

    ngOnInit(): void {

    }

    onSelectContact(contact: AvatarItem): void {
        if (contact) {
            const c = this._contactsList.find(a => a.id === contact.id);
            if (c) {
                this.selectionChanged.emit(c);
            }
        } else {
            this.selectionChanged.emit(null);
        }
    }

    private updateContactList(id: string): void {
        if (id) {
            this._enabled = true;
            this._customerID = id;
            this._customerService.getContacts(this._customerID).subscribe(conList => {
                this._contactsList = conList;
                this._contacts = [];
                conList.forEach(c => {
                    const name = c.firstName + ' ' + c.lastName;
                    this._contacts.push(new AvatarItem(name, c.id, c.image));
                });
            });

        } else {
            this._enabled = false;
            this._contacts = null;
            this._contactsList = [];
        }
    }
}
