<ng-container *transloco="let t">
    <h1 mat-dialog-title>{{t('Title-DELETE-CONFIRMATION')}}</h1>
    <div mat-dialog-content>
        <p>{{ message }}</p>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-button id="btn-delete-no" color="primary" (click)="closeDialog(false)">
            {{ no_text.toUpperCase() }}
        </button>
        <button mat-button color="warn" id="btn-delete-yes" (click)="closeDialog(true)" cdkFocusInitial>
            {{ yes_text.toUpperCase() }}
        </button>
    </div>
</ng-container>