import { NgModule } from '@angular/core';
import { AccountComponent } from './account.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { PasswordChangeFormComponent } from './password-change-form/password-change-form.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SharedModule} from '../../../../core/shared/shared.module';

@NgModule({
    declarations: [AccountComponent, PasswordChangeFormComponent],
    imports: [
        MatCardModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatExpansionModule,
        MatDialogModule,
        MatIconModule,
        FlexLayoutModule,
        SharedModule
    ],
    exports: [AccountComponent],
    entryComponents: [PasswordChangeFormComponent]
})
export class AccountModule {}
