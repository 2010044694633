import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvironmentService} from '../../../../common/env/environment.service';

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    constructor(private _httpClient: HttpClient, private env: EnvironmentService) {
    }

    changePassword(oldPw: String, newPw: String): Observable<any> {
        const pwData = {oldPassword: oldPw, newPassword: newPw};
        return this._httpClient.put<any>(
            this.env.environment.api + '/user/changePassword',
            pwData
        );
    }

    deleteUserAccount(pw: string): Observable<any> {
        const login = {password: pw};
        return this._httpClient.post<any>(
            this.env.environment.api + '/user/delete',
            login
        );
    }

    changeEmail(newMail: string): Observable<any> {
        const mailData = {email: newMail};

        return this._httpClient.put<any>(
            this.env.environment.api + '/user/email',
            mailData
        );
    }
}
