export class AvatarItem {
    image: string;
    name: string;
    id: string;
    email: string;

    constructor(name: string, id: string, image: string, email?: string) {
        this.id = id;
        this.name = name;
        this.image = image;
        this.email = email;
    }
}