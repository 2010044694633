<ng-container *transloco="let t">
<div fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
    <h1 mat-dialog-title>{{t("General-FILE-UPLOAD")}}</h1>

    <div [@animate]="{ value: '*',  params: { delay: '300ms', y: '-25px' } }" class="pt-8 pb-16">

        <input (change)="fileChangeEvent($event)" class="pl-0" type="file"/>

        <image-cropper (cropperReady)="cropperReady()" (imageCropped)="imageCropped($event)"
                       (imageLoaded)="imageLoaded()"
                       (loadImageFailed)="loadImageFailed()" [aspectRatio]="format" [containWithinAspectRatio]="true"
                       [imageChangedEvent]="imageChangedEvent"
                       [maintainAspectRatio]="true"
                       [resizeToWidth]="512"
                       [roundCropper]="roundImage"
                       class="cropper"
                       format="png"></image-cropper>

        <img *ngIf="croppedImage" [ngClass]="{'cropped-image-round ': roundImage, 'cropped-image-rect ': !roundImage}"
             [src]="croppedImage"/>


    </div>


    <div fxLayout="row" fxLayoutAlign="end end" mat-dialog-actions>
        <button (click)="closeDialog(false)" color="warn" mat-button>
            {{t("General-CANCEL-CAPS")}}
        </button>
        <button (click)="closeDialog(true)" [disabled]="!fileAllowed" color="primary" mat-button>
            {{t("General-UPLOAD-CAPS")}}
        </button>
    </div>

</div>
</ng-container>
