import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';

import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { JWT, JWTPayload, JWTRefresh } from './JWT';

import { TranslocoService } from '@ngneat/transloco';
import { ProgressBarService } from '../../common-ui/progressbar/progress-bar.service';
import { AutoUnsubscribable } from '../../helpers/autounsub';
import { SegmentService } from '../../services/segment.service';
import { EnvironmentService } from '../env/environment.service';

@Injectable({
    providedIn: 'root',
})
export class LoginService extends AutoUnsubscribable {
    // invalidate every token before this unix timestamp in seconds:
    private VALID_AFTER = 1652296918;

    private loginRoute = '/auth/login';
    private authTokenSpec = 'authToken';

    private refrTokenSpec = 'refreshToken';

    private loginSubject: Subject<JWT> = new BehaviorSubject(null);
    private tokenSubject: Subject<JWT> = new BehaviorSubject(null);
    private jwtPayload: Observable<JWTPayload>;

    constructor(
        private http: HttpClient,
        private router: Router,
        private progressBar: ProgressBarService,
        private env: EnvironmentService,
        private _translocoService: TranslocoService,
        private _segment: SegmentService,
    ) {
        super();
        this.env.getCurrentEnvironment().subscribe((x) => {
            this.loginSubject.next(this.getCurrentToken());
            this.tokenSubject.next(this.getCurrentToken());
        });

        this.jwtPayload = this.tokenSubject.pipe(
            map((jwt) => {
                if (jwt) {
                    return LoginService.getPayloadOfToken(jwt.authToken);
                } else {
                    return null;
                }
            }),
        );

        this.loginSubject.pipe(takeUntil(this._unsubscribeAll)).subscribe((jwt) => {
            if (jwt) {
                const payload = LoginService.getPayloadOfToken(jwt.authToken);
                this._segment.setUserByToken(payload);
            }
        });
    }

    public static getPayloadOfToken(t: string): JWTPayload {
        return JSON.parse(atob(t.split('.')[1]));
    }

    /**
     * Performs the actual login of the user. Will display a snackbar with an error message if the login does not succeed
     *
     * @param userOrMail The username or alternatively the email adress of the user
     * @param passwd The password
     *
     * @returns Observable with the JWT token
     */
    login(team: string, userOrMail: string, passwd: string): Observable<{} | JWT> {
        // this.progressBar.setMode('indeterminate');
        const o = { password: passwd };
        return this.http.post<JWT>(this.apiLogin() + '/' + team + '/' + userOrMail, o).pipe(
            map((p) => {
                // this.progressBar.show();
                this.doLogin(p);

                return p;
            }),

            // catchError(error => {
            //     // this.progressBar.hide();
            //
            //     const errorMsg: string = this.translate.instant(
            //         'LOGIN.ERROR'
            //     );
            //     const close: string = this.translate.instant(
            //         'LOGIN.ERROR_CONFIRM'
            //     );
            //
            //     // this.progressBar.hide();
            //     console.log('errorMsg', errorMsg);
            //
            //     return of();
            // })
        );

        // this.progressBar.hide();
    }

    public loginWithToken(token: string): Observable<JWT | undefined> {
        // this.progressBar.setMode('indeterminate');
        const o = { apiToken: token };
        return this.http.post<JWT>(this.tokenLogin(), o).pipe(
            map((p) => {
                // this.progressBar.show();
                this.doLogin(p);
                return p;
            }),

            catchError((error) => {
                // this.progressBar.hide();

                const errorMsg: string = this._translocoService.translate(
                    'Auth-OOOOPS-LOGIN-WAS-NOT-POSSIBLE-DID-YOU-PROVIDE-CORRECT-USERNAME-AND-PASSWORD',
                );
                const close: string = this._translocoService.translate('General-CLOSE');

                // this.progressBar.hide();;
                return of();
            }),
        );
        //
    }

    /**
     * Sets JWT token from the backend to the local storage.
     */
    public doLogin(p: JWT): any {
        this.setCurrentJWT(p);
        this.loginSubject.next(p);
    }

    public getLoginSubject(): Subject<JWT> {
        return this.loginSubject;
    }

    public getLoginSubjectWitPayload(): Observable<JWTPayload> {
        return this.loginSubject.pipe(
            map((t) => {
                if (t) {
                    return LoginService.getPayloadOfToken(t.authToken);
                } else {
                    return null;
                }
            }),
        );
    }

    public getTokenSubject(): Subject<JWT> {
        return this.tokenSubject;
    }

    public getJWTPayload(): Observable<JWTPayload> {
        return this.jwtPayload;
    }

    public getTokenPayloadSubject(): Observable<JWTPayload> {
        return this.tokenSubject.pipe(
            filter((t) => t != null && t.authToken != null),
            map((t) => LoginService.getPayloadOfToken(t.authToken)),
        );
    }

    /**
     * Is used to refresh the auth token
     *
     * @returns
     */
    refreshAuthToken(updateFields: string): Observable<JWTRefresh> {
        const paramList = new HttpParams().set('updateFields', updateFields);

        const options = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem(this.refrTokenSpec),
            },
            params: paramList,
        };
        const call = this.http.post<JWTRefresh>(this.apiRefresh(), null, options).pipe(
            map((p) => {
                localStorage.setItem(this.authTokenSpec, p.authToken);
                this.tokenSubject.next(this.getCurrentToken());
                return p;
            }),
        );

        return call;
    }

    /**
     * Will remove authToken and RefreshToken from the local storage - which will logout the user.
     * Redirects to the login page after logout is performed
     */
    logout(): void {
        localStorage.removeItem(this.authTokenSpec);
        localStorage.removeItem(this.refrTokenSpec);
        this.router.navigate([this.loginRoute]);
        this.loginSubject.next(null);
        this.tokenSubject.next(null);
    }

    /**
     * Checks if
     * - 1. There is an authentication token in the local storage
     * - 2. The authentication token is not yet expired
     * - 3. keeps a manual expiration limit (to force expiration if something changed)
     */
    isLoggedIn(): boolean {
        if (this.getCurrentAuthToken() != null) {
            const now = new Date();

            if (this.getPayload().iat < this.VALID_AFTER) {
                // the issued time is before its considered valid => fail
                return false;
            }

            if (this.getAuthExpirationTime().getTime() - now.getTime() > 0) {
                return true;
            }
        }
        return false;
    }

    /**
     * Returns the username of the user that is currently logged in
     */
    getCurrentUserName(): string {
        if (this.getPayload() != null) {
            return this.getPayload().username;
        }
        return '';
    }

    getTeam(): string {
        if (this.getPayload() != null) {
            return this.getPayload().team;
        }
        return '';
    }

    /**
     * Returns the userid of the user that is currently logged in
     */
    getCurrentUserId(): string {
        if (this.getPayload() != null) {
            return this.getPayload().userId;
        }
        return '';
    }

    /**
     * Returns the email address of the user that is currently logged in
     */
    getCurrentEmail(): string {
        if (this.getPayload() != null) {
            return this.getPayload().email;
        }
        return '';
    }

    /**
     * Returns the time when the authentication token expires
     */
    getAuthExpirationTime(): Date {
        const exp = this.getPayload().exp;
        return new Date(exp * 1000);
    }

    /**
     * Returns the authentication token
     */
    getCurrentAuthToken(): string {
        return localStorage.getItem(this.authTokenSpec);
    }

    getCurrentToken(): JWT {
        const auth = this.getCurrentAuthToken();
        const ref = localStorage.getItem(this.refrTokenSpec);

        if (auth && ref) {
            const j = new JWT();
            j.authToken = auth.toString();
            j.refreshToken = ref;
            return j;
        }

        return null;
    }

    public getPayload(): JWTPayload {
        if (this.getCurrentAuthToken() != null) {
            return LoginService.getPayloadOfToken(this.getCurrentAuthToken());
        }
        return null;
    }

    private apiLogin(): string {
        return this.env.environment.api + '/user/login';
    }

    private tokenLogin(): string {
        return this.env.environment.api + '/user/token';
    }

    private apiRefresh(): string {
        return this.env.environment.api + '/user/refresh';
    }

    private setCurrentJWT(tokens: JWT): void {
        localStorage.setItem(this.authTokenSpec, tokens.authToken);
        localStorage.setItem(this.refrTokenSpec, tokens.refreshToken);
        this.tokenSubject.next(tokens);
    }
}
