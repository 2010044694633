import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {NgxFileDropEntry} from 'ngx-file-drop';
import {Assembly, AssemblyFile, FileType, FileUpdate} from '../../../../../../pcb-common/assembly/assembly';
import {LayerFile} from '../../../../../../pcb-common/pcb/pcb';
import {LoginService} from '../../../../../../common/auth/login.service';
import {EnvironmentService} from '../../../../../../common/env/environment.service';

@Injectable({
    providedIn: 'root'
})
export class PcbFileUploadService {
    private assemblyEndpoint = '/assembly/assemblies';
    private assemblyEndpoint2 = '/assembly';
    private unsupportedFileTypes = [];


    constructor(private _httpClient: HttpClient, private _loginService: LoginService, private env: EnvironmentService,) {
    }

    getFile(a: Assembly, fileName: string): string {
        return this.env.environment.files + '/assembly/' + a.id + '/versions/' +
            a.currentVersion.id + '/upload/' + fileName + '?k=' + this._loginService.getCurrentAuthToken();
    }

    public isSupportedFileType(file: NgxFileDropEntry): boolean {
        const fileType = file.relativePath.split('.')[1];
        if (fileType) {
            return !this.unsupportedFileTypes.includes(fileType);
        }
    }

    public uploadFiles(a: Assembly, f: File[]): Observable<any> {
        const formData: FormData = new FormData();
        f.forEach(file => {
            formData.append(file.name, file);
        });
        return this._httpClient
            .post<any>(
                this.env.environment.files +
                this.assemblyEndpoint2 +
                '/' +
                a.id +
                '/versions/' +
                a.currentVersion.id +
                '/files',
                formData
            );
    }

    public deleteFile(a: Assembly, aFile: AssemblyFile): Observable<any> {

        return this._httpClient
            .delete<any>(
                this.env.environment.api +
                this.assemblyEndpoint +
                '/' +
                a.id +
                '/versions/' +
                a.currentVersion.id +
                '/files/' +
                encodeURIComponent(aFile.name)
            );
    }

    public invertFileTo(a: Assembly, lFile: LayerFile, inverted: boolean): Observable<any> {

        return this._httpClient
            .put<any>(
                this.env.api('/ems/pcb', [a.id, 'versions', a.currentVersion.id, 'layers', lFile.id.toString()]),
                {inverted: inverted}
            );
    }

    public updateFile(a: Assembly, fType: FileType, aFile: AssemblyFile): Observable<any> {

        const fUpdate = new FileUpdate();
        fUpdate.function = fType;

        return this._httpClient
            .put<any>(
                this.env.environment.api +
                this.assemblyEndpoint +
                '/' +
                a.id +
                '/versions/' +
                a.currentVersion.id +
                '/files/' +
                aFile.name,
                fUpdate
            );
    }


}
