import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../common-ui/snackbar/snackbar.service';

@Component({
    selector: 'app-unlock-dialog',
    templateUrl: './unlock-dialog.component.html',
    styleUrls: ['./unlock-dialog.component.scss']
})
export class UnlockDialogComponent {

    public userInput = '';
    public confirmText = '';

    constructor(public dialogRef: MatDialogRef<UnlockDialogComponent>,
                private _snackBarService: SnackbarService,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        if (data && data.confirmText) {
            this.confirmText = data.confirmText;
        }

    }

    testInput(): boolean {
        return this.confirmText !== this.userInput;
    }


}
