import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DocumentViewerService, FileDescriptor} from './document-viewer.service';
import {PdfViewerComponent} from 'ng2-pdf-viewer';
import {animate, style, transition, trigger} from '@angular/animations';
import {AssemblyService} from '../../assembly.service';
import {EmailService} from '../../../../email/email.service';
import {FileMimeType} from '../files/pcb-file-manager/pcb-file-item-list/pcb-layer-order';
import {filter} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {LoginService} from '../../../../../common/auth/login.service';

@Component({
    selector: 'document-viewer',
    templateUrl: './document-viewer.component.html',
    styleUrls: ['./document-viewer.component.scss'],
    animations: [
        trigger('anim', [
            transition(':enter', [
                style({width: 0}),
                animate(250, style({width: '*'})),
            ]),
            transition(':leave', [
                style({width: '*'}),
                animate(250, style({width: 0})),
            ]),
        ]),
    ],
})


export class DocumentViewerComponent implements OnInit, OnDestroy {
    public pdfZoom = 1;
    public angle = 0;
    public selectedFile: BehaviorSubject<FileDescriptor> = new BehaviorSubject<FileDescriptor>(null);


    @Input() imagePath: string = null;
    @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;

    constructor(public documentService: DocumentViewerService,
                private _assemblyService: AssemblyService,
                private _loginService: LoginService,
                private _mailService: EmailService) {

        this.documentService.getSelectedFile().subscribe(file => {

            if (file) {
                this.selectedFile.next(file);
                if (this.isImage(file)) {
                    this.imagePath = file.path;
                } else {
                    this.imagePath = null;
                    // get from service
                }
            } else {

                // console.log('selected file is null');
            }
        });


        this.documentService.fileList.pipe(filter(l => !!l && !this.documentService.getCurrentlySelected())).subscribe(list => {
            if (list?.length !== 0) {

                const firstFile = list [0];
                // this.selectedFile.next(firstFile);
                this.documentService.selectFile(firstFile);
            }
        });
    }

    ngOnDestroy(): void {
        this.imagePath = null;
        this.selectedFile.next(null);
    }

    ngOnInit(): void {
        // console.log('selectedFile is', this.selectedFile.getValue());

    }

    public fileSelected(file: FileDescriptor): void {
        this.documentService.selectFile(file);
    }

    public isImage(file: FileDescriptor): boolean {
        return file && (file.type === FileMimeType.GERBER || file.type === FileMimeType.DRILL || file.type?.startsWith(FileMimeType.ODB));
    }

    public onRotateLeft(): void {
        this.angle -= 90 % 360;
    }

    public onZoom(zoomIncrease: boolean): void {
        const zoomStep = 0.2;
        this.pdfZoom += zoomIncrease ? zoomStep : -zoomStep;
    }

    public onClose(): void {
        this.documentService.closeViewerState();
    }

}
