import {Component, OnInit} from '@angular/core';
import {fabHelpAnimations} from './fab-help.animations';
import {ActivatedRoute} from '@angular/router';
import {AssemblyConstants} from '../../../../../../pcb-common/assembly/assembly-constants';


@Component({
    selector: 'fab-navigator',
    templateUrl: './fab-navigator.component.html',
    styleUrls: ['./fab-navigator.component.scss'],
    animations: fabHelpAnimations
})
export class FabNavigatorComponent implements OnInit {

    private assemblyGid: string;


    fabButtons = [];
    buttons = [];
    fabTogglerState = 'inactive';

    constructor(private _route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.assemblyGid = this._route.snapshot.params.assembly;

        this.fabButtons = [
            {
                icon: 'dashboard',
                tooltip: 'Go to Dashboard',
                route: ['/', AssemblyConstants.PROJECTS_URL, this.assemblyGid, AssemblyConstants.DASHBOARD_URL],
                queryParams: null
            },
            {
                icon: 'file_copy',
                tooltip: 'Go to File Manager',
                route: ['/', AssemblyConstants.PROJECTS_URL, this.assemblyGid, AssemblyConstants.FILES_URL],
                queryParams: null
            },
            {
                icon: 'search',
                tooltip: 'Go to Gerber Viewer',
                route: ['/', AssemblyConstants.PROJECTS_URL, this.assemblyGid, AssemblyConstants.PCB_VIEWER_URL],
                queryParams: null
            },
            {
                icon: 'view_headline',
                tooltip: 'Go to Layerstack Editor',
                route: ['/', AssemblyConstants.PROJECTS_URL, this.assemblyGid, AssemblyConstants.PCB_VIEWER_URL],
                queryParams: null
            },
            {
                icon: 'grid_on',
                tooltip: 'Go to Panelizer',
                route: ['/', AssemblyConstants.PROJECTS_URL, this.assemblyGid, AssemblyConstants.PCB_VIEWER_URL],
                queryParams: {panel: true}
            }
        ];
    }

    showItems(): void {
        this.fabTogglerState = 'active';
        this.buttons = this.fabButtons;
    }

    hideItems(): void {
        this.fabTogglerState = 'inactive';
        this.buttons = [];
    }

    onToggleFab(): void {
        this.buttons.length ? this.hideItems() : this.showItems();
    }

}
