import {BehaviorSubject} from 'rxjs';
import {
    RigidBlackSilkScreen,
    RigidBlackSolderMask,
    RigidBlueSolderMask,
    RigidENIGColor,
    RigidGreenGlossySolderMask,
    RigidGreenMattSolderMask,
    RigidGreenSolderMask,
    RigidHALColor,
    RigidNoneColor,
    RigidPurpleSolderMask,
    RigidRedSolderMask,
    RigidWhiteSilkScreen,
    RigidWhiteSolderMask,
    RigidYellowSilkScreen
} from '../pcb/pcb2-color-scheme';
import {AssemblyReference} from '../../../../../pcb-common/assembly/assembly';
import {LayerStyle} from '../../../../../pcb-common/pcb/pcb';

export class SpecificationPreviews {
    front?: string
    // Not used anywhere on the frontend right now
    rear?: string
    generating?: boolean
}


export class PCBSpecification {
    spec: Specification;
    previews: SpecificationPreviews;

    // non api
    selected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}

export class Specification {
    assembly: AssemblyReference;
    id: string;
    alias: string;
    dfm: any;
    user: any;
    settings: any;
    base: any;
    status: string;
    technology: string;
}


export class SpecUpdateCommand {
    dfm: any[];
    settings: any[];
    user: any[];
}


export const PropertyToSolderMaskColors: Map<string, Map<String, LayerStyle>> = new Map(
    [
        ['green', RigidGreenSolderMask],
        ['red', RigidRedSolderMask],
        ['blue', RigidBlueSolderMask],
        ['white', RigidWhiteSolderMask],
        ['black', RigidBlackSolderMask],
        ['green-matt', RigidGreenMattSolderMask],
        ['green-glossy', RigidGreenGlossySolderMask],
        ['purple', RigidPurpleSolderMask],
    ]
);

export const PropertyToSilkscreenColors: Map<string, Map<String, LayerStyle>> = new Map(
    [
        ['white', RigidWhiteSilkScreen],
        ['black', RigidBlackSilkScreen],
        ['yellow', RigidYellowSilkScreen],
    ]
);

export const PropertyToFinishColors: Map<string, Map<String, LayerStyle>> = new Map(
    [
        ['none', RigidNoneColor],
        ['hal', RigidHALColor],
        ['hal-leadfree', RigidHALColor],
        ['enig', RigidENIGColor],
        ['silver', RigidHALColor],
        ['chemical-tin', RigidHALColor]
    ]
);

export class PCBSettingProposal {
    boardFinish = [
        ['none', 'None'],
        ['hal', 'HAL'],
        ['hal-leadfree', 'HAL-LF'],
        ['enig', 'ENIG'],
        ['silver', 'Chemical silver'],
        ['chemical-tin', 'Chemical tin'],
    ];
    soldermask_color = [
        ['red', 'Red'],
        ['green', 'Green'],
        ['green-matt', 'Green matt'],
        ['green-glossy', 'Green glossy'],
        ['blue', 'Blue'],
        ['black', 'Black'],
        ['white', 'White'],
        ['purple', 'Purple'],
        ['yellow', 'Yellow'],
    ];
    soldermask_sides = [
        ['none', 'None'],
        ['both', 'Both'],
        ['top', 'Top'],
        ['bottom', 'Bottom'],
    ];


    silkscreen_color = [
        ['white', 'White'],
        ['black', 'Black'],
        ['yellow', 'Yellow'],
    ];
    silkscreen_sides = [
        ['none', 'None'],
        ['both', 'Both'],
        ['top', 'Top'],
        ['bottom', 'Bottom'],
    ];

    ipc_2221_type = [
        1,
        2,
        3,
        4,
        5,
        6
    ];

    ipc_600_type = [
        1,
        2,
        3,
    ];

    standards = [
        ['none', 'None'],
        ['MIL', 'MIL'],
        ['JSS', 'JSS'],
        ['IPC6012B', 'IPC6012B'],
        ['PCA600', 'PCA600'],
    ];
}

export class ViolationHint<T> {
    key: string;

    value: T[];
}


export class SpecKey {
    static FILE_UNIT = 'unit';
    static FINISH = 'finish';
    static COLOR = 'color';
    static SOLDERMASK_SIDES = 'soldermask_sides';
    static SILK_COLOR = 'silkscreen_color';
    static SILK_SIDES = 'silkscreen_sides';
    static UL_MATERIAL = 'ul_material';
    static ETEST = 'etest';
    static PEEL_SOLDER_STOP = 'peel_solder_stop';
    static IPC_2221 = 'ipc_2221_type';
    static IPC_600 = 'ipc_600_class';
    static STANDARDS = 'standards';
    static IMPEDANCE = 'impedance_controlled';
    static IMPEDANCE_TOLERANCE = 'impedance_tolerance';
    static FAB_LOGO = 'fabricator_logo';
    static FAB_DATE = 'fabricator_date_code';
    static VIA_PROTECT = 'via_protection';
    static HOLE_PLATE_THICK = 'hole_plating_thickness';
    static HALOGEN_FREE = 'halogen_free';
    static PRESS_FIT = 'press_fit';
    static PRESS_FIT_TECHNOLOGY = 'press_fit_technology';
    static HEAT_SINK_PASTE = 'heat_sink_paste';
    static EDGE_PLATING = 'edge_plating';
    static CASTELLATED = 'castellated';
    static EDGE_CONNECT = 'edge_connector';
    static EDGE_CONNECT_BEVELLED = 'edge_connector_bevelled';
    static HARD_GOLD_AREA = 'hard_gold_area';
    static HARD_GOLD_THICKNESS = 'hardgold_thickness';
    static ROHS = 'rohs';
    static FOIL = 'foil';
    static SUBSTRATES = 'substrates';
    static MATERIAL_TG = 'material_tg';
    static ITAR = 'itar';
    static SIZE = 'size';
    static WIDTH = 'width';
    static HEIGHT = 'height';
    static AREA = 'area';
    static NPH_COUNT = 'nph_count';
    static NPH_TOOL_COUNT = 'nph_tool_count';

    static NPH_MIN_SIZE = 'nph_min_size';
    static NPH_MAX_SIZE = 'nph_max_size';
    static NPH_MIN_DISTANCE = 'nph_max_distance';
    static PH_COUNT = 'ph_count';
    static PH_TOOL_COUNT = 'ph_tool_count';
    static PH_MIN_SIZE = 'ph_min_size';
    static PH_MAX_SIZE = 'ph_max_size';
    static PH_MIN_DISTANCE = 'ph_max_distance';
    static PH_ANNULAR_RING = 'ph_annular_ring';
    static PH_ANNULAR_RING_BREAKOUT = 'ph_annular_ring_breakout';
    static DRILL_COUNT_TOTAL = 'drill_count';
    static DRILL_MIN_SIZE_TOTAL = 'drill_max_size';
    static DRILL_MAX_SIZE_TOTAL = 'drill_min_size';
    static PH_RATIO = 'ph_ratio';
    static BLIND_VIA_COUNT = 'blind_via_count';
    static BLIND_NPH_VIA_COUNT = 'blind_nph_via_count';
    static BLIND_VIA_MIN_SIZE = 'blind_via_min_size';
    static BLIND_VIA_MAX_SIZE = 'blind_via_max_size';
    static BLIND_NPH_VIA_MIN_SIZE = 'blind_nph_via_min_size';
    static BLIND_NPH_VIA_MAX_SIZE = 'blind_nph_via_max_size';
    static BLIND_VIA_ANNULAR_RING = 'blind_via_annular_ring';
    static BLIND_VIA_RATIO = 'blind_via_ratio';
    static BURIED_VIA_COUNT = 'buried_via_count';
    static BURIED_NPH_VIA_COUNT = 'buried_nph_via_count';
    static BURIED_VIA_MIN_SIZE = 'buried_via_min_size';
    static BURIED_VIA_MAX_SIZE = 'buried_via_max_size';
    static BURIED_NPH_VIA_MIN_SIZE = 'buried_nph_via_min_size';
    static BURIED_NPH_VIA_MAX_SIZE = 'buried_nph_via_max_size';
    static BURIED_VIA_ANNULAR_RING = 'buried_via_annular_ring';
    static BURIED_VIA_RATIO = 'buried_via_ratio';
    static OUTLINE_CLEARANCE = 'outline_clearance';
    static TRACE_WIDTH = 'trace_width';
    static COPPER_CLEARANCE = 'copper_clearance';
    static SOLDERMASK_DAM = 'soldermask_dam';
    static SOLDERMASK_CLEARANCE = 'soldermask_clearance';
    static SILKSCREEN_CLEARANCE = 'silkscreen_clearance';
    static CUCOUNT = 'cucount';
    static LAYERCOUNT = 'copperfilescount';
    static SPEC_LAYERCOUNT = 'layercount';
    static EXPOSED_COPPER_AREA_TOP = 'exposed_copper_area_top';
    static EXPOSED_COPPER_PERCENTAGE_TOP = 'exposed_copper_percentage_top';
    static EXPOSED_COPPER_AREA_BOTTOM = 'exposed_copper_area_bottom';
    static EXPOSED_COPPER_PERCENTAGE_BOTTOM = 'exposed_copper_percentage_bottom';
    static ENIG_THICKNESS = 'enig_thickness';
    static LAYERSTACK_TYPE = 'layerstacktype';
    static MAX_XOUTS_ALLOWED = 'maxxoutsallowed';


    static PH_COVERED_BOTH_SIDES = 'ph_covered_both_sides';
    static PH_COVERED_ONE_SIDE = 'ph_covered_one_side';
    static PH_UNCOVERED = 'ph_uncovered';
    static NPH_COVERED_BOTH_SIDES = 'nph_covered_both_sides';
    static NPH_COVERED_ONE_SIDE = 'nph_covered_one_side';
    static NPH_UNCOVERED = 'nph_uncovered';
    static PH_SURFACE_AREA = 'ph_surface_area';
    static CROSS_SECTION_REPORT = 'cross_section_report'

    static XRAY_REPORT = 'xray_measurement_report';
    static XRAY_REPORT_POINTS = 'xray_measurement_points_report';
    static FIRST_ARTICLE_INSPECTION_REPORT = 'first_article_inspection_report';
    static CERTIFICATE_OF_CONFORMANCE = 'certificate_of_conformance_report';
    static DATE_CODE = 'date_code_marking';
    static DATAMATRIX_CODE = 'datamatrix_code_marking';
    static MANUFACTURING_INFO = 'manufacturing_information';
    static FABRICATOR_LOGO = 'FABRICATOR_LOGO_MARKING';
}