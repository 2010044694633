import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'stackrate-delete-confirm-dialog',
    templateUrl: './delete-confirm-dialog.component.html',
    styleUrls: ['./delete-confirm-dialog.component.scss']
})
export class DeleteConfirmDialogComponent implements OnInit {
    public result: boolean;
    public message: string;
    public yes_text: string;
    public no_text: string;

    constructor(
        public dialogRef: MatDialogRef<DeleteConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.message = data.message;
        this.yes_text = data.yes_text;
        this.no_text = data.no_text;
    }

    ngOnInit(): void {
    }

    closeDialog(value: boolean): void {
        this.dialogRef.close(value);
    }
}
