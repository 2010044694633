<ng-container *transloco="let t">
<div *ngIf="status" [matTooltipPosition]="'above'" [ngClass]="getStatusClass()" class="quotation-badge m-0 text-sm px-2"
     fxLayout="row"
     fxLayoutAlign="center center"
     matTooltip="Updated : {{dateService.simpleDateFormat(status?.date)}}" [matMenuTriggerFor]="statusMenu">
        {{t("Status-"+getStatusString().toUpperCase())}}
        <button mat-icon-button class="pl-2" >
            <mat-icon class="dropdown-arrow">expand_more</mat-icon>
        </button>
</div>
<div *ngIf="!status">
    {{t("Status-UNDEFINED")}}
</div>

<mat-menu #statusMenu="matMenu">
    <button (click)="setQuotationStatus(draft)" [disabled]="status?.name === draft" mat-menu-item>
        <span class="text-sm">{{t("Status-DRAFT")}}</span>
    </button>
    <button (click)="setQuotationStatus(sent)" [disabled]="status?.name === sent" mat-menu-item>
        <span class="text-sm">{{t("Status-SENT")}}</span>
    </button>
    <button (click)="setQuotationStatus(won)" [disabled]="status?.name === won" mat-menu-item>
        <span class="text-sm">{{t("Status-WON")}}</span>
    </button>
    <button (click)="setQuotationStatus(lost)" [disabled]="status?.name === lost" mat-menu-item>
        <span class="text-sm">{{t("Status-LOST")}}</span>
    </button>
</mat-menu>
</ng-container>