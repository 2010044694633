// import {FuseNavigation} from '../../@fuse/types';

import { FuseNavigationItem } from '../../@fuse/components/navigation';

export class NavigationDefinitions {
    // navigation guards.
    // permission string of the form resourceclass,team,owner,resourceid,attribute,action
    // use "_" as a placeholder in team or owner to select the currently logged id team/user
    //
    // These are permission guards, not checks. They deviate from the usual permission checks.
    // The main difference is that * (or missing) parts translate to _any_ instead of _all_
    //
    // usually, a permissioncheck of "assembly,_" would require the user to have complete control access
    // to all assemblies of every user in the team.
    // a guard of this form however just requires the user to have at least one permission for assemblies in this team
    //
    public static DEFAULT_PERMISSIONS = new Map([
        ['project-overview', 'assembly:_:_'],
        ['customer-overview', 'customer:_:_'],
        ['quotation-overview', 'quotation:_:_'],
        ['profile', 'profile:_:_'],
        ['settings', 'profile:_:_'],
        ['approx-price', 'prices:_:_'],
        ['layerstack', 'layerstackdefinition:_:_:*:*:write'],
        ['admin-settings', 'admin:_:*:*:*:admin'], // have at least one admin permission
    ]);

    // todo fuse
    // When adding new entries, add 'Navigation-$id' in the translation File
    public static DEFAULT_MENU: FuseNavigationItem[] = [
        {
            id: 'applications', // Navigation-applications
            title: 'Projects',
            // translate: 'General-PROJECTS',
            type: 'group',
            children: [
                {
                    id: 'project-parent',
                    title: 'Projects',
                    // translate: 'NAV.CONFIG',
                    type: 'collapsable',
                    icon: 'developer_board',

                    children: [
                        {
                            id: 'project-overview',
                            title: 'My Projects',
                            // translate: 'NAV.PROJECT.TITLE',
                            type: 'basic',
                            icon: 'developer_board',
                            link: '/project',
                        },
                        {
                            id: 'shares-overview',
                            title: 'Shared Projects',
                            // translate: 'NAV.PROJECT.TITLE',
                            type: 'basic',
                            icon: 'share',
                            link: '/shares',
                            hidden: (_) => false, // TODO
                            disabled: false, // TODO
                        },
                    ],
                },

                {
                    id: 'quotation',
                    title: 'Quotations',
                    // translate: 'NAV.QUOTATIONS.TITLE',
                    type: 'basic',
                    icon: 'request_quote',
                    link: '/quotations',
                },
                {
                    id: 'customer-overview',
                    title: 'Customers',
                    // translate: 'NAV.CUSTOMER.TITLE',
                    type: 'basic',
                    icon: 'supervised_user_circle',
                    link: '/customer',
                },
                {
                    id: 'stackup',
                    title: 'StackUp',
                    // translate: 'NAV.LAYERSTACK.TITLE',
                    type: 'basic',
                    icon: 'view_day',
                    link: '/stackup',
                },
            ],
        },

        {
            id: 'settings',
            title: 'Settings',
            // translate: 'NAV.SETTINGS',
            type: 'group',
            children: [
                {
                    id: 'profile',
                    title: 'Profile',
                    // translate: 'NAV.PROFILE.TITLE',
                    type: 'basic',
                    icon: 'account_circle',
                    link: '/settings/profile',
                },
                // {
                //     id: 'exit_to_app',
                //     title: 'Logout',
                //     translate: 'NAV.LOGOUT.TITLE',
                //     type: 'basic',
                //     icon: 'account_circle',
                //     link: '/settings/profile'
                // },

                {
                    id: 'team-settings',
                    title: 'Configuration',
                    // translate: 'NAV.CONFIG',
                    type: 'collapsable',
                    icon: 'settings',

                    children: [
                        {
                            id: 'template',
                            title: 'Template',
                            // translate: 'NAV.TEMPLATE.TITLE',
                            type: 'basic',
                            icon: 'assignment',
                            link: '/settings/template',
                        },
                        {
                            id: 'pcb-man',
                            title: 'Supplier',
                            // translate: 'NAV.SUPPLIER.TITLE',
                            type: 'basic',
                            icon: 'build',
                            link: '/supplier',
                        },
                        {
                            id: 'pricing',
                            title: 'Pricing',
                            // translate: 'NAV.PRICING.TITLE',
                            type: 'basic',
                            icon: 'attach_money',
                            link: '/pricings',
                        },
                        {
                            id: 'capabilities',
                            title: 'Capabilities',
                            // translate: 'NAV.PRICING.TITLE',
                            type: 'basic',
                            icon: 'tune',
                            link: '/capabilities',
                        },
                        {
                            id: 'team',
                            title: 'Team',
                            // translate: 'NAV.TEAM_SETTINGS.TITLE',
                            type: 'basic',
                            icon: 'group',
                            link: '/settings/team',
                        },
                    ],
                },
            ],
        },

        {
            id: 'admin-settings',
            title: 'Admin Settings',
            // translate: 'NAV.ADMIN_SETTINGS',
            type: 'group',
            children: [
                {
                    id: 'admin',
                    title: 'Admin',
                    // translate: 'NAV.ADMIN',
                    type: 'collapsable',
                    icon: 'admin_panel_settings',

                    children: [
                        {
                            id: 'teams',
                            title: 'Teams',
                            // translate: 'NAV.TEAM.TITLE',
                            type: 'basic',
                            icon: 'group',
                            link: '/team',
                        },
                        {
                            id: 'groups',
                            title: 'Groups',
                            // translate: 'NAV.GROUP.TITLE',
                            type: 'basic',
                            icon: 'group_add',
                            link: '/group',
                        },
                    ],
                },
            ],
        },

        {
            type: 'divider',
        },

        {
            id: 'logout',
            title: 'Logout',
            // translate: 'NAV.LOGOUT.TITLE',
            type: 'basic',
            icon: 'exit_to_app',
        },
    ];
}
