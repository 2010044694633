import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UploadImageComponent} from './upload-image/upload-image.component';
import {ChipFilterComponent} from './chip-filter/chip-filter.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {AvatarListComponent} from './avatar-list/avatar-list.component';
import {ContactListComponent} from './avatar-list/contact-list/contact-list.component';
import {UserListComponent} from './avatar-list/user-list/user-list.component';
import {AssemblyListComponent} from './assembly-list/assembly-list.component';
import {PdfViewerDialogComponent} from './pdf-viewer-dialog/pdf-viewer-dialog.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {EmailChipContactComponent} from './email/email-chip-contact/email-chip-contact.component';
import {QuotationCardComponent} from './quotation-card/quotation-card.component';
import {QuotationStatusBadgeComponent} from './quotation-card/quotation-status-badge/quotation-status-badge.component';
import {TextLinkComponent} from './text-link/text-link.component';
import {ExpandIconComponent} from './expand-icon/expand-icon.component';
import {InfoTextDialogComponent} from './info-text-dialog/info-text-dialog.component';
import {UnlockDialogComponent} from './unlock-dialog/unlock-dialog.component';
import {PrintFormatSelectionDialogComponent} from './print-format-selection-dialog/print-format-selection-dialog.component';
import {TagButtonComponent} from './tag-button/tag-button.component';
import {StackrateSnackbarComponent} from '../generic-components/snackbar/stackrate-snackbar.component';
import {SharedModule} from '../core/shared/shared.module';
import {CustomerSelectorComponent} from './customer-selector/customer-selector.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {CustomerListComponent} from './avatar-list/customer-list/customer-list.component';

@NgModule({
    declarations: [UploadImageComponent,
        ChipFilterComponent, AvatarListComponent, ContactListComponent, UserListComponent,
        AssemblyListComponent, PdfViewerDialogComponent, CustomerListComponent,
        QuotationCardComponent, EmailChipContactComponent, QuotationStatusBadgeComponent,
        TextLinkComponent, ExpandIconComponent, InfoTextDialogComponent, UnlockDialogComponent, PrintFormatSelectionDialogComponent, TagButtonComponent, StackrateSnackbarComponent,
        CustomerSelectorComponent],
    imports: [
        CommonModule,
        MatMenuModule,
        MatIconModule,
        FlexLayoutModule,
        MatButtonModule,
        MatInputModule,
        MatDividerModule,
        MatListModule,
        MatTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCardModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatTooltipModule,
        MatSelectModule,
        MatMenuModule,
        MatDialogModule,
        NgxMatSelectSearchModule,
        PdfViewerModule,
        MatButtonToggleModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        SharedModule,
    ],
    entryComponents: [PdfViewerDialogComponent, PrintFormatSelectionDialogComponent],
    exports: [UploadImageComponent,
        ChipFilterComponent, AvatarListComponent, UserListComponent, ContactListComponent,
        AssemblyListComponent, PdfViewerDialogComponent, CustomerListComponent,
        QuotationCardComponent, QuotationStatusBadgeComponent, TextLinkComponent, ExpandIconComponent,
        InfoTextDialogComponent, UnlockDialogComponent, TagButtonComponent, CustomerSelectorComponent]
})
export class HelperModule {
}
