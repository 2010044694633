<!-- HEADER -->
<ng-container *transloco="let t">
    <div class="page-header text-on-accent-800 flex flex-row" style="border-bottom: 1px solid; border-color: #EAEBF1;">


        <div class="ml-3 flex flex-col flex-grow" style="padding-top:30px; padding-left:20px">

            <div fxLayout="row" fxLayoutAlign="start center">
                <div *ngFor="let bc of breadcrumbs; index as i" class="centered-div  flex flex-row align-middle">
                    <div class="mx-1 flex flex-col align-middle" *ngIf="i > 0">
                        <mat-icon style="font-size: 12px;">navigate_next</mat-icon>
                    </div>
                    <div>
                        <a [routerLink]="getRouterLink(bc)" class="breadlink" id="{{bc.label}}"
                            [ngClass]="i === breadcrumbs.length - 1 ? 'bold' : 'no-bold'">
                            {{ bc.label }}
                        </a>
                    </div>
                </div>
            </div>

            <div [@animate]="{ value: '*', params: { x: '50px' } }"
                class="h1 h1-semibold flex flex-row flex-grow text-neutral900" style="padding-top: 5px;">
                <a *ngIf="goBackRoute" [routerLink]="goBackRoute" class="" matTooltip="{{goBackTooltip}}">
                    <mat-icon class="s-36 mr-2">arrow_back</mat-icon>
                </a>
                <inline-edit (onModelChanged)="onRename($event)" [(ngModel)]="pageTitle" class="w-full justify-start"
                    [renamingActive]="renamingActive"></inline-edit>
            </div>


        </div>


        <div class="buttonContainer" fxFlex fxLayout="column" fxLayoutAlign="start end">
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="end center" class="pt-4">
                <!-- SEARCH -->
                <div *ngIf="useSearchBar" class="ml-sm-32" fxFlex fxLayout="row" fxLayoutAlign="center end">
                    <div class="search-wrapper mt-sm-0">
                        <div *ngIf="showSearchBar"
                            [@animate]="{ value: '*', params: {duration: '300ms', width: '100%' } }" class="search"
                            fxFlex fxLayout="row" fxLayoutAlign="start center">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <mat-icon *ngIf="!isSearching" class="search-icon text-accent-500">search</mat-icon>
                                <mat-spinner *ngIf="isSearching" [diameter]="24" class="spinner"></mat-spinner>

                                <input #searchInput="matInput" (keyup)="applyFilter($event)" matInput
                                    placeholder="{{t('General-SEARCH')}}...">
                                <mat-icon class="text-accent-500">search</mat-icon>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- / SEARCH -->
                <button (click)="toggleSearchBar()" *ngIf="useSearchBar" mat-icon-button>
                    <mat-icon *ngIf="!showSearchBar" class="text-secondary">search</mat-icon>
                    <mat-icon *ngIf="showSearchBar" class="text-secondary">close</mat-icon>
                </button>

                <button (click)="toggleCollaborationSideBar()" *ngIf="collaborationEntity" mat-icon-button
                    matTooltip="{{t('Tooltip-SHOW-TIMELINE')}}">
                    <mat-icon *ngIf="(colla.subscribeTimeLineEventCount() | async) as count ; else else_case"
                        [matBadge]="count" class="text-secondary">timeline
                    </mat-icon>
                    <ng-template #else_case>
                        <mat-icon class="text-secondary">timeline
                        </mat-icon>
                    </ng-template>
                </button>

                <button (click)="secondButtonClicked()" *ngIf="secondButtonIcon" mat-icon-button>
                    <mat-icon *ngIf="!secondButtonBadgeActive" class="text-secondary"
                        matTooltip="{{secondButtonTooltip}}">
                        {{secondButtonIcon}}
                    </mat-icon>

                    <mat-icon *ngIf="secondButtonBadgeActive" class="text-secondary"
                        matBadge="{{secondButtonBadgeNumber}}" matBadgeColor="{{secondButtonBadgeColor}}"
                        matTooltip="{{secondButtonTooltip}}">{{secondButtonIcon}}
                    </mat-icon>
                </button>

                <button *ngIf="menu" [matMenuTriggerFor]="menu" id="page-header-menu-button" mat-icon-button>
                    <mat-icon class="text-secondary">more_vert</mat-icon>
                </button>
            </div>
            <div *ngIf="!simple && !primaryButtonHidden" fxLayout="row" fxLayoutAlign="end end">
                <button (click)="addButtonClicked()" *ngIf="enablePrimaryButton" [disabled]="primaryButtonDisabled"
                    style="background-color: #4d58b2" disabled="{{!enablePrimaryButton}}" id="btn-add-project" mat-fab
                    matTooltip="{{primaryButtonTooltip}}">
                    <mat-icon>{{primaryButtonIcon}}</mat-icon>
                </button>

                <mat-progress-spinner *ngIf="!enablePrimaryButton && progressValue >= 0" [color]="'primary'"
                    [diameter]="56" [mode]="'indeterminate'" class="progress-spinner">
                </mat-progress-spinner>

            </div>
        </div>

    </div>
</ng-container>
<!-- / HEADER -->