<div class="flex flex-row w-full" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
    <div class="flex flex-grow" *ngIf="renamingActive && isEditable">
        <form class="flex flex-grow">
            <input [(ngModel)]="value" name="myInput" (blur)="onBlur()" class="flex flex-grow"
                (keyup.enter)="onEnterPress()" />
        </form>
    </div>

    <div id="inline-page-title" *ngIf="!isEditable || !renamingActive && value" [(ngModel)]="value" ngDefaultControl>
        {{ value }}
    </div>


    <div *ngIf="isHovered && renamingActive">
        <a color="primary" (click)="onButtonClick()">
            <mat-icon class="ml-1">edit</mat-icon>
        </a>
    </div>

</div>