<ng-container *transloco="let t">
    <div class="content" fxLayout="row">
        <div class="mb-24" fxFlex="100" fxLayout="row">
            <form fxLayout="row" fxLayoutAlign="start" fxFlex="100" name="form" (ngSubmit)="onSubmit()"
                [formGroup]="generalForm">
                <div fxLayout="column" fxFlex.xs="100" fxFlex.sm="100" fxFlex.gt-sm="50">
                    <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
                        <div class="text-lg">
                            {{ t("Settings-GENERAL-NAME-AND-EMAIL") }}
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="100" fxFlex.gt-sm="50">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>
                                {{ t("Settings-FIRST-NAME") }}</mat-label>
                            <input matInput id="input-firstName" formControlName="firstName" required />
                            <mat-icon matSuffix class="text-secondary">account_circle</mat-icon>
                            <mat-error>{{ t("Settings-FIRST-NAME-ERROR") }}</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="100" fxFlex.gt-sm="50">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{ t("Settings-LAST-NAME") }}</mat-label>
                            <input matInput id="input-lastName" formControlName="lastName" />
                            <mat-icon matSuffix class="text-secondary">account_circle</mat-icon>
                            <mat-error>{{ t("Settings-LAST-NAME-ERROR") }}</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="100" fxFlex.gt-sm="50">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{t("General-PHONE")}}</mat-label>
                            <input matInput id="input-phone" formControlName="phone" />
                            <mat-icon matSuffix class="text-secondary">phone</mat-icon>
                            <mat-error>{{ t("Settings-PHONE-ERROR") }}</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex.xs="100" fxFlex.sm="100" fxFlex.gt-sm="50">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{ t("General-COUNTRY") }}</mat-label>
                            <mat-select id="select-country" formControlName="country" required>
                                <mat-option id="germany" [value]="'Germany'">
                                    <span class="flag-icon flag-icon-gr"></span>
                                    {{ t("General-GERMANY") }}
                                </mat-option>
                                <mat-option id="france" [value]="'France'">
                                    <span class="flag-icon flag-icon-gr"></span>
                                    {{ t("General-FRANCE")}}
                                </mat-option>
                                <mat-option id="greatbritain" [value]="'Great Britain'">
                                    <span class="flag-icon flag-icon-gr"></span>
                                    {{ t("General-GREAT-BRITAIN") }}
                                </mat-option>
                                <mat-option id="czech-republic" [value]="'Czech Republic'">
                                    <span class="flag-icon flag-icon-gr"></span>
                                    {{ t("General-CZECH-REPUBLIC") }}
                                </mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="text-secondary">outlined_flag</mat-icon>
                            <mat-error>{{ t("Settings-COUNTRY-ERROR") }}</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxFlex.gt-xs="100" fxFlex.gt-md="50">
                        <div fxLayoutAlign="end end">
                            <button mat-raised-button id="btn-save" color="accent" class="submit-button"
                                aria-label="Save" [disabled]="generalForm.invalid">
                                {{ t("General-SAVE") }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-container>