import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PcbFileUploadService} from '../pcb-file-upload.service';
import {Pcb2Service} from '../../../pcb/pcb2.service';
import {DateService} from '../../../../../../../services/date.service';
import {MaterialService} from '../../../../../../../layerstack-common/material/material.service';
import {Assembly, AssemblyFile, LifeCycleStage, LifeCycleHistory} from '../../../../../../../pcb-common/assembly/assembly';
import {LayerFile} from '../../../../../../../pcb-common/pcb/pcb';

@Component({
    selector: 'app-file-inspection-dialog',
    templateUrl: './file-inspection-dialog.component.html',
    styleUrls: ['./file-inspection-dialog.component.scss']
})
export class FileInspectionDialogComponent {

    public file: AssemblyFile;
    public previewPath: string;
    public assembly: Assembly;
    public pcb: Pcb2Service;
    public pcbFile: LayerFile;
    public lifecycles: LifeCycleStage[] = [];
    constIndex = Array.from(Array(10).keys());

    constructor(public dialogRef: MatDialogRef<FileInspectionDialogComponent>,
                private _materialService: MaterialService,
                private _pcbFileService: PcbFileUploadService,
                public dateService: DateService,
                @Inject(MAT_DIALOG_DATA) public data: any) {


        if (data.file) {
            this.file = data.file;
            this.lifecycles = this.file.lifecycles;
        }
        if (data.previewPath) {
            this.previewPath = data.previewPath;
        }
        if (data.assembly) {
            this.assembly = data.assembly;
        }
        if (data.pcb) {
            this.pcb = data.pcb;
        }
        if (data.pcbFile) {
            this.pcbFile = data.pcbFile;
        }

    }

    public getTimeInMs(hist: LifeCycleHistory): number {

        if (!hist || !hist.start || !hist.end) {
            return 0;
        }

        return (hist.end - hist.start) / 1000.0;
    }
    onCancel(): void {
        this.dialogRef.close();
    }

    invertFile(): void {
        if (this.assembly && this.pcbFile) {
            const inverted = !this.pcbFile.inverted;
            this.pcb.invertFileTo(this.assembly, this.pcbFile, inverted).subscribe(i => {
                this.pcbFile.inverted = inverted;
            });
        }
    }
}
