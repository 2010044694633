import {Component, OnInit} from '@angular/core';
import {CollaborationUiService} from '../collaboration-ui.service';

@Component({
    selector: 'timeline-sidebar',
    templateUrl: './timeline-sidebar.component.html',
    styleUrls: ['./timeline-sidebar.component.scss']
})
export class TimelineSidebarComponent implements OnInit {

    constructor(public collabUI: CollaborationUiService) {
    }

    ngOnInit(): void {
    }

    onClose(): void {
        this.collabUI.closeCollaborationSidebar();
    }

}
