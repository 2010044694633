export function assignMap<A, B>(m: Map<A, B>): Map<A, B> {

    if (typeof m.get === 'function') {
        return m; // map is correct
    } else {
        const gm = new Map();
        Object.keys(m).forEach(k => {
            const val = m[k];
            gm.set(k, val);
        });

        return gm;
    }
}

export function unassignMap<A, B>(m: Map<A, B>): any {

    if (typeof m.get === 'function') {
        const res = Array.from(m).reduce((obj, [key, value]) => {
            // @ts-ignore
            obj[key] = value;
            return obj;
        }, {});

        return res;
        // const gm = new Map();
        // Object.keys(m).forEach(k => {
        //     const val = m[k];
        //     gm.set(k, val);
        // });
        //
        // return gm;
    } else {
        return m;
    }
}

