import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PcbFileUploadService} from '../files/pcb-file-manager/pcb-file-upload.service';
import {AssemblyService} from '../../assembly.service';
import {concatMap, filter, flatMap, map, startWith} from 'rxjs/operators';
import {EmailService} from '../../../../email/email.service';
import {FileCategory, FileMimeType} from '../files/pcb-file-manager/pcb-file-item-list/pcb-layer-order';
import {AssemblyListService} from '../../assembly-list.service';
import {Assembly, AssemblyFile, AssemblyWithReference} from '../../../../../pcb-common/assembly/assembly';
import {LoginService} from '../../../../../common/auth/login.service';
import {EnvironmentService} from '../../../../../common/env/environment.service';

export class FileDescriptor {
    public name: string;
    public path: string;
    public type: string;

    constructor(name: string, type: string, path: string) {
        this.name = name;
        this.type = type;
        this.path = path;
    }
}


@Injectable({
    providedIn: 'root'
})
export class DocumentViewerService {
    public fileList: BehaviorSubject<FileDescriptor[]> = new BehaviorSubject<FileDescriptor[]>(null);
    public pdfSource: Observable<string>;
    public imageSource: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private selectedFile: BehaviorSubject<FileDescriptor> = new BehaviorSubject<FileDescriptor>(null);
    private viewerState: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _fileCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(private _pcbUploadService: PcbFileUploadService,
                private _assemblyService: AssemblyService,
                private _assemblyListService: AssemblyListService,
                private _loginService: LoginService,
                private mail: EmailService,
                private env: EnvironmentService
    ) {

        this.initService(this._assemblyService.getCurrentReferencedAssembly());

    }

    public initService(assemblyObservable: Observable<AssemblyWithReference>): void {
        this.reset();

        assemblyObservable.pipe(
            concatMap(ass => {
                return this.getAssemblyDocumentFiles(ass).pipe(
                    map(list => {

                        const paths = list.map(assFile => new FileDescriptor(assFile.name, assFile.fType.mimeType, this.getAssemblyDocument(ass.assembly, assFile)));
                            if (ass.assembly.mail) {
                                paths.push(new FileDescriptor('E-Mail', FileMimeType.PDF, this.mail.emailPDF(ass.assembly.mail, this._loginService.getCurrentAuthToken().toString())));
                            }
                            return paths;
                        }
                    )
                );
            })
        ).subscribe(fileList => {
            this.fileList.next(fileList);
            return this.fileList;
        });

        this.pdfSource =
            assemblyObservable.pipe(
                flatMap(ass => {
                        return this.selectedFile.pipe(
                            filter(f => f != null),
                            map(f =>
                                f.path
                            )
                        );
                    }
                )
            );
    }

    public deleteServiceContent(): void {
        this.reset();
    }

    public setAssembly(assemblyObservable: Observable<AssemblyWithReference>): void {
        this.initService(assemblyObservable);
    }

    public getSelectedFile(): Observable<FileDescriptor> {
        return this.selectedFile.asObservable();
    }

    public getViewerState(): Observable<boolean> {
        return this.viewerState.asObservable();
    }

    public toggleViewerState(): void {
        this.viewerState.next(!this.viewerState.getValue());
    }

    public closeViewerState(): void {
        this.viewerState.next(false);
    }

    public displayFile(file: AssemblyFile): void {
        this.viewerState.next(true);

        const imageFile = this.fileList.getValue().find(f => f.name === file.name);
        if (imageFile) {
            this.selectedFile.next(imageFile);
        }
    }


    public reset(): void {

        this.fileList.next([]);
        this.imageSource.next(null);
        this.selectedFile.next(null);
        this.viewerState.next(false);
    }

    public selectFile(file: FileDescriptor): void {
        this.selectedFile.next(file);
    }

    public getCurrentlySelected(): FileDescriptor {
        return this.selectedFile.getValue();
    }

    public getAssemblyDocument(a: Assembly, file: AssemblyFile): string {

        if (file.fType.mimeType === FileMimeType.GERBER || file.fType.mimeType === FileMimeType.DRILL || file.fType?.mimeType?.startsWith(FileMimeType.ODB)) {
            const previewPath =
                this.env.environment.files +
                '/assembly/' +
                a.id +
                '/versions/' +
                a.currentVersion.id;

            return previewPath + '/' + file.preview;
        } else {
            return this._pcbUploadService.getFile(a, file.name);
        }
    }

    public getAssemblyDocumentCount(): BehaviorSubject<number> {
        return this._fileCount;
    }

    public getIcon(): string {
        return 'file_copy';
    }

    public getTooltip(): string {
        if (this.viewerState.getValue()) {
            return 'Hide documentation viewer';
        } else {
            return 'Show documentation viewer';
        }
    }

    private getAssemblyDocumentFiles(a: AssemblyWithReference): Observable<AssemblyFile[]> {

        return this._assemblyService.getFilteredFiles(f =>
            f.name.includes('.pdf') ||
            f.fType.category === FileCategory.GERBER ||
            f.fType.category === FileCategory.ODB ||
            f.fType.category === FileCategory.MECHANICAL
        ).pipe(
            startWith(a.assembly.currentVersion.files)
        );
    }
}
