import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FileUploadDialogComponent} from '../../layout/dialogs/file-upload-dialog/file-upload-dialog.component';
import {SnackbarService} from '../../common-ui/snackbar/snackbar.service';
import {ProgressBarService} from '../../common-ui/progressbar/progress-bar.service';

@Component({
    selector: 'upload-image',
    templateUrl: './upload-image.component.html',
    styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
    @Input() customHeight;

    @Input()
    format: number;    // image format eg '1/1', '4/3', '16/9'

    @Input()
    image: string;      // image information

    @Input()
    size = `normal`;    // 'tiny' | 'normal' unused

    @Input()
    type = `rect`;       // 'round' | 'rect'

    @Output()
    fileSelected = new EventEmitter<File>();    // called when new image was selected

    isHovered = false;

    defaultImage = 'assets/images/avatars/profile.jpg';

    constructor(public dialog: MatDialog,
                private _progressBarService: ProgressBarService,
                private _snackBarService: SnackbarService) {


    }

    ngOnInit(): void {

        if (!this.image || this.image === '') {
            this.image = 'assets/images/avatars/profile.jpg';
        }
    }


    mouseEnter(): void {
        this.isHovered = true;
    }

    mouseLeave(): void {
        this.isHovered = false;
    }

    uploadImage(): void {
        const dialogConfig = new MatDialogConfig<any>();
        dialogConfig.disableClose = false;
        dialogConfig.width = '50vw';
        dialogConfig.maxWidth = '500px';
        dialogConfig.data = {image: this.image, type: this.type, format: this.format};
        const dialogRef = this.dialog.open(
            FileUploadDialogComponent,
            dialogConfig
        );
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.fileSelected.emit(result);
            }
        });
    }

}
