import {Pcb2Service} from '../../../pcb/pcb2.service';
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Assembly, AssemblyFile, AssemblyWithReference} from '../../../../../../../pcb-common/assembly/assembly';
import {LoginService} from '../../../../../../../common/auth/login.service';
import {EnvironmentService} from '../../../../../../../common/env/environment.service';
import {LayerFile, Outline} from "../../../../../../../pcb-common/pcb/pcb";
import {Svg, SVG} from "@svgdotjs/svg.js";

@Component({
    selector: 'mechanical-card',
    templateUrl: './mechanical-card.component.html',
    styleUrls: ['./mechanical-card.component.scss']
})
export class MechanicalCardComponent implements AfterViewInit {
    @Input()
    outline: Outline;

    @Input()
    file?: LayerFile;

    @Input()
    ass: AssemblyWithReference;

    @Input()
    noFile = false;

    hasPath = false;

    @Output() acceptFiles: EventEmitter<any> = new EventEmitter<any>();

    @Input() selected = false;

    @ViewChild('canvas') canvas: ElementRef

    private _draw: Svg;

    constructor(
        private _pcbService: Pcb2Service,
        private _loginService: LoginService,
        private env: EnvironmentService
    ) {
    }

    ngAfterViewInit(): void {
        this._draw = SVG().addTo(this.canvas.nativeElement);

        this.outline.graphic.paths.forEach(g => g?.forEach(p => {
            if (p.path) {
                this._draw.width("100%")
                this._draw.path(p.path)
                    .fill("#888888")
                    .stroke({
                        color: '#000000',
                        width: 10
                    })

                const minX = this.outline.graphic.viewbox.min.x
                const minY = this.outline.graphic.viewbox.min.y
                const maxX = this.outline.graphic.viewbox.max.x
                const maxY = this.outline.graphic.viewbox.max.y

                this._draw.viewbox(minX + " " + minY + " " + maxX + " " + maxY)
                this._draw.translate(0, -(maxY + minY))
                this._draw.scale(1, -1)
                this.hasPath = true
            }
        }))

    }

    onClick(): void {
        this._pcbService.setOutlineForPcb(this.outline.id, this.ass).subscribe()
    }
}
