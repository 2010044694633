import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { CollaborationModule } from '../../main/collaboration/collaboration.module';
import { InlineEditModule } from '../inline-edit/inline-edit.module';
import { MaterialPageHeaderComponent } from './material-page-header.component';

@NgModule({
    declarations: [MaterialPageHeaderComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        RouterModule,
        FlexLayoutModule,
        MatTooltipModule,
        MatMenuModule,
        MatBadgeModule,
        InlineEditModule,
        MatProgressSpinnerModule,
        CollaborationModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
    ],
    exports: [MaterialPageHeaderComponent],
})
export class MaterialPageHeaderModule {}
