import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountComponent } from '../account.component';
import { AccountService } from '../account.service';
import { SnackbarService } from '../../../../../common-ui/snackbar/snackbar.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-password-change-form',
    templateUrl: './password-change-form.component.html',
    styleUrls: ['./password-change-form.component.scss']
})
export class PasswordChangeFormComponent implements OnInit {
    passwordForm: FormGroup;

    constructor(
        public _dialogRef: MatDialogRef<AccountComponent>,
        private _snackBarService: SnackbarService,
        private _accountService: AccountService,
        private _translocoService: TranslocoService,
    ) {
        this.passwordForm = new FormGroup({
            oldPw: new FormControl('', { validators: Validators.required }),
            newPw: new FormControl('', { validators: Validators.required }),
            newPw2: new FormControl('', {
                validators: [Validators.required, confirmPasswordValidator]
            })
        });
    }

    ngOnInit(): void { }
    onSubmit(): void {
        this._accountService
            .changePassword(
                this.passwordForm.get('oldPw').value,
                this.passwordForm.get('newPw').value
            )
            .subscribe(
                data => {
                    this._snackBarService.openSnackBar(
                        this._translocoService.translate('Auth-PASSWORD-SUCCESSFULLY-CHANGED'),
                        this._translocoService.translate('General-OK')
                    );
                },
                error => {
                    if (error.error.name === 'Forbidden') {
                        this._snackBarService.openSnackBar(
                            this._translocoService.translate('Auth-WE-COULD-NOT-CHANGE-YOUR-PASSWORD-THE-PROVIDED-PASSWORD-WAS-WRONG'),
                            this._translocoService.translate('General-CLOSE')
                        );
                    } else {
                        this._snackBarService.openSnackBar(
                            this._translocoService.translate('Auth-OOOOPS-SOMETHING-WENT-WRONG-WE-COULD-NOT-CHANGE-YOUR-PASSWORD'),
                            this._translocoService.translate('General-CLOSE')
                        );
                    }
                }
            );
        this._dialogRef.close();
    }
    onClose(): void {
        this._dialogRef.close();
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('newPw');
    const passwordConfirm = control.parent.get('newPw2');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
