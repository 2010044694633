import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {ChipElement} from './chip-filter';
import {MatMenu} from '@angular/material/menu';

@Component({
    selector: 'chip-filter',
    templateUrl: './chip-filter.component.html',
    styleUrls: ['./chip-filter.component.scss']
})
export class ChipFilterComponent {

    @Input()
    availableElements: ChipElement[];

    @Input()
    placeholder: string;

    @Input()
    disabled = false;

    @Input()
    filterText = 'Filter';

    @Output()
    filterChanged: EventEmitter<ChipElement[]> = new EventEmitter();

    @Output()
    addedEvent: EventEmitter<ChipElement> = new EventEmitter<ChipElement>();

    @Output()
    removeEvent: EventEmitter<ChipElement> = new EventEmitter<ChipElement>();

    filteredMenuElements: ChipElement[];

    selectedElements: ChipElement[] = [];
    @ViewChild('filterInput', {static: true}) filterInput;
    @ViewChild('auto', {static: true}) matAutocomplete: MatAutocomplete;
    @ViewChild('addMenu', {static: true}) addMenu: MatMenu;

    constructor() {
    }

    @Input() set preselect(preselect: string []) {
        // add pre-selected chips to selection list and remove from available elements
        if (preselect && preselect.length > 0 && this.availableElements.length > 0) {
            preselect.forEach(pre => {
                const chip = this.availableElements.find(a => a.data === pre);
                if (chip) {
                    this.selectedElements.push(chip);
                    this.availableElements = this.availableElements.filter(a => a.data !== pre);
                }

            });
        }
    }

    public onMenuOpen(): void {
        if (!this.filteredMenuElements) {
            this.filteredMenuElements = this.availableElements;
        }
        setTimeout(() => {
            this.filterInput.nativeElement.focus();
        });
    }

    public remove(element: ChipElement): void {
        if (!this.disabled) {
            const index = this.selectedElements.indexOf(element);
            if (index >= 0) {
                this.selectedElements.splice(index, 1);
                this.availableElements.push(element);
                this.removeEvent.emit(element);
                this.filterChanged.emit(this.selectedElements);
            }
        }
    }

    public itemClicked(chipElement: ChipElement): void {
        const chip = this.textToChip(chipElement.data);

        if (chip && !this.containsChip(chip)) {
            this.selectedElements.push(chip);
            this.clearInputFilter();
            this.addedEvent.emit(chip);
            this.filterChanged.emit(this.selectedElements);

            const index = this.availableElements.indexOf(chipElement, 0);
            if (index > -1) {
                this.availableElements.splice(index, 1);
            }
        }
    }

    public search(event): void {
        const value = event.target.value.toLowerCase();

        if (value === '') {
            this.filteredMenuElements = this.availableElements;
            return;
        }

        this.filteredMenuElements = this.availableElements.filter((chipElement) => {
            return chipElement.data.toLowerCase().includes(value);
        });
    }

    private clearInputFilter(): void {
        // remove filter value
        this.filterInput.nativeElement.value = '';
        // set datasource
        this.filteredMenuElements = this.availableElements;
    }

    private textToChip(text: string): ChipElement {
        const chip = this.availableElements.find(c => c.data === text);
        return chip;
    }

    private containsChip(chip: ChipElement): boolean {
        return !!this.selectedElements.find(c => c === chip);
    }

}
