import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { DocumentViewerComponent } from './document-viewer.component';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { FuseCardModule } from '../../../../../../@fuse/components/card';
import { SharedModule } from 'app/core/shared/shared.module';

@NgModule({
    declarations: [DocumentViewerComponent],
    imports: [
        CommonModule,
        PdfViewerModule,
        MatFormFieldModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSelectModule,
        ReactiveFormsModule,
        RouterModule,
        MatTooltipModule,
        FlexLayoutModule,
        MatInputModule,
        PinchZoomModule,
        FuseCardModule,
        SharedModule
    ],
    exports: [DocumentViewerComponent]
})
export class DocumentViewerModule {
}



