import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

// TODO: Add Angular decorator.
@Injectable()
export abstract class AutoUnsubscribable implements OnDestroy {

    // Private
    protected _unsubscribeAll: Subject<any>;


    constructor() {
        this._unsubscribeAll = new Subject();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next('');
        this._unsubscribeAll.complete();
    }
}
