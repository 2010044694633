<!-- CARD 1 -->
<div *ngIf="size==='normal'" class="quotation-card">
    <div fxLayout="row" fxLayoutAlign="start center">
        <!-- PREVIEW -->
        <div class="card-preview" fxFlex="100">
            <div class="fuse-card">
                <div class="p-16" fxLayout="row" fxLayoutAlign="start center" (click)="openQuotation( )">

                    <div class="ml-16" fxLayout="column" fxLayoutAlign="center stretch" fxFlex="100">
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
                            <div class="text-xl mb-8 shorten-long-text" matTooltip="{{quotation?.name}}"
                                 [matTooltipPosition]="'above'">{{quotation.name}}</div>
                            <div class="ml-16 pr-8" fxLayout="row" fxLayoutAlign="end center">
                                <quotation-status-badge [status]="quotation?.status"></quotation-status-badge>
                            </div>
                        </div>
                        <div class="text-sm text-bold text-secondary">
                            {{dateService.fullDateFormat(quotation.created)}}</div>

                        <!--                        <div class="px-16 py-4" fxLayout="row" fxLayoutAlign="end center">-->
                        <!--                            <button mat-icon-button (click)="archiveQuotation()">-->
                        <!--                                <mat-icon>archive</mat-icon>-->
                        <!--                            </button>-->
                        <!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="size==='tiny'" class="fuse-card p-8 auto-width quotation-card" fxFlex="100" (click)="openQuotation( )">

    <div class="position-relative">
        <div class="ml-16" fxLayout="column" fxLayoutAlign="center stretch" fxFlex="100">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
                <div style="width: 140pt" class="h3 shorten-long-text mb-8" matTooltip="{{quotation?.name}}"
                     [matTooltipPosition]="'above'"
                >{{quotation?.name}}</div>
                <div class="ml-16 pr-8" fxLayout="row" fxLayoutAlign="end center">
                    <quotation-status-badge [status]="quotation?.status"></quotation-status-badge>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
                <div class="text-base text-secondary " fxFlex="100">
                    {{dateService.simpleDateFormat(quotation?.created)}}</div>
            </div>
        </div>
    </div>

</div>

<!-- / CARD 1 -->