import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen/splash-screen.service';

import { PermissionService } from './services/permission.service';
import { NavigationProviderService } from './navigation/navigation-provider.service';
import { RegisterIconsService } from './services/register-icons.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NavigationInfoService } from './services/navigation-info.service';
import * as LogRocket from 'logrocket';
import { EnvironmentService } from './common/env/environment.service';
import { LoginService } from './common/auth/login.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { FuseNavigationItem, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { NavigationDefinitions } from './navigation/navigation-definition';
import { ThrowStmt } from '@angular/compiler';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;

    // Private
    private _unsubscribeAll: Subject<any>;



    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param env
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param _loginService
     * @param _navigationProvService
     * @param _permissionService
     * @param _navInfoService
     * @param {Platform} _platform
     * @param _registerIcons
     * @param renderer2
     * @param http
     * @param router
     * @param _document
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private env: EnvironmentService,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _loginService: LoginService,
        private _navigationProvService: NavigationProviderService,
        private _permissionService: PermissionService,
        private _navInfoService: NavigationInfoService,
        private _platform: Platform,
        private _registerIcons: RegisterIconsService,
        private renderer2: Renderer2,
        private http: HttpClient,
        private router: Router,
        private _translocoService: TranslocoService,
        @Inject(DOCUMENT) private _document,
    ) {
        // register custom stackrate icons
        this._registerIcons.registerStackrateIcons();

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------


    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config$.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            console.log('fuse config: ', config);
            this.fuseConfig = config;
        });
        this._fuseNavigationService.latestRegisteredComponentName.subscribe(name => {
            if (name == 'mainNavigation') this._updateNavigation();
        })

        this._translocoService.langChanges$.subscribe(_ => {
            this._updateNavigation();
        });

    }


    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next("");
        this._unsubscribeAll.complete();
    }

    translateItem(navigation: FuseNavigationItem[], navComponent: FuseVerticalNavigationComponent): void {
        for (const item of navigation) {
            if (item && item.id) {
                this._translocoService.selectTranslate('Navigation-' + item.id.toUpperCase()).pipe(take(1))
                    .subscribe((translatedTitle) => { item.title = translatedTitle; navComponent?.refresh(); });
            }
            if (item.children) {
                const childItem = this.translateItem(item.children, navComponent);
            }
        }
    }

    private _updateNavigation(): void {
        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;

        }
        // Get the flat navigation data
        const navigation = navComponent.navigation;
        this.translateItem(navigation, navComponent);
    }
}
