import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PrintTemplate} from '../../main/settings/print-template/printTemplate.model';
import {PrintTemplateService} from '../../main/settings/print-template/print-template.service';

@Component({
    selector: 'app-print-format-selection-dialog',
    templateUrl: './print-format-selection-dialog.component.html',
    styleUrls: ['./print-format-selection-dialog.component.scss']
})
export class PrintFormatSelectionDialogComponent implements OnInit {
    public templateForm: FormGroup;

    public category: string;
    templates: PrintTemplate[];

    constructor(
        public dialogRef: MatDialogRef<PrintFormatSelectionDialogComponent>,
        public ptService: PrintTemplateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.templateForm = new FormGroup({
            template: new FormControl('', {validators: Validators.required})
        });

        if (data.category) {
            this.category = data.category;
        }
    }

    ngOnInit(): void {
        this.ptService.getPrintTemplatesByCategory(this.category).subscribe(t => {
            this.templates = t;
        });
    }


    onSubmit(): void {
        if (this.templateForm.valid) {
            let template = this.templateForm.get('template').value;
            console.log('selected template is', template);
            this.dialogRef.close(template);
        }
    }

    onClose(): void {
        this.dialogRef.close();
    }

}
