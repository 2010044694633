import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EnvironmentService} from '../../common/env/environment.service';
import {Email, EmailAttachment, QDItem} from '../../pcb-common/assembly/email';
import {EncodedMailMessageEntityId} from "../../pcb-common/assembly/assembly";

@Injectable({
    providedIn: 'root'
})
export class EmailService {
    private emailEndpoint = '/inbox/mails';

    constructor(private _http: HttpClient,
                private env: EnvironmentService) {
    }

    public emailPDF(messageId: EncodedMailMessageEntityId, token: string): string {
        return (this.env.environment.files + '/inbox/mails/' + messageId + '?k=' + token);
    }

    public getEmailById(messageId: EncodedMailMessageEntityId): Observable<Email> {
        return this._http.get<Email>(this.env.api(this.emailEndpoint, ['inboxes', 'mails', messageId]));
    }

    public getEmailAttachment(messageId: EncodedMailMessageEntityId, attachment: EmailAttachment): string {
        return (this.env.environment.files + '/inbox/mails/' + messageId + '/' + attachment.path);
    }
}

export function parsePlainEmail(mail: Email): QDItem [] {
    const foundItems: QDItem [] = [];

    if (mail.plainMessage) {
        let m;
        const reg = /([0-9]+)/g;
        do {
            m = reg.exec(mail.plainMessage);
            if (m) {
                if (m[1]) {
                    foundItems.push(new QDItem(m[1]));
                }
            }
        } while (m);
    }
    return foundItems;
}

export function parseEmail(mail: Email): QDItem [] {
    const ngHtmlParser = require('angular-html-parser');
    const {rootNodes, errors} = ngHtmlParser.parse(mail.message);
    const foundItems: QDItem [] = [];
    rootNodes.forEach(node => {
        const children = node.children;
        if (children) {
            const strList: [] = children;
            if (strList) {
                strList.forEach(o => {
                    const str = (o as any).value as string;
                    if (str) {
                        let m;
                        const reg = /([0-9]+)/g;
                        do {
                            m = reg.exec(str);
                            if (m) {
                                if (m[1]) {
                                    foundItems.push(new QDItem(m[1]));
                                }
                            }
                        } while (m);
                    }
                });

            }
        }
    });
    return foundItems;
}

