/**
 * This service can be used to register custom icons. Call the register method in app.component.ts.
 * Watch out for calling the register method only once.
 *
 * Note: To use custom icons you can use the following names and replace the '-' by '_'
 * Example: <mat-icon svgIcon="gerber_file"></mat-icon>
 *
 * Icon names :
 * 'nanular-ring'
 * 'aspect-ratio'
 * 'blind-via'
 * 'board-size'
 * 'bottom-layer'
 * 'buried-via'
 * 'clearance'
 * 'doc-file'
 * 'drill-file'
 * 'gerber-file'
 * 'gerber-inverted-file'
 * 'hole-size'
 * 'inner-layer'
 * 'mechanical-file'
 * 'micro-via'
 * 'milling-pcb'
 * 'min-hole-distance'
 * 'min-via-distance'
 * 'non-plated'
 * 'outer-layers'
 * 'outline-clearance'
 * 'panel'
 * 'pdf-file'
 * 'plated'
 * 'slot-file'
 * 'soldermask-clearance'
 * 'soldermask-dam'
 * 'top-layer'
 * 'trace-width'
 * 'unknown-file'
 * 'v-cut-pcb'
 * 'zip-file'
 */


import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class RegisterIconsService {
    static iconPath = '../assets/icons/stackrate/';

    constructor(private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer) {
    }

    registerStackrateIcons(): void {
        this.registerFileTypeIcons();
        this.registerViolationIcons();
        this.registerLayerStackIcons();
        this.registerPanelIcons();
    }

    /**
     * This function is used to register icons by name
     * @param name : name of the svg icon, without file type eq. gerber-file
     *
     * The icon name will be created, '-' will be replaced by '_' so 'gerber-file' will be accessible by
     * <mat-icon svgIcon="gerber_file"></mat-icon>
     *
     */
    private registerIcon(name: string): void {
        const iconPath = RegisterIconsService.iconPath + '/' + name + '.svg';

        const re = new RegExp('-', 'g');
        const iconName = name.replace(re, '_');

        this.matIconRegistry.addSvgIcon(
            iconName,
            this.domSanitizer.bypassSecurityTrustResourceUrl(iconPath)
        );
    }

    /**
     * Register icons used to display file types
     */
    private registerFileTypeIcons(): void {
        this.registerIcon('gerber-file');
        this.registerIcon('gerber-inverted-file');
        this.registerIcon('pdf-file');
        this.registerIcon('doc-file');
        this.registerIcon('drill-file');
        this.registerIcon('slot-file');
        this.registerIcon('mechanical-file');
        this.registerIcon('unknown-file');
        this.registerIcon('zip-file');
        this.registerIcon('lq');

    }

    /**
     * Register icons for violations
     */
    private registerViolationIcons(): void {
        this.registerIcon('annular-ring');
        this.registerIcon('aspect-ratio');
        this.registerIcon('board-size');
        this.registerIcon('clearance');
        this.registerIcon('hole-size');
        this.registerIcon('trace-width');
        this.registerIcon('soldermask-clearance');
        this.registerIcon('soldermask-dam');
        this.registerIcon('outline-clearance');
        this.registerIcon('min-hole-distance');
        this.registerIcon('min-via-distance');
    }

    /**
     * Register icons for layer stack and via's
     */
    private registerLayerStackIcons(): void {
        this.registerIcon('blind-via');
        this.registerIcon('buried-via');
        this.registerIcon('micro-via');
        this.registerIcon('plated');
        this.registerIcon('non-plated');
        this.registerIcon('top-layer');
        this.registerIcon('inner-layer');
        this.registerIcon('outer-layer');
        this.registerIcon('bottom-layer');
    }

    /**
     * Register icons used by panel designer
     */
    private registerPanelIcons(): void {
        this.registerIcon('milling-pcb');
        this.registerIcon('panel');
        this.registerIcon('v-cut-pcb');
    }
}
