<ng-container *transloco="let t">
    <div class="content" fxLayout="row" fxLayout.lt-md="column">
        <div fxFlex="50" fxLayout="row">
            <div fxLayout="column" fxFlex.xs="100" fxFlex.sm="100" fxFlex.gt-sm="100">
                <form name="form" [formGroup]="accountForm" fxLayout="column" fxLayoutAlign="start" fxFlex="100">
                    <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
                        <div class="text-lg">
                            {{ t("Settings-ACCOUNT-DETAILS") }}
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label> {{t("General-USERNAME")}}</mat-label>
                            <input matInput id="input-userName" formControlName="userName" />
                            <mat-icon matSuffix class="text-secondary">account_circle</mat-icon>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start">
                        <div fxFlex="100">
                            <mat-form-field appearance="outline" fxFill>
                                <mat-label>{{t("General-EMAIL")}}</mat-label>
                                <input matInput id="input-email" formControlName="email" required />
                                <mat-icon matSuffix class="text-secondary">mail</mat-icon>
                                <mat-error>{{t("Settings-EMAIL-IS-REQUIRED")}}</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="pl-16 pb-20" fxLayoutAlign="end center">
                            <button mat-raised-button id="btn-change-mail" color="accent" class="change-button"
                                aria-label="Save" [disabled]="accountForm.invalid" (click)="changeEmail()">
                                <mat-icon>check</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start">
                        <div class="pw-field" fxFlex="100">
                            <mat-form-field appearance="outline" fxFill>
                                <mat-label>{{t("General-PASSWORD")}}</mat-label>
                                <input matInput id="input-pw" formControlName="password" type="password" />
                                <mat-icon matSuffix class="text-secondary">lock</mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="pl-16 pb-20" fxLayoutAlign="end center">
                            <button mat-raised-button id="btn-change-pw" color="accent" class="change-button"
                                aria-label="Save" (click)="onPasswordChange()">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start">
                        <div class="pw-field" fxFlex="100">
                            <mat-form-field appearance="outline" fxFill>
                                <mat-label>{{t("Settings-API-TOKEN")}}</mat-label>
                                <input matInput id="api-token" formControlName="token" readonly />
                                <mat-icon matSuffix class="text-secondary">lock</mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="pl-16 pb-20" fxLayoutAlign="end center">
                            <button mat-raised-button color="accent" class="change-button" aria-label="Save"
                                (click)="generateApiToken()">
                                <mat-icon>check</mat-icon>
                            </button>
                        </div>
                    </div>
                </form>

                <div class="mb-24 mt-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
                    <div class="h3">
                        {{ t("Settings-DELETE-MY-ACCOUNT") }}
                    </div>
                </div>

                <form name="deleteForm" (ngSubmit)="onDelete()" [formGroup]="deleteForm" fxLayout="column"
                    [ngStyle.sm]="{ 'margin-right': '0px !important' }" fxLayoutAlign="start" fxFlex="100">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700">
                            <div class="p-16">
                                <div class="text-base">
                                    {{ t("Settings-DELETE-MY-ACCOUNT-WARNING") }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-24" fxLayout="row" fxLayoutAlign="start" fxLayout.lt-sm="column">
                        <!-- Password -->
                        <div class="pt-16 pw-field" fxFlex="100">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>
                                    {{ t("General-PASSWORD")}}</mat-label>
                                <input matInput id="deletePwConfirm" formControlName="passwordConfirm"
                                    type="password" />
                                <mat-icon matSuffix class="text-secondary">lock</mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="pl-16" fxLayoutAlign="end center">
                            <button mat-raised-button id="btn-delete" color="warn" [disabled]="accountForm.invalid"
                                class="change-button">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="p-24 mr-24" fxFlex="50" fxLayout="row">

        </div>
    </div>
</ng-container>