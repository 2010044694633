import {Component, Input, OnInit} from '@angular/core';
import {Assembly} from '../../pcb-common/assembly/assembly';

@Component({
    selector: 'text-link',
    templateUrl: './text-link.component.html',
    styleUrls: ['./text-link.component.scss']
})
export class TextLinkComponent implements OnInit {

    @Input() set name(name: string) {
        this._name = name;
    }

    get name(): string {
        return this._name;
    }

    @Input() set assembly(assembly: Assembly) {
        if (assembly) {
            this.hrefLink = '/project/' + assembly.gid + '/dashboard';
        }
    }

    public hrefLink = '';
    private _name = '';

    constructor() {
    }

    ngOnInit() {
    }

}
