<div class="avatar-list mb-4" fxLayout="row" fxLayoutAlign="start start">

    <mat-form-field appearance="outline" class="email-contact">
        <mat-chip-list #chipList>
            <mat-chip
                (removed)="remove(name)"
                *ngFor="let name of selectedNames"
                [removable]="true"
                [selectable]="true"
                matTooltip="{{getEmail(name)}}">
                {{name}}
                <mat-icon (click)="remove(name)" class="ml-4">cancel</mat-icon>
            </mat-chip>
            <input
                #nameInput
                (matChipInputTokenEnd)="add($event)"
                [formControl]="emailFormCtrl"
                [matAutocomplete]="auto"
                [matChipInputAddOnBlur]="true"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                placeholder="{{headerText}}">
        </mat-chip-list>
        <mat-autocomplete #auto (optionSelected)="selected($event)">
            <mat-option *ngFor="let name of filteredNameList | async" [value]="name">
                {{name}}
                <span class="text-secondary"> - {{getEmail(name)}} </span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

</div>
