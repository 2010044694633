import {Point} from '@svgdotjs/svg.js';
import {SvgDrillClassNPTH, SvgDrillClassPTH} from '../../../../../common-ui/svg/svg-constants';

export class DrillStyle {
    color: string;
    color_highlighted: string;
    opacity: number;

    constructor(color: string, color_highlighted: string, opacity: number) {
        this.color = color;
        this.color_highlighted = color_highlighted;
        this.opacity = opacity;
    }
}

export const DefaultDrillColors = new Map<String, DrillStyle>([
    [SvgDrillClassPTH, new DrillStyle('#aaa', '#59d4e8', 1)],
    [SvgDrillClassNPTH, new DrillStyle('#777', '#f76262', 1)]
]);

export class HoleList {
    from: number;
    to: number;
    tools: Tool[];
    scaling: number;
}

export class HoleSettings {
    offset: Point = new Point();
    format: [number, number];

    constructor() {

    }
}

export class HoleInfo {
    name: string;
    count: number;
    diameter: string;
    type: string;
    classes: string [] = [];
    active: boolean;
    from: number;
    to: number;

    constructor(name: string, count: number, diameter: string, type: string, classes: string[], active: boolean, from ?: number, to ?: number) {
        this.name = name;
        this.count = count;
        this.diameter = diameter;
        this.type = type;
        this.classes = classes;
        this.active = active;
        this.from = from;
        this.to = to;
    }
}

export class Tool {
    name: string; // eq. T1
    drillType: string; // PLATED | NON_PLATED
    diameter: number; // 0.4
    drills: DrillHit[]; // [{0,0} , {1,1}]
}

export class DrillHit {
    x: number;
    y: number;
    id: string;
}

export class DrillFormat {
    decimal_places: number;
    integer_places: number;

    constructor(integer_places: number, decimal_places: number) {
        this.integer_places = integer_places;
        this.decimal_places = decimal_places;
    }
}

export const DrillUnitList = ['Millimeter', 'Inch'];
export const DrillFormatListMM: DrillFormat [] = [
    new DrillFormat(4, 2),
    new DrillFormat(4, 3),
    new DrillFormat(4, 4),
];


export const DrillFormatListInch: DrillFormat [] = [
    new DrillFormat(2, 3),
    new DrillFormat(2, 4),
    new DrillFormat(2, 5),
];
export const DrillZeroStyles = ['Keep zeros', 'Leading zeros', 'Trailing zeros'];


export const DRILL_DECIMAL_PLACES = 'drill_decimal_places';
export const DRILL_INTEGER_PLACES = 'drill_integer_places';
export const DRILL_ZEROES_MODE = 'drill_zeroes_mode';
export const DRILL_UNIT = 'drill_unit';
