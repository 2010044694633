import {Component, Input, OnInit} from '@angular/core';
import {CollaborationService} from '../collaboration.service';
import {ActivatedRoute} from '@angular/router';
import {AvatarService} from '../../settings/profile/avatar/avatar.service';
import {CollaborationUiService} from '../collaboration-ui.service';
import {CollaborationEntities} from '../collaboration';
import {fuseAnimations} from '../../../fuse/animations';


interface TagStyle {
    color: string,
    text: string
};

@Component({
    selector: 'timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
    animations: fuseAnimations
})
export class TimelineComponent implements OnInit {

    @Input()
    assembly: string;
    @Input()
    version: string;

    public loading = false;

    public systemAvatar = 'assets/images/avatars/stackrate-logo.png';
    public guestAvatar = 'assets/images/avatars/profile.jpg';

    public defaultAvatar: string;
    public tagMap: Map<string, TagStyle> = new Map<string, TagStyle>([
        [CollaborationEntities.ASSEMBLY, {color: '#106CC8', text: 'Assembly'}],
        [CollaborationEntities.PCB, {color: '#004200', text: 'PCB'}],
        [CollaborationEntities.PCB_SPECIFICATION, {color: '#283747', text: 'Spec.'}],
        [CollaborationEntities.CUSTOMER_PANEL, {color: '#154360', text: 'Panel'}],
        [CollaborationEntities.QUOTATION_ITEM, {color: '#581845', text: 'Quotation item'}],
        [CollaborationEntities.QUOTATION, {color: '#581845', text: 'Quotation'}],
        [CollaborationEntities.LAYERSTACK_USAGE, {color: '#a0522d', text: 'Stack-Up'}],
        [CollaborationEntities.DFM_VIOLATIONS, {color: '#cb4335', text: 'WebDFM'}],
        [CollaborationEntities.SYSTEM, {color: '#17202a', text: 'System'}],
    ]);

    constructor(public colla: CollaborationService,
                private route: ActivatedRoute,
                public collabUI: CollaborationUiService,
                private av: AvatarService) {
        this.defaultAvatar = this.systemAvatar; // av.defaultAvatar;
    }

    ngOnInit(): void {
    }

    public getTagElement(category: string): TagStyle {
        if (this.tagMap.has(category)) {
            return this.tagMap.get(category);
        }
        return {color: '#fff', text: 'Unknown'};
    }


    private getSharingURL(shareID: string): string {
        return location.origin + '/share/' + shareID;
    }
}
