import {Component, Inject} from '@angular/core';
import {PcbFileUploadService} from '../pcb-file-upload.service';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {BehaviorSubject, Observable} from 'rxjs';
import {Pcb2Service} from '../../../pcb/pcb2.service';
import {AssemblyService} from '../../../../assembly.service';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {LayerFile, Outline} from '../../../../../../../pcb-common/pcb/pcb';
import {AssemblyConstants} from '../../../../../../../pcb-common/assembly/assembly-constants';
import {
    Assembly,
    AssemblyFile,
    AssemblyWithReference,
    FileTypes
} from '../../../../../../../pcb-common/assembly/assembly';
import {fuseAnimations} from '../../../../../../../fuse/animations';
import {SnackbarService} from '../../../../../../../common-ui/snackbar/snackbar.service';

@Component({
    selector: 'bottom-sheet-set-outline',
    templateUrl: 'bottom-sheet-set-outline.component.html',
    styleUrls: ['./bottom-sheet-set-outline.component.scss'],
    animations: fuseAnimations
})
export class BottomSheetSetOutlineComponent {
    outline: LayerFile;
    pcbService: Pcb2Service = null;
    assService: AssemblyService = null;
    dashboardRoute;

    public outlines: BehaviorSubject<[Outline, LayerFile][]> = new BehaviorSubject<[Outline, LayerFile][]>([])

    constructor(
        private _fileUploadService: PcbFileUploadService,
        private _bottomSheetRef: MatBottomSheetRef<BottomSheetSetOutlineComponent>,
        private _snackbarService: SnackbarService,
        private _logger: NGXLogger,
        private _router: Router,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {

        if (data.pcbService) {
            this.pcbService = data.pcbService;
        }
        if (data.assService) {
            this.assService = data.assService;
        }
        if (data.assGid) {
            this.dashboardRoute = ['/', AssemblyConstants.PROJECTS_URL, data.assGid, AssemblyConstants.PCB_VIEWER_URL];
        }


        this.pcbService.getOutlineCandidates(true).subscribe(outlines => {
            return this.pcbService.getCurrentPCB().subscribe(pcb => {
                this.outlines.next(outlines.map(o => {
                    return [o, pcb.files.find(l => l.id == o.file)]
                }))
            })
        })
    }

    getAssembly(): Observable<AssemblyWithReference> {
        return this.data.assService.assemblySubject.asObservable();
    }

    openMechanicalCard(event: MouseEvent): void {
        this._bottomSheetRef.dismiss(true);
        event.preventDefault();
    }
}
