import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AvatarItem} from './avatar-list';
import {FormControl} from '@angular/forms';
import {CustomerService} from '../../main/customer/customer.service';
import {AvatarService, ImageSizes} from '../../main/settings/profile/avatar/avatar.service';

export interface State {
    flag: string;
    name: string;
    population: string;
}

@Component({
    selector: 'avatar-list',
    templateUrl: './avatar-list.component.html',
    styleUrls: ['./avatar-list.component.scss']
})
export class AvatarListComponent implements OnInit {


    @Input() appearance;
    @Input()
    placeholder: string;
    @Input()
    customerID: string;
    @Output()
    selectionChanged: EventEmitter<AvatarItem> = new EventEmitter();
    selectedAvatar: AvatarItem = null;
    stateCtrl = new FormControl();
    filteredAvatars: AvatarItem[];
    allElements: AvatarItem[] = [];
    _avatarIcon: string;

    constructor(private _customerService: CustomerService, private _avatarService: AvatarService) {

    }

    @Input()
    set staticAvatar(staticAvatar: AvatarItem) {
        if (staticAvatar) {
            this.placeholder = staticAvatar.name;
            this.selectedAvatar = staticAvatar;
        }
    }

    @Input()
    set availableElements(availableElements: AvatarItem[]) {
        if (availableElements) {
            this.setList(availableElements);
        } else {
            this.stateCtrl.setValue('');
        }
    }

    _enabled: boolean;

    @Input() set enabled(enabled: boolean) {
        if (enabled) {
            this.stateCtrl.enable();
        } else {
            this.stateCtrl.disable();
        }
        this._enabled = enabled;
    }

    _type: string;

    @Input() set type(type: string) {
        this._type = type;
        switch (this._type) {
            case 'user':
            case 'contact':
                this._avatarIcon = 'face';
                break;
            case 'customer':
                this._avatarIcon = 'business';
                break;
        }
    }

    ngOnInit() {


    }

    public selectAvatar(name: string): void {
        const a = this.allElements.find(f => f.name === name);

        if (a) {
            this.selectedAvatar = a;
            this.selectionChanged.emit(this.selectedAvatar);
        } else {
            if (this.selectedAvatar) {
                this.selectedAvatar = null;
                this.selectionChanged.emit(null);
            }
        }


    }

    public getImagePath(id: string, image: string): string {

        switch (this._type) {
            case 'contact':
                return this._customerService.getContactImagePath(this.customerID, id, image, ImageSizes.TINY);

            case 'customer':
                return this._customerService.getCustomerImagePath(id, image, ImageSizes.TINY);

            case 'user':
                return image;
                break;

            case 'assembly':
                return '';
                //this._avatarService.getUserImage(id, image, ImageSizes.TINY);
                break;
        }
        return image;
    }

    private _filterAvatars(value: string): AvatarItem[] {
        if (value) {
            const filterValue = value.toLowerCase();
            return this.allElements.filter(avatar => avatar.name.toLowerCase().indexOf(filterValue) === 0);
        }
        return this.allElements;
    }

    private findAvatarByName(name: string): AvatarItem {
        return this.allElements.find(f => f.name === name);
    }

    private setList(list: AvatarItem []): void {
        this.allElements = list;
        if (this.allElements) {
            this.filteredAvatars = this.allElements;

            this.stateCtrl.valueChanges.subscribe(input => {
                this.filteredAvatars = this._filterAvatars(input);

                if (!this.findAvatarByName(input)) {
                    this.selectAvatar('');
                }
            });
        }
    }
}
