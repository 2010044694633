import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PasswordChangeFormComponent } from './password-change-form/password-change-form.component';
import { AccountService } from './account.service';
import { UserService } from '../../../authentication/user.service';
import { first } from 'rxjs/operators';
import { LoginService } from '../../../../common/auth/login.service';
import { SnackbarService } from '../../../../common-ui/snackbar/snackbar.service';
import { DeleteConfirmDialogComponent } from '../../../../common-ui/dialogs/delete-confirm-dialog/delete-confirm-dialog.component';
import { ProgressBarService } from '../../../../common-ui/progressbar/progress-bar.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
    accountForm: FormGroup;
    deleteForm: FormGroup;

    constructor(
        public dialog: MatDialog,
        private _loginService: LoginService,
        private _accountService: AccountService,
        private _snackBarService: SnackbarService,
        private _fuseProgressBarService: ProgressBarService,
        private _userService: UserService,
        private _translocoService: TranslocoService,
    ) {
        this.deleteForm = new FormGroup({
            passwordConfirm: new FormControl('', {
                validators: Validators.required
            })
        });

        this.accountForm = new FormGroup({
            userName: new FormControl(
                { value: '', disabled: true },
                { validators: Validators.required }
            ),
            email: new FormControl(
                { value: '', disabled: false },
                { validators: [Validators.required, Validators.email] }
            ),
            password: new FormControl(
                { value: 'xxxxxxxx', disabled: true },
                { validators: Validators.required }
            ),
            token: new FormControl()
        });
    }

    /**
     * Initializes the new account form
     */
    ngOnInit(): void {
        this.accountForm.patchValue({
            userName: this._loginService.getCurrentUserName(),
            email: this._loginService.getCurrentEmail()
        });
    }

    /**
     * Opens the dialog on which the user can change his password
     */
    onPasswordChange(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '90vw';
        dialogConfig.maxWidth = '700px';
        const dialogRef: MatDialogRef<PasswordChangeFormComponent> = this.dialog.open(PasswordChangeFormComponent, dialogConfig);
    }

    changeEmail(): void {
        const email = this.accountForm.get('email').value;
        this._fuseProgressBarService.show();
        this._accountService.changeEmail(email).subscribe(
            data => {
                this._snackBarService.openSnackBar(this._translocoService.translate('Auth-EMAIL-SUCCESSFULLY-CHANGED'), this._translocoService.translate('General-CLOSE'));
                // Since email is part of the JWT token we have to update it
                this._loginService.refreshAuthToken('true').subscribe();
                this._fuseProgressBarService.hide();
            },
            error => {
                if (error.error) {
                    if (error.error.service.type === 'EmailInUse') {
                        this._snackBarService.openSnackBar(this._translocoService.translate('Auth-THIS-E-MAIL-ADDRESS-IS-ALREADY-IN-USE'), this._translocoService.translate('General-CLOSE'));
                    } else {
                        this._snackBarService.openSnackBar(this._translocoService.translate('Auth-OOOOPS-SOMETHING-WENT-WRONG-WE-COULD-NOT-CHANGE-YOUR-EMAIL-ADDRESS'), this._translocoService.translate('General-CLOSE'));
                    }
                }
                this._fuseProgressBarService.hide();
            }
        );
    }

    /**
     * Opens the confirmation dialog to ask the user if he really wants
     * to delete his account
     */
    onDelete(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '90vw';
        dialogConfig.maxWidth = '600px';
        dialogConfig.data = {
            message: this._translocoService.translate('Settings-DELETE-ACCOUNT-CONFIRM'),
            yes_text: this._translocoService.translate('General-YES'),
            no_text: this._translocoService.translate('General-NO')
        };
        const dialogRef = this.dialog.open(
            DeleteConfirmDialogComponent,
            dialogConfig
        );
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._accountService
                    .deleteUserAccount(
                        this.deleteForm.get('passwordConfirm').value
                    )
                    .subscribe(
                        data => {
                            this._loginService.logout();
                        },
                        error => {
                            if (error.error.name === 'Forbidden') {
                                this._snackBarService.openSnackBar(
                                    this._translocoService.translate('Settings-DELETE-ACCOUNT-WRONG-PASSWORD-ERROR'), this._translocoService.translate('General-CLOSE'));
                            } else {
                                this._snackBarService.openSnackBar(this._translocoService.translate('Settings-GENERAL-ERROR'), this._translocoService.translate('General-CLOSE'));
                            }
                        }
                    );
            }
        });
    }

    generateApiToken(): void {
        this._loginService.getTokenPayloadSubject().pipe(first()).subscribe(token => {
            this._userService.generateNewToken(token.userId, token.team).subscribe(newToken => {
                this.accountForm.patchValue({ 'token': newToken.token });
            });
        });
    }
}
