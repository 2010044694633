<div (click)="uploadImage()" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()"
     class="form-group" fxLayout="row" fxLayoutAlign="start center">

    <div *ngIf="size==='normal'" class="content_img_normal">

        <img #img (error)="img.src = defaultImage" [ngClass]="{'round-image': type==='round'}" [ngClass]="customHeight"
             class="image-style"
             src="{{image}}">
        <div>
            <mat-icon>cloud_upload</mat-icon>
            Upload
        </div>
    </div>

    <div *ngIf="size==='tiny'" class="content_img_tiny">

        <img #img (error)="img.src = defaultImage" [ngClass]="{'round-image': type==='round'}" class="image-style "
             src="{{image}}">
        <div>
            <mat-icon>cloud_upload</mat-icon>
        </div>
    </div>

</div>
