<ng-container *transloco="let t">
    <div class="flex flex-col flex-grow w-full overflow-hidden">

        <material-page-header (primaryButtonAction)="approveFiles()"
            (secondButtonAction)="documentService.toggleViewerState()" *ngIf="showHeader"
            [breadcrumbs]="breads" [browserTitle]="title"
            [enablePrimaryButton]="enableApproveButton" [goBackRoute]="dashboardRoute" [menu]="fileMenu"
            [pageTitle]="(_assService.getCurrentAssembly() | async)?.name"
            [primaryButtonColor]=" (outlineFound | async) ? 'primary': 'warn'"
            [primaryButtonIcon]="(primaryButtonIcon | async)" [primaryButtonTooltip]="(primaryButtonTooltip | async)"
            [progressValue]="previewsRenderedProgress"
            [secondButtonBadgeActive]="(documentService.getAssemblyDocumentCount()|async)>0"
            [secondButtonBadgeNumber]="documentService.getAssemblyDocumentCount()|async"
            [secondButtonIcon]="documentService.getIcon()" [secondButtonTooltip]="documentService.getTooltip()"
            [useSearchBar]="false" goBackTooltip='{{t("Tooltip-BACK-TO-DASHBOARD")}}' secondButtonIcon="cached">
        </material-page-header>

        <mat-menu #fileMenu="matMenu">
            <button (click)="onSetOutline()"  mat-menu-item>
                <mat-icon>vignette</mat-icon>
                {{t("Project_assemblies-SET-OUTLINE")}}
            </button>
            <button (click)="downloadZip()" mat-menu-item>
                <mat-icon>download</mat-icon>
                {{t("Project_assemblies-DOWNLOAD-FILES")}}
            </button>
            <button (click)="acceptFiles()" [disabled]="(pcbService.getPCBState() | async)?.locked" mat-menu-item>
                <mat-icon>check</mat-icon>
                {{t("Project_assemblies-ACCEPT-FILES")}}
            </button>
        </mat-menu>

        <div class="flex flex-row flex-grow m-2">
            <div class="flex flex-col flex-grow w-full overflow-hidden">
                <!-- FileDrop Component -->
                <pcbfile-drop *ngIf="(isFileViewerProtected() | async)===false" class="pb-5"></pcbfile-drop>
                <!-- / FileDrop Component -->


                <!-- Error Accordion -->
                <div *ngIf="fileErrorCount > 0" [@animate]="{  value: '*', params: { duration: '300ms', x: '-100px' } }"
                    class="flex flex-col">
                    <mat-accordion class="pb-2 w-full overflow-hidden">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon color="warn" class="mr-4">warning</mat-icon>
                                    {{fileErrorCount}} {{t("Project_assemblies-ERRORS-IN-THAT-PROJECT")}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngIf="(fileErrorMap | async) as errorMap">

                                <div *ngFor="let fileName of fileErrorNames|async" class="flex flex-col">
                                    <div class="text-sm flex flex-row">
                                        <div> {{t("General-FILE")}} : <u>{{fileName}}</u></div>
                                        <!--                                <div class="px-8"> -</div>-->
                                        <!--                                <div>-->
                                        <!--                                    {{errorMap?.get(fileName)?.status?.name?.toString()}}-->
                                        <!--                                </div>-->

                                    </div>
                                    <div *ngFor="let msg of errorMap?.get(fileName)?.messages"
                                        class="flex flex-wrap p-2">
                                        <p class="font-weight-100 text-sm">{{msg?.message}}</p>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>


                <!-- Gerber -->
                <div class="flex flex-col pb-6 w-full">
                    <div class="flex flex-col p-0 pb-2">
                        <div class="text-2xl flex flex-row"> {{t("General-GERBER")}}
                            <expand-icon (stateChange)="showGerberFiles=$event" [state]="showGerberFiles"></expand-icon>
                        </div>
                        <span class="text-secondary text-base">Gerber, GerberX2</span>
                    </div>

                    <!-- Gerber table -->
                    <div class="content flex flex-col flex-grow w-full">
                        <pcb-file-item-list
                            (previewsRenderedProgress)="onPreviewsRenderedChanged($event); previewsRenderedProgressEvent.emit($event)"
                            [category]="fileCategory.GERBER" [showFileList]="showGerberFiles"
                            [statusMap]="fileStatusMap" class="pcbFileTable" file iconClass="gerber">
                        </pcb-file-item-list>
                    </div>
                </div>
                <!-- Gerber -->

                <!-- ODB -->
                <div class="flex flex-col pb-6 w-full">
                    <div class="flex flex-col p-0 pb-2">
                        <div class="text-2xl flex flex-row"> {{t("General-ODB")}}
                            <expand-icon (stateChange)="showGerberFiles=$event" [state]="showGerberFiles"></expand-icon>
                        </div>
                        <span class="text-secondary text-base">ODB++</span>
                    </div>

                    <!-- ODB table -->
                    <div class="content flex flex-col flex-grow w-full">
                        <pcb-file-item-list
                            (previewsRenderedProgress)="onPreviewsRenderedChanged($event); previewsRenderedProgressEvent.emit($event)"
                            [category]="fileCategory.ODB" [showFileList]="showODBFiles"
                            [statusMap]="fileStatusMap" class="pcbFileTable" file iconClass="gerber">
                        </pcb-file-item-list>
                    </div>
                </div>
                <!-- ODB -->

                <!-- Mechanics -->
                <div class="section pb-6 flex flex-col w-full">
                    <div class="section flex flex-row flex-grow">
                        <div class="p-0 pb-3 flex flex-col">
                            <div class="text-2xl flex flex-row"> {{t("General-MECHANICAL")}}
                                <expand-icon (stateChange)="showMechanicalFiles=$event" [state]="showMechanicalFiles">
                                </expand-icon>
                            </div>
                            <span
                                class="text-secondary text-base">{{t("Project_assemblies-DRILL-FILES-MILLING-CUT-OUT")}}</span>
                        </div>
                    </div>
                    <!-- Mechanics table -->
                    <div class="content flex flex-col flex-grow">
                        <pcb-file-item-list [category]="fileCategory.MECHANICAL" [showFileList]="showMechanicalFiles"
                            [statusMap]="fileStatusMap" class="pcbFileTable" iconClass="mechanical">
                        </pcb-file-item-list>
                    </div>
                </div>
                <!-- Mechanics -->

                <!-- Unused files -->
                <div class="section pb-6 flex flex-col flex-grow w-full">
                    <div class="section flex flex-row flex-grow">
                        <div class="p-0 pb-3 flex flex-col">
                            <div class="text-2xl flex flex-row"> {{t("General-MISCELLANEOUS")}}
                                <expand-icon (stateChange)="showUnusedFiles=$event" [state]="showUnusedFiles">
                                </expand-icon>
                            </div>
                            <span class="text-secondary text-base">
                                {{t("Project_assemblies-CAD-FILES-PDFS-AND-OTHER-TYPES")}} </span>
                        </div>
                    </div>

                    <!-- Unused Files table -->
                    <div class="content flex flex-col flex-grow">
                        <pcb-file-item-list [category]="fileCategory.MISC" [showFileList]="showUnusedFiles"
                            class="pcbFileTable" iconClass="unknown"></pcb-file-item-list>
                    </div>
                </div>
                <!-- Unused files -->
            </div>
            <document-viewer class="doc-viewer"></document-viewer>
        </div>

    </div>
</ng-container>