<ng-container *transloco="let t">
    <div mat-dialog-title>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="100" fxLayoutAlign="flex-start">{{t("Project_assemblies-SUPPORTED-FILE-FORMATS")}}</div>
            <button (click)="dialogRef.close()" class="close-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <mat-dialog-content class="content p-16">
        <div fxLayout="column" fxLayoutAlign="start stretch">

            <table [dataSource]="dataSource" mat-table>

                <ng-container class="mat-column-type" matColumnDef="type">
                    <th *matHeaderCellDef mat-header-cell> {{t("General-TYPE")}}</th>
                    <td *matCellDef="let f" class="type-text" mat-cell> {{t(f.type)}} </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th *matHeaderCellDef mat-header-cell> {{t("General-DESCRIPTION")}}</th>
                    <td *matCellDef="let f" mat-cell> {{t(f.description)}} </td>
                </ng-container>

                <ng-container class="mat-column-extension" matColumnDef="extension">
                    <th *matHeaderCellDef mat-header-cell> {{t("General-FILE-EXTENSION")}}</th>
                    <td *matCellDef="let f" class="extension-text" mat-cell> {{t(f.extension)}} </td>
                </ng-container>

                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
            </table>

        </div>
    </mat-dialog-content>
</ng-container>