export class AssemblyConstants {
    public static PROJECTS_NAME = 'Projects';
    public static PROJECTS_URL = 'project';

    public static SHARES_NAME = 'Shared Assemblies';
    public static SHARES_URL = 'shares';

    public static DASHBOARD_NAME = 'Dashboard';
    public static DASHBOARD_URL = 'dashboard';

    public static PCB_VIEWER_NAME = 'PCB Viewer';
    public static EMAIL_VIEWER = 'Email';
    public static LAYERSTACK_SELECTION = 'Layerstacks';
    public static SPECIFICATION_SELECTION_URL = 'layerstacks';
    public static LAYERSTACK_VIEWER = 'Viewer';

    public static WEB_DFM_NAME = "Web DFM";
    public static WEB_DFM_VIEW_NAME = 'Viewer';
    public static WEB_DFM_URL = 'webdfm';
    public static PCB_VIEWER_URL = 'pcb';


    public static FILES_NAME = 'File Manager';
    public static FILES_URL = 'files';

    public static CALC_NAME = 'Calculation';
    public static CALC_URL = 'calc';


    public static SPECIFICATION_NAME = 'Specifications';
    public static SPECIFICATION_URL = 'specifications';


    public static PANEL_NAME = 'Panels';
    public static PANEL_VIEW_NAME = 'Panel';
    public static PANEL_URL = 'panels';


    public static SPECIFICATION_VIEW_NAME = 'Specification';

    public static QUOTATION_NAME = 'Quotations';
    public static QUOTATION_URL = 'quotation';

    public static QUOTATION_VIEW_NAME = 'Quotation';


}
