import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Quotation, QuotationItemReference} from './quotation';
import {mergeMap, tap} from 'rxjs/operators';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {QuotationService} from './quotation.service';
import {AutoUnsubscribable} from '../../helpers/autounsub';
import {EnvironmentService} from '../../common/env/environment.service';
import {SegmentService} from '../../services/segment.service';
import {AssemblyReference, SharedAssemblyReference} from "../../pcb-common/assembly/assembly";
import {PrintTemplateService} from "../settings/print-template/print-template.service";

@Injectable({
    providedIn: 'root'
})
export class QuotationViewService extends AutoUnsubscribable {
    quotationEndpoint = '/quotation';
    private quotation: BehaviorSubject<Quotation> = new BehaviorSubject(null);

    constructor(
        private _httpClient: HttpClient,
        private env: EnvironmentService,
        private qservice: QuotationService,
        private _segment: SegmentService,
        private _templates: PrintTemplateService
    ) {
        super();
    }

    public subscribeQuotation(): Observable<Quotation> {
        return this.quotation.asObservable();
    }

    public addQuotationItemToQuotation(item: QuotationItemReference, customerId: string, quotationId: string): Observable<any> {
        return this._httpClient.put<any>(this.env.api(this.quotationEndpoint, ['quotations', customerId, 'quotations', quotationId, 'items']), item).pipe(
            tap(q => this.quotation.next(q))
        );
    }

    public removeQuotationItemFromQuotation(item: QuotationItemReference, customerId: string, quotationId: string): Observable<any> {
        return this._httpClient.delete<any>(this.env.api(this.quotationEndpoint, ['quotations', customerId, 'quotations', quotationId, 'items', item.id.toString()])).pipe(
            tap(q => this.quotation.next(q))
        );
    }

    public getQuotation(quotationNameOrId: string, items = false): Observable<Quotation> {
        const cachedId = this.qservice.getCachedQuotationID(quotationNameOrId);
        if (cachedId) {
            quotationNameOrId = cachedId;
        }

        return this._httpClient.get<Quotation>(this.env.api(this.quotationEndpoint, ['quotations', quotationNameOrId], {items: true})).pipe(
            tap(q => {
                this.quotation.next(q);
            })
        );
    }

    public printQuotation(): Observable<HttpResponse<Blob>> {
        const quotation = this.quotation?.getValue();
        const quotationId = quotation?.quotationId;

        if (quotation.assembly.isShare) {
            let assembly = quotation?.assembly as SharedAssemblyReference;
            this._segment.logData('download_quotation_document', {
                'team_name': assembly.team,
                'assembly_id': assembly.id,
                'quotation': quotationId
            });
        } else {
            let assembly = quotation?.assembly as AssemblyReference;
            this._segment.logData('download_quotation_document', {
                'team_name': assembly.team,
                'assembly_id': assembly.id,
                'quotation': quotationId
            });
        }


        let printTemplateObservable = this._templates.selectPrintTemplateForCategory("quotation");
        return printTemplateObservable.pipe(
            mergeMap(template => {
                if (template) {
                    return this._httpClient.get(this.env.api('quotation', ['quotations', quotationId, 'print'], {template: template.name}), {
                        headers: {
                            'Accept': 'application/pdf'
                        },
                        observe: 'response',
                        responseType: 'blob'
                    });
                } else {
                    return of(null)
                }
            })
        )


    }

    public updateQuotation(quotation: Quotation): Observable<Quotation> {
        const quotationWithoutItems: Quotation = JSON.parse(JSON.stringify(quotation));
        quotationWithoutItems.items = [];
        return this._httpClient.put<Quotation>(this.env.api(this.quotationEndpoint, ['quotations', quotationWithoutItems.name]), quotationWithoutItems).pipe(
            tap(q => this.quotation.next(q))
        );
    }

    public setQuotation(quotation: Quotation): void {
        this.quotation.next(quotation);
    }

}
