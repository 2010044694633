<ng-container *transloco="let t">
    <div class="flex flex-col">

        <div class="p-2 flex flex-row justify-center">
            <div class="text-2xl flex flex-grow">{{t("Timeline-TIMELINE")}}</div>
            <button (click)="onClose()" color="accent" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <div class="text-secondary p-2 pt-0 pb-0">
            {{t("Timeline-FILTER-TIMELINE-ENTITIES")}}
        </div>
        <timeline></timeline>

    </div>
</ng-container>