import {Injectable} from '@angular/core';
import {AbstractBearerInterceptor} from './abstract-bearer.interceptor';
import {Observable} from 'rxjs';
import {HttpRequest} from '@angular/common/http';
import {JWTRefresh} from '../common/auth/JWT';
import {LoginService} from '../common/auth/login.service';

@Injectable({
    providedIn: 'root'
})
export class BearerInterceptor extends AbstractBearerInterceptor {
    getAuthExpirationTime(): Date {
        return this._lgn.getAuthExpirationTime();
    }

    getCurrentAuthToken(request: HttpRequest<any>): String {
        return this._lgn.getCurrentAuthToken();
    }

    clearToken(): void {
        this._lgn.logout();
    }

    refresh(): Observable<JWTRefresh> {
        return this._lgn.refreshAuthToken('false');
    }

    constructor(private _lgn: LoginService) {
        super();
    }

}
