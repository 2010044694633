import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { saveAs } from 'file-saver';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { ProgressBarService } from '../../../../../../common-ui/progressbar/progress-bar.service';
import { SnackbarService } from '../../../../../../common-ui/snackbar/snackbar.service';
import { fuseAnimations } from '../../../../../../fuse/animations';
import { AutoUnsubscribable } from '../../../../../../helpers/autounsub';
import { Breadcrumb } from '../../../../../../helpers/breadcrumb';
import { AssemblyConstants } from '../../../../../../pcb-common/assembly/assembly-constants';
import { AssemblyService } from '../../../assembly.service';
import { DocumentViewerService } from '../../document-viewer/document-viewer.service';
import { LayerStackService } from '../../pcb/layer-stack.service';
import { Pcb2Service } from '../../pcb/pcb2.service';
import { RenderLogMessageTypes, RenderResult } from '../../pcb/render';
import { RendererService } from '../../pcb/renderer.service';
import { BottomSheetSetOutlineComponent } from './bottom-sheet-set-outline/bottom-sheet-set-outline.component';
import { FileCategory } from './pcb-file-item-list/pcb-layer-order';
import { PcbFileUploadService } from './pcb-file-upload.service';

@Component({
    selector: 'pcb-file-manager',
    templateUrl: './pcb-file-manager.component.html',
    styleUrls: ['./pcb-file-manager.component.scss'],
    animations: fuseAnimations,
})
export class PcbFileManagerComponent extends AutoUnsubscribable implements OnInit, OnDestroy {
    public breads: Breadcrumb[];
    public dashboardRoute: string[];
    @Input()
    public showHeader = true;
    public showGerberFiles = true;
    public showODBFiles = true;
    public showUnusedFiles = true;
    public showMechanicalFiles = true;
    @Input()
    showNavButton: true;
    enableApproveButton = false;
    outlineFound: Observable<boolean>;
    primaryButtonIcon: Observable<string>;
    primaryButtonTooltip: Observable<string>;

    title: Observable<string>;

    previewsRenderedProgress = 0;
    @Output() previewsRenderedProgressEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() fileErrorCountChanged: EventEmitter<number> = new EventEmitter<number>();
    public fileStatusMap: Map<string, RenderResult> = new Map<string, RenderResult>(new Map());
    public fileErrorMap: BehaviorSubject<Map<string, RenderResult>> = new BehaviorSubject<Map<string, RenderResult>>(
        new Map<string, RenderResult>(new Map()),
    );
    public fileErrorNames: Observable<string[]>;
    public fileErrorCount = 0;
    public fileCategory = FileCategory;
    private assemblyGid: string;
    private ngUnsubscribe = new Subject();

    private LOCK_ICON = 'lock';
    private OUTLINE_ICON = 'border_outer';
    private APPROVE_ICON = 'check';

    constructor(
        private _bottomSheet: MatBottomSheet,
        private _fileUploadService: PcbFileUploadService,
        public _assService: AssemblyService,
        public pcbService: Pcb2Service,
        private _snackbarService: SnackbarService,
        private _layerstackService: LayerStackService,
        private _route: ActivatedRoute,
        private _router: Router,
        public documentService: DocumentViewerService,
        private logger: NGXLogger,
        public dialog: MatDialog,
        private _rendererService: RendererService,
        private _progressService: ProgressBarService,
        private _translocoService: TranslocoService,
    ) {
        super();
        this.outlineFound = this.pcbService.getOutline().pipe(map((o) => !!o));

        this.title = this._assService.getAssemblySubject().pipe(
            map((ass) => {
                return ass.name + ' - File Manager';
            }),
        );

        // Set the Icon for the main Button of the component according to locked state and outline
        this.primaryButtonIcon = this.pcbService.getPCBState().pipe(
            map((state) => {
                if (state.locked) {
                    return this.LOCK_ICON;
                } else {
                    this._progressService.hide();
                    return this.APPROVE_ICON;
                }
            }),
        );

        // Set the Tooltip for the main Button of the component according to locked state and outline
        this.primaryButtonTooltip = this.pcbService.getPCBState().pipe(
            map((state) => {
                if (state.locked) {
                    return this._translocoService.translate('Tooltip-UNLOCK-THIS-ASSEMBLY');
                } else {
                    return this._translocoService.translate('Tooltip-APPROVE-THIS-PCB');
                }
            }),
        );

        this.fileErrorNames = this.fileErrorMap.pipe(map((x) => [...x.keys()]));
    }

    setFileStatus(fileName: string, status: RenderResult): void {
        this.fileStatusMap.set(fileName, status);
        this.filterErrorStates(this.fileStatusMap);
    }

    ngOnDestroy(): any {
        super.ngOnDestroy();
        this.fileStatusMap.clear();
        // this.fileErrorMap.clear();
        this.fileErrorCount = 0;
    }

    ngOnInit(): void {
        this.assemblyGid = this._route.snapshot.params.assembly;
        this.dashboardRoute = ['/', AssemblyConstants.PROJECTS_URL, this.assemblyGid, AssemblyConstants.DASHBOARD_URL];

        // initialize on reload
        this._assService
            .getCurrentAssembly()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                first(),
                filter((a) => !!a),
            )
            .subscribe((ass) => {
                // get gerber/mechanical file names
                const fileList = ass?.currentVersion?.files
                    .filter(
                        (f) => f.fType.category === FileCategory.GERBER || f.fType.category === FileCategory.MECHANICAL,
                    )
                    .map((f) => f.name);

                const lf = ass?.currentVersion?.files.map((file) => file.lifecycles);

                fileList.forEach((fileName) => {
                    this._rendererService.getRenderingFileStatus(fileName).subscribe((status) => {
                        this.setFileStatus(fileName, status);
                    });
                });
            });

        this._rendererService
            .subscribeFileStatusUpdates()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((_fileStatusMap) => {
                this.fileStatusMap = _fileStatusMap;
                this.filterErrorStates(this.fileStatusMap);
            });

        // clear maps
        this.createBreadcrumbs();
    }

    public onSetOutline(): void {
        const ref = this._bottomSheet.open(BottomSheetSetOutlineComponent, {
            data: {
                pcbService: this.pcbService,
                assService: this._assService,
                assGid: this.assemblyGid,
            },
        });

        ref.afterDismissed().subscribe((bsResult) => {
            if (bsResult) {
            }
        });
    }

    public onPreviewsRenderedChanged(progress: number): void {
        this.previewsRenderedProgress = progress;
        if (progress >= 100) {
            this.enableApproveButton = true;
        }
    }

    public approveFiles(): void {
        this.pcbService
            .getPCBState()
            .pipe(first())
            .subscribe((state) => {
                if (state.locked) {
                    // User decided to unlock assembly
                    this._assService.unlockCurrentAssembly().subscribe((ok) => {
                        this._snackbarService.openSnackBar(
                            this._translocoService.translate('General-UNLOCKING-ASSEMBLY') + ' ...',
                            this._translocoService.translate('General-CLOSE-CAPS'),
                        );
                        this._progressService.show();
                    });
                } else {
                    this._assService.approveFiles().subscribe(
                        (x) => {
                            this._snackbarService.openSnackBar(
                                this._translocoService.translate('General-FILES-SUCCESSFULLY-APPROVED'),
                                this._translocoService.translate('General-CLOSE-CAPS'),
                            );
                            this._router.navigate(this.dashboardRoute);
                        },
                        (error) => {
                            // TODO: handle error
                        },
                    );
                }
            });
    }

    public acceptFiles(): void {
        this._assService.approveFiles().subscribe(
            (x) => {
                this._snackbarService.openSnackBar(
                    this._translocoService.translate('General-FILES-APPROVED-WITHOUT-OUTLINE'),
                    this._translocoService.translate('General-CLOSE-CAPS'),
                );
                this._router.navigate(this.dashboardRoute);
            },
            (error) => {
                // TODO: handle error
            },
        );
    }

    public downloadZip(): void {
        this._assService.downloadFilesAsZip().subscribe((b) => {
            this.logger.debug('downloaded', b);
            const contentDispositionHeader = b.headers.get('Content-Disposition');
            const name = contentDispositionHeader.split(';')[1].trim().split('=')[1].replace(/"/g, '');
            this.logger.debug('save file as', name);
            saveAs(b.body, name);
        });
    }

    isFileViewerProtected(): Observable<boolean> {
        return this._assService.getCurrentAssembly().pipe(
            map((ass) => {
                if (ass.currentVersion.released) {
                    return true;
                } else if (ass.currentVersion.filesLocked) {
                    return true;
                } else {
                    return false;
                }
            }),
        );
    }

    private filterErrorStates(fileMap: Map<string, RenderResult>): void {
        const files = Array.from(fileMap.keys());

        const failedFileNames = files.filter((f) => {
            if (fileMap && fileMap.has(f) && fileMap.get(f).status) {
                return fileMap.get(f).status.name === RenderLogMessageTypes.NAME_FAILED;
            }
            return false;
        });
        this.fileErrorCount = failedFileNames.length;
        this.fileErrorCountChanged.emit(this.fileErrorCount);

        const errorMap = this.fileErrorMap.getValue();
        failedFileNames.forEach((f) => {
            errorMap.set(f, fileMap.get(f));
        });
        this.fileErrorMap.next(errorMap);
    }

    private createBreadcrumbs(): void {
        this.breads = [];
        this.breads.push(
            new Breadcrumb(this._translocoService.translate('Navigation-PROJECT-OVERVIEW'), [
                AssemblyConstants.PROJECTS_URL,
            ]),
        );
        this.breads.push(new Breadcrumb(this._translocoService.translate('Navigation-DASHBOARD'), this.dashboardRoute));
        this.breads.push(new Breadcrumb(this._translocoService.translate('Navigation-FILE-MANAGER'), null));
    }
}
