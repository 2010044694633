import { Injectable } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { PermissionService } from 'app/services/permission.service';
import { BehaviorSubject } from 'rxjs';
import { FuseNavigationItem, FuseVerticalNavigationComponent } from '../../@fuse/components/navigation';
import { LoginService } from '../common/auth/login.service';
import { Permission } from '../common/auth/permission';
import { Navigation } from '../core/navigation/navigation.types';
import { NavigationDefinitions } from './navigation-definition';

@Injectable({
    providedIn: 'root',
})
export class NavigationProviderService {
    public navPermissionMap: Map<String, String> = new Map();
    // todo fuse
    public nav: BehaviorSubject<Navigation> = new BehaviorSubject<Navigation>(null);

    constructor(
        public _navService: FuseNavigationService,
        private login: LoginService,
        private perms: PermissionService,
        private _loginService: LoginService,
    ) {
        // todo fuse
        this.nav.next(this.initNavigation());
        // todo fuse

        perms.getPermissions().subscribe((ps) => this.updatePermissionVisibility(ps));
    }

    // todo fuse
    private initNavigation(): Navigation {
        // const navName = 'main';

        const navigationItems = this.getNavigationList();
        const nav: Navigation = {
            items: navigationItems,
        };
        navigationItems.find((item) => item.id === 'logout').function = () => {
            this._loginService.logout();
        };

        this.perms
            .isPermittedForResourceClass(NavigationDefinitions.DEFAULT_PERMISSIONS.get('admin-settings'))
            .subscribe((permission) => {
                if (!permission) {
                    nav.items = nav.items.filter((y) => y.id != 'admin-settings');
                }
            });
        return nav;
    }

    /**
     * Set the desired permissions for navItems or navItemGroups. Simply add a new entry to this map
     *  - First String:     The ID of the navItem or navItemGroup
     *  - Second String:    The desired resourceClassPermission
     */
    private setNavPermission(): void {
        this.navPermissionMap.set('pcb-mand', 'pcb');
        this.navPermissionMap.set('documentation', 'pcb');
    }

    private doUpdatePermissionVisibility(items: FuseNavigationItem[], ps: Permission[]): void {
        items.forEach((ni) => {
            const guard = NavigationDefinitions.DEFAULT_PERMISSIONS.get(ni.id);

            let hidden = false;

            if (guard && !PermissionService.checkGuard(Permission.fromString(guard), ps)) {
                hidden = true;
            }

            ni.hidden = () => hidden;

            if (ni.children) {
                this.doUpdatePermissionVisibility(ni.children, ps);
            }
        });
    }

    private updatePermissionVisibility(ps: Permission[]): void {
        const navComponent = this._navService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
        let value = this.nav.getValue();
        if (navComponent && value) {
            this.doUpdatePermissionVisibility(value.items, ps);
            navComponent.refresh();
        }
    }

    // todo fuse
    private getNavigationList(): FuseNavigationItem[] {
        return NavigationDefinitions.DEFAULT_MENU;
    }
}
