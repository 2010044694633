import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Permission} from '../common/auth/permission';
import {LoginService} from '../common/auth/login.service';
import {JWTPayload} from '../common/auth/JWT';
import {NavigationDefinitions} from "../navigation/navigation-definition";

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    private permissions: BehaviorSubject<Permission[]> = new BehaviorSubject([]);

    constructor(private _loginService: LoginService) {
        this._loginService.getTokenSubject().subscribe(login => {
            if (login) {
                const payload = this.getPayload(login.authToken);
                const claims = payload.claims;
                const perms = claims.map(c => {
                    const p = Permission.fromString(c.permission, payload.team, payload.userId);
                    return p;
                });

                this.permissions.next(perms);
            }
        });
    }

    private static guardAllows(g: string, p: string): boolean {
        return g.trim() === '*' || // guard requests any permission
            p.trim() === '*' || // permission allows all
            g.trim() === p.trim(); // permission allows this specific guard
    }

    public static checkSingleGuard(g: Permission, permission: Permission): boolean {
        const that = this;

        function helper(gp: String[], p: String[]): boolean {
            const r = gp.map(gs =>
                p.find(ps => that.guardAllows(gs.toString(), ps.toString())) !== undefined
            ).find(x => x) !== undefined;

            return r;
        }

        // check if all parts of the guard apply
        const res = helper(g.resourceClass, permission.resourceClass) &&
            helper(g.team, permission.team) &&
            helper(g.owner, permission.owner) &&
            helper(g.resource, permission.resource) &&
            helper(g.attribute, permission.attribute) &&
            helper(g.action, permission.action);


        return res;
    }

    public static checkGuard(g: Permission, permissions: Permission[]): boolean {
        return permissions.find(perm => this.checkSingleGuard(g, perm)) !== undefined;
    }

    public getPermissions(): Observable<Permission[]> {
        return this.permissions.asObservable();
    }

    private getPayload(authToken: String): JWTPayload {
        if (authToken != null) {
            return JSON.parse(atob(authToken.split('.')[1]));
        }
        return null;
    }

    public isAdminUser(): Observable<boolean> {
        return this.isPermittedForResourceClass(NavigationDefinitions.DEFAULT_PERMISSIONS.get('admin-settings'))
    }


    public isPermittedForResourceClass(resourceClass: String): Observable<boolean> {
        return this.permissions.pipe(
            map(p => {
                    const toCheck = Permission.fromString(resourceClass)

                    if (p) {
                        const filteredList = p.filter(perm => {
                            return perm.resourceClass.find(x => toCheck.resourceClass.indexOf(x) >= 0 || x === '*') !== undefined;
                        });
                        return filteredList.length > 0;
                    } else {
                        return false;
                    }
                }
            )
        );
    }
}
