import {FileSystemFileEntry} from 'ngx-file-drop';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {filter, first} from 'rxjs/operators';


export function resolveFiles(files: FileSystemFileEntry[]): Observable<File[]> {
    const resolvedFiles: File[] = [];
    const added: Subject<File> = new Subject<File>();
    const allFiles: BehaviorSubject<File[]> = new BehaviorSubject<File[]>(null);

    added.subscribe(a => {
        resolvedFiles.push(a);
        if (resolvedFiles.length === files.length) {
            added.complete();
            allFiles.next(resolvedFiles);
        }
    });

    files.forEach((f, index) => f.file(res => added.next(res)));

    return allFiles.pipe(filter(x => !!x), first());
}




