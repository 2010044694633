import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {base64ToFile, ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {fuseAnimations} from '../../../fuse/animations';
import {SnackbarService} from '../../../common-ui/snackbar/snackbar.service';

@Component({
    selector: 'app-file-upload-dialog',
    templateUrl: './file-upload-dialog.component.html',
    styleUrls: ['./file-upload-dialog.component.scss'],
    animations: fuseAnimations
})
export class FileUploadDialogComponent implements OnInit {
    fileToUpload: File = null;
    maxFileSize = 2 * 1024 * 1024;
    fileAllowed = false;

    inputImage: File = null;
    image: File = null;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    roundImage = true;  // default round
    format = 1 / 1;
    fileName: string;
    @ViewChild(ImageCropperComponent, {static: true}) imageCropper: ImageCropperComponent;

    constructor(
        public dialogRef: MatDialogRef<FileUploadDialogComponent>,
        private _snackbar: SnackbarService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {

        if (this.data.type === 'round') {
            this.roundImage = true;
        } else {
            this.roundImage = false;
        }

        if (this.data.format) {
            this.format = this.data.format;
        }
    }

    ngOnInit(): void {

    }


    closeDialog(value: boolean): void {
        if (value) {    // add
            this.dialogRef.close(this.fileToUpload);
        } else {    // cancel
            this.dialogRef.close(null);
        }
    }


    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        console.log('event', event);
        this.fileName = event.target.files[0].name;
    }

    imageCropped(event: ImageCroppedEvent): void {

        this.croppedImage = event.base64;

        const file = base64ToFile(event.base64);
        const size = file.size;

        if (size < this.maxFileSize) {
            this.fileAllowed = true;
            this.fileToUpload = new File([file], this.fileName);
        } else {
            this.fileAllowed = false;
            this.fileToUpload = null;
        }
    }

    imageLoaded(): void {

    }

    cropperReady(): void {

    }

    loadImageFailed(): void {
        this._snackbar.openSnackBar('filetype is not supported', 'CLOSE');
    }
}


