import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {StackrateSnackbarService} from './stackrate-snackbar.service';

@Component({
    selector: 'app-stackrate-snackbar',
    templateUrl: './stackrate-snackbar.component.html',
    styleUrls: ['./stackrate-snackbar.component.scss']
})
export class StackrateSnackbarComponent implements OnInit {

    message: string;
    buttonText: string;


    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        public stackrateSnackbar: StackrateSnackbarService) {
        this.message = data.message;
        this.buttonText = data.buttonText;
    }

    ngOnInit(): void {
    }
}
