import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AssemblyListService} from '../../main/project/assembly/assembly-list.service';
import {AvatarItem} from '../avatar-list/avatar-list';
import {UUID} from 'angular2-uuid';
import {Assembly} from '../../pcb-common/assembly/assembly';

@Component({
    selector: 'assembly-list',
    templateUrl: './assembly-list.component.html',
    styleUrls: ['./assembly-list.component.scss']
})
export class AssemblyListComponent implements OnInit {

    @Input() set staticAssembly(staticAssemblyId: UUID) {
        if (staticAssemblyId) {
            this._assService.getAssembly(staticAssemblyId).subscribe(assemblyWithReference => {
                const assembly = assemblyWithReference.assembly;
                this._staticAvatar = new AvatarItem(assembly.name, assembly.id, assembly.preview);
            });
        }
    }

    @Input() set customerID(customerID: string) {
        this.findAssembliesForCustomer(customerID);
    }

    @Output()
    selectionChanged: EventEmitter<Assembly> = new EventEmitter();

    constructor(private _assService: AssemblyListService) {
    }

    _customerID: string;
    _enabled = true;
    _assemblies: AvatarItem [] = [];
    _assemblyList: Assembly [] = [];
    _staticAvatar: AvatarItem;

    ngOnInit() {
    }

    onSelectAssembly(assembly: AvatarItem): void {
        if (assembly) {
            const a = this._assemblyList.find(ass => ass.id === assembly.id);
            if (a) {
                this.selectionChanged.emit(a);
            }
        }
    }

    findAssembliesForCustomer(id: string): void {
        if (id) {
            this._customerID = id;
            // TODO implement other endpoint to get assemblies by customerID
            this._assService.loadAssemblies().subscribe(result => {
                const cl = result.results.filter(a => a.information.customer === this._customerID);
                this._assemblyList = cl;
                cl.forEach(ass => {
                    this._assemblies.push(new AvatarItem(ass.name, ass.id, ass.preview));
                });
            });
        }
    }

}
