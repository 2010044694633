import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';


export interface FileTypeInfo {
    type: string;
    description: string;
    extension: string [];
}

const FILE_TYPE_INFO: FileTypeInfo[] = [
    {
        type: 'Gerber X2',
        description: 'Gerber X2 compatible Gerber files',
        extension: ['.GTX, .gbr, .pho, .art, and more']
    },
    {type: 'Drill files', description: 'Excellon compatible drill files ', extension: ['.txt, .drd, .drl, and more']},
    {type: 'EAGLE', description: 'Board file must be version 6.x or higher', extension: ['.brd']},
    {type: 'KiCad', description: 'Board file must be version 3.x or higher', extension: ['.kicad_pcb, .brd']},
    {type: 'Target3001!', description: 'Support is planned', extension: [' - ']},
    {type: 'Altium', description: 'Support is planned', extension: [' - ']},
];

const FILE_TYPE_INFO_T: FileTypeInfo[] = [
    {
        type: 'Gerber X2',
        description: 'General-GERBER-X2-COMPATIBLE-GERBER-FILES',
        extension: ['General-GTX-GBR-PHO-ART-AND-MORE']
    },
    { type: 'General-DRILL-FILES', description: 'General-EXCELLON-COMPATIBLE-DRILL-FILES', extension: ['General-TXT-DRD-DRL-AND-MORE'] },
    { type: 'EAGLE', description: 'General-BOARD-FILE-MUST-BE-VERSION-6X-OR-HIGHER', extension: ['.brd'] },
    { type: 'KiCad', description: 'General-BOARD-FILE-MUST-BE-VERSION-3X-OR-HIGHER', extension: ['.kicad_pcb, .brd'] },
    { type: 'Target3001!', description: 'General-SUPPORT-IS-PLANNED', extension: [' - '] },
    { type: 'Altium', description: 'General-SUPPORT-IS-PLANNED', extension: [' - '] },
];

@Component({
    selector: 'app-pcb-file-manager-supported-files-dialog',
    templateUrl: './pcb-file-manager-supported-files-dialog.component.html',
    styleUrls: ['./pcb-file-manager-supported-files-dialog.component.scss']
})
export class PcbFileManagerSupportedFilesDialogComponent implements OnInit {

    displayedColumns: string[] = ['type', 'description', 'extension'];
    dataSource = FILE_TYPE_INFO_T;

    constructor(public dialogRef: MatDialogRef<PcbFileManagerSupportedFilesDialogComponent>, private _translocoService: TranslocoService) {

    }

    ngOnInit(): void {

    }


}
