import {NgModule} from '@angular/core';
import {ClassyLayoutModule} from './vertical/classy/classy.module';
import {EmptyLayoutModule} from './vertical/empty/empty.module';
import {LayoutComponent} from './layout.component';
import {SharedModule} from '../core/shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
    declarations: [LayoutComponent],
    imports: [MatIconModule,
        MatTooltipModule,
        SharedModule, ClassyLayoutModule, EmptyLayoutModule],
    exports: [ClassyLayoutModule, EmptyLayoutModule]
})
export class LayoutModule {
}
