import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {RenderedLayer} from './pcb-ui.service';
import {filter, map} from 'rxjs/operators';
import {flatMap as _flatMap, map as _map} from 'lodash-es';
import {NGXLogger} from 'ngx-logger';
import {
    LayerStack,
    LayerStackChangedMessage,
    LayerStacks,
    LayerStackSetMessage,
    LayerStackStreamMessage
} from '../../../../../pcb-common/pcb/layer-stack';
import {
    Constants,
    FileTypes,
    LayerStackDefinition, LayerStackDescription,
    MaterialType
} from '../../../../../layerstack-common/layerstack/layer-stack-definition';
import {Assembly, AssemblyWithReference} from '../../../../../pcb-common/assembly/assembly';
import {LoginService} from '../../../../../common/auth/login.service';
import {EnvironmentService} from '../../../../../common/env/environment.service';
import {Pcb2Service} from "./pcb2.service";

@Injectable({
    providedIn: 'root'
})
export class LayerStackService implements OnDestroy {

    private _layerStacks: BehaviorSubject<LayerStacks> = new BehaviorSubject(null);

    private _ws: WebSocket;

    constructor(private _http: HttpClient,
                private _loginService: LoginService,
                private env: EnvironmentService,
                private logger: NGXLogger) {

    }

    public static toRenderedStack(stack: LayerStack): RenderedLayer[] {
        let thicknessOffset = 0
        const ls = stack.stacks[0].layers;
        const layers = _flatMap(ls, l => {
            if (l.files && l.files.length > 0) {


                const rl = l.files.map((f, idx) => {
                    return new RenderedLayer(l, f, idx)
                })

                switch (l.definition.layerType) {
                    case MaterialType.core:
                    case MaterialType.flexcore:
                        rl.splice(1, 0, new RenderedLayer(l, null));
                }
                return rl;
            } else {
                return [new RenderedLayer(l, null)];
            }
        });


        stack.unmatchedFiles.filter(umf => umf.fileType.fileType === FileTypes.SILKSCREEN_TOP)
            .forEach(sst => layers.splice(0, 0, new RenderedLayer(null, sst)));
        stack.unmatchedFiles.filter(umf => umf.fileType.fileType === FileTypes.SILKSCREEN_BOTTOM)
            .forEach(ssb => layers.push(new RenderedLayer(null, ssb)));

        stack.unmatchedFiles.filter(umf => umf.fileType.fileType === FileTypes.PASTE_TOP)
            .forEach(sst => layers.splice(0, 0, new RenderedLayer(null, sst)));
        stack.unmatchedFiles.filter(umf => umf.fileType.fileType === FileTypes.PASTE_BOTTOM)
            .forEach(ssb => layers.push(new RenderedLayer(null, ssb)));

        return layers;
    }

    ngOnDestroy(): void {

        if (this._ws) {
            this._ws.close();
        }
    }

    public subscribeLayerStack(): Observable<LayerStack> {
        return this._layerStacks.pipe(
            filter(x => x != null),
            map(x => x.selected),
            filter(x => x != null),
        );
    }

    public reset(): void {
        this._layerStacks.next(null);
    }


    /**
     * This method returns a layerStack if existent or null if no layerStack is set
     */
    public optionalLayerStack(): Observable<LayerStack | null> {
        return this._layerStacks.pipe(
            map(x => {
                if (x) {
                    return x.selected;
                } else {
                    return null;
                }
            }),
        );
    }

    public subscribeLayerStackSocket(assemblyId: string, version: string): void {

        this.closeCurrentWebsocket();

        const token = this._loginService.getCurrentAuthToken();
        // this.logger.debug('subscribe to WS with token ', token);
        if (token) {
            this._ws = new WebSocket(this.env.ws(Constants.layerstackEndpoint, ['layerstacks', 'assemblies', assemblyId, 'versions', version, 'updates'], {k: token}));

            this._ws.onclose = ev => {
                if (!ev.wasClean) {
                    this.subscribeLayerStackSocket(assemblyId, version)
                }
            }
            this._ws.onmessage = ev => {
                const data: LayerStackStreamMessage = JSON.parse(ev.data);

                if (data._t.endsWith('LayerStackChangedMessage')) {
                    const _data = data as LayerStackChangedMessage;
                    if (_data.stack) {
                        const stacks = this._layerStacks.getValue();
                        stacks.selected = _data.stack;
                        this._layerStacks.next(stacks);
                    }
                }
                if (data._t.endsWith('LayerStackMessage')) {
                    const _data = data as LayerStackSetMessage;
                    if (_data.stacks) {
                        this._layerStacks.next(_data.stacks);
                    }
                }
            };
        }
    }


    private closeCurrentWebsocket() {
        if (this._ws !== undefined && this._ws.readyState === WebSocket.OPEN) {
            this._ws.onclose = evt => {
            }
            this._ws.close();
        }
    }

    public loadLayerStack(assemblyWithReference: AssemblyWithReference): void {
        const a = assemblyWithReference.assembly;
        if (assemblyWithReference.isShared) {
            // TODO: https://www.notion.so/luminovo/Restrict-Shared-Assembly-Access-8f9deb67f68c4207bcfa06b1fd61194f
            this._loadLayerStack(a.id, a.currentVersion.id);
        } else {
            this._loadLayerStack(a.id, a.currentVersion.id);
        }
    }

    public getChosenPCBLayerStack(a: Assembly): Observable<LayerStack> {
        return this.getPCBLayerStacks(a.id, a.currentVersion.id).pipe(map(ls => {
            return ls.selected;
        }));
    }

    public getPCBLayerStacks(assemblyId: string, version: string): Observable<LayerStacks> {
        return this._http.get<LayerStacks>(this.env.api(Constants.layerstackEndpoint, ['layerstacks', 'assemblies', assemblyId, 'versions', version], {
            'materials': true
        }));
    }

    public selectPCBLayerStack(assemblyId: string, version: string, id: string): Observable<LayerStacks> {
        return this._http.put<LayerStacks>(this.env.api(Constants.layerstackEndpoint, ['layerstacks', 'assemblies', assemblyId, 'versions', version]), {id: id});
    }

    public getPCBLayerStackOptions(assemblyId: string, version: string): Observable<LayerStackDescription[]> {
        return this._http.get<LayerStackDescription[]>(this.env.api(Constants.layerstackEndpoint, ['layerstacks', 'assemblies', assemblyId, 'versions', version, 'match']));
    }

    private _loadLayerStack(assemblyId: string, version: string): void {

        this.subscribeLayerStackSocket(assemblyId, version);

        this.getPCBLayerStacks(assemblyId, version).subscribe(ls => {
            if (ls) {
                this._layerStacks.next(ls);
            }
        });
    }

    public getPreview(stack: LayerStack): string {
        return this.env.api(Pcb2Service.RENDER_ENDPOINT, ["layerstacks", stack.assembly.id.toString(), "versions", stack.assembly.version.toString()], {k: this._loginService.getCurrentAuthToken()})
    }
}
