/*
    removed:
    'ExcessResin','Outer',MaterialAttr.ResinContent,  'UseInAutoStack', 'LaserDrillable', 'Tg', 'Td', 'CAFResistance', 'LeadTime',
    'ZAxisExpansion','FinishedThickness', MaterialAttr.Type
 */


import {MaterialAttr, MaterialLibraryTypes} from './material-constants';


const adhesive_bondply_names: string   [] = [MaterialAttr.ArticleID, MaterialAttr.AreaPrice, MaterialAttr.UnitPrice, MaterialAttr.BaseThickness, MaterialAttr.Tolerance, MaterialAttr.DielectricConstant, MaterialAttr.LossTangent,
    MaterialAttr.Supplier, MaterialAttr.SupplierDescription, MaterialAttr.Description,
];

/*
    removed :  'Size',  'Outer', 'LeadTime', 'UseInAutoStack', 'LaserDrillable', 'FinishedThickness', MaterialAttr.Type, 'CAFResistance',
 */
const prepreg_names: string [] = [MaterialAttr.ArticleID, MaterialAttr.AreaPrice, MaterialAttr.UnitPrice, MaterialAttr.BaseThickness, MaterialAttr.Tolerance, MaterialAttr.DielectricConstant, MaterialAttr.LossTangent, MaterialAttr.ExcessResin,
    MaterialAttr.ResinContent, MaterialAttr.Tg, MaterialAttr.Td, MaterialAttr.ZAxisExpansion, MaterialAttr.Supplier, MaterialAttr.SupplierDescription, MaterialAttr.Description,

];
/*
    removed: 'Size', 'ExcessResin',  'UseInAutoStack', 'LaserDrillable', 'Tg', 'Td', 'PlanesBothSides', 'LeadTime',
    'CAFResistance', 'ZAxisExpansion', 'FinishedThickness',
*/
const core_flexcore_names: string   [] = [MaterialAttr.ArticleID, MaterialAttr.AreaPrice, MaterialAttr.UnitPrice, MaterialAttr.UpperCuThickness, MaterialAttr.BaseThickness, MaterialAttr.LowerCuThickness, MaterialAttr.Tolerance,
    MaterialAttr.LossTangent, MaterialAttr.Tg, MaterialAttr.Td, MaterialAttr.ResinContent, MaterialAttr.SupplierDescription, MaterialAttr.Description, MaterialAttr.Supplier, MaterialAttr.DielectricConstant, MaterialAttr.Type
];

/*
    removed :  'FinishedThickness', 'LeadTime', MaterialAttr.Type,
 */
const coverlay_names: string [] = [MaterialAttr.ArticleID, MaterialAttr.AreaPrice, MaterialAttr.UnitPrice, MaterialAttr.BaseThickness, MaterialAttr.Tolerance, MaterialAttr.DielectricConstant, MaterialAttr.LossTangent, MaterialAttr.Supplier,
    MaterialAttr.SupplierDescription, MaterialAttr.Description,
];

/*
    removed : 'FinishedThickness',  'LeadTime', MaterialAttr.Type, MaterialAttr.Tolerance, MaterialAttr.DielectricConstant, MaterialAttr.LossTangent,
 */
const foil_names: string [] = [MaterialAttr.ArticleID, MaterialAttr.AreaPrice, MaterialAttr.UnitPrice, MaterialAttr.CuThickness, MaterialAttr.Supplier,
    MaterialAttr.SupplierDescription, MaterialAttr.Description
];

/*
    removed : 'FinishedThickness',  'LeadTime', MaterialAttr.Type, MaterialAttr.Tolerance, MaterialAttr.DielectricConstant, MaterialAttr.LossTangent,
 */
const ident_names: string [] = [MaterialAttr.ArticleID, MaterialAttr.AreaPrice, MaterialAttr.UnitPrice, MaterialAttr.InkThickness, MaterialAttr.Supplier,
    MaterialAttr.SupplierDescription, MaterialAttr.Description
];

/*
    removed :  'Size',  MaterialAttr.Type, 'LeadTime','Colour'
 */
const peelable_names: string [] = [MaterialAttr.ArticleID, MaterialAttr.AreaPrice, MaterialAttr.UnitPrice, MaterialAttr.InkThickness, MaterialAttr.Supplier, MaterialAttr.SupplierDescription, MaterialAttr.Description,];



/*
    removed : 'LeadTime',  MaterialAttr.Type,
 */
const soldermask_names: string [] = [MaterialAttr.ArticleID, MaterialAttr.AreaPrice, MaterialAttr.UnitPrice, MaterialAttr.MaskThickness, MaterialAttr.Tolerance, MaterialAttr.DielectricConstant,
    MaterialAttr.SupplierDescription, MaterialAttr.LossTangent, MaterialAttr.Supplier, MaterialAttr.Description,
];

export const MaterialColumnSettingsDefaultMaps = new Map<string, string[]>([
    [MaterialLibraryTypes.ADHESIVE, adhesive_bondply_names],
    [MaterialLibraryTypes.BONDPLY, adhesive_bondply_names],
    [MaterialLibraryTypes.CORE, core_flexcore_names],
    [MaterialLibraryTypes.COVERLAY, coverlay_names],
    [MaterialLibraryTypes.FLEX_CORE, core_flexcore_names],
    [MaterialLibraryTypes.FOIL, foil_names],
    [MaterialLibraryTypes.IDENT, ident_names],
    [MaterialLibraryTypes.PEELABLE, peelable_names],
    [MaterialLibraryTypes.PREPREG, prepreg_names],
    [MaterialLibraryTypes.SOLDERMASK, soldermask_names],
]);


export function typeToName(type: string): string {
    switch (type) {
        case MaterialLibraryTypes.FLEX_CORE :
            return 'Flex-Cores';
        case MaterialLibraryTypes.PEELABLE :
            return 'Peelable';
        case MaterialLibraryTypes.IDENT :
            return 'Ident';
        case MaterialLibraryTypes.ADHESIVE :
            return 'Adhesive';
        case MaterialLibraryTypes.SOLDERMASK :
            return 'Soldermask';
        case MaterialLibraryTypes.BONDPLY :
            return 'Bondply';
        case MaterialLibraryTypes.CORE :
            return 'Core';
        case MaterialLibraryTypes.PREPREG :
            return 'Pre-Preg';
        case MaterialLibraryTypes.COVERLAY :
            return 'Cover-lay';
        case MaterialLibraryTypes.FOIL :
            return 'Foils';
        default:
            return 'Misc';
    }
}
