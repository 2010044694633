import {Assembly, Tag, UserRole} from './assembly';

export class Project {
    name: string;
    desc: string;
    dummyProject: boolean;
    lastUpdate: Date;
    status: ProjectStatus;
    owner: string;
    tags: Tag[];
    members: UserRole[];
    assemblies: Assembly[];
}

export enum ProjectStatus {
    active = 'active',
    archived = 'archived',
    deleted = 'deleted'
}


export class AssemblyID {
    assembly: string;
    version: string;
}
