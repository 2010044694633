import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'expand-icon',
    templateUrl: './expand-icon.component.html',
    styleUrls: ['./expand-icon.component.scss']
})
export class ExpandIconComponent {
    @Input() state: boolean;
    @Output() stateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {


    }

    changeState(): void {
        this.state = !this.state;
        this.stateChange.emit(this.state);
    }

}
