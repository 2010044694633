<div class="avatar-list" fxLayout="row" fxLayoutAlign="start start">

    <form class="example-form">

        <mat-form-field [appearance]="appearance" class="example-full-width" fxLayout="column"
                        style=" margin-bottom: -1.25em">
            <div class="p-0" fxLayout="row" fxLayoutAlign="start center">
                <div *ngIf="selectedAvatar && _type !== 'assembly'">
                    <img *ngIf="selectedAvatar.image" [src]="getImagePath(selectedAvatar.id, selectedAvatar.image)"
                         class="option-image">
                    <mat-icon *ngIf="!selectedAvatar.image" class="option-icon">{{_avatarIcon}}</mat-icon>
                </div>
                <div *ngIf="!_enabled">
                    <mat-icon class="mr-4">lock</mat-icon>
                </div>
                <input [formControl]="stateCtrl" [matAutocomplete]="auto" aria-label="State" class="avatar-input p-0"
                       matInput
                       placeholder="{{placeholder}}">
            </div>

            <mat-autocomplete #auto="matAutocomplete"
                              (optionSelected)="selectAvatar($event.option.value)">
                <mat-option *ngFor="let avatar of filteredAvatars " [value]="avatar.name">
                    <img *ngIf="avatar.image && _type !== 'assembly'" [src]="getImagePath(avatar.id, avatar.image)"
                         class="option-image">
                    <mat-icon *ngIf="!avatar.image" class="option-icon">{{_avatarIcon}}</mat-icon>
                    <span>{{avatar.name}}</span>
                </mat-option>
            </mat-autocomplete>

        </mat-form-field>

    </form>
</div>
