export function getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
        case agent.indexOf('edge') > -1:
            return 'edge';
        case agent.indexOf('opr') > -1 && !!(<any>window).opr:
            return 'opera';
        case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
            return 'chrome';
        case agent.indexOf('trident') > -1:
            return 'ie';
        case agent.indexOf('firefox') > -1:
            return 'firefox';
        case agent.indexOf('safari') > -1:
            return 'safari';
        default:
            return 'other';
    }
}

export function getBrowserSvgUseSupport(): boolean {
    const browserName = getBrowserName();
    return (browserName !== 'chrome')
    // return true;
}

export function getBrowserZoomFactor(): number {
    const browserName = getBrowserName();
    let v = 1;
    if (browserName === 'firefox') {
        v = 0.05;
    } else if (browserName === 'chrome') {
        v = 0.05;
    } else if (browserName === 'edge' || browserName === 'ie') {
        v = 0.05;
    }
    return v;
}


