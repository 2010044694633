import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {AutoUnsubscribable} from '../../../helpers/autounsub';

@Component({
    selector: 'empty-layout',
    templateUrl: './empty.component.html',
    encapsulation: ViewEncapsulation.None
})
export class EmptyLayoutComponent extends AutoUnsubscribable {

    /**
     * Constructor
     */
    constructor() {
        super();
    }

}
