<div mat-dialog-title>
    <div fxLayout="row">
        <div fxFlex="100" fxLayoutAlign="flex-start">{{textHeader}}</div>
    </div>
</div>
<mat-dialog-content class="content p-16">

    <div class="p-16" fxLayout="column" fxLayoutAlign="space-around stretch">


        <div class="pb-16" *ngIf="description">{{description}}</div>
        <mat-form-field appearance="outline" fxFlex="100">
            <!--            <mat-label></mat-label>-->
            <textarea [(ngModel)]="text" [rows]="10" cdkFocusInitial matInput></textarea>
        </mat-form-field>

        <div class="mt-8" fxFlex="100" fxLayout="row">
            <div class="button-row" fxFlex="100" fxLayoutAlign="flex-end">


                <button (click)="this.dialogRef.close( )" class="mt-8 mr-8" color="warn" mat-button>
                    CLOSE
                </button>

                <button *ngIf="showSaveButton" (click)="this.dialogRef.close(text)"
                        [disabled]="text?.length === 0"
                        class="mt-8 mr-8"
                        color="primary"
                        mat-button>
                    SAVE
                </button>

            </div>
        </div>
    </div>


    <!--    </form>-->
</mat-dialog-content>
