export class PcbLayerOrder { }


export const layerOrder = new Map<string, number>([
    ['Mechanical', -4],
    ['KeepOut', -3],
    ['Outline', -2],
    ['Drill', -1],
    ['PasteTop', 0],
    ['SilkscreenTop', 1],
    ['SoldermaskTop', 2],
    ['CopperTop', 3],
    ['CopperMid', 4],
    ['PlaneMid', 5],
    ['CopperBottom', 6],
    ['SilkscreenBottom', 7],
    ['SoldermaskBottom', 8],
    ['PasteBottom', 9]
]);

export enum FileCategory {
    GERBER = 'gerber',
    ODB = 'odb',
    MECHANICAL = 'mechanical',
    MISC = 'unknown'
}

export enum FileMimeType {
    UNKNOWN = 'unknown',
    GERBER = 'text/gerber',
    DRILL = 'text/drill',
    ODB = 'text/odb',
    PDF = 'application/pdf',
    ZIP = 'application/zip',
    ALTIUM = 'application/altium',
    EAGLE = 'application/eagle',
    KICAD = 'application/kicad',
    MISC = 'unknown',


}



export const ICON_FILE_TYPE_KICAD = 'assets/images/extensions/kicad.png';
export const ICON_FILE_TYPE_EAGLE = 'assets/images/extensions/eagle.png';
export const ICON_FILE_TYPE_ALTIUM = 'assets/images/extensions/altium.png';

