import {Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InlineEditComponent),
    multi: true
};
const noop = () => {
};

@Component({
    selector: 'inline-edit',
    templateUrl: './inline-edit.component.html',
    styleUrls: ['./inline-edit.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InlineEditComponent implements ControlValueAccessor {
    @Output() onModelChanged = new EventEmitter<string>();


    @Input()
    renamingActive = true;

    isHovered = false;
    isEditable = false;

    private innerValue: any = '';
    private prevInnerValue: string = '';
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    constructor(private eRef: ElementRef) {
    }

    //get accessor
    get value(): any {
        return this.innerValue;
    }

    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    onBlur(): void {
        this.onTouchedCallback();
    }

    // From ControlValueAccessor interface
    writeValue(value: string): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    onButtonClick(): void {
        if (this.isEditable) {
            //this.onModelChanged.emit();
        }

        this.isEditable = !this.isEditable;
    }

    onEnterPress(): void {
        this.isEditable = false;
        this.onModelChanged.emit(this.innerValue);
    }

    mouseEnter(): void {
        this.isHovered = true;
    }

    mouseLeave(): void {
        this.isHovered = false;
    }

    updateIfFileEditChanged() {
    }

    @HostListener('document:click', ['$event'])
    clickout(event): void {
        if (this.eRef.nativeElement.contains(event.target)) {
            this.isEditable = true;
            this.prevInnerValue = this.innerValue;
        } else {
            if (this.prevInnerValue !== this.innerValue && this.isEditable) {
                this.onModelChanged.emit(this.innerValue);
            }
            this.isEditable = false;
        }
    }
}
