import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomerService} from '../../main/customer/customer.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Customer} from '../../common/customer';
import {filter, first, takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'customer-selector',
    templateUrl: './customer-selector.component.html',
    styleUrls: ['./customer-selector.component.scss']
})
export class CustomerSelectorComponent implements OnInit {

    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();

    @Input()
    public multiple: boolean = false;

    @Input()
    public initialValue: Observable<String>;

    @Output()
    public selection: EventEmitter<string> = new EventEmitter<string>();
    public comboForm: FormGroup;

    selectedCustomerCtrl = new FormControl();
    entryFilterCtrl = new FormControl();
    allCustomers = new BehaviorSubject<Customer[]>([]);
    filteredEntries: Customer[];
    selectedCustomer: Customer;

    constructor(private _customerService: CustomerService,
                private _activatedRoute: ActivatedRoute) {
        this._customerService.getCustomersSubject().subscribe(customerList => {
            this.allCustomers.next(customerList);
            this.filteredEntries = customerList;


        });

        this.comboForm = new FormGroup({
            comboValue: new FormControl({validators: Validators.required}),
        });

    }

    ngOnInit(): void {
        this.entryFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                let result = [];
                const searchString = this.entryFilterCtrl.value.toLowerCase();
                if (searchString.length === 0) {
                    result = this.allCustomers.value;
                } else {
                    for (const item of this.allCustomers.value) {
                        if (result.length > 25) {
                            break;
                        }
                        if (item?.name?.toLowerCase().indexOf(searchString) > -1) {
                            result.push(item);
                        }
                    }
                }
                this.filteredEntries = result;
            });

        this.selectedCustomerCtrl.valueChanges.subscribe(c => {
            if (c) {
                if (!c.name) {
                    this.selectedCustomer = null;
                } else {
                    this.selectedCustomer = c;
                    this.selection.emit(c.id);
                }
            } else {
                this.selection.emit(null);
            }
        });

        this.allCustomers.pipe(filter(x => x.length > 0), first()).subscribe(ac => {
            console.log('initial value ', this.initialValue);
            this.initialValue?.pipe(first()).subscribe(i => {
                if (i) {
                    const c = ac.find(c => c.id === i);
                    this.selectedCustomer = c;
                    this.selectedCustomerCtrl.setValue(c);
                }
            });
        });
    }

    resetFilter() {
        this.selectedCustomer = null;
        this.selectedCustomerCtrl.setValue(null);
    }
}
