<ng-container *transloco="let t">
    <div mat-dialog-title>
        <div fxLayout="column">
            <div fxFlex="100" fxLayoutAlign="flex-start">{{t("General-FILE-NAME")}} : {{file.name}}</div>
            <div class="text-base text-secondary" fxFlex="100" fxLayoutAlign="flex-start">
                {{t("General-CREATED")}} : {{ dateService.fullDateFormat(file.created.toString()) }}</div>
            <div class="text-base text-secondary" fxFlex="100" fxLayoutAlign="flex-start">
                {{t("Project_assemblies-MIMETYPE")}} : {{ file?.fType?.mimeType }}</div>
        </div>
    </div>


    <div class="p-8 ml-0" mat-dialog-content>
        <div class="m-8" fxLayout="column" fxLayoutAlign="start stretch">

            <div class="px-8 text-xl">
                {{t("Project_assemblies-LIFE-CYCLES")}} :
            </div>
            <div class="px-8">
                <mat-list>
                    <ng-container *ngFor="let cycle of lifecycles">
                        <mat-list-item><b>{{cycle.name}}</b> : {{cycle.status.name}}</mat-list-item>
                        <mat-list-item *ngFor="let hist of cycle.history">
                            <mat-icon mat-list-icon>update</mat-icon>
                            <div mat-line>{{t("General-STATE-AS-STATUS")}} : <b>{{hist.name}}</b></div>
                            <div mat-line>{{t("General-TIME")}} : <b>{{getTimeInMs(hist)?.toFixed(2) }}
                                    {{t("General-SEC-AS-SECONDS")}} </b></div>
                        </mat-list-item>
                    </ng-container>
                </mat-list>
            </div>

        </div>
        <!--        <div class="p-8" fxFlex="40" fxLayout="column" fxLayoutAlign="space-between stretch">-->
        <!--            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between stretch">-->

        <!--                <mat-form-field appearance="outline" fxFlex="100" tabindex="1">-->
        <!--                    <mat-label>Layer style</mat-label>-->
        <!--                    <mat-select (selectionChange)="file.inverted = $event.value" [placeholder]="'index'"-->
        <!--                                [value]="file.inverted" class="index-select">-->
        <!--                        <mat-option [value]="true">-->
        <!--                            <div fxLayout="row" fxLayoutAlign="start center">-->
        <!--                                &lt;!&ndash;                                <mat-icon color="accent" svgIcon="gerber_inverted_file"></mat-icon>&ndash;&gt;-->
        <!--                                inverted-->
        <!--                            </div>-->
        <!--                        </mat-option>-->
        <!--                        <mat-option [value]="false">-->
        <!--                            <div fxLayout="row" fxLayoutAlign="start center">-->
        <!--                                &lt;!&ndash;                                <mat-icon color="accent" svgIcon="gerber_file"></mat-icon>&ndash;&gt;-->
        <!--                                not-inverted-->
        <!--                            </div>-->
        <!--                        </mat-option>-->
        <!--                    </mat-select>-->
        <!--                </mat-form-field>-->
        <!--            </div>-->
        <!--        </div>-->

        <!--        <div class="p-8 text-base text-secondary" fxFlex="100">Preview image</div>-->
        <!--        <div class="p-8" fxFlex="60" fxLayout="row" fxLayoutAlign="start stretch">-->
        <!--            <img *ngIf="!file.preview" class="preview-image"-->
        <!--                 src="assets/images/backgrounds/placeholder.png"/>-->

        <!--            <img *ngIf="file.preview " alt="preview image loading ..." class="preview-image"-->
        <!--                 src="{{ previewPath }}/{{ file.preview }}"/>-->
        <!--        </div>-->


    </div>

    <div mat-dialog-actions>
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="end end">
            <button (click)="onCancel( )" cdkFocusInitial color="warn" mat-button>
                {{t("General-CLOSE-CAPS")}}</button>
        </div>
    </div>
</ng-container>