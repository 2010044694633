export const SvgZoomFactor = 500;
export const SvgZoomMinValue = 2;
export const SvgZoomMaxValue = 1000;


export const SvgSelectionColor = '#fff';
export const SvgIgnoredColor = '#ffc300';
export const SvgHoverColor = '#FF5733';

export const SvgDrillClassNPTH = 'NON_PLATED';
export const SvgDrillClassPTH = 'PLATED';
export const SvgViolationClassName = 'violation';
export const SvgTraceClassName = 'trace';
export const SvgDrillClassName = 'drill';


// creates a beautified string with unit from numer
export function beautifyNumber(n: number, scaling?: number): string {
    let s = '0';
    if (n < 1) {
        n = n * 1000;
        s = n.toFixed(2) + ' μm';
    } else {
        s = n.toFixed(2) + ' mm';
    }
    return s;
}
