import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OldMetaInfo} from './pcb2-viewer/pcb-settings/pcb-settings';
import {Format} from '../../../../../pcb-common/pcb/pcb';
import {LoginService} from '../../../../../common/auth/login.service';

@Injectable({
    providedIn: 'root'
})
export class PcbMetaService {
    public metaSubject: BehaviorSubject<OldMetaInfo> = new BehaviorSubject<OldMetaInfo>(null);

    constructor(private _httpClient: HttpClient, private _loginService: LoginService) {
    }

    public getMetaSubject(): Observable<OldMetaInfo> {
        return this.metaSubject.asObservable();
    }

    setTotalHeight(height: number): void {
        const m = this.metaSubject.getValue();
        m.stackHeight = height;
        this.metaSubject.next(m);
    }

    setColor(color: string): void {
        const m = this.metaSubject.getValue();
        m.color = color;
        this.metaSubject.next(m);
    }

    setFinish(finish: string): void {
        const m = this.metaSubject.getValue();
        m.finish = finish;
        this.metaSubject.next(m);
    }

    setInnerCopperThickness(copper: number): void {
        const m = this.metaSubject.getValue();
        m.innerCopperThickness = copper;
        this.metaSubject.next(m);
    }

    setOuterCopperThickness(copper: number): void {
        const m = this.metaSubject.getValue();
        m.outerCopperThickness = copper;
        this.metaSubject.next(m);
    }

    setDimension(format: Format): void {
        const m = this.metaSubject.getValue();
        m.format = format;
        this.metaSubject.next(m);
    }


}
