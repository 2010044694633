<ng-container *transloco="let t">
    <div class="card-wrapper" fxFlex="100">
        <div class="pr-16 pb-16" fxFlex="100" fxLayout="row" fxLayoutAlign="start stretch">
            <!-- PREVIEW -->
            <div class="card-preview">

                <div (click)=onClick() *ngIf="!noFile" [ngClass]="selected ? 'selected': 'not-selected'"
                     class="fuse-card">
                    <div #canvas id="canvas"></div>
                    <div class="p-16" *ngIf="file">
                        <div class="h1">{{t("Project_assemblies-FILE-BASED-OUTLINE")}}</div>
                        <div class="text-base text-secondary file-name-wrapper">{{file.name}}</div>
                    </div>
                    <div class="p-16" *ngIf="!file">
                        <div class="h1">{{t("Project_assemblies-AUTOMATIC-OUTLINE")}}</div>
                        <div class="text-base text-secondary file-name-wrapper"></div>
                    </div>


                </div>

            </div>
            <div (click)=onClick() *ngIf="noFile" [ngClass]="selected ? 'selected': 'not-selected'" class="fuse-card">
                <div class="feature-image" fxLayout="column" fxLayoutAlign="center center">
                    <div align="center" class="card-text">
                        {{t("Project_assemblies-SELECT-FOR-OUTLINE-FROM-GERBERVIEWER")}}</div>
                </div>

                <div class="p-16">
                    <div class="h1">{{t("Project_assemblies-OUTLINE-CREATOR")}}</div>
                    <div class="text-base text-secondary">{{t("Project_assemblies-OUTLINE-CREATOR-HINT")}}</div>
                </div>
            </div>
            <!-- / PREVIEW -->
        </div>
    </div>
</ng-container>