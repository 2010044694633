import { PcbFileUploadService } from '../pcb-file-upload.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { first, map, takeUntil } from 'rxjs/operators';
import { DateService } from 'app/services/date.service';
import {
    FileCategory,
    FileMimeType,
    ICON_FILE_TYPE_ALTIUM,
    ICON_FILE_TYPE_EAGLE,
    ICON_FILE_TYPE_KICAD,
    layerOrder
} from './pcb-layer-order';
import { AssemblyService } from '../../../../assembly.service';
import { DefaultColors } from '../../../pcb/pcb2-color-scheme';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Pcb2Service } from '../../../pcb/pcb2.service';

import { find } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { animate, style, transition, trigger } from '@angular/animations';
import { RenderLogMessageTypes, RenderResult } from '../../../pcb/render';
import { DocumentViewerService } from '../../../document-viewer/document-viewer.service';
import { FileInspectionDialogComponent } from '../file-inspection-dialog/file-inspection-dialog.component';
import {
    AssemblyFile,
    FileType,
    FileTypes,
    LifeCycleStageName,
    LifecycleStageStatus,
    StageStatusName
} from '../../../../../../../pcb-common/assembly/assembly';
import { fuseAnimations } from '../../../../../../../fuse/animations';
import { SnackbarService } from '../../../../../../../common-ui/snackbar/snackbar.service';
import { LoginService } from '../../../../../../../common/auth/login.service';
import { EnvironmentService } from '../../../../../../../common/env/environment.service';
import { getBrowserName } from '../../../../../../../common/graphic-utils';
import { SegmentService } from '../../../../../../../services/segment.service';
import { TranslocoService } from "@ngneat/transloco";

var count = 0;

@Component({
    selector: 'pcb-file-item-list',
    templateUrl: './pcb-file-item-list.component.html',
    styleUrls: ['./pcb-file-item-list.component.scss'],
    // animations: fuseAnimations
    animations: [
        trigger('tooltip', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(300, style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate(300, style({ opacity: 0 })),

            ]),
        ]),
        fuseAnimations],
})
export class PcbFileItemListComponent implements OnInit, OnDestroy {
    @Input()
    category: FileCategory;
    @Input()
    iconClass: string;
    previewPath: string;
    @Input() statusMap: Map<string, RenderResult> = new Map<string, RenderResult>(new Map());

    @Input() showFileList = true;

    fileList: AssemblyFile[];
    basicUnknown = new FileType();
    displayedColumns = ['icon', 'fileName', 'modified', 'function', 'preview', 'delete_button'];
    compareFileType: ((f1: any, f2: any) => boolean) | null = PcbFileItemListComponent.compareFileTypeByContent;
    types: FileType[] = [];

    gerberTypes: FileType[] = FileTypes.GERBER_TYPES;
    drillTypes: FileType[] = FileTypes.DRILL_TYPES;
    mechanicalTypes: FileType[] = FileTypes.MECHANICAL_TYPES;
    nativeCADTypes: FileType[] = FileTypes.NATIVE_TYPES;
    otherTypes: FileType[] = FileTypes.MISC_TYPES;

    constIndex: number[] = [];

    @Output() previewsRenderedProgress: EventEmitter<number> = new EventEmitter<number>();
    private destroy = new Subject<any>();
    LifeCycleStageName_RENDERER: LifeCycleStageName = LifeCycleStageName.RENDERER;

    renderingTimes: Map<string, string> = new Map<string, string>();

    constructor(
        public documentService: DocumentViewerService,
        public _fileUploadService: PcbFileUploadService,
        private _assService: AssemblyService,
        private _loginService: LoginService,
        public _dateService: DateService,
        public snackBarService: SnackbarService,
        public dialog: MatDialog,
        private _pcbService: Pcb2Service,
        private logger: NGXLogger,
        private env: EnvironmentService,
        private _segment: SegmentService,
        private _translocoService: TranslocoService
    ) {
        for (let i = 1; i < 40; i++) {
            this.constIndex.push(i);
        }
    }

    getLifeCycleTime(af: AssemblyFile, type: LifeCycleStageName): string {
        if (af && af.lifecycles && af.lifecycles.find(lf => lf.name === type)) {
            const status = af.lifecycles.find(lf => lf.name === type).status;

            if (status?.name === StageStatusName.PROGRESS) {
                return 'active';
            } else if (status?.name === StageStatusName.SUCCESS) {
                const hist = af.lifecycles.find(lf => lf.name === type).history;
                let sum = 0;

                hist.forEach(h => {
                    const workingState = h.name !== StageStatusName.WAITING && h.name !== StageStatusName.SUCCESS;
                    if (h.start && h.end && workingState) {
                        sum += (h.end - h.start) / 1000.0;
                    }
                });
                return sum.toFixed(2) + ' Sec.';
            }
        }
        return '-';
    }

    ngOnDestroy(): void {
        this.documentService.reset(); // close
        this.destroy.next('');
        this.destroy.complete();
    }

    isChrome(): boolean {
        return getBrowserName() === 'chrome';
    }

    getNativeCadName(s: string): string {
        if (s === 'native-altium') {
            return 'Altium Designer CAD-file';
        } else if (s === 'native-kicad') {
            return 'KiCad CAD-file';
        } else if (s === 'native-eagle') {
            return 'EAGLE CAD-file';
        } else {
            return 'CAD-file';
        }
    }


    getFileStatus(fileName: string): RenderLogMessageTypes {
        if (this.statusMap && this.statusMap.has(fileName)) {
            const status = this.statusMap.get(fileName);
            if (status && status.status && status.status.name) {
                return status.status.name;
            }
        }
    }

    fileStatusFailed(file: AssemblyFile): boolean {
        return this.getFileStatus(file.name) === RenderLogMessageTypes.NAME_FAILED;
    }

    getErrorMessage(af: AssemblyFile): string[] {
        const renderingCycle = af.lifecycles.find(cycle => cycle.name === LifeCycleStageName.RENDERER);

        const msgs = renderingCycle?.status?.messages;

        if (msgs) {
            return msgs;
        } else {
            // old status
            if (this.statusMap && this.statusMap.has(af.name)) {
                const status = this.statusMap.get(af.name);
                if (status && status.messages && status.messages.length > 0) {
                    return status.messages.map(m => m.message);
                }
            }

            return ['No specific error message'];
        }


    }

    ngOnInit(): void {

        this._assService.fileSubject.subscribe(fileList => {
            this.setFileList(fileList);
        });
        this._assService.getCurrentAssembly().subscribe(a => {
            this._assService.fileSubject.pipe(first()).subscribe(fileList => {

                this.setFileList(fileList);
            });

            if (a) {
                this.previewPath =
                    this.env.environment.files +
                    '/assembly/' +
                    a.id +
                    '/versions/' +
                    a.currentVersion.id;
            }
        });

        this._pcbService.getPCBObservable().pipe(takeUntil(this.destroy)).subscribe(pcb => {
            this.fileList = this.fileList.map(assfile => {
                const pcbf = find(pcb.files, f => f.name === assfile.name);
                if (pcbf) {
                    assfile.inverted = pcbf.inverted;
                }
                return assfile;
            });
        }
        );
    }

    getIcon(file: AssemblyFile): [boolean, string] {
        let iconName = 'unknown_file';
        let isImage = false;    // set true if the returned iconName is an image, set to icon by default

        if (file.fType.service !== 'local') {
            const mimeType = file.fType.mimeType as FileMimeType;

            // files with mimeType
            if (mimeType) {
                switch (file.fType.mimeType) {
                    case FileMimeType.GERBER:
                        if (this.category === FileCategory.GERBER) {
                            iconName = file.inverted ? 'gerber_inverted_file' : 'gerber_file';
                        } else if (FileCategory.MECHANICAL) {
                            iconName = 'mechanical_file';
                        }
                        break;
                    case FileMimeType.DRILL:
                        iconName = 'drill_file';
                        break;

                    case FileMimeType.PDF:
                        iconName = 'pdf_file';
                        break;
                    case FileMimeType.ZIP:
                        iconName = 'zip_file';
                        break;

                    case FileMimeType.ALTIUM:
                        iconName = ICON_FILE_TYPE_ALTIUM;
                        isImage = true;
                        break;
                    case FileMimeType.KICAD:
                        iconName = ICON_FILE_TYPE_KICAD;
                        isImage = true;
                        break;
                    case FileMimeType.EAGLE:
                        iconName = ICON_FILE_TYPE_EAGLE;
                        isImage = true;
                        break;
                    default:
                        iconName = 'unknown_file';

                }

            }
            // files without mimeType
            else {
                switch (this.category) {
                    case FileCategory.GERBER:
                        iconName = file.inverted ? 'gerber_inverted_file' : 'gerber_file';
                        break;
                    case FileCategory.MECHANICAL:
                        iconName = 'mechanical_file';
                        break;
                    default:
                        iconName = 'unknown_file';

                }
            }
        }
        return [isImage, iconName];
    }

    public isTypeInnerLayer(f: FileType): boolean {
        if (f) {
            return f.fileType === FileTypes.COPPER_MID.fileType || f.fileType === FileTypes.COPPER_PLANE.fileType;
        }
        return false;
    }

    public isDrillFile(f: FileType): boolean {
        if (f) {
            const drillFiles = [FileTypes.DRILL.fileType, FileTypes.NPHDRILL.fileType, FileTypes.PHDRILL.fileType];
            return drillFiles.includes(f.fileType);
        }
        return false;
    }

    public setLayerIndex(i: number, file: AssemblyFile): void {
        if (file) {
            const index = Number(i);
            // check if index is already set
            const fileWithSetIndex = this.fileList.find(f => f.fType.index === index);

            if (fileWithSetIndex) {
                // change index of existing file
                fileWithSetIndex.fType.index = file.fType.index;
                this.updateFile(fileWithSetIndex.fType, fileWithSetIndex);

                // change index of new file
                file.fType.index = index;
                this.updateFile(file.fType, file);

                this.snackBarService.openSnackBar('swapped file-indices of ', +file.name + ' & ' + fileWithSetIndex.name);

            } else {
                file.fType.index = index;
                this.updateFile(file.fType, file);
            }
        }
    }

    public setDrillSetTo(to: number, file: AssemblyFile): void {
        if (file) {
            file.fType.to = to;
            this.updateFile(file.fType, file);
        }
    }

    public setDrillSetFrom(from: number, file: AssemblyFile): void {
        if (file) {
            file.fType.from = from;
            this.updateFile(file.fType, file);
        }
    }

    public getPreviewIcon(af: AssemblyFile): string {
        if (af.fType.service === 'local') {
            // Means that the upload is still in progress
            return '';
        }
        if (af.fType.fileType === 'unknown' && af.fType.service === 'general') {
            return 'insert_drive_file';
        }
        return 'camera';
    }

    public getLayerColor(fileType: FileType): string {
        if (fileType.fileType) {
            const s = DefaultColors.get(fileType.fileType);
            if (s) {
                return s.color.concat('64'); // set opacity to 40% (#64)
            }
        }
        return '#fff';
    }

    public fileStateIsError(af: AssemblyFile): boolean {
        return this.getLifecycleState(af, LifeCycleStageName.RENDERER) === StageStatusName.ERROR;
    }

    public fileStateIsTimeout(af: AssemblyFile): boolean {
        return this.getLifecycleState(af, LifeCycleStageName.RENDERER) === StageStatusName.TIMEOUT;
    }

    public hasRenderingLifeCycle(af: AssemblyFile): boolean {
        if (!af.lifecycles || af.lifecycles.length === 0) {
            return false;
        }
        const renderingCycle = af.lifecycles.find(cycle => cycle.name === LifeCycleStageName.RENDERER);
        return !!renderingCycle;
    }

    public getIconClass(af: AssemblyFile): string {

        const render = this.getLifecycleState(af, LifeCycleStageName.RENDERER);
        // const preview = af.preview ? StageStatusName.SUCCESS : StageStatusName.WAITING;
        const preview = this.getLifecycleState(af, LifeCycleStageName.PREVIEW);

        if (preview === StageStatusName.SUCCESS) {
            if (render === StageStatusName.SUCCESS) {
                return '';
            } else {
                return 'icon-analyzing';
            }
        } else {
            if (render === StageStatusName.WAITING) {
                return 'icon-waiting';
            } else {
                return 'icon-rendering';
            }
        }
    }

    public onFileTypeChanged(newFileType: FileType, file: AssemblyFile): void {
        if (this.isDrillFile(newFileType) && this.isDrillFile(file.fType)) {
            newFileType.from = file.fType.from;
            newFileType.to = file.fType.to;
        }
        if (this.isTypeInnerLayer(newFileType) && this.isTypeInnerLayer(file.fType)) {
            newFileType.index = file.fType.index;
        }
        this.updateFile(newFileType, file);
    }

    public deleteFile(f: AssemblyFile): void {
        f.deleting = true;
        this._assService.getCurrentAssembly().subscribe(
            ass => {
                this._fileUploadService.deleteFile(ass, f).subscribe(
                    _ => {
                        this.fileList = this.fileList.filter(fl => fl !== f);
                    },
                    _ => {
                        f.deleting = false;
                    }
                );
            },
            _ => {
                f.deleting = false;
            }
        );
    }

    public openFileLifeCycleDialog(file: AssemblyFile): void {
        this._pcbService.getCurrentPCB().subscribe(pcb => {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;
            dialogConfig.width = '30vw';
            const pcbFile = find(pcb.files, f => f.name === file.name);

            dialogConfig.data = {
                file: file,
                previewPath: this.previewPath,
                assembly: this._assService.assemblySubject.getValue(),
                pcb: this._pcbService,
                pcbFile: pcbFile
            };

            const dialogRef: MatDialogRef<FileInspectionDialogComponent> = this.dialog.open(
                FileInspectionDialogComponent,
                dialogConfig
            );

            dialogRef.keydownEvents().subscribe(k => {
                if (k.code === 'Escape') {
                    dialogRef.close();
                }
            });
        });

    }

    public openFileInspectionDialog(file: AssemblyFile): void {
        if (file.preview || this.isPDFFile(file)) {
            this.documentService.displayFile(file);
        }
    }

    public invert(row: AssemblyFile): void {
        this._assService.getCurrentAssembly().subscribe(ass => {
            if (!ass.currentVersion.released && !ass.currentVersion.filesLocked) {
                this._pcbService.getCurrentPCB().pipe(map(pcb => find(pcb.files, f => f.name === row.name))).subscribe(lfile => {
                    if (lfile) {
                        const inverted = !lfile.inverted;
                        this._pcbService.invertFileTo(this._assService.assemblySubject.getValue().assembly, lfile, inverted).subscribe();
                    }
                });
            }
        });
    }

    isFileViewerProtected(): Observable<boolean> {
        return this._assService.getCurrentAssembly().pipe(map(ass => {
            if (ass.currentVersion.released) {
                return true;
            } else {
                return ass.currentVersion.filesLocked;
            }
        }));
    }

    public getLifecycleState(af: AssemblyFile, lc: string): string {

        const renderingCycle = af.lifecycles.find(cycle => cycle.name === lc);

        if (renderingCycle) {
            return renderingCycle.status.name;
        } else {
            return StageStatusName.UNKNOWN;
        }
    }

    private static compareFileTypeByContent(f1: FileType, f2: FileType): boolean {
        return f1 &&
            f2 &&
            f1.service === f2.service &&
            f1.fileType === f2.fileType &&
            (f1.index === f2.index || f1.index == null);
    }

    private updateFile(newFileType: FileType, file: AssemblyFile): void {
        if (newFileType && file) {
            this.logger.log('fileType', newFileType, ' for file ', file);
            this._segment.logData("change_file_type", {
                'assembly_id': this._assService.assemblySubject.getValue().assembly.id,
                'file_name': file.name,
                'old_type': file.fType.fileType,
                'new_type': newFileType.fileType
            });

            file.fType = newFileType;

            this._fileUploadService.updateFile(this._assService.assemblySubject.getValue().assembly, newFileType, file).subscribe(
                _ => {
                    console.info(`File ${file.name} got a new type ${newFileType.fileType}`);
                },
                error => {
                    this.logger.log('error', error);
                }
            );
        }

    }

    private setFileList(newFileList: AssemblyFile[]): void {
        if (newFileList) {
            newFileList = newFileList.filter(f => {
                if (this.category === FileCategory.MISC) {
                    return f.fType.fileType === this.category || f.fType.category === this.category;
                } else if (this.category === FileCategory.MECHANICAL) {
                    return f.fType.fileType === 'Mechanical' || f.fType.category === FileCategory.MECHANICAL;
                } else {
                    if (this.category === FileCategory.GERBER) {
                        return f.fType.category === this.category && f.fType.fileType !== 'Mechanical';
                    } else {
                        return f.fType.category === this.category;
                    }
                }
            });
            if (this.fileList && this.fileList.length == newFileList.length) {
                for (let newFile of newFileList) {
                    const oldFile = this.fileList.find(f => f.name == newFile.name);
                    if (oldFile) {
                        oldFile.fType = newFile.fType;
                        oldFile.preview = newFile.preview;
                        oldFile.inverted = newFile.inverted;
                        oldFile.deleting = newFile.deleting;
                        oldFile.created = newFile.created;
                        oldFile.detectedTypes = newFile.detectedTypes;
                        oldFile.lifecycles = newFile.lifecycles;
                    } else {
                        this.fileList = newFileList;
                        break;
                    }
                }
            } else {
                this.fileList = newFileList;
            }

            const that = this;
            this.fileList.sort(function (a, b) {
                if (that.isTypeInnerLayer(a.fType) && that.isTypeInnerLayer(b.fType)) {
                    return a.fType.index - b.fType.index;
                } else if (that.isTypeInnerLayer(b.fType) || that.isTypeInnerLayer(a.fType)) {
                    return layerOrder.get(a.fType.fileType) - layerOrder.get(b.fType.fileType); // a.fType.index - b.fType.index;
                } else {
                    return layerOrder.get(a.fType.fileType) - layerOrder.get(b.fType.fileType);
                }
            });


            if (this.category === FileCategory.GERBER) {
                const total = this.fileList.length;

                function isRenderingStopped(file: AssemblyFile) {
                    const renderLifeCycle = file.lifecycles.find(x => x.name === LifeCycleStageName.RENDERER);
                    if (!renderLifeCycle) {
                        return true;
                    }
                    return LifecycleStageStatus.stopped(renderLifeCycle.status);
                }

                const numberOfStoppedRenderers = this.fileList.filter(isRenderingStopped).length;
                const renderProgress = total > 0 ? 100 * numberOfStoppedRenderers / total : 0;
                this.previewsRenderedProgress.emit(renderProgress);
            }
        }

        this.fileList.forEach(file => {
            this.renderingTimes.set(file.name, this.getLifeCycleTime(file, LifeCycleStageName.RENDERER));
        });
    }

    isPDFFile(file: AssemblyFile): boolean {
        return file.name?.toLowerCase().endsWith('.pdf');
    }

    getRenderingtime(af: AssemblyFile): string {
        return this.renderingTimes.get(af.name) || '-';
    }

    getTooltip(file: AssemblyFile): string {
        if (file.preview || this.isPDFFile(file)) {
            return this._translocoService.translate('PCB-CLICK-FOR-PREVIEW')
        } else if (this.fileStateIsTimeout(file)) {
            return this._translocoService.translate('General-RENDERING-TIMEOUT')
        } else {
            return this._translocoService.translate('PCB-NO-PREVIEW-AVAILABLE')
        }
    }
}
