import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateService {
    constructor() {}

    timeSince(date: string): string {
        const timeStamp = new Date(Date.parse(date));
        const now = new Date();
        let secondsPast = (now.getTime() - timeStamp.getTime()) / 1000;
        secondsPast = Math.round(secondsPast);

        if (secondsPast < 60) {
            return secondsPast + 's';
        }
        if (secondsPast < 3600) {
            const minutes = secondsPast / 60;
            return Math.round(minutes) + 'm';
        }
        if (secondsPast <= 86400) {
            const hours = secondsPast / 3600;
            return Math.round(hours) + 'h';
        }
        if (secondsPast > 86400) {
            const day = timeStamp.getDate();
            const month = timeStamp
                .toDateString()
                .match(/ [a-zA-Z]*/)[0]
                .replace(' ', '');
            const year = timeStamp.getFullYear() === now.getFullYear() ? '' : ' ' + timeStamp.getFullYear();
            return day + ' ' + month + year;
        }
    }

    fullDateFormat(dateString: string): string {

        const date = new Date(dateString);

        const day = date.getDate();
        let dayStr = '';
        if (day < 10) {
            dayStr = '0' + day;
        } else {
            dayStr = day.toString();
        }

        const month = date.getMonth() + 1;

        let monthStr = '';
        if (month < 10) {
            monthStr = '0' + month;
        } else {
            monthStr = month.toString();
        }
        const year = date.getFullYear();

        const hours = date.getHours();
        let hoursStr = '';
        if (hours < 10) {
            hoursStr = '0' + hours;
        } else {
            hoursStr = hours.toString();
        }

        const minutes = date.getMinutes();
        let minutesStr = '';
        if (minutes < 10) {
            minutesStr = '0' + minutes;
        } else {
            minutesStr = minutes.toString();
        }
        return dayStr + '.' + monthStr + '.' + year + ' - ' + hoursStr + ':' + minutesStr;
    }

    simpleDateFormat(dateString: string): string {
        const date = new Date(dateString);
        const day = date.getDate();
        let dayStr = '';
        if (day < 10) {
            dayStr = '0' + day;
        } else {
            dayStr = day.toString();
        }

        const month = date.getMonth();
        let monthStr = '';
        if (month < 10) {
            monthStr = '0' + month;
        } else {
            monthStr = month.toString();
        }
        const year = date.getFullYear();

        const hours = date.getHours();
        let hoursStr = '';
        if (hours < 10) {
            hoursStr = '0' + hours;
        } else {
            hoursStr = hours.toString();
        }

        const minutes = date.getMinutes();
        let minutesStr = '';
        if (minutes < 10) {
            minutesStr = '0' + minutes;
        } else {
            minutesStr = minutes.toString();
        }
        return dayStr + '.' + monthStr + '.' + year;
    }
}
