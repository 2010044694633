import {AssemblyReference, Message} from '../../../../../pcb-common/assembly/assembly';

export enum RenderLogMessageTypes {
    NAME_ANALYZING = 'analyzing',
    NAME_RENDERING = 'rendering',
    NAME_RENDERED = 'rendered',
    NAME_READY = 'ready',
    NAME_EMPTY = 'empty',
    NAME_FAILED = 'failed'
}

export class RenderStatus {
    name: RenderLogMessageTypes;
    instant: string;
}

export class RenderResult {
    status: RenderStatus;
    messages: RenderLogMessage [] = [];
}

export class RenderLogMessage {
    renderType: string;
    severity: string;
    message: string;
}

export class RenderStreamMessage {
    assRef: AssemblyReference;
    m: Message;
}

export class StatusChangedMessage implements Message {
    time: string;
    file: string;
    status: RenderLogMessageTypes;
    _type: string;
}

export class MessageChangedMessage implements Message {
    time: string;
    file: string;
    messages: RenderLogMessage[] = [];
    _type: string;
}

