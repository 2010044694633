import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { MatMenuPanel } from '@angular/material/menu';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { LoginService } from '../../common/auth/login.service';
import { fuseAnimations } from '../../fuse/animations';
import { CollaborationUiService } from '../../main/collaboration/collaboration-ui.service';
import { CollaborationService } from '../../main/collaboration/collaboration.service';
import { AutoUnsubscribable } from '../autounsub';
import { Breadcrumb } from '../breadcrumb';

@Component({
    selector: 'material-page-header',
    templateUrl: './material-page-header.component.html',
    styleUrls: ['./material-page-header.component.scss'],
    animations: fuseAnimations,
})
export class MaterialPageHeaderComponent extends AutoUnsubscribable implements OnInit {
    /**
     * Title that is shown on the page
     */
    @Input()
    pageTitle: string;

    /**
     * Whether the Header title can be changed or not. Please note, that
     * this also requires the renamingAction to be defined
     */
    @Input()
    renamingActive = false;

    @Input()
    breadcrumbs: Breadcrumb[];

    /**
     * Title that is shown on the page
     */
    @Input()
    primaryButtonIcon = 'add';

    /**
     * Title that is shown on the page
     */
    @Input()
    primaryButtonTooltip = '';

    /**
     * Set true to disable primary button
     */
    @Input()
    primaryButtonDisabled = false;

    /**
     * Enable/disable primary button
     */
    @Input()
    enablePrimaryButton = true;

    /**
     *Color primary button
     */
    @Input()
    primaryButtonColor = 'accent-800';

    /**
     * Hide primary button
     */
    @Input()
    primaryButtonHidden = false;

    /**
     * Can be used to create a progress spinner at the
     * primary buttons place
     * only used while enablePrimaryButton is false otherwise
     * primary button is displayed
     */
    @Input()
    progressValue = -1;
    /**
     * If true simple version is displayed
     */
    @Input()
    simple = false;

    /**
     * If true simple version is displayed
     */
    @Input()
    browserTitle: Observable<string>;

    /**
     * menu item to display
     * example:
     *     <mat-menu #menu="matMenu"> ... </mat-menu>
     */
    @Input()
    menu: MatMenuPanel;

    /**
     * Material Icon for the second button (the one right to the search icon)
     */
    @Input()
    secondButtonIcon: string;

    /**
     * Tooltip for the second icon button
     */
    @Input()
    secondButtonTooltip: string;

    /**
     * Badge count for second button
     */
    @Input()
    secondButtonBadgeNumber: number;

    /**
     * Badge active second button
     */
    @Input()
    secondButtonBadgeActive: boolean;

    /**
     * Badge color second button can be 'primary' | 'accent' | 'warn'
     */
    @Input()
    secondButtonBadgeColor = 'primary';

    /**
     * Optional route for the back button in the header
     */
    @Input()
    goBackRoute: string[];

    /**
     * Optional toolTip for the back button
     */
    @Input()
    goBackTooltip: string;

    /**
     * Function that is called if the user types something into the search field.
     * Is meant to be used to filter the content below the page header
     */
    @Output()
    filterAction: EventEmitter<any> = new EventEmitter();

    /**
     * Function that will be executed if the second icon button is pressed
     */
    @Output()
    secondButtonAction: EventEmitter<any> = new EventEmitter();

    /**
     * Function that will be executed if the main FabButton is pressed
     */
    @Output()
    primaryButtonAction: EventEmitter<any> = new EventEmitter();

    /**
     * Function that will be executed if renaming is performed
     */
    @Output()
    renamingAction: EventEmitter<string> = new EventEmitter();

    @ViewChild('searchInput') searchInput: MatInput;
    showSearchBar = false;

    /**
     * CMust be true if searching is supported
     */
    @Input()
    useSearchBar = true;

    search: BehaviorSubject<KeyboardEvent> = new BehaviorSubject<KeyboardEvent>(null);

    /**
     * Can be used to indicate searching
     */
    @Input()
    isSearching = false;

    @Input()
    collaborationEntity = null;

    // Private

    constructor(
        private changeDetector: ChangeDetectorRef,
        private _router: Router,
        private _title: Title,
        private _collaUI: CollaborationUiService,
        public colla: CollaborationService,
        private _activatedRoute: ActivatedRoute,
        private _translocoService: TranslocoService,
        private loginService: LoginService,
    ) {
        super();
    }

    ngOnInit(): void {
        // debounce input
        this.search
            .pipe(
                debounceTime(250),
                filter((v) => !!v),
            )
            .subscribe((searchText) => {
                this.filterAction.emit(searchText);
            });

        if (this.pageTitle) {
            this._title.setTitle(this.pageTitle + ' | ' + this.loginService.getTeam());
        }

        if (this.browserTitle) {
            this.browserTitle.pipe(takeUntil(this._unsubscribeAll)).subscribe((title) => {
                if (title && title.trim() !== '') {
                    this._title.setTitle(title);
                } else if (this.pageTitle) {
                    this._title.setTitle(this.pageTitle + ' | ' + this.loginService.getTeam());
                }
            });
        }
    }

    onRename(value: string): void {
        this.renamingAction.emit(value);
    }

    /**
     * Apply the content filter with respect to the user
     * provided text
     *
     * @param keyEvent KeyEvent sent to the textField
     */
    applyFilter(keyEvent: KeyboardEvent): void {
        if (keyEvent.key === 'Escape') {
            this.filterAction.emit('');
            this.toggleSearchBar();
        } else {
            // this.filterAction.emit(keyEvent);
            this.search.next(keyEvent);
        }
    }

    /**
     * Execute the desired function for the second button
     */
    secondButtonClicked(): void {
        this.secondButtonAction.emit();
    }

    /**
     * Execute the desired function for the add button
     */
    addButtonClicked(): void {
        this.primaryButtonAction.emit();
    }

    /**
     * Toggle the visibility of the search bar
     */
    toggleSearchBar(): void {
        this.showSearchBar = !this.showSearchBar;
        this.changeDetector.detectChanges();
        if (this.searchInput) {
            this.searchInput.focus();
        } else {
            const keyEvent = new KeyboardEvent('keydown', {
                key: 'Escape',
            });
            this.search.next(keyEvent);
        }
    }

    getRouterLink(bc: Breadcrumb): string[] {
        if (bc.url) {
            if (!bc.url[0].startsWith('/')) {
                bc.url = ['/'].concat(bc.url);
            }
            return bc.url;
        }
        return null;
    }

    toggleCollaborationSideBar(): void {
        this._collaUI.toggleCollaborationSidebar();
    }
}
