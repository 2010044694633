<ng-container *transloco="let t">
    <mat-table *ngIf="showFileList" [@animateStagger]="{ value: '50' }" [dataSource]="fileList">
        <ng-container matColumnDef="icon">
            <mat-cell *matCellDef="let row" fxFlex="64px">
                <!-- IN CASE IT IS A CUSTOM IMAGE-->
                <div>
                    <img *ngIf="getIcon(row)[0]" class="pcb-icon" src="{{getIcon(row)[1]}}" />

                    <!-- IN CASE IT IS A CUSTOM ICON-->
                    <mat-icon (click)="invert(row)" *ngIf="!getIcon(row)[0]" class="click-icon" color="accent"
                        svgIcon="{{getIcon(row)[1]}}"></mat-icon>
                </div>
            </mat-cell>
            <mat-header-cell *matHeaderCellDef fxFlex="64px"></mat-header-cell>
        </ng-container>

        <!-- filename -->
        <ng-container matColumnDef="fileName">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let row"><span [matTooltip]="_dateService.fullDateFormat(row.created)">{{ row.name
                    }}</span></mat-cell>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="modified">
            <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Modified</mat-header-cell>
            <mat-cell *matCellDef="let row" fxHide fxShow.gt-md>
                <div fxLayout="row" fxLayoutAlign="space-around center">
                    <mat-icon (click)="openFileLifeCycleDialog(row)" class="mr-4"
                        matTooltip="{{t('Tooltip-CLICK-TO-SEE-FILE-LIFECYCLE')}}">history
                    </mat-icon>

                    <p class="pr-8">
                        {{t("General-RENDERING")}}: <b>{{ getRenderingtime(row) }}</b>
                    </p>
                    <!--                <p>-->
                    <!--                    Analysis: {{getLifeCycleTime(row, 'fileanalysis')}}-->
                    <!--                </p>-->
                </div>

                <!--            <span *ngIf="row.created"> Created : {{  }}</span>-->


            </mat-cell>
        </ng-container>

        <!-- File list -->
        <ng-container matColumnDef="function">
            <mat-header-cell *matHeaderCellDef> No.</mat-header-cell>

            <mat-cell *matCellDef="let row" class="pr-16">

                <!-- FILE TYPE -->
                <mat-select (selectionChange)="onFileTypeChanged($event.value, row)" [compareWith]="compareFileType"
                    [disabled]="(isFileViewerProtected() | async)" [value]="row.fType" placeholder="Unknown File">
                    <mat-optgroup label="Gerber">
                        <mat-option *ngFor="let dType of gerberTypes"
                            [ngStyle]="{'background-color': getLayerColor(dType)}" [value]="dType">
                            {{ dType.fileType }}
                        </mat-option>
                    </mat-optgroup>

                    <mat-optgroup label="Mechanical">
                        <mat-option *ngFor="let dType of mechanicalTypes" [value]="dType">
                            {{ dType.fileType }}
                        </mat-option>
                    </mat-optgroup>

                    <mat-optgroup label="Drill">
                        <mat-option *ngFor="let dType of drillTypes" [value]="dType">
                            {{ dType.fileType }}
                        </mat-option>
                    </mat-optgroup>

                    <mat-optgroup label="CAD">
                        <mat-option *ngFor="let dType of nativeCADTypes" [value]="dType">
                            {{getNativeCadName(dType?.fileType)}}
                        </mat-option>
                    </mat-optgroup>

                    <mat-optgroup label="Misc">
                        <!--                    <mat-option [value]="basicUnknown">Unknown</mat-option>-->
                        <mat-option *ngFor="let dType of otherTypes" [value]="dType">
                            {{dType?.fileType}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>

                <!-- FILE INDEX -->
                <mat-select (selectionChange)="setLayerIndex($event.value, row)" *ngIf="isTypeInnerLayer(row.fType)"
                    [disabled]="(isFileViewerProtected() | async)" [placeholder]="'index'" [value]="row.fType.index"
                    class="index-select">
                    <mat-option *ngFor="let index of constIndex" [value]="index">
                        L{{ index }}
                    </mat-option>
                </mat-select>


                <mat-select (selectionChange)="setDrillSetFrom($event.value, row)" *ngIf="isDrillFile(row.fType)"
                    [disabled]="(isFileViewerProtected() | async)" [placeholder]="'from'" [value]="row.fType.from"
                    class="drill-layer-select">
                    <mat-option *ngFor="let i of constIndex" [value]="i">
                        L{{ i }}
                    </mat-option>
                </mat-select>

                <mat-select (selectionChange)="setDrillSetTo($event.value, row)" *ngIf="isDrillFile(row.fType)"
                    [disabled]="(isFileViewerProtected() | async)" [placeholder]="'to'" [value]="row.fType.to"
                    class="drill-layer-select">
                    <mat-option *ngFor="let i of constIndex" [value]="i">
                        L{{ i }}
                    </mat-option>
                </mat-select>

            </mat-cell>
        </ng-container>

        <!-- Hover icon list -->
        <ng-container matColumnDef="preview">
            <mat-cell *matCellDef="let row">
                <!-- Popup stuff which is shown if a preview image is available -->
                <button (click)="openFileInspectionDialog(row)" mat-icon-button [matTooltip]="getTooltip(row)">

                    <mat-icon *ngIf="hasRenderingLifeCycle(row) && !fileStateIsError(row) && !fileStateIsTimeout(row)"
                        [ngClass]="getIconClass(row)" class="previewArea">camera
                    </mat-icon>
                    <mat-icon *ngIf="!hasRenderingLifeCycle(row) && !isPDFFile(row)" class="icon-waiting">
                        help_outline
                    </mat-icon>
                    <mat-icon *ngIf="!hasRenderingLifeCycle(row) && isPDFFile(row)" class="icon-waiting">
                        picture_as_pdf
                    </mat-icon>
                    <mat-icon *ngIf="fileStateIsError(row)" color="warn">
                        error
                    </mat-icon>
                    <mat-icon *ngIf="fileStateIsTimeout(row)" color="warn">
                        timer
                    </mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <!-- Delete button list -->
        <ng-container matColumnDef="delete_button">
            <mat-cell *matCellDef="let row">
                <button (click)="deleteFile(row)" [disabled]="(isFileViewerProtected() | async)" color="warn"
                    mat-icon-button>
                    <mat-icon aria-label="delete icon button">delete</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: displayedColumns">

        </mat-row>
    </mat-table>

    <div *ngIf="!showFileList">
        <div class="text-xl">
            {{fileList.length}} {{t("General-FILES")}}
        </div>
    </div>
</ng-container>