import {EncodedMailMessageEntityId} from "./assembly";

export class EmailContact {
    from: string;
    to: string [] = [];
    cc: string [] = [];
    bcc: string [] = [];
}

export class EmailAttachment {
    name: string;
    disposition: string;
    path: string;
}

export class Email {
    // TODO check: there is a change we don't use it anymore
    info: EmailInfo;
    encodedEntityId: EncodedMailMessageEntityId
    subject: string;
    message: string;
    plainMessage: string;
    contact: EmailContact;
    attachments: EmailAttachment [] = [];
    qdItems: QDItem [] = [];
    sentDate: Date;
}

export class EmailInfo {
    id: string;
    mailbox: Mailbox;
}

export class Mailbox {
    folder: string; // mailbox id
    team: string; // team
}

// tslint:disable-next-line:max-line-length


export class QDItem {
    quantity: number;

    constructor(quantity?: number, delivery?: number) {
        this.quantity = quantity;
    }
}



