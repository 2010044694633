import {Point} from '@svgdotjs/svg.js';

import {UUID} from 'angular2-uuid';
import {Layer} from './layer-stack';
import {FileType, Message} from '../assembly/assembly';
import {
    PCBV2Properties,
    PCBV2Specification
} from "../../main/project/assembly/assembly-view/specification/PCBV2Specification";

export class LayerStyle {
    color: string;
    opacity: number;
    reflectivity: number;
    metalness: number;

    constructor(color: string, opacity: number, reflectivity?: number, metalness?: number) {
        this.color = color;
        this.opacity = opacity;
        if (reflectivity) {
            this.reflectivity = reflectivity
        } else {
            this.reflectivity = 0
        }
        if (metalness) {
            this.metalness = metalness
        } else {
            this.metalness = 0
        }
    }
}

export enum ATTRIBUTES_KEYS {
    NET_LIST = 'N',
    PIN_LIST = 'P',
}


export const SVG_SCALE = 100; // scale factor used in backend scaling

export class Outline {
    id: UUID;
    file?: UUID;
    preview?: String;
    userChoice: Boolean;
    metaInfo: any;

    graphic?: Graphic;
}

export class PCB {
    // API
    // public layerstack: Layer[] = [];
    outline: Outline;
    assembly: UUID;
    version: UUID;
    holes: string[] = [];
    files: LayerFile[] = [];
    netList?: NetList;
    metaInfo: any;
    defaultSpecification: string;
    // NON-API

    constructor() {
    }
}

export class PCBV2 {
    id: UUID;
    name?: string;
    assembly: UUID;
    description?: string;
    created: string;
    files: PCBV2File[];
    filesLocked: boolean;
    properties: PCBV2Properties;
    specifications: PCBV2Specification[];
}

export class PCBV2File {
    name: string;
    fileType: FileType;
    preview?: string;
    path?: string;
}

export class FileWithScore {
    file: PCBV2File;
    score: number;
}

export class PropertyMatch {
    name: string;
}

export class DuplicatePCB {
    pcb: PCBV2;
    gid: string;
    propertyMatches: PropertyMatch[];
    propertyMatchScore: number;
    exactfiles: PCBV2File[];
    fileMatchScore: number;
    totalScore: number;
}

export class PCBState {
    locked: boolean;
    approved: boolean;
}

export class LayerFile {
    id: UUID;
    name: string;
    data: string;
    format: Format;
    fileType: FileType;
    inverted = false; // TODO: fetch from backend when available
}

export class NetList {
    nets: Net[]

    static findNet(netlist: NetList, trace: String) {
        return netlist?.nets.find(n => {
            return !!n.traces.find(t => t.id === trace)
        })
    }
}

export class Net {
    traces: NetTrace[]
    drills: string[]
}

export class NetTrace {
    id: string
    elements: string[]
}


export class Format {
    unit: string;
    resolution: number;
    dimension: Dimension;
    scaling: number;
    complexity: number;
}

export class Dimension {
    min: Point;
    max: Point;
}

export class Graphic {
    viewbox: Dimension;
    format: Format;
    count: number;
    paths: GraphicElement[][];
    defs: GraphicDef[];
}

export enum SvgSpecifier {
    TRACE = 'trace',
    TRACE_PREFIX = 't-',
    DRILL = 'drill',
    NET_PREFIX = 'net_'
}

export enum SvgElementOutline {
    INSIDE = 'iol',
    OUTSIDE = 'ool'
}

export class NetListInfo {
    netClass: string;
    netName: string;
    netGroups: string [] = [];

    constructor(net_class: string, netname: string, traceGroup: string[]) {
        this.netClass = net_class;
        this.netName = netname;
        this.netGroups = traceGroup;
    }

    addElement(el: string): void {
        this.netGroups.push(el);
    }
}

export class GraphicDef {
    id: string;
    path: string;
}

export class GraphicElement {
    path: string;
    use: GraphiUsage;
    attributes: Map<string, string[]> = new Map();
    trace: string;
    elementIds: string [] = [];
    orientation?: GraphicOrientation;
}

export class GraphicOrientation {
    degrees: number;
    mirrored: boolean;
}

export class GraphiUsage {
    location: Point;
    reference: string;
}

//
// Messages
//

export class SetOutline {
    candidate: UUID;

    constructor(candidate: UUID) {
        this.candidate = candidate;
    }
}


export class OutlineCandidateSetMsg implements Message {
    outline: Outline;
    _type: string;
}

export class AnalysisMsg implements Message {
    status?: any;
    meta: any;
    _type: string;
}

export class FileSetMsg implements Message {
    file: LayerFile;
    _type: string;
}

export class FormatChangedMsg implements Message {
    file: LayerFile;
    _type: string;
}

export class DrillFileMsg implements Message {
    drillFile: string; // path to the drill file
    fileId: UUID;
    _type: string;
}

export class LayerStackChanged implements Message {
    newStack: Layer[];
    _type: string;
}

export class LayerRendered implements Message {
    id: number;
    data: Map<string, Graphic>;
    _type: string;
}

export class DistanceDescription {
    from: number;
    to: number;
    length: number;

    distance: Distance;
}

export class Distance {
    start: Point;
    end: Point;
}