import {Injectable} from '@angular/core';
import {PCBSpecification, SpecUpdateCommand} from './PCBSpecification';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {filter, first, flatMap, map, takeUntil, tap} from 'rxjs/operators';
import {MatchingFinishedMessage, PCBSupplierStreamMessage, SupplierManufacture} from './pcbsupplier-stream';
import {AssemblyService} from '../../assembly.service';
import {UUID} from 'angular2-uuid';
import {AutoUnsubscribable} from '../../../../../helpers/autounsub';
import {
    Assembly, AssemblyWithReference,
    LifeCycleStageName,
    SharedAssemblyReference,
    StageStatusName
} from '../../../../../pcb-common/assembly/assembly';
import {LoginService} from '../../../../../common/auth/login.service';
import {EnvironmentService} from '../../../../../common/env/environment.service';
import {SegmentService} from '../../../../../services/segment.service';

@Injectable({
    providedIn: 'root'
})
export class SpecificationService extends AutoUnsubscribable {

    specificationList: BehaviorSubject<PCBSpecification[]> = new BehaviorSubject<PCBSpecification[]>([]);
    private listLoaded = false;
    private ws: WebSocket;
    private prevws: WebSocket;


    constructor(
        private _httpClient: HttpClient,
        private _loginService: LoginService,
        private _assService: AssemblyService,
        private env: EnvironmentService,
        private _segment: SegmentService
    ) {
        super();
        this.init();
    }

    private init() {
        this.subscribeToSpecificationRenderUpdates();
        this._assService.getCurrentAssembly().pipe(
            tap(ass => {
                this.closePreviewSocket()
                this.openPreviewSocket(ass);
            }),
        ).subscribe();
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.closeViolationSocket()
        this.closePreviewSocket();
    }


    private closePreviewSocket() {
        if (this.prevws) {
            this.prevws.onclose = () => {
            }
            this.prevws.close();
        }
    }

    private baseApiUrl(assemblyId: UUID, versionId: UUID, path: string): string {
        return this.env.environment.api + '/ems/pcb/' + assemblyId +
            '/versions/' + versionId + '/specifications' + path;
    }

    private baseApiUrlPerAssembly(assemblyWithReference: AssemblyWithReference, path: string): string {
        if (assemblyWithReference.isShared) {
            const ref = assemblyWithReference.reference as SharedAssemblyReference
            return this.env.environment.api + '/ems/pcb/shares/' + ref.id + '/specifications' + path;
        } else {
            const ass = assemblyWithReference.assembly
            return this.baseApiUrl(ass.id, ass.currentVersion.id, path);
        }
    }


    public getSpecificationListForAssembly(ass: AssemblyWithReference): Observable<PCBSpecification[]> {
        return this._httpClient.get<PCBSpecification[]>(this.baseApiUrlPerAssembly(ass, ''));
    }

    public getSpecificationList(): Observable<PCBSpecification[]> {

        if (!this.listLoaded) {
            this.fetchSpecificationList();
        }
        return this.specificationList.asObservable();
    }

    public getSpecificationAliasList(): string[] {
        return this.specificationList.getValue().map(spec => spec.spec.alias);
    }

    public getLocalSpecificationByID(specID: string): Observable<PCBSpecification> {
        return this.getSpecificationList().pipe(map(specList => {
            return specList.find(spec => {
                return spec.spec.id === specID;
            });
        }), filter(spec => !!spec), first());
    }

    public cloneSpecification(alias: string, spec: PCBSpecification): Observable<PCBSpecification> {
        return this.cloneSpecificationByID(alias, spec.spec.id);
    }


    cloneSpecificationByID(alias: string, spec: string) {
        return this._assService.getCurrentReferencedAssembly()
            .pipe(
                flatMap(ass => {
                    return this._httpClient.post<PCBSpecification>
                    (this.baseApiUrlPerAssembly(ass, ''), {
                        'alias': alias,
                        'template': spec
                    }).pipe(
                        tap(
                            d => {
                                this.addSpecToList(d);
                            }
                        )
                    );
                })
            );
    }

    addSpecToList(d: PCBSpecification) {
        const s = this.specificationList.value;
        s.push(d);
        this.specificationList.next(s);
    }

    public openViolationSocket<T>(spec: PCBSpecification, callback: (supp: SupplierManufacture[], me: T) => void, me: T): void {
        this.closeViolationSocket();

        // TODO: can this be removed?
        // this.ws = new WebSocket(
        //     this.env.environment.ws +
        //     '/ems/pcbsupplier/violations/' +
        //     spec.spec.assembly.version +
        //     '/' +
        //     spec.spec.id +
        //     '/stream?k=' +
        //     this._loginService.getCurrentAuthToken());
        //
        // this.ws.onclose = evt => {
        //     if (!evt.wasClean) {
        //         this.openViolationSocket(spec, callback, me)
        //     }
        // }
        // this.ws.onmessage = function (event: MessageEvent): void {
        //     const pcbSupplierMessage: PCBSupplierStreamMessage = JSON.parse(event.data);
        //     const messageType = pcbSupplierMessage.m._type;
        //
        //     if (messageType.endsWith('MatchingFinishedMessage')) {
        //
        //         const mfMessage: MatchingFinishedMessage = pcbSupplierMessage.m as MatchingFinishedMessage;
        //
        //         const supplierEvaluation = mfMessage.info;
        //         callback(supplierEvaluation, me);
        //
        //     }
        //
        // };

    }

    private closeViolationSocket() {
        if (this.ws) {
            this.ws.onclose = () => {
            }
            this.ws.close();
        }
    }

    getPreviewForAssembly(ass: Assembly, preview: string): string {
        if (ass && preview) {

            return this.env.environment.files +
                '/assembly/' +
                ass?.id +
                '/versions/' +
                ass?.currentVersion?.id +
                '/' +
                preview;
        }
        return '';
    }

    public getSpecificationByAliasAndAssembly(assembly: UUID, version: UUID, specAlias: string): Observable<PCBSpecification> {
        return this._httpClient.get<PCBSpecification>(
            this.baseApiUrl(assembly, version, '/search?alias=' + specAlias)
        );
    }

    public getSpecificationByAlias(specAlias: string): Observable<PCBSpecification> {
        return this._assService.getCurrentAssembly()
            .pipe(
                flatMap((ass) => {
                    return this.getSpecificationByAliasAndAssembly(ass.id, ass.currentVersion.id, specAlias);
                }));
    }

    public getSpecificationByIdAndAssembly(ass: UUID, version: UUID, specId: UUID): Observable<PCBSpecification> {
        return this._httpClient.get<PCBSpecification>(this.baseApiUrl(ass, version, '/' + specId));
    }


    public getSpecificationById(specId: string): Observable<PCBSpecification> {
        return this._assService.getCurrentAssembly()
            .pipe(
                flatMap((ass) => {
                    return this.getSpecificationByIdAndAssembly(ass.id, ass.currentVersion.id, specId);
                }));
    }

    public updateSpecification(s: PCBSpecification, specUpdate: SpecUpdateCommand): Observable<PCBSpecification> {
        return this._httpClient.put<PCBSpecification>(
            this.baseApiUrl(s.spec.assembly.id, s.spec.assembly.version, '/' + s.spec.id), specUpdate);
    }

    public saveSpecification(s: PCBSpecification): Observable<PCBSpecification> {
        this._segment.logData('save_specification', {'assembly_id': s.spec.assembly.id, 'specification_id': s.spec.id});

        return this._httpClient.put<PCBSpecification>(
            this.baseApiUrl(s.spec.assembly.id, s.spec.assembly.version, '/' + s.spec.id), {
                'status': 'active'
            });
    }

    public deleteSpecification(ass: UUID, version: UUID, specId: UUID): Observable<any> {
        return this._httpClient.delete<PCBSpecification>(this.baseApiUrl(ass, version, '/' + specId));
    }

    private fetchSpecificationList(): void {
        this._assService.getCurrentReferencedAssembly().pipe(
            flatMap(assWithRef => {
                const ass = assWithRef.assembly
                let request: Observable<PCBSpecification[]> =
                    this._httpClient.get<PCBSpecification[]>(
                        this.baseApiUrlPerAssembly(assWithRef, '')
                    )
                return request.pipe(map(specs => {
                    this.listLoaded = true;
                    const specsWithPreviews = [];
                    specs.forEach(spec => {
                        this.setPreviews(ass, spec, false, false);
                        specsWithPreviews.push(spec);
                    });
                    this.specificationList.next(specsWithPreviews);
                }));
            }),
            takeUntil(this._unsubscribeAll)
        ).subscribe();
    }

    /**
     * Updates the `generating` status of previews for ALL specifications in the current assembly in case if
     * the specification render status has changed.
     *
     * This approach is flawed because the regeneration of preview for one specification doesn't trigger
     * the regeneration of previews for other specifications. However, right now we don't have any information on
     * which specification is affected by the regeneration of a preview.
     * @private
     */
    private subscribeToSpecificationRenderUpdates() {
        this._assService.getLifecycleUpdates().pipe(takeUntil(this._unsubscribeAll)).subscribe(up => {
            const [assembly, cycles] = up;
            if (cycles.has(LifeCycleStageName.SPECIFICATION)) {
                const specs = this.specificationList.getValue();
                const currentStage = cycles.get(LifeCycleStageName.SPECIFICATION).status.name;
                const generating = currentStage === StageStatusName.PROGRESS || currentStage === StageStatusName.WAITING;
                const newSpecs = specs.map(s => {
                    return this.setPreviews(assembly, s, generating, true)
                });
                this.specificationList.next(newSpecs);
            }
        });
    }

    /**
     * Updates the preview images and their state (generating/generated) for a given specification.
     */
    private setPreviews(assembly: Assembly, spec: PCBSpecification, generating: boolean, timeStamp: boolean): PCBSpecification {
        if (spec.previews.front) {
            spec.previews.front = this._getPreviewPath(assembly, spec.previews.front, timeStamp);
            spec.previews.generating = generating;
        }
        // At this point, we don't use `rear` anywhere in the frontend
        if (spec.previews.rear) {
            spec.previews.rear = this._getPreviewPath(assembly, spec.previews.rear, timeStamp);
        }
        return spec;
    }

    private openPreviewSocket(ass: Assembly) {
        this.prevws = new WebSocket(
            this.env.environment.ws +
            '/ems/pcb/' +
            ass.id +
            '/versions/' +
            ass.currentVersion.id +
            '/specifications/previewupdates?k=' +
            this._loginService.getCurrentAuthToken());

        this.prevws.onclose = evt => {
            if (!evt.wasClean) {
                this.openPreviewSocket(ass)
            }
        }

        this.prevws.onmessage = event => {
            const data = JSON.parse(event.data);
            if (data.t === 'specpreview') {
                const newprev = data.m.preview;
                const version = data.ref.version;

                const specs = this.specificationList.value
                    .map(s => {
                        if (s.spec.assembly.version === version && s.spec.id === data.m.specification) {
                            const assemblyPreview =
                                this.env.environment.files +
                                '/assembly/' +
                                ass.id +
                                '/versions/' +
                                ass.currentVersion.id +
                                '/' +
                                newprev;
                            if (assemblyPreview.includes('specification-preview-front-')) {
                                s.previews.front = assemblyPreview + '&ts=' + Date.now();
                            } else if (assemblyPreview.includes('specification-preview-rear-')) {
                                s.previews.rear = assemblyPreview + '&ts=' + Date.now();
                            }
                        }
                        return s;
                    });

                this.specificationList.next(specs);
            }
        };
    }

    private _getPreviewPath(ass: Assembly, origPath: string, ts: boolean = false): string {
        return this.getPreviewPath(ass.id, ass.currentVersion.id, origPath, ts)
    }

    public getPreviewPath(assemblyId: string, assemblyVersionId: string, origPath: string, ts: boolean = false): string {
        if (origPath.startsWith(this.env.environment.files)) {
            return origPath;
        }
        let res = this.env.environment.files +
            '/assembly/' +
            assemblyId +
            '/versions/' +
            assemblyVersionId +
            '/' +
            origPath;

        if (ts) {
            res = res + '&ts=' + Date.now();
        }

        return res;
    }

    printSpecification(specification: PCBSpecification) {
        this._segment.logData('download_specification_document', {
            'assembly_id': specification.spec.assembly.id,
            'specification_id': specification.spec.id
        });
        return this._httpClient.get(
            this.baseApiUrl(specification.spec.assembly.id,
                specification.spec.assembly.version,
                '/' + specification.spec.id + '/print'), {
                headers: {
                    'Accept': 'application/pdf'
                },
                observe: 'response',
                responseType: 'blob'
            });
    }
}
