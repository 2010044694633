import {Component, Input, OnInit} from '@angular/core';
import {Quotation} from '../../main/quotation/quotation';
import {DateService} from '../../services/date.service';
import {Router} from '@angular/router';
import {LoginService} from '../../common/auth/login.service';

@Component({
    selector: 'scrumboard-quotation-card',
    templateUrl: './quotation-card.component.html',
    styleUrls: ['./quotation-card.component.scss']
})
export class QuotationCardComponent implements OnInit {

    @Input()
    quotation: Quotation;

    @Input()
    size = 'normal';

    constructor(public dateService: DateService,
                private _loginService: LoginService,
                public _router: Router) {
    }

    ngOnInit(): void {
    }

    openQuotation(): void {
        this._router.navigate(['quotation', this.quotation.name]);
    }

    archiveQuotation(): void {

    }
}
