<div id="fab-dismiss"
     *ngIf="fabTogglerState==='active'"
     (click)="onToggleFab()">
</div>

<div class="fab-container">
    <button mat-fab class="fab-toggler"
            (click)="onToggleFab()">
        <mat-icon class="fab-button-icon"  [@fabToggler]="{value: fabTogglerState}">near_me</mat-icon> <!-- Animation here -->
    </button>
    <div [@speedDialStagger]="buttons.length"> <!-- and here -->
        <button *ngFor="let btn of buttons"
                mat-mini-fab
                class="fab-secondary"
                matTooltip="{{btn.tooltip}}"
                color="secondary"
                [routerLink]="btn.route"
                [queryParams]="btn.queryParams"
                (click)="hideItems()"
        >
            <i class="material-icons">{{btn.icon}}</i>
        </button>
    </div>
</div>