export class CustomerResult {
    customer: Customer;
}

export class Customer {
    id: string;
    externalID: string;
    name: string;
    image: string;
    addresses: Address[] = [];
    type: string;
    email: string;
    phone: string;
    contacts: Contact[] = [];
    tags: CustomerTag[] = [];
    assignee: string;
    creator: string;
    created: Date;
    domains: string [] = [];
    discount: number;
    priceLevel: number;
    skontoEnabled: boolean;
    skontoEntries: SkontoEntry[];
    notes: string;
    taxID: string;
    lumiquoteTenant: string;
}

export class ContactResult {
    contact: Contact;
}

export class SkontoEntry {
    days: number;
    discountPercentage: number;
}

export class SkontoEnabler {
    enabled: boolean;
}


export class Contact {
    id: string;
    image: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address: Address;
    assignee: string;
    creator: string;
    created: Date;
    position: string;
    notes: string;
}

export const ContactRoles: string [] = ['Default', 'Technicians', 'Sales'];

export class Address {
    id: string;
    name: string;
    alias: string;
    phone: string;
    company: string;
    street: string;
    no: string;
    apartment: string;
    district: string;
    city: string;
    postal: string;
    country: string;
    //TODO: this will be dropped
    county: string;
    billing = false;
    shipping = false;
    notes: string;
}

export class CustomerTag {
    name: string;
    description: string;
}