import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {filter, flatMap, map, takeUntil, tap} from 'rxjs/operators';
import {UUID} from 'angular2-uuid';
import {NavigationInfoService} from '../../../services/navigation-info.service';
import {FuseNavigationService} from '../../../../@fuse/components/navigation/navigation.service';
import {FileSystemFileEntry} from 'ngx-file-drop';
import {Project} from '../../../pcb-common/assembly/project';
import {
    Assembly,
    AssemblyMessage,
    AssemblyPreviewMessage,
    AssemblyUpdate,
    AssemblyVersionMessage,
    FilesAddedMessage,
    LifeCycleStage,
    LifeCycleStageName,
    StageStatusName,
    StreamMessage,
    UIStatus,
    AssemblyStageUpdateMessage,
    SharedAssembly,
    AssemblyWithReference,
    AssemblyReference,
    AbstractAssemblyReference,
    SharedAssemblyReference, AssemblyWithShare
} from '../../../pcb-common/assembly/assembly';
import {SnackbarService} from '../../../common-ui/snackbar/snackbar.service';
import {LoginService} from '../../../common/auth/login.service';
import {EnvironmentService} from '../../../common/env/environment.service';
import {resolveFiles} from '../../../common/file-utils';
import {
    DeleteConfirmDialogComponent
} from '../../../common-ui/dialogs/delete-confirm-dialog/delete-confirm-dialog.component';
import {ProgressBarService} from '../../../common-ui/progressbar/progress-bar.service';
import {PaginationListResult} from '../../../common/PaginationListResult';
import {TranslocoService} from '@ngneat/transloco';

// {
//     providedIn: 'root'
// }
@Injectable()
export class AssemblyListService implements OnDestroy {
    public placeHolderProject: Project;
    private assemblyList: BehaviorSubject<Assembly[]> = new BehaviorSubject([]);
    private sharedAssemblyList: BehaviorSubject<AssemblyWithShare[]> = new BehaviorSubject([]);

    private assemblyUpdated: BehaviorSubject<Assembly> = new BehaviorSubject(null);
    private assemblyAdded: BehaviorSubject<Assembly> = new BehaviorSubject(null);
    private assemblyRemoved: BehaviorSubject<Assembly> = new BehaviorSubject(null);

    private shareUpdated: BehaviorSubject<AssemblyWithShare> = new BehaviorSubject(null);
    private shareAdded: BehaviorSubject<AssemblyWithShare> = new BehaviorSubject(null);
    private shareRemoved: BehaviorSubject<AssemblyWithShare> = new BehaviorSubject(null);

    private lifecycleUpdates: Subject<AssemblyStageUpdateMessage> = new Subject();

    private destroyed: Subject<string> = new Subject<string>();

    private assemblyEndpoint = '/assembly/assemblies';
    private sharesEndpoint = '/assembly/fullshares';
    private shareEndpoint = '/assembly/shares';
    private ws: WebSocket;

    constructor(
        private _router: Router,
        public confirmDialog: MatDialog,
        private _httpClient: HttpClient,
        private _loginService: LoginService,
        private _fuseProgressBarService: ProgressBarService,
        private _snackBarService: SnackbarService,
        private _navInfoService: NavigationInfoService,
        private _navService: FuseNavigationService,
        private env: EnvironmentService,
        private _translocoService: TranslocoService
    ) {
        this.placeHolderProject = new Project();
        this.placeHolderProject.name = this._translocoService.translate('General-UNASSIGNED-ASSEMBLIES');
        this.placeHolderProject.desc = this._translocoService.translate('General-THIS-SERVES-AS-A-PLACEHOLDER-PROJECT');
        this.placeHolderProject.dummyProject = true;
        this.subscribeUpdate();
        this.updateOpenAssemblyCount();

        this.lifecycleUpdates.pipe(takeUntil(this.destroyed)).subscribe(msg => {
            this.updateAssemblyPreview(msg);
        });
    }

    makeLocalAssemblyStageUpdate(msg: AssemblyStageUpdateMessage) {
        this.lifecycleUpdates.next(msg);
    }

    /**
     * Updates assembly's preview when specification render is succeeded
     * @private
     */
    private updateAssemblyPreview(updateMsg: AssemblyStageUpdateMessage) {
        const assemblyId = updateMsg.assembly;

        let currentList = this.assemblyList.getValue();
        let hasUpdate = false;
        updateMsg.lifecycle.forEach((lc) => {
            if (lc.name === LifeCycleStageName.SPECIFICATION && lc.status.name === StageStatusName.SUCCESS) {
                currentList = currentList.map(x => {
                    if (x.id === assemblyId) {
                        x.preview = this.getPreview(x, true);
                        hasUpdate = true;
                        this.assemblyUpdated.next(x);
                        return x;
                    } else {
                        return x;
                    }

                });
            }
        });

        if (hasUpdate) {
            this.assemblyList.next(currentList);
        }

    }


    public getAssemblyUpdates(): Observable<Assembly> {
        return this.assemblyUpdated.pipe(filter(a => a != null));
    }

    public getShareUpdates(): Observable<SharedAssembly> {
        // TODO: implement
        return new Subject<SharedAssembly>();
    }

    ngOnDestroy(): void {
        this.destroyed.complete();
    }


    public getUpdatesForAssembly(assemblyId: string): Observable<Assembly> {
        return this.assemblyUpdated.pipe(filter(a => a != null), filter(a => a.id === assemblyId));
    }

    public getLifecycleUpdates(): Observable<[string, Map<string, LifeCycleStage>]> {
        return this.assemblyUpdated.pipe(filter(a => a != null), map(x => {
            const s = x.id;
            const lcs = x.currentVersion.lifecycles;
            const m = new Map<string, LifeCycleStage>();
            lcs.forEach(lc => {
                m.set(lc.name, lc);
            });
            return [s, m];
        }));
    }

    public getLifecycleUpdatesForAssembly(assemblyId: UUID): Observable<Map<LifeCycleStageName, LifeCycleStage>> {
        return this.lifecycleUpdates.pipe(filter(a => a.assembly === assemblyId), map(x => {
            const m = new Map<LifeCycleStageName, LifeCycleStage>();

            x.lifecycle.forEach(lc => {
                m.set(lc.name, lc);
            });

            return m;
        }));
    }

    public getAdds(): Observable<Assembly> {
        return this.assemblyAdded.pipe(filter(a => a != null));
    }

    public getRemoves(): Observable<Assembly> {
        return this.assemblyRemoved.pipe(filter(a => a != null));
    }

    public getAssemblies(): Observable<Assembly[]> {
        return this.assemblyList.pipe(filter(a => a != null));
    }

    public getAssemblyByGid(assemblyGid: string): Observable<AssemblyWithReference> {

        return this.assemblyList.pipe(
            map(l => l.find(z => z.gid === assemblyGid)),
            filter(x => x != null),
            map(a => new AssemblyWithReference(
                a, false, new AssemblyReference(this._loginService.getTeam(), a.id, a.currentVersion.id)
            ))
        );
    }

    public getAssemblyById(assemblyId: string): Observable<Assembly> {
        return this.assemblyList.pipe(
            filter(x => x != null),
            map(l => l.find(z => z.id === assemblyId))
        );
    }

    public getCurrentAssemblyByAssemblyId(assemblyID: string): Assembly | undefined {
        return this.assemblyList.value.find(x => x.id === assemblyID);
    }

    public getAssemblyByReference(assemblyReference: AbstractAssemblyReference): Observable<AssemblyWithReference> {
        if (assemblyReference._type === SharedAssemblyReference.TYPE) {
            return this.getSharedAssembly((assemblyReference as SharedAssemblyReference).id).pipe(
                map(a => new AssemblyWithReference(a.assembly, true,
                    new SharedAssemblyReference(
                        a.share.team,
                        a.share.id,
                        a.share.ref,
                    )))
            )
        } else {
            return this.getAssembly((assemblyReference as AssemblyReference).id)
        }
    }

    public getAssembly(assemblyId: UUID): Observable<AssemblyWithReference> {
        return this._httpClient.get<Assembly>(this.env.environment.api + this.assemblyEndpoint + '/' + assemblyId.toString())
            .pipe(
                map(a => new AssemblyWithReference(
                    a, false, new AssemblyReference(this._loginService.getTeam(), a.id, a.currentVersion.id)
                ))
            );
    }

    public getSharedAssembly(share: UUID): Observable<AssemblyWithShare> {
        return this._httpClient.get<AssemblyWithShare>(this.env.environment.api + '/assembly/shares/' + share.toString())
    }

    public getPreviewForEither(ref: AssemblyWithReference, ts: boolean = false): string {
        const assembly = ref.assembly;
        return this.getPreview(assembly, ts); // TODO get the preview from the share if it is a shared assembly
    }

    public getPreview(assembly: Assembly, ts: boolean = false) {
        if (assembly && assembly.preview) {
            if (assembly.preview.startsWith(this.env.environment.files)) {
                return assembly.preview;
            } else {
                let s = this.env.environment.files +
                    '/assembly/' +
                    assembly.id +
                    '/versions/' +
                    assembly.currentVersion?.id +
                    '/' +
                    assembly.preview;

                if (ts) {
                    s = s + '&ts=' + Date.now();
                }

                return s;
            }
        } else {
            return '';
        }
    }

    public createAssembly(a: Assembly): Observable<Assembly> {
        return this._httpClient.post<any>(this.env.environment.api + this.assemblyEndpoint, a)
            .pipe(
                tap(ass => this.addAssembly(ass, this))
            );
    }

    public createAssemblyWithFiles(files: FileSystemFileEntry[], name?: string): Observable<Assembly> {

        const params = {};

        return resolveFiles(files)
            .pipe(
                flatMap(systemfiles => {
                        const formData: FormData = new FormData();
                        systemfiles.forEach(file => {
                            formData.append(file.name, file);
                        });

                        if (name) {
                            formData.append('name', name);
                        }

                        return this._httpClient.post<Assembly>(this.env.files(this.assemblyEndpoint, [], params), formData)
                            .pipe(
                                tap(ass => this.addAssembly(ass, this))
                            );
                    }
                )
            );


    }

    public removeAssemblyByID(assembly: string): Observable<any> {
        return this._httpClient.delete<any>(this.env.environment.api + this.assemblyEndpoint + '/' + assembly).pipe(
            tap(p => {
                const ass = this.assemblyList.getValue();
                ass.splice(this.indexOfID(ass, assembly), 1);
                this.assemblyList.next(ass);
            })
        );
    }

    public removeAssembly(a: Assembly): Observable<any> {
        return this._httpClient.delete<any>(this.env.environment.api + this.assemblyEndpoint + '/' + a.id).pipe(
            tap(p => {
                const ass = this.assemblyList.getValue();
                ass.splice(this.indexOf(ass, a), 1);
                this.assemblyList.next(ass);
            })
        );
    }

    openAssembly(assembly: Assembly): void {
        if (assembly.preview) {
            this._router.navigate(['project', assembly.gid, 'pcb']);
        } else {
            this._router.navigate(['project', assembly.gid, 'files']);
        }
    }

    deleteAssembly(a: Assembly): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '50vw';
        dialogConfig.maxWidth = '600px';
        dialogConfig.data = {
            message: this._translocoService.translate('General-DO-YOU-REALLY-WANT-TO-DELETE-THIS-ASSEMBLY'),
            yes_text: this._translocoService.translate('General-YES'),
            no_text: this._translocoService.translate('General-NO')
        };

        const dialogRef = this.confirmDialog.open(DeleteConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._fuseProgressBarService.show();

                this.removeAssembly(a).subscribe(
                    p => this._fuseProgressBarService.hide(),
                    error => {
                        this._snackBarService.openSnackBar(this._translocoService.translate('Snackbar-ERROR-OCCURED-SORRY-BUT-THE-DESIRED-ASSEMBLY-COULD-NOT-BE-REMOVED'), this._translocoService.translate('General-CLOSE-CAPS'));
                    }
                );
            }
        });
    }

    deleteAssembliesByID(assemblies: string[]): void {
        this._fuseProgressBarService.show();
        assemblies.forEach(v => {
            this.removeAssemblyByID(v).subscribe();
        });
        this._fuseProgressBarService.hide();
    }

    deleteAssemblies(assemblyList: Assembly[]): void {
        this._fuseProgressBarService.show();
        assemblyList.forEach(ass => {
            this.removeAssembly(ass).subscribe();
        });
        this._fuseProgressBarService.hide();
    }


    public loadAssemblies(): Observable<PaginationListResult<Assembly>> {
        return this._httpClient.get<PaginationListResult<Assembly>>(this.env.environment.api + this.assemblyEndpoint);
    }

    public getFilteredSharedAssemblies(page: number, pagesize: number, filter: string[], sort?: string, asc?: boolean): Observable<PaginationListResult<AssemblyWithShare>> {
        this._fuseProgressBarService.show();

        const params = {
            'page': page.toString(),
            'pagesize': pagesize.toString(),
            'filter': filter.join(',')
        };

        if (sort) {
            params['sort'] = sort + '=' + (asc ? 'asc' : 'desc');
        }

        return this._httpClient.get<PaginationListResult<AssemblyWithShare>>(this.env.api(this.sharesEndpoint, [], params)).pipe(tap(assList => {
            this._fuseProgressBarService.hide();
            this.sharedAssemblyList.next(assList.results);
        }));
    }

    public getAssemblyShareById(share: UUID): Observable<AssemblyWithShare> {
        console.log("getSharedAssemblyById", share, this.sharedAssemblyList.getValue())
        return this.sharedAssemblyList.pipe(
            filter(x => x != null),
            map(l => l.find(z => z.share.id === share))
        )
    }

    public getFilteredAssemblies(page: number, pagesize: number, filter: string[], sort?: string, asc?: boolean): Observable<PaginationListResult<Assembly>> {
        this._fuseProgressBarService.show();

        const params = {
            'page': page.toString(),
            'pagesize': pagesize.toString(),
            'filter': filter.join(',')
        };

        if (sort) {
            params['sort'] = sort + '=' + (asc ? 'asc' : 'desc');
        }

        return this._httpClient.get<PaginationListResult<Assembly>>(this.env.api(this.assemblyEndpoint, [], params)).pipe(tap(assList => {
            this._fuseProgressBarService.hide();
            this.assemblyList.next(assList.results);
        }));
    }


    // "name"
    // "gid"
    // "customer"
    // "creator"
    // "assignee"
    // "description"
    // "project"
    // "uiStatusSeverity"
    // "uiStatusMessage"


    indexOf(assemblies: Assembly[], assembly: Assembly): any {
        return assemblies.findIndex(a => a.id === assembly.id);
    }

    indexOfID(assemblies: Assembly[], assembly: string): any {
        return assemblies.findIndex(a => a.id === assembly);
    }

    setStateForAssemblies(selected: Assembly[], selectedState: string): void {
        selected.forEach(ass => {
            this.setStateForSingleAssembly(ass.id, selectedState, false);
        });
    }

    setStateForSingleAssembly(id: string, selectedState: string, isShare: boolean): void {
        const newState = new UIStatus(selectedState);
        // 'severity': 'finished',
        // 'message': selectedState

        if (isShare) {
            const assList: AssemblyWithShare[] = this.sharedAssemblyList.getValue();

            this.sharedAssemblyList.next(assList.map(ass => {
                if (ass.share.id === id) {
                    ass.share.information.uiStatus = newState;
                    this.shareUpdated.next(ass);
                    return ass;
                } else {
                    return ass;
                }
            }));

            this._httpClient.put(this.env.environment.api + '/assembly/shares/' + id + '/uiStatus', newState).subscribe();

        } else {
            const assList = this.assemblyList.getValue();

            this.assemblyList.next(assList.map(ass => {
                if (ass.id === id) {
                    ass.information.uiStatus = newState;
                    this.assemblyUpdated.next(ass);
                    return ass;
                } else {
                    return ass;
                }
            }));

            this._httpClient.put(this.env.environment.api + '/assembly/assemblies/' + id + '/uiStatus', newState).subscribe();
        }
    }

    public doSharedAssemblyUpdate(a: AssemblyWithShare, update: AssemblyUpdate): Observable<any> {
        return this._httpClient.put<any>(this.env.environment.api + this.shareEndpoint + '/' + a.share.id, update)
    }

    public doAssemblyUpdate<A>(a: Assembly, update: AssemblyUpdate): Observable<any> {
        return this._httpClient.put<any>(this.env.environment.api + this.assemblyEndpoint + '/' + a.id, update).pipe(tap(a => {
            const assList = this.assemblyList.getValue();
            this.assemblyUpdated.next(a);

            this.assemblyList.next(assList.map(ass => {
                if (ass.id === a.id) {
                    return a;
                } else {
                    return ass;
                }
            }));
        }));
    }

    public updateOpenAssemblyCount(): void {
        this._navInfoService.getNumberOfOpenAssemblies().subscribe(assemblies => {
            // todo fuse
            // if (assemblies.length > 0) {
            //     this._navService.updateNavigationItem('project-overview', {
            //         badge: {
            //             title: assemblies.length,
            //             bg: '#039be5'
            //         }
            //     });
            //
            // } else {
            //     this._navService.updateNavigationItem('project-overview', {});
            // }
        });
    }

    private subscribeUpdate(): void {
        this.ws = new WebSocket(
            this.env.environment.ws + this.assemblyEndpoint + '/stream?k=' + this._loginService.getCurrentAuthToken()
        );

        const me = this;

        this.ws.onclose = evt => {
            if (!evt.wasClean) {
                me.subscribeUpdate()
            }
        }

        this.ws.onmessage = function (event: MessageEvent): void {
            const data: StreamMessage = JSON.parse(event.data);

            if (data.t === 'file') {
                const files = data.m as FilesAddedMessage;
                const assID = files.assembly;
                let currentList = me.assemblyList.getValue();
                let updated: Assembly;
                currentList = currentList.map(cf => {
                    if (cf.id === assID) {
                        cf.currentVersion.files = files.file;
                        updated = cf;
                        return cf;
                    } else {
                        return cf;
                    }
                });

                if (updated) {
                    me.assemblyUpdated.next(updated);
                }
                me.assemblyList.next(
                    currentList
                );
            }
            if (data.t === 'assembly-ui') {
                if (data.m._type.endsWith('AssemblyUIStateUpdatedMessage')) {
                    const uistate = data.m['version'] as UIStatus;
                    const assID = data.m['assembly'];


                    let currentList = me.assemblyList.getValue();
                    let updated: Assembly;
                    currentList = currentList.map(cf => {
                        if (cf.id === assID) {
                            cf.information.uiStatus = uistate;
                            updated = cf;
                            return cf;
                        } else {
                            return cf;
                        }
                    });

                    if (updated) {
                        me.assemblyUpdated.next(updated);
                    }
                    me.updateOpenAssemblyCount();
                    me.assemblyList.next(
                        currentList
                    );
                }

            }
            if (data.t === 'assembly-preview') {
                let currentList = me.assemblyList.getValue();
                const f = data.m as AssemblyPreviewMessage;

                let updated = null;
                currentList = currentList.map(cf => {
                    if (cf.id === f.assembly) {
                        cf.preview = f.preview + '&ts=' + Date.now();
                        updated = cf;
                        return cf;
                    } else {
                        return cf;
                    }
                });
                me.assemblyUpdated.next(updated);
                me.assemblyList.next(currentList);
            }
            if (data.t === 'lifecycle') {
                if (data.m._type.endsWith(AssemblyStageUpdateMessage.jsonName)) {
                    const stageUpdate = data.m as AssemblyStageUpdateMessage;

                    let currentList = me.assemblyList.getValue();
                    me.lifecycleUpdates.next(stageUpdate);
                    const list = me.updateAssemblyStages(currentList, stageUpdate, me);
                    me.assemblyList.next(list);
                }
            }
            if (data.t === 'assembly') {
                if (data.m._type.endsWith('AssemblyAddedMessage')) {
                    // not needed until now

                }
                if (data.m._type.endsWith('AssemblyUpdatedMessage')) {
                    const f = data.m as AssemblyMessage;

                    let currentList = me.assemblyList.getValue();

                    currentList = currentList.map(cf => {
                        if (cf.id === f.assembly.id) {
                            f.assembly.currentVersion = cf.currentVersion;
                            return f.assembly;
                        } else {
                            return cf;
                        }
                    });

                    if (f.assembly.status === 'deleted') {
                        const index = currentList.findIndex(a => a.id === f.assembly.id);
                        if (index >= 0) {
                            currentList.splice(index, 1);
                        }
                        me.assemblyRemoved.next(f.assembly);
                    } else {
                        me.assemblyUpdated.next(f.assembly);
                    }
                    me.assemblyList.next(
                        currentList
                    );
                }
                if (data.m._type.endsWith('AssemblyVersionMessage')) {
                    const f = data.m as AssemblyVersionMessage;
                    f.assembly.currentVersion = f.version;

                    me.addAssembly(f.assembly, me);

                    me.assemblyAdded.next(f.assembly);
                }
            }

        };
    }

    private updateAssemblyStages(assemblies: Assembly[], message: AssemblyStageUpdateMessage, self: AssemblyListService): Assembly[] {
        return assemblies.map(assembly => {
            if (assembly.id === message.assembly) {
                const ass = assembly;
                let newCycles = ass.currentVersion.lifecycles;
                message.lifecycle.forEach(lc => {
                    let updated = false;
                    newCycles = newCycles.map(c => {
                        if (c.name === lc.name) {
                            updated = true;
                            return lc;
                        } else {
                            return c;
                        }
                    });

                    if (!updated) {
                        newCycles.push(lc);
                    }

                    ass.currentVersion.lifecycles = newCycles;

                });

                self.assemblyUpdated.next(ass);
                return ass;
            } else {
                return assembly;
            }
        });

    }

    private addAssembly(ass: Assembly, me: AssemblyListService): void {
        const currentList = me.assemblyList.getValue();

        const ex = currentList.findIndex(v => v.id === ass.id);
        if (ex >= 0) {
            currentList.splice(ex, 1);
        }

        const l = [ass].concat(currentList);

        me.assemblyList.next(
            l
        );
    }


}
