import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropComponent, NgxFileDropEntry } from 'ngx-file-drop';
import { AssemblyService } from '../../../../assembly.service';
import { PcbFileUploadService } from '../pcb-file-upload.service';
import { NGXLogger } from 'ngx-logger';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PcbFileManagerSupportedFilesDialogComponent } from '../pcb-file-manager-supported-files-dialog/pcb-file-manager-supported-files-dialog.component';
import { Subject } from 'rxjs';
import { SnackbarService } from '../../../../../../../common-ui/snackbar/snackbar.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'pcbfile-drop',
    templateUrl: './pcbfile-drop.component.html',
    styleUrls: ['./pcbfile-drop.component.scss']
})
export class PCBFileDropComponent implements OnInit {


    @Input() showTiny = false;
    @Input() disabled = false;

    @ViewChild(NgxFileDropComponent, { static: true }) ngxFileDrop;
    public files: NgxFileDropEntry[] = [];


    constructor(public _assService: AssemblyService,
        public dialog: MatDialog,
        private _fileUploadService: PcbFileUploadService,
        private _snackBarService: SnackbarService,
        private logger: NGXLogger,
        private _translocoService: TranslocoService) {
    }

    ngOnInit(): void {
    }

    public dropped(files: NgxFileDropEntry[]): void {
        this.files = files;


        const alldrops = files
            .filter(f => f.fileEntry.isFile && this._fileUploadService.isSupportedFileType(f));


        const resolvedFiles: File[] = [];
        const added: Subject<File> = new Subject<File>();

        added.subscribe(a => {
            resolvedFiles.push(a);
            if (resolvedFiles.length === alldrops.length) {
                added.complete();

                // resolvedFiles.forEach(f => this._assService.addUploadingFile(f));

                this._fileUploadService
                    .uploadFiles(this._assService.assemblySubject.getValue().assembly, resolvedFiles)
                    .subscribe(data => {
                        // this.logger.debug('this is data ');
                        // this.logger.debug(data);
                        data.forEach(file => this._assService.addUploadingFile(file['name']))
                    });
            }
        });

        let i = 0;
        alldrops
            .map(d => d.fileEntry as FileSystemFileEntry)
            .forEach(drop => {
                const _i = i;
                i++;
                drop.file(res => added.next(res));
            });

        // for (const droppedFile of files) {
        //     // Is it a file?
        //     if (droppedFile.fileEntry.isFile) {
        //
        //
        //         if (this._fileUploadService.isSupportedFileType(droppedFile)) {
        //             const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        //             fileEntry.file((file: File) => {
        //
        //             });
        //         } else {
        //             this.openFileNotSupportedMessage();
        //         }
        //
        //     } else {
        //
        //         const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        //         this.logger.debug(droppedFile.relativePath, fileEntry);
        //     }
        // }
    }


    public fileOver(event): void {
        // this.logger.debug(event);
    }

    public fileLeave(event): void {
        // this.logger.debug(event);
    }

    public dropZoneClicked(event): void {
        this.logger.debug('clicked');
        this._assService.getCurrentAssembly().subscribe(ass => {
            if (!ass.currentVersion.released) {
                this.ngxFileDrop.openFileSelector(event);
            }
        });
    }

    public showSupportedFilesDialog(): void {

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.width = '40vw';
        dialogConfig.maxWidth = '600px';

        this.dialog.open(PcbFileManagerSupportedFilesDialogComponent, dialogConfig);
    }

    public openFileNotSupportedMessage(): void {
        this._snackBarService.openSnackBar(this._translocoService.translate("General-SORRY-THIS-FILE-TYPE-IS-NOT-SUPPORTED"), this._translocoService.translate('General-CLOSE-CAPS'));
    }

}
