import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {first, tap} from 'rxjs/operators';
import {lastValueFrom, merge} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {EnvironmentService} from '../common/env/environment.service';
import {StackrateEnvironment, StackratePlatform} from '../common/env/environment-api';

@Injectable({
    providedIn: 'root'
})
export class ConfInitializerService {


    constructor(private http: HttpClient, private env: EnvironmentService,
                private logger: NGXLogger) {
    }

    load(base?: string): Promise<any> {
        console.log('loading environment...');

        const promises = merge(
            this.http.get<StackrateEnvironment>((base || '') + 'assets/config/config.json').pipe(first(), tap(e => {
                this.logger.debug('loading environment: ', e);
                this.env.environment = e;
            })),
            this.http.get<StackratePlatform>((base || '') + 'assets/config/platform.json').pipe(first(), tap(e => {
                this.logger.debug('loading platform: ', e);
                this.env.platform = e;
            }))
        );

        return lastValueFrom(promises);
    }
}
