import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {EmptyLayoutComponent} from './empty.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SharedModule} from '../../../core/shared/shared.module';

@NgModule({
    declarations: [
        EmptyLayoutComponent
    ],
    imports: [
        SharedModule,
        RouterModule,
        FlexLayoutModule
    ],
    exports: [
        EmptyLayoutComponent
    ]
})
export class EmptyLayoutModule {
}
